import { GalleryImageParentType, GalleryImageVM } from '../../shared/gallery/gallery-image-vm';
import { sortBySortOrder } from '../helpers/sorters';
import { ActionSet } from './action-set';
import { ElevationVM } from './elevation-vm';
import { FavoritesSession } from './favorites-session';
import { FloorVM } from './floor-vm';
import { FloorplanVM } from './floorplan-vm';
import { InventoryHomeVM } from './inventory-home-vm';

import { ILot, LogicState, MediaType, sortByOrderNullable } from '@ml/common';

export class EffectiveFloorplan extends FloorplanVM {
  Id: number;

  IsInventoryHome = false;
  InventoryHomeId: number;
  IsReversed = false;

  CommunityId: number;
  PublicName: string;
  Title: string;
  IsActive: boolean;
  Elevations: ElevationVM[] = [];
  NeighborhoodId: number;
  VirtualDirectory: string;
  SortOrder: number;
  BaseTotalSquareFeet: number;
  NumberOfBedrooms: number;
  NumberOfFullBaths: number;
  NumberOfHalfBaths: number;
  NumberOfGarages: number;
  GarageCarSize: number;
  OutdoorSquareFeet: number;
  NumberOfDens: number;
  Price: number;
  Floors: FloorVM[] = [];
  Description: string;
  AllTagNames: string;

  IsFavorited: boolean;
  IsSelected: boolean;
  AssociatedLots: ILot[] = [];
  ElevationGalleryImages: GalleryImageVM[] = [];
  NeighborhoodName: string;
  DefaultFloor: FloorVM;
  FloorCount: string;
  BedCount: string;
  BathCount: string;
  GarageCount: string;
  GarageSizeCount: string;
  DenCount: string;
  HalfBathCount: string;
  SqFtCount: string;
  PriceDisplay: string;
  MoveInDate: string;

  IhOverrideNumberOfBedrooms = false;
  IhOverrideNumberOfFullBaths = false;
  IhOverrideNumberOfHalfBaths = false;
  IhOverrideNumberOfGarages = false;
  IhOverrideGarageCarSize = false;

  private name: string;
  get Name(): string {
    return this.name;
  }

  set Name(value: string) {
    this.name = value;
  }

  constructor() {
    super();
  }

  HydrateFromFloorplan(floorplan: FloorplanVM): EffectiveFloorplan {
    this.Name = floorplan.Name;
    this.Id = floorplan.FloorplanId;

    Object.assign(this, floorplan);

    this.DefaultFloor = floorplan.DefaultFloor;

    return this;
  }

  HydrateFromInventoryHome(
    inventoryHome: InventoryHomeVM,
    floorplan: FloorplanVM,
    favoritesSession: FavoritesSession
  ): EffectiveFloorplan {
    this.Id = inventoryHome.InventoryHomeId;
    this.InventoryHomeId = inventoryHome.InventoryHomeId;
    this.IsInventoryHome = true;
    this.IsReversed = inventoryHome.IsReversed;
    this.MoveInDate = inventoryHome.MoveInDate;

    Object.assign(this, floorplan);

    // remove any range values from the FP since IHs won't have ranges
    for (const key in this) {
      if (this.hasOwnProperty(key) && key.endsWith('Range')) delete this[key];
    }

    for (const key in inventoryHome) {
      if (
        this.hasOwnProperty(key) &&
        inventoryHome[key] !== undefined &&
        inventoryHome[key] !== null
      ) {
        if (this.hasOwnProperty(`IhOverride${key}`)) {
          this[`IhOverride${key}`] = true;
        }
        this[key] = inventoryHome[key];
      }
    }

    this.Name = floorplan.Name;
    this.DefaultFloor = floorplan.DefaultFloor;
    this.BaseTotalSquareFeet = inventoryHome.TotalSquareFeet;
    this.AssociatedLots = inventoryHome.AssociatedLots;

    this.Floors = this.Floors.map(floor => {
      floor.ActionSets = floor.ActionSets.map(
        a =>
          new ActionSet(
            a,
            inventoryHome.ActionSetIds.includes(a.ActionSetId)
              ? LogicState.ON
              : LogicState.DISABLED_OFF
          )
      ).sort(sortBySortOrder);
      return floor;
    });

    const isCustomElevation = !!inventoryHome.CustomElevationFilename;
    if (isCustomElevation) {
      const customElevation = new ElevationVM();
      customElevation.Title = inventoryHome.CustomElevationName;
      customElevation.ImageFilename = inventoryHome.CustomElevationFilename;
      this.Elevations = [customElevation];
    } else if (inventoryHome.ElevationId) {
      this.Elevations = [
        floorplan.Elevations.find(elevation => elevation.ElevationId === inventoryHome.ElevationId)
      ];
    }

    this.Elevations.forEach(
      elevation =>
        (elevation.IsFavorited = favoritesSession.containsInventoryHomeElevation(
          elevation.ElevationId,
          this.Id
        ))
    );

    // Per business rules - if IH has a price then all related entities are considered inclusive to that, so null them out
    // PBI 52147
    if (typeof inventoryHome.Price === 'number') {
      this.Floors.flatMap(x => x.ActionSets).forEach(x => (x.Price = null));
      this.Elevations.forEach(x => (x.Cost = null));
      this.AssociatedLots.forEach(x => {
        x.Cost = null;
        x.TotalCost = null;
        x.Premium = null;
        x.OptionsPremium = null;
      });
    }

    this.ElevationGalleryImages = this.Elevations.map(elevation =>
      new GalleryImageVM(
        isCustomElevation ? inventoryHome.BaseUrl : floorplan.BaseUrl
      ).HydrateFromElevation(
        elevation,
        GalleryImageParentType.InventoryHome,
        this.Id,
        isCustomElevation
      )
    ).sort(sortByOrderNullable);

    this.Media.forEach(
      media => (media.IsFavorited = favoritesSession.containsInventoryHomeMedia(media.MediaId))
    );

    this.MediaGalleryImages = this.Media.filter(
      m => m.MediaType === MediaType.Unspecified || m.MediaType === MediaType.Static
    )
      .map(m =>
        new GalleryImageVM(this.BaseUrl).HydrateFromMedia(
          m,
          GalleryImageParentType.InventoryHome,
          this.Id
        )
      )
      .sort(sortByOrderNullable);

    return this;
  }
}

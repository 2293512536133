<modal [isShowing]="isOpen" (modalClose)="handleClose()">
    <form class="share-form" #shareForm="ngForm" (ngSubmit)="handleShare(shareForm)">
        <div class="row">
            <h3>Share this with someone.</h3>
            <i>Separate multiple email addresses with a comma. <icon class="frg-1" name="circle_i"></icon></i>
        </div>
        <div class="row">
            <label for="emails">Send To</label>
            <input type="text" multiple required [mlAutofocus]="true" id="emails" name="emails" [(ngModel)]="emailAddresses"
                   placeholder="Enter email addresses" [pattern]="regexPatterns.CommaSeparatedEmailAddresses" autocomplete="off" />
        </div>
        <div class="row">
            <label for="message">Your Message (Optional)</label>
            <textarea rows="4" id="message" name="message" [(ngModel)]="customMessage" placeholder="Why do you want to share this?"></textarea>
        </div>
        <icon-button text="Send" class="priority" [showArrow]="true" [disabled]="!emailAddresses || shareForm.invalid"></icon-button>
    </form>
</modal>
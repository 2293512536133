import { OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type Constructor<T> = new (...args: any[]) => T;

export class MixinRoot {}

/*
 * Automatically unsubscribe on ngOnDestory
 *
 * NOTE: If your component implements ngOnDestroy, you must manually call
 * super.ngOnDestroy() for this function to work. There may be a keyword coming
 * later to help: https://github.com/microsoft/TypeScript/issues/21388
 */
export const AutoUnsubscriber = Destroyable(MixinRoot);

function Destroyable<T extends Constructor<{}>>(Base: T) {
  return class Mixin extends Base implements OnDestroy {
    private readonly subscriptions: Subscription[] = [];
    private readonly unlisteners: (() => void)[] = [];
    private readonly unsubscribe$ = new Subject<void>();

    protected addSub(...sub: Subscription[]) {
      this.subscriptions.push(...sub);
    }

    protected addUnlistener(cb: () => void) {
      this.unlisteners.push(cb);
    }

    protected addObservable<O>(observable: Observable<O>): Observable<O> {
      return observable.pipe(takeUntil(this.unsubscribe$));
    }

    public ngOnDestroy() {
      this.subscriptions.forEach(x => x.unsubscribe());
      this.subscriptions.length = 0;

      this.unlisteners.forEach(x => x());
      this.unlisteners.length = 0;

      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
  };
}

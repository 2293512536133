import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { GalleryImageType, GalleryImageVM } from '../../shared/gallery/gallery-image-vm';
import { FavoriteType } from '../../utility/helpers/favorite-types.enum';
import { EffectiveFloorplan } from '../../utility/models/effective-floorplan';
import { FloorVM } from '../../utility/models/floor-vm';
import { FloorplanVM } from '../../utility/models/floorplan-vm';
import { GraphicsModel } from '../../utility/models/graphics-model';
import { InventoryHomeVM } from '../../utility/models/inventory-home-vm';
import { FavoritesService } from '../../utility/services/favorites.service';
import { FloorArtService } from '../../utility/services/floor-art.service';
import { FloorplanRulesService } from '../../utility/services/floorplan-rules.services';
import { NavigationService, PageTitle } from '../../utility/services/navigation.service';
import { SettingsService } from '../../utility/services/settings.service';
import { ThemeService } from '../../utility/services/theme.service';
import { ViewModelFactoryService } from '../../utility/services/vm-factory.service';
import { ScrollingGalleryComponent } from '../scrolling-gallery/scrolling-gallery.component';
import { ISvgInjectorLoaded } from '../svg-injector.directive';

import { ILot } from '@ml/common';

@Component({
  selector: 'floorplan-card',
  templateUrl: './floorplan-card.component.html',
  styleUrls: ['./floorplan-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FloorplanCardComponent implements OnInit, OnChanges {
  @Input() floorplan: FloorplanVM;
  @Input() inventoryHome: InventoryHomeVM;
  @Input() openByDefault = false;
  @Input() allowCollapse = true;
  @Input() currentLot: ILot;
  @Input() showIfpButton = true;
  @Input() showNeighborhoodName = false;
  @Input() forceNonCollapsibleSpecs = false;
  @Output() selectLot = new EventEmitter<ILot>();
  @Output() selectAllLots = new EventEmitter();
  @Output() galleryChanged = new EventEmitter();
  @Output() floorplanNavigate = new EventEmitter<EffectiveFloorplan>();
  featuresSettingValue: string;
  card: EffectiveFloorplan;
  infoDescriptionTitle: string;
  showFloorplanFeatures = true;
  galleryImages: GalleryImageVM[] = [];
  floorPlanLabel: string;
  lotLabel: string;
  showNeighborhoodIcon = true;
  useLetterbox = false;
  fullDetailsUrl: string;
  svgLoadCount = 0;
  graphicsModel = new GraphicsModel();
  doneWithHiddenSvgs = false;
  forceLoadIHSvgs = false;
  staticMediaImages: GalleryImageVM[] = [];
  hasExternalMedia = false;

  constructor(
    private navigator: NavigationService,
    private settingsService: SettingsService,
    private favorites: FavoritesService,
    private themeService: ThemeService,
    private vmFactory: ViewModelFactoryService,
    private floorArtService: FloorArtService,
    private floorplanRulesService: FloorplanRulesService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.featuresSettingValue = this.settingsService.get('OptionsAndFeatures');

    this.infoDescriptionTitle = this.settingsService.get('InfoDescTitle');

    this.lotLabel = this.themeService.getLotLabel(true);

    switch (this.featuresSettingValue) {
      case 'Description':
        this.showFloorplanFeatures = !!this.card.Description?.length;
        break;
      case 'Options List':
        this.showFloorplanFeatures = this.card.Floors.some(x => x.ActionSets.length);
        break;
      default:
        this.showFloorplanFeatures = false;
    }

    this.floorPlanLabel = this.themeService.getFloorPlanLabel();

    this.showNeighborhoodIcon = !!this.settingsService.get('ShowNeighborhoodLabelIcon');

    this.useLetterbox = this.settingsService.get('SmallGalleryWideScreenLetterbox');
  }

  ngOnChanges() {
    this.initializeCard();
  }

  private initializeCard() {
    if (this.inventoryHome) {
      this.card = this.vmFactory.getEffectiveFloorplan(
        this.inventoryHome.FloorPlanId,
        this.inventoryHome.InventoryHomeId
      );
    } else {
      this.card = this.vmFactory.getEffectiveFloorplan(this.floorplan.FloorplanId, null, {
        filteredElevationIds: this.currentLot?.MappedElevationIds.map(x => x.Id)
      });
    }
    // Images only in the scrollable gallery modal
    if (this.card.MediaGalleryImages.length) {
      this.staticMediaImages = this.card.MediaGalleryImages.filter(mgi => {
        return !mgi.IsIframe && !mgi.IsVideo;
      });
    }

    if (this.floorplan.Media.some(x => x.ExternalUrl) && !this.staticMediaImages.length) {
      this.hasExternalMedia = true;
    }

    this.setupGalleryImages();

    //setup customdata image and url for inventory home
    if (!this.showIfpButton) {
      if (this.inventoryHome && this.inventoryHome.CustomData) {
        if (this.inventoryHome.CustomData.GalleryUrl) {
          const mediaVM = new GalleryImageVM('');
          mediaVM.FullUrl = this.inventoryHome.CustomData.GalleryUrl;
          mediaVM.ThumbnailUrl = this.inventoryHome.CustomData.GalleryUrl;
          this.galleryImages = [mediaVM, ...this.card.FloorGalleryImages];
        }
        if (this.inventoryHome.CustomData.ExternalUrl)
          this.fullDetailsUrl = this.inventoryHome.CustomData.ExternalUrl;
      }
    }
  }

  private setupGalleryImages() {
    const showMediaInGallery = this.settingsService.get('ShowMediaInGallery');

    this.galleryImages = [
      ...this.card.ElevationGalleryImages,
      ...(showMediaInGallery ? this.card.MediaGalleryImages : []),
      ...(this.card.IsInventoryHome ? [] : this.card.FloorGalleryImages)
    ];

    // if nothing exists for an IH, we need to insert something so the onFloorLoad event is able to fire and complete the process
    if (this.card.IsInventoryHome && this.galleryImages.length < 1) {
      this.forceLoadIHSvgs = true;
      this.galleryImages = [...this.card.FloorGalleryImages];
    }
  }

  handleGalleryImageModal() {
    this.dialog.open(ScrollingGalleryComponent, {
      data: {
        images: this.staticMediaImages,
        effectiveFp: this.card
      },
      panelClass: 'scrolling-gallery-modal'
    });
  }

  handleFavoriteToggle(isFavorited: boolean) {
    if (this.card.IsInventoryHome) this.toggleInventoryHomeFavorite(isFavorited);
    else this.toggleFloorPlanFavorite(isFavorited);
  }

  private toggleFloorPlanFavorite(isFavorited: boolean) {
    if (isFavorited) {
      this.favorites.addFloorlan(this.floorplan.FloorplanId);
    } else {
      this.favorites.removeFloorplan(this.floorplan.FloorplanId);
    }
  }

  private toggleInventoryHomeFavorite(isFavorited: boolean) {
    this.inventoryHome.IsFavorited = isFavorited;
    if (this.inventoryHome.IsFavorited) {
      this.favorites.addInventoryHome(this.inventoryHome.InventoryHomeId);
    } else {
      this.favorites.removeInventoryHome(this.inventoryHome.InventoryHomeId);
    }
  }

  handleNavigateToFloorplan() {
    this.floorplanNavigate.emit(this.card);
    this.navigator.go({
      PageTitle: PageTitle.FloorPlans,
      RouteParams: { FloorplanId: this.floorplan.FloorplanId },
      QueryParams: { inventoryHomeId: this.inventoryHome?.InventoryHomeId }
    });
  }

  handleImageFavoriteToggle(image: GalleryImageVM) {
    if (image.IsFavorited)
      this.favorites.add({
        favoriteType:
          image.Type === GalleryImageType.Elevation ? FavoriteType.Elevation : FavoriteType.Media,
        id: image.Id,
        floorplanId: this.floorplan.FloorplanId,
        inventoryHomeId: this.inventoryHome?.InventoryHomeId
      });
    else {
      this.favorites.remove(
        image.Type === GalleryImageType.Elevation ? FavoriteType.Elevation : FavoriteType.Media,
        image.Id,
        this.inventoryHome?.InventoryHomeId
      );
    }
  }

  handleDomChange() {
    this.galleryChanged.emit();
  }

  handleClickFullDetails() {
    window.open(this.fullDetailsUrl, '_blank');
  }

  onFloorLoad(floor: FloorVM, svgData?: ISvgInjectorLoaded) {
    if (svgData) {
      const svgElement = svgData.Parent.querySelector('svg');
      this.floorArtService.addFloorSvg(floor, svgElement as SVGElement);
    }

    this.svgLoadCount++;
    if (this.svgLoadCount === this.card.Floors.length) {
      this.graphicsModel = { ...this.graphicsModel, IsReversed: this.card.IsReversed };
      this.floorplanRulesService.initialize(this.card);
      this.floorArtService.initializeFloorPlanArt(this.card);

      setTimeout(() => {
        if (this.forceLoadIHSvgs) {
          this.galleryImages = [];
          this.forceLoadIHSvgs = false;
        }
        this.card.Floors.forEach(f => {
          // for IHs, we only want to show optional floors if they are enabled
          if (
            !f.IsOption ||
            !this.floorplanRulesService.isFloorDisabled(f.FloorId, f.FloorplanId)
          ) {
            const svg = this.floorArtService.getFloorSvg(f.FloorId);
            if (svg) {
              const vm = new GalleryImageVM('').HydrateFromFloor(
                f,
                svg.parentElement.parentElement.outerHTML
              );
              this.galleryImages.push(vm);
            }
          }
        });

        this.doneWithHiddenSvgs = true;
      }, 0);
    }
  }
}

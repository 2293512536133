<header>
    <section class="title-container">
        <h1>Select a {{theme.fpLabel}}</h1>
        <h2>
            <icon name="community"></icon>
            {{communityName}}
        </h2>
    </section>

    <button *ngIf="isWithinModal" class="close-btn" (click)="dialogRef.close()">
        <icon name="x"></icon>
    </button>
</header>

<section class="cards-container">
    <ng-container *ngIf="showLotFilter && allNeighborhoodsWithLots.length">
        <p class="label">Filter by {{theme.lotLabel}}</p>
        <mat-select panelClass="hc-styles lot-select" [(value)]="dropdownSelectedLot" aria-label="Filter by lot"
                    (selectionChange)="handleLotSelectChange($event.value)" placeholder="All {{theme.lotLabel}}s">
            <mat-select-trigger>
                <div *ngIf="+currentBuild.Lot?.EntityId === dropdownSelectedLot?.LotId" class="bubble">
                    <icon name="check"></icon>
                </div>
                {{dropdownSelectedLot?.Title}}
            </mat-select-trigger>
            <mat-option>All {{theme.lotLabel}}s</mat-option>
            <mat-optgroup *ngFor="let hood of allNeighborhoodsWithLots" [label]="hood.DisplayName">
                <mat-option *ngFor="let lot of hood.Lots" [value]="lot">
                    <div *ngIf="+currentBuild.Lot?.EntityId === lot.LotId" class="bubble">
                        <icon name="check"></icon>
                    </div>
                    {{$any(lot).Title}}
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </ng-container>

    <div class="col" *ngFor="let floorplan of filteredFloorplansAndIH; index as index; trackBy: trackByFloorPlanId">
        <hc-fp-card [effectiveFP]="floorplan" [indexInList]="index + 1" [listTotal]="filteredFloorplansAndIH.length" [build]="currentBuild"
                    (floorplanSelect)="handleFloorplanSelect(floorplan)" (floorplanChange)="handleFloorplanChange()"></hc-fp-card>
    </div>
</section>
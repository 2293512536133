import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  constructor() {}

  getItem(key: string): string {
    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      console.error('Error getting item from session storage', e);
    }
  }

  setItem(key: string, value: string): void {
    try {
      sessionStorage.setItem(key, value);
    } catch (e) {
      console.error('Error setting item in session storage', e);
    }
  }

  removeItem(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      console.error('Error removing item in session storage', e);
    }
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { OfflineHelp } from '../../utility/models/offline-help';

@Component({
  selector: 'offline-help',
  templateUrl: './offline-help.component.html',
  styleUrls: ['./offline-help.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfflineHelpComponent implements OnInit {
  @Input() offlineHelp: OfflineHelp;
  @Output() closeHelp = new EventEmitter<boolean>();
  helpUrl = '';

  constructor() {}

  ngOnInit(): void {
    // eslint-disable-next-line max-len
    this.helpUrl = `https://omnihelp.ml3ds-icon.com/help-request/${this.offlineHelp.Id}/1?offlineSCP=true&community=${this.offlineHelp.CommunityName}`;
  }

  close() {
    this.closeHelp.emit(true);
  }
}

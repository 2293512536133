import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    Input,
    OnInit,
    ViewEncapsulation
} from '@angular/core';

import { PushToGoogleTagManager } from '../../utility/helpers/google-tag-manager';
import { FloorVM } from '../../utility/models/floor-vm';
import { ThemeService } from '../../utility/services/theme.service';

import { LogicState } from '@ml/common';

@Component({
  selector: 'floorplan-features',
  templateUrl: './floorplan-features.component.html',
  styleUrls: ['./floorplan-features.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloorplanFeaturesComponent implements OnInit {
  @HostListener('keydown', ['$event']) onKeydown(event) {
    if (event.key === 'Escape' && this.isOpen) this.toggleState();
  }
  @Input() floors: FloorVM[] = [];
  @Input() featuresSettingValue: string;
  @Input() description?: string;
  @Input() infoDescriptionTitle: string;
  @Input() isInventoryHome: boolean;
  isOpen = false;
  showDescription: boolean;
  showOptions: boolean;
  optionsByFloor = [];
  noOptionsMessage: string;

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.showOptions = this.featuresSettingValue === 'Options List';
    this.showDescription = this.featuresSettingValue === 'Description';
    this.optionsByFloor = this.floors
      .map(f => ({
        Title: f.Title,
        ActionSets: f.ActionSets.filter(as => !this.isInventoryHome || as.State === LogicState.ON)
      }))
      .filter(f => f.ActionSets.length);

    if (!this.optionsByFloor.length)
      this.noOptionsMessage = 'Standard ' + this.themeService.getFloorPlanLabel();
  }

  toggleState() {
    this.isOpen = !this.isOpen;

    const floorplanId = this.floors[0]?.FloorplanId || null;
    PushToGoogleTagManager({
      eventName: 'ClickCardInfoDescription',
      eventType: 'App Click Interaction',
      extraData: {
        FloorPlanId: floorplanId
      }
    });
  }
}

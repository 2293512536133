import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'pan-control',
  templateUrl: './pan-control.component.html',
  styleUrls: ['./pan-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanControlComponent {
  @Input() moveStepInterval = 20;
  @Output() move = new EventEmitter<{ x: number; y: number }>();
  stepX = 0;
  stepY = 0;
  moveTypes = PositionMoveType;
  ariaLiveText = '';
  constructor(private cdr: ChangeDetectorRef) {}

  handleMove(moveType: PositionMoveType) {
    switch (moveType) {
      case PositionMoveType.Up: {
        this.move.emit({ x: 0, y: -this.moveStepInterval });
        break;
      }
      case PositionMoveType.Down: {
        this.move.emit({ x: 0, y: this.moveStepInterval });
        break;
      }
      case PositionMoveType.Left: {
        this.move.emit({ x: -this.moveStepInterval, y: 0 });
        break;
      }
      case PositionMoveType.Right: {
        this.move.emit({ x: this.moveStepInterval, y: 0 });
        break;
      }
      default:
        break;
    }
    // HACK -- reads message through aria live on button click
    this.ariaLiveText = `Map moved ${moveType}`;
    setTimeout(() => {
      this.ariaLiveText = '';
      this.cdr.markForCheck();
    }, 100);
  }
}

export enum PositionMoveType {
  Up = 'up',
  Down = 'down',
  Left = 'left',
  Right = 'right'
}

import { AnimationEvent } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { AnimationState, heightExpandCollapseAnimation } from '../../global/animations';
import { SettingsService } from '../../utility/services/settings.service';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [heightExpandCollapseAnimation(200)]
})
export class AccordionComponent implements OnInit, OnChanges {
  @HostBinding('class.sticky-header') @Input() hasStickyHeader = false;
  @Input() openByDefault = false;
  @Input() allowCollapse = true;
  @Input() isOpen = false;
  @Input() isHeaderVisible = true;
  @Input() ariaLabel = '';
  @Input() ignoreAccessibiliy = false; // I feel bad about this needed to allow lot svg preview to setup while accordion is collapsed
  @Input() leftSideExpander = false;
  @Output() isOpenChanged = new EventEmitter<boolean>();
  hasCustomToggleIcons = false;
  iconToOpen: string;
  iconToClose: string;
  animationState: string;
  isHidden = false;
  finalAriaLabel = '';

  constructor(private cdr: ChangeDetectorRef, private settingsService: SettingsService) {}

  ngOnInit() {
    this.isOpen = !this.allowCollapse || this.openByDefault;
    this.updateAnimationState();
    if (this.isOpen) {
      this.finalAriaLabel = `Collapse ${this.ariaLabel}`;
    } else {
      this.finalAriaLabel = `Expand ${this.ariaLabel}`;
    }
  }

  ngOnChanges() {
    this.updateAnimationState();
  }

  toggleOpen() {
    if (!this.allowCollapse) return;

    this.isOpen = !this.isOpen;
    this.updateAnimationState();

    if (this.isOpen) {
      this.finalAriaLabel = `Collapse ${this.ariaLabel}`;
    } else {
      this.finalAriaLabel = `Expand ${this.ariaLabel}`;
    }

    // if opening then immediately set isHidden here so that animation is ready to go smoothly
    if (this.isOpen) this.isHidden = false;

    this.cdr.detectChanges();
    this.isOpenChanged.emit(this.isOpen);
  }

  animationStartStop(event: AnimationEvent) {
    if (event.phaseName === 'start') {
      if (event.toState === AnimationState.Expanded) {
        this.isHidden = false;
      }
    } else {
      if (event.toState === AnimationState.Collapsed) {
        this.isHidden = true;
      }
    }
  }

  private updateAnimationState() {
    this.animationState = this.isOpen ? AnimationState.Expanded : AnimationState.Collapsed;
  }
}

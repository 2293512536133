import { ILotMapStatus } from '@ml/common';

export class StatusVM implements ILotMapStatus {
  LotMapStatusId: number;
  NeighborhoodId: number;
  Name: string;
  Description: string;
  ColorHexValue: string;
  FocusColorHexValue: string;
  Code: string;
  StatusType: number;
  ClientId: number;
  ModifiedOn: string;
  ApiLastModifiedOn: string;
  InternalStatusType?: InternalStatusType = null;
  InternalStatusName: string;
  SortOrder: number;

  IsSelected = true;
  LotCount = 0;
  Color: string;

  constructor(data?: ILotMapStatus) {
    if (data) Object.assign(this, data);

    if (this.ColorHexValue)
      this.Color = this.ColorHexValue.startsWith('#')
        ? this.ColorHexValue
        : '#' + this.ColorHexValue;

    if (this.InternalStatusType) {
      if (this.InternalStatusType === InternalStatusType.Available)
        this.InternalStatusName = 'Available';
      if (this.InternalStatusType === InternalStatusType.Reserved)
        this.InternalStatusName = 'Reserved';
      if (this.InternalStatusType === InternalStatusType.Final) this.InternalStatusName = 'Final';
      if (this.InternalStatusType === InternalStatusType.Unavailable)
        this.InternalStatusName = 'Unavailable';
    }
  }
}

export class ParentStatusAccordionVM {
  Name: string;
  InternalStatusType: InternalStatusType;
  ChildStatuses: StatusVM[] = [];
  AllChildrenSelected = true;
  ChildLotCount = 0;

  constructor(status: StatusVM) {
    this.Name = status.InternalStatusName;
    this.InternalStatusType = status.InternalStatusType;
  }

  setChildLotCount() {
    this.ChildLotCount = this.ChildStatuses.reduce((acc, s) => acc + s.LotCount, 0);
  }
}

export enum InternalStatusType {
  Available = 1,
  Reserved = 2,
  Final = 3,
  Unavailable = 4
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import { SettingsService } from '../../utility/services/settings.service';

@Component({
  selector: 'favorites-counter',
  templateUrl: './favorites-counter.component.html',
  styleUrls: ['./favorites-counter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoritesCounterComponent implements OnInit, OnChanges {
  @HostBinding('class.has-count') hasCount = false;
  @HostBinding('class.icon-only') iconOnly = false;
  @Input() count = 0;

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    this.iconOnly = this.settingsService.get('FavoritesCounterStyle') === 'Icon only';
  }

  ngOnChanges() {
    this.hasCount = !!this.count;
  }
}

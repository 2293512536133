import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { filter } from 'rxjs/operators';

import { AutoUnsubscriber } from '../../utility/helpers/mixins';
import { OverlayService, OverlayState, OverlayTypes } from '../../utility/services/overlay.service';
import { AnimationState, visibilityScaleAnimation } from '../animations';

@Component({
  selector: 'positionable-popup',
  templateUrl: './positionable-popup.component.html',
  styleUrls: ['./positionable-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [visibilityScaleAnimation(350)]
})
export class PositionablePopupComponent extends AutoUnsubscriber implements OnInit {
  @ViewChild('viewContainer', { read: ViewContainerRef }) viewContainer: ViewContainerRef;
  isShowing = false;
  animationState: AnimationState;
  style: { [key: string]: any };

  constructor(
    private overlay: OverlayService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef
  ) { super(); }

  ngOnInit(): void {
    this.updateAnimationState();
    this.addObservable(this.overlay.state$
      .pipe(filter(state => state.targetedComponent === OverlayTypes.PositionablePopup)
      ))
      .subscribe(state => {
        // if different popup, close and reopen
        if (this.isShowing && state.isShowing) {
          this.isShowing = false;
          this.updateAnimationState();
          this.cdr.detectChanges();
          setTimeout(() => {
            this.updatePopup(state);
          }, 350); // animation time
        } else {
          this.updatePopup(state);
        }
      });
  }

  private updatePopup(state: OverlayState) {
    this.isShowing = state.isShowing;

    this.updateAnimationState();
    if (this.isShowing) {
      const popupData = state.data as PopupData;

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        popupData.component
      );
      this.viewContainer.clear();

      this.style = popupData.getStyle();

      const componentRef = this.viewContainer.createComponent(componentFactory);
      (componentRef.instance as PopupComponentData).data = popupData.data;
    }

    this.cdr.detectChanges();
  }

  handleClose() {
    this.overlay.hide(OverlayTypes.PositionablePopup);
  }

  private updateAnimationState() {
    this.animationState = this.isShowing ? AnimationState.Visible : AnimationState.Hidden;
  }
}

export class PopupData {
  constructor(
    public component: Type<any>,
    public location: { x: string; y: string; corner: PopupLocation },
    public data: any
  ) { }

  getStyle(): { [key: string]: any } {
    return {
      top: this.getTopLocation(),
      bottom: this.getBottomLocation(),
      left: this.getLeftLocation(),
      right: this.getRightLocation(),
      transformOrigin: this.getTransformOrigin()
    };
  }

  private getTopLocation(): string {
    return this.location.corner === PopupLocation.TopLeft ||
      this.location.corner === PopupLocation.TopRight
      ? this.location.y
      : 'auto';
  }

  private getBottomLocation(): string {
    return this.location.corner === PopupLocation.BottomLeft ||
      this.location.corner === PopupLocation.BottomRight
      ? this.location.y
      : 'auto';
  }

  private getLeftLocation(): string {
    return this.location.corner === PopupLocation.TopLeft ||
      this.location.corner === PopupLocation.BottomLeft
      ? this.location.x
      : 'auto';
  }

  private getRightLocation(): string {
    return this.location.corner === PopupLocation.TopRight ||
      this.location.corner === PopupLocation.BottomRight
      ? this.location.x
      : 'auto';
  }

  private getTransformOrigin(): string {
    switch (this.location.corner) {
      case PopupLocation.TopLeft:
        return 'top left';
      case PopupLocation.TopRight:
        return 'top right';
      case PopupLocation.BottomLeft:
        return 'bottom left';
      case PopupLocation.BottomRight:
      default:
        return 'bottom right';
    }
  }
}

export interface PopupComponentData {
  data: any;
}

export enum PopupLocation {
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { EmailRequest } from '../interfaces/email-request';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private api: ApiService) {}

  sendEmail(emailRequest: EmailRequest): Observable<string> {
    return this.api.postForm(emailRequest.mapToFormData(), 'email/form-data');
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { FavoritesService } from '../../utility/services/favorites.service';
import { SettingsService } from '../../utility/services/settings.service';

@Component({
  selector: 'favorite-btn',
  templateUrl: './favorite-btn.component.html',
  styleUrls: ['./favorite-btn.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteBtnComponent implements OnInit {
  @Input() isActive = false;
  @Output() toggle = new EventEmitter<boolean>();
  isFavoritingEnabled = true;
  iconOnly = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private favorites: FavoritesService,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.isFavoritingEnabled = this.favorites.isFavoritingEnabled();

    this.iconOnly = this.settingsService.get('FavoriteButtonStyle') === 'Icon only';
  }

  handleToggle(evt: Event) {
    evt.stopPropagation();
    const newState = !this.isActive;
    this.cdr.detectChanges();
    this.toggle.emit(newState);
  }
}

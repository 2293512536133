import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

import { LotVM } from '../../site-map/models/lot-vm';
import { ThemeService } from '../../utility/services/theme.service';

import { ILot } from '@ml/common';

@Component({
  selector: 'lot-selector',
  templateUrl: './lot-selector.component.html',
  styleUrls: ['./lot-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotSelectorComponent implements OnInit {
  @Input() currentLot: ILot;
  @Input() lots: Array<ILot>;
  @Input() disableViewAllOption = false;
  @Input() isInventoryHome = false;
  @Input() lotLabel: string;
  @Output() selectLot = new EventEmitter<ILot>();
  @Output() selectAll = new EventEmitter();
  readonly viewAllValue = 'all';
  selectOptionsLots: LotVM[] = [];

  constructor(private theme: ThemeService) {}

  ngOnInit() {
    this.selectOptionsLots = this.lots.map(l => new LotVM(l));

    if (!this.lotLabel) this.lotLabel = this.theme.lotLabel;
  }

  handleLotSelection(selectedValue: number | string, evt?: MatSelectChange) {
    evt?.source.writeValue(null);
    if (selectedValue === this.viewAllValue) {
      this.selectAll.emit();
    } else {
      const lot = this.lots.find(x => x.LotId === selectedValue);
      this.selectLot.emit(lot);
    }
  }
}

import { Content, Layout } from '../interfaces/custom-sub-page';

import { ICustomSubPage, IPage } from '@ml/common';

export class CustomSubPage implements ICustomSubPage {
  CustomSubPageId: number;
  Name: string;
  ClientId: number;
  ProjectId: number;
  CommunityId: number;
  ContentJson: string;
  Layout: Layout;
  CreatedOn: string;
  ModifiedOn: string;
  Pages: IPage[];
  PageIds: number[];

  Content: Content;

  constructor(data?: ICustomSubPage) {
    if (data) {
      Object.assign(this, data);

      if (data.ContentJson) this.Content = JSON.parse(data.ContentJson);
    }
  }
}

export class SpecialtyTemplate implements ICustomSubPage {
  CustomSubPageId: number;
  Name: string;
  ClientId: number;
  ProjectId: number;
  CommunityId: number;
  ContentJson: string;
  Layout: Layout;
  CreatedOn: string;
  ModifiedOn: string;
  Pages: IPage[];
  PageIds: number[];

  Content: any;

  constructor(data?: ICustomSubPage) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
export class IframeContent {
  Id: string;
  Url: string;

  constructor(rawJson?: string) {
    if (rawJson) {
      const parsed: IframeContent = JSON.parse(rawJson);
      Object.assign(this, parsed);
    }
  }
}

export class IframeContentTemplate extends SpecialtyTemplate {
  Layout = Layout.IframeContent;
  Content = new IframeContent();

  constructor(data?: ICustomSubPage) {
    super(data);
    if (data) {
      if (data.ContentJson) {
        this.Content = new IframeContent(data.ContentJson);
      }
    }
  }
}

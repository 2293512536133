import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { FullscreenGalleryVM } from '../../global/fullscreen-gallery/fullscreen-gallery-vm';
import { OverlayService, OverlayTypes } from '../../utility/services/overlay.service';
import { GalleryImageVM } from '../gallery/gallery-image-vm';

@Component({
  selector: 'video-with-preview',
  templateUrl: './video-with-preview.component.html',
  styleUrls: ['./video-with-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoWithPreviewComponent implements OnChanges {
  @Input() src: string;
  @Input() previewUrl?: string;
  @Input() showControls = false;
  @Input() startTime = 0;
  @ViewChild('video', { static: true }) video: ElementRef<HTMLVideoElement>;
  isPlaying = false;
  isIframe = false;
  galleryVM: GalleryImageVM;

  constructor(private overlay: OverlayService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.src) {
      const videoExt = ['.mp4', '.wmv', '.m4v', 'webm'];
      const srcExtension = this.getSourceFileExt(this.src);
      if (!videoExt.some(x => x === srcExtension)) {
        this.isIframe = true;
        this.galleryVM = new GalleryImageVM(this.src);
        this.galleryVM.IsIframe = true;
      } else {
        this.isIframe = false;
        this.galleryVM = null;
      }
    }
    if (changes.startTime) {
      this.setCurrentTime(this.startTime);
      // autoplay attribute will be enabled based on startTime -- this next line is just to prevent preview from flashing
      this.isPlaying = true;
    }
  }

  getSourceFileExt(source: string): string {
    const queryParamIndex = source.indexOf('?');

    if (queryParamIndex > -1) {
      source = source.substring(0, queryParamIndex);
    }

    return source.substring(source.length - 4);
  }

  pauseVideo(evt: Event) {
    evt.preventDefault();
    this.video.nativeElement.pause();
  }

  playVideo() {
    if (!this.isIframe) {
      this.video.nativeElement.play();
    } else if (this.galleryVM) {
      this.overlay.setState({
        isShowing: true,
        targetedComponent: OverlayTypes.FullscreenGallery,
        data: new FullscreenGalleryVM([this.galleryVM], 0)
      });
    }
  }

  onPause() {
    this.isPlaying = false;
  }

  onPlay() {
    this.isPlaying = true;
  }

  getCurrentTime() {
    return this.video.nativeElement.currentTime;
  }

  setCurrentTime(time: number) {
    if (this.video && this.video.nativeElement) this.video.nativeElement.currentTime = time || 0;
  }
}

import { IBaseContentJson, PageType, PageVersion } from '../interfaces/page';
import { PageTitle } from '../services/navigation.service';
import { CustomSubPage } from './custom-sub-page';

import { IPage } from '@ml/common';

export class PageVM<T extends IBaseContentJson> implements IPage {
  PageId: number;
  CommunityId: number;
  PageTitle: PageTitle;
  PageHeading: string;
  NavigationTitle: string;
  HtmlContent: string;
  SortOrder: number;
  IsHome: boolean;
  CreatedOn: string;
  IsBuiltIn: boolean;
  ParentPageId: number;
  BuiltInPageNavigateUrl: string;
  BuiltInPageViewNameOverride: string;
  ModifiedOn: string;
  HtmlContent2: string;
  HtmlContent3: string;
  HtmlContent4: string;
  PageType: PageType;
  ContentJson: string;
  Version: PageVersion;
  IsActive: boolean;
  ClientId: number;
  ProjectId: number;
  CustomSubPageIds: number[];
  CustomSubPages: CustomSubPage[];
  IconId: number;
  IconSvgContent: string;

  Content: T;

  constructor(data?: IPage) {
    Object.assign(this, data);

    this.Content = {} as T;
    if (this.ContentJson) this.Content = JSON.parse(this.ContentJson);

    if (data && data.CustomSubPages)
      this.CustomSubPages = data.CustomSubPages.map(x => new CustomSubPage(x));
  }
  CommunityName: string;
  IsLocked: boolean;
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { calculateMonthlyPayment, formatUsaCurrency } from '../../../utility/helpers/helpers';
import {
  HcMortgageCalcLoanLengthTerms,
  HcMortgageCalcTerms
} from '../../hc-session/hc-session-models';
import {
  MortgageCalcFormProperties,
  MortgageCalcFormValues
} from './hc-mortgage-calculator-models';

@Component({
  selector: 'hc-mortgage-calculator',
  templateUrl: './hc-mortgage-calculator.component.html',
  styleUrls: ['./hc-mortgage-calculator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HcMortgageCalculatorComponent implements OnInit {
  @HostBinding('class') class = 'hc-styles';
  formValues: MortgageCalcFormValues;
  calcFormProperties = MortgageCalcFormProperties;
  loanLengthTerms = HcMortgageCalcLoanLengthTerms;
  monthlyPayment: string;

  constructor(
    @Optional() public dialogRef: MatDialogRef<HcMortgageCalculatorComponent, HcMortgageCalcTerms>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: HcMortgageCalcTerms
  ) {}

  ngOnInit(): void {
    this.formValues = new MortgageCalcFormValues(this.data);
    this.formValues.setDownPaymentPrice();
    this.handleMonthlyPaymentCalculation();
  }

  // Dynamically change value of opposite Down Payment input based on value of current input.
  handleDownPaymentChange(value: number, name: MortgageCalcFormProperties) {
    this.formValues[name] = value;
    if (name === MortgageCalcFormProperties.DownPayPrice) {
      const newPercentVal = (this.formValues.DownPayPrice / this.formValues.TotalPrice) * 100;
      this.formValues.DownPayPercent = +newPercentVal.toFixed(2);
    }
    if (name === MortgageCalcFormProperties.DownPayPercent) {
      this.formValues.setDownPaymentPrice();
    }
    this.formValues.setMortgageAmount();
    this.handleMonthlyPaymentCalculation();
  }

  handleMonthlyPaymentCalculation() {
    this.monthlyPayment = formatUsaCurrency(
      calculateMonthlyPayment(
        this.formValues.TotalPrice,
        this.formValues.InterestRate,
        this.formValues.Years,
        this.formValues.DownPayPercent
      )
    );
  }

  handleInterestRate(value: number) {
    this.formValues.InterestRate = value;
    this.handleMonthlyPaymentCalculation();
  }

  handleLoanLengthTerms(length: HcMortgageCalcLoanLengthTerms) {
    this.formValues.Years = length;
    this.handleMonthlyPaymentCalculation();
  }

  handleTotalPriceChange(value) {
    this.formValues.TotalPrice = value;
    this.formValues.setMortgageAmount();
    this.handleMonthlyPaymentCalculation();
  }

  handleSave() {
    this.dialogRef.close({
      TotalPrice: this.formValues.TotalPrice,
      DownPayPercent: this.formValues.DownPayPercent,
      InterestRate: this.formValues.InterestRate,
      Years: this.formValues.Years
    } as HcMortgageCalcTerms);
  }

  handleClose() {
    this.dialogRef.close();
  }
}

import { Injectable } from '@angular/core';

import { IFloorPlan } from '../interfaces/floorplan';
import { CommunityService } from './community.service';
import { NeighborhoodService } from './neighborhood.service';

import { ILot } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class FloorplanService {
  private floorplans = new Array<IFloorPlan>();

  constructor(
    private neighborhoodService: NeighborhoodService,
    private communityService: CommunityService
  ) {
    this.communityService.current$.subscribe(() => {
      this.floorplans = [];
    });
  }

  getAll(): Array<IFloorPlan> {
    if (this.floorplans.length > 0) return this.floorplans;

    this.floorplans = this.neighborhoodService
      .getAll()
      .flatMap(neighborhood => neighborhood.FloorPlans);
    return this.floorplans;
  }

  get(floorplanId: number): IFloorPlan {
    return this.getAll().find(floorplan => floorplan.FloorplanId === floorplanId);
  }

  getAssociatedLots(floorplanId: number, neighborhoodId: number): ILot[] {
    return this.neighborhoodService
      .get(neighborhoodId)
      .Lots.filter(lot => lot.MappedFloorPlanIds.map(i => i.Id).includes(floorplanId));
  }

  getByElevationId(elevationId: number): IFloorPlan {
    return this.getAll().find(floorplan =>
      floorplan.Elevations.map(elevation => elevation.ElevationId).includes(elevationId)
    );
  }

  getByMediaId(mediaId: number): IFloorPlan {
    return this.getAll().find(floorplan =>
      floorplan.Media.map(media => media.MediaId).includes(mediaId)
    );
  }
}

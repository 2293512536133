import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewEncapsulation
} from '@angular/core';

import { AnimationState, heightExpandCollapseAnimation } from '../../global/animations';

@Component({
  selector: 'expanding-quick-menu',
  templateUrl: './expanding-quick-menu.component.html',
  styleUrls: ['./expanding-quick-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [heightExpandCollapseAnimation(150)]
})
export class ExpandingQuickMenuComponent implements OnInit {
  @Input() togglerIconName = 'wrench';
  isExpanded = false;
  animationState = AnimationState.Collapsed;
  currentIcon: string;

  constructor() {}

  ngOnInit(): void {
    this.currentIcon = this.togglerIconName;
  }

  toggleExpandingQuickMenu() {
    this.isExpanded = !this.isExpanded;
    this.updateAnimationState();
  }

  private updateAnimationState() {
    this.animationState = this.isExpanded ? AnimationState.Expanded : AnimationState.Collapsed;
    this.currentIcon = this.isExpanded ? 'x' : this.togglerIconName;
  }
}

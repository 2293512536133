import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';

import { enterLeaveFadeAnimation } from '../animations';
import { ToastOptions, ToastService, ToastState } from './toast.service';

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [enterLeaveFadeAnimation()],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent implements OnInit, OnDestroy {
  @HostListener('keydown', ['$event']) onKeydown(event) {
    if (event.key === 'Escape') this.close();
  }
  isShowing = false;
  options: ToastOptions = {};
  subscription: Subscription;
  resolveFn: (value?: boolean | PromiseLike<boolean>) => void;

  private timeout: number;

  constructor(private toaster: ToastService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscription = this.toaster.state$.subscribe(toastState => {
      if (this.ignoreLoadingHide(toastState)) return;

      window.clearTimeout(this.timeout);
      document.removeEventListener('click', this.onClick, false);

      this.options = toastState.options;
      this.setOptionPresets();

      if (this.options.clickToClose) {
        // inexplicably this event gets triggered from the click that launched this whole process
        // so we must wait 300ms before attaching listener
        window.setTimeout(() => {
          document.addEventListener('click', this.onClick, false);
        }, 300);
      }

      this.isShowing = toastState.isShowing;

      // timeout to remove toast
      if (!this.options.infinite)
        this.timeout = window.setTimeout(() => {
          this.close();
        }, this.options.duration);

      if (this.options.isConfirmation) this.resolveFn = this.options.resolveFn;

      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  close() {
    this.isShowing = false;
    this.options = {};

    // unregister here in case user never actually clicked to close and just waited the duration
    document.removeEventListener('click', this.onClick, false);

    // Assuming it doesn't matter if was already resolved
    if (this.resolveFn) this.resolveFn(false);

    this.cdr.detectChanges();
  }

  confirm(shouldConfirm: boolean) {
    if (this.resolveFn) this.resolveFn(shouldConfirm);
    this.close();
  }

  onClick = () => {
    if (this.options.clickToClose) {
      window.clearTimeout(this.timeout);
      this.close();
    }
  };

  // presets for success, error, etc
  private setOptionPresets() {
    if (this.options.useSuccessStyle && !this.options.iconName) this.options.iconName = 'success';

    if (this.options.useErrorStyle) {
      if (!this.options.iconName) this.options.iconName = 'alert';

      // default for error messages is longer
      if (!this.options.duration) this.options.duration = 5000;
    }

    if (this.options.onlyLoadingIndicator) this.options.infinite = true;
    else {
      // all none loading indicator toasts:

      // unless specifically set to false we default to allow click to close
      if (this.options.clickToClose !== false) this.options.clickToClose = true;
    }

    if (!this.options.duration) this.options.duration = this.toaster.defaultDuration;

    if (!this.options.confirmNegativeButtonText) this.options.confirmNegativeButtonText = 'Go Back';
    if (!this.options.confirmPositiveButtonText) this.options.confirmPositiveButtonText = 'Yes';
  }

  /** Check to make sure a call to hide loading does not force an actual message to be hidden */
  private ignoreLoadingHide(incomingState: ToastState): boolean {
    return (
      this.isShowing &&
      !this.options.onlyLoadingIndicator &&
      !incomingState.isShowing &&
      incomingState.options.onlyLoadingIndicator
    );
  }
}

<div class="flex row justify-center allow-wrap">
    <div class="status" *ngFor="let status of statuses">
        <div class="swatch" [style.background-color]="status.Color"></div>
        <label>{{status.Name}}</label>
        <div class="counter" [class.active]="status.LotCount">{{status.LotCount}}</div>
    </div>
    <div *ngIf="showModelHomeStatus" class="status">
        <img class="swatch" [src]="modelHomeImgUrl" />
        <label>Model Home</label>
    </div>
</div>
<hr>
<div #mapViewport class="svg-viewport">
    <div class="svg-container" [class.has-printbox]="hasPrintbox" [transformApplier]="graphicsModel" [svgInjector]="neighborhood.FullSvgUrl"
         (svgLoad)="onSvgLoad($event)" [style.background-image]="neighborhood.FullBackgroundImageUrl | safeBackgroundImageUrl"></div>
</div>
import { formatCurrency } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';

import { PageTitle } from '../services/navigation.service';

/* eslint-disable prefer-arrow/prefer-arrow-functions */
export const getActiveProductName = () => {
  if (window.location.pathname.includes('/scp/')) return 'myscp';
  else if (window.location.pathname.includes('/home-configurator/')) return 'home-configurator';
  else return 'myhomebuilder';
};

export function roundToPlace(num: number, place: number = 0) {
  const mult = Math.pow(10, place);
  return Math.round(mult * num) / mult;
}

export function roundDownToPrecision(num: number, precision: number) {
  return Math.floor(num / precision) * precision;
}

export function roundUpToPrecision(num: number, precision: number) {
  return Math.ceil(num / precision) * precision;
}

export function getAllRouteParams(route: ActivatedRoute) {
  return combineLatest([route.paramMap, route.queryParamMap]);
}

export function degreesToRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

export function htmlAsString(element: Element) {
  const wrapper = document.createElement('div');
  wrapper.appendChild(element);
  return wrapper.innerHTML;
}

export function filterByUniqueItems<T>(item: T, index: number, array: T[]): boolean {
  return array.indexOf(item) === index;
}

export function removeFromArray<T>(item: T, array: T[]): T[] {
  const copy = array.slice();
  const i = copy.findIndex(x => x === item);
  if (i > -1) copy.splice(i, 1);

  return copy;
}

// Very basic pluralization helper. We should definitely update this for more usecases
// Use with caution
const plural = {
  '(quiz)$': '$1zes',
  '^(ox)$': '$1en',
  '([m|l])ouse$': '$1ice',
  '(matr|vert|ind)ix|ex$': '$1ices',
  '(x|ch|ss|sh)$': '$1es',
  '([^aeiouy]|qu)y$': '$1ies',
  '(hive)$': '$1s',
  '(?:([^f])fe|([lr])f)$': '$1$2ves',
  '(shea|lea|loa|thie)f$': '$1ves',
  sis$: 'ses',
  '([ti])um$': '$1a',
  '(tomat|potat|ech|her|vet)o$': '$1oes',
  '(bu)s$': '$1ses',
  '(alias)$': '$1es',
  '(octop)us$': '$1i',
  '(ax|test)is$': '$1es',
  '(us)$': '$1es',
  '([^s]+)$': '$1s'
};

const irregular = {
  move: 'moves',
  foot: 'feet',
  goose: 'geese',
  sex: 'sexes',
  child: 'children',
  man: 'men',
  tooth: 'teeth',
  person: 'people'
};

const uncountable = [
  'sheep',
  'fish',
  'deer',
  'moose',
  'series',
  'species',
  'money',
  'rice',
  'information',
  'equipment'
];
export function pluralizeString(strToPluralize: string) {
  if (!strToPluralize) return;
  const arr = plural;
  // save some time in the case that singular and plural are the same
  uncountable.forEach(x => {
    if (strToPluralize.endsWith(x)) return strToPluralize;
  });

  // check for irregular forms
  for (const word in irregular) {
    if (irregular.hasOwnProperty(word)) {
      let reGex: RegExp;
      let replace: string;
      if (strToPluralize) {
        reGex = new RegExp(irregular[word] + '$', 'i');
        replace = word;
      } else {
        reGex = new RegExp(word + '$', 'i');
        replace = irregular[word];
      }
      if (reGex.test(strToPluralize)) return strToPluralize.replace(reGex, replace);
    }
  }

  // check for matches using regular expressions
  for (const reg in arr) {
    if (arr.hasOwnProperty(reg)) {
      const reGex = new RegExp(reg, 'i');
      if (reGex.test(strToPluralize)) return strToPluralize.replace(reGex, arr[reg]);
    }
  }
  return strToPluralize;
}

export function getDefaultIconNameForPage(pageTitle: string) {
  switch (pageTitle) {
    case PageTitle.Brochure:
      return 'favorite';
    case PageTitle.AreaMap:
      return 'marker';
    case PageTitle.CommunityMap:
      return 'site_map';
    case PageTitle.FloorPlans:
      return 'floorplan';
    case PageTitle.Homepage:
      return 'home_menu';
    case PageTitle.EnergyEfficiency:
      return 'lightbulb_leaf';
    case PageTitle.Visualizer:
      return 'cube';
    default:
      return 'nav_module';
  }
}

export function getPercentage(part: number, total: number): number {
  return Math.trunc((part / total) * 100);
}

export function isInIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function locationHrefContainsStandalone() {
  return window.location?.href.toLowerCase().includes('standalone=true');
}

export function calculateMonthlyPayment(
  principal: number,
  annualInterestRate: number,
  years: number,
  downPayPercent: number
) {
  const monthlyInterest = annualInterestRate / 100 / 12;
  const totalPayments = years * 12;
  const loanAmount = principal - principal * (downPayPercent / 100);

  const monthlyPayment =
    (loanAmount * monthlyInterest * Math.pow(1 + monthlyInterest, totalPayments)) /
    (Math.pow(1 + monthlyInterest, totalPayments) - 1);

  return roundToPlace(monthlyPayment, 0);
}

export function formatUsaCurrency(value: number, overrideSymbol?: string) {
  const formatted = formatCurrency(value, 'en-US', '$', 'USD', '1.0-2');
  if (overrideSymbol) return formatted.replace('$', overrideSymbol);
  else return formatted;
}

export function convertUnderscoresToBoldHtml(text: string) {
  if (!text) return;
  let updated = '';
  let boldStarted = false;

  for (let i = 0; i < text.length; i++) {
    if (text[i + 1] && text[i] === '_' && text[i + 1] === '_') {
      i++;
      if (boldStarted) {
        updated += '</b>';
        boldStarted = false;
      } else {
        updated += '<b>';
        boldStarted = true;
      }
    } else {
      updated += text[i];
    }
  }

  return updated;
}

// https://stackoverflow.com/questions/26501688/a-typescript-guid-class
export function newGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;

    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function allValuesAreUnique(values: Array<any>) {
  const uniqueValues = new Set(values);
  return values.length === uniqueValues.size;
}

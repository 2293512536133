import { Injectable } from '@angular/core';

import { CommunityService } from './community.service';
import { FloorplanService } from './floorplan.service';
import { NeighborhoodService } from './neighborhood.service';

import { IInventoryHome, ILot } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class InventoryHomeService {
  private inventoryHomes = new Array<IInventoryHome>();

  constructor(
    private floorplanService: FloorplanService,
    private neighborhoodService: NeighborhoodService,
    private communityService: CommunityService
  ) {
    this.communityService.current$.subscribe(() => {
      this.inventoryHomes = [];
    });
  }

  getAll(): IInventoryHome[] {
    if (this.inventoryHomes.length > 0) return this.inventoryHomes;

    this.inventoryHomes = this.floorplanService
      .getAll()
      .flatMap(floorplan => floorplan.InventoryHomes);

    return this.inventoryHomes;
  }

  get(inventoryHomeId: number): IInventoryHome {
    return this.getAll().find(inventoryHome => inventoryHome.InventoryHomeId === inventoryHomeId);
  }

  getByLotId(lotId: number): IInventoryHome {
    return this.getAll().find(ih => ih.LotIds.includes(lotId));
  }

  getAssociatedLots(inventoryHomeId: number, neighborhoodId: number): ILot[] {
    const ih = this.get(inventoryHomeId);
    return this.neighborhoodService
      .get(neighborhoodId)
      .Lots.filter(lot => ih.LotIds.includes(lot.LotId));
  }
}

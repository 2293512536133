import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppModeTypes } from '../helpers/app-mode.enum';
import { Lookup } from '../helpers/lookup';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
import { DataStoreService } from './data-store.service';

import { CommunityDataLevel, IAmenityMap, ICommunity, IHotspotGroup } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {
  private communitySubject = new Subject<ICommunity>();
  private lastUpdated: Date;
  private updateInProgress = false;

  constructor(
    private lookup: Lookup,
    private store: DataStoreService,
    private api: ApiService,
    private browser: BrowserService
  ) {}

  get current$() {
    return this.communitySubject.asObservable();
  }

  get current() {
    return this.store.currentCommunity;
  }

  set current(c: ICommunity) {
    this.store.currentCommunity = c;
    this.communitySubject.next(c);
  }

  getLastUpdate(): Date {
    return this.lastUpdated;
  }

  get(communityId: number, appMode?: AppModeTypes): Observable<ICommunity> {
    let storedCommunity: ICommunity;
    try {
      storedCommunity = this.current;
    } catch {
      // No community in store... Calling API next
    }

    if (storedCommunity && storedCommunity.CommunityId === communityId) return of(storedCommunity);

    this.lastUpdated = new Date();
    return this.getFromApi(communityId, appMode);
  }

  // no longer used as client preferred full window reload to ensure fresh files as well
  // leaving in case minds are changed... again :)
  updateCurrentInBackground() {
    try {
      const hasBeenAtLeast15Minutes =
        !this.lastUpdated || new Date().getTime() - this.lastUpdated.getTime() > 15 * 60 * 1000;

      if (this.current && hasBeenAtLeast15Minutes && !this.updateInProgress) {
        // no need to await since this it meant to happen silently in background
        this.getFromApi(this.current.CommunityId).toPromise();
        console.log('Updating community data in background...');
      }
    } catch (error) {
      console.warn('Failed to update community data in background', error);
    }
  }

  private getFromApi(communityId: number, appMode?: AppModeTypes) {
    this.updateInProgress = true;

    const dataLevel =
      appMode && appMode === AppModeTypes.HomeConfigurator
        ? CommunityDataLevel.HomeConfiguratorOnly
        : CommunityDataLevel.MyScpOnly;
    return this.api.getCommunityById(communityId, dataLevel).pipe(
      map((community: ICommunity) => {
        this.updateInProgress = false;
        this.current = community;
        this.lastUpdated = new Date();
        return community;
      })
    );
  }

  getBaseApiUrl() {
    return `${this.lookup.ApiProductDataEndpoint}${this.store.currentCommunity.VirtualDirectory}`;
  }

  getBaseApiUrlForClient() {
    return `${this.lookup.ApiProductDataEndpoint}/${this.store.currentCommunity.ClientName}_${this.store.currentCommunity.ClientId}`;
  }

  getFormattedAddress(excludeStreet = false) {
    const c = this.store.currentCommunity;
    const parts = [];
    if (c.Address && !excludeStreet) parts.push(c.Address);
    if (c.City) parts.push(c.City);
    if (c.State) parts.push(c.State);

    let address = parts.join(', ');
    if (c.PostalCode) address += ' ' + c.PostalCode;

    return address;
  }

  getOverallMapHotspotGroup(): IHotspotGroup {
    const newVersion = this.store.currentCommunity.HotspotGroup.find(
      group => group.ParentType === 'myscp community map'
    );
    if (newVersion) return newVersion;

    return null;
  }

  hasOverallCommunityMap(): boolean {
    const hotspotGroup = this.getOverallMapHotspotGroup();
    return (
      hotspotGroup &&
      !!hotspotGroup.ImageFilename &&
      // when in compact mode we require hotspot markers
      (!this.browser.isCompact() || !!hotspotGroup.Hotspot.length)
    );
  }

  getAmenityMaps(): IAmenityMap[] {
    return this.store.currentCommunity.AmenityMaps;
  }

  getDisplayName() {
    const c = this.store.currentCommunity;
    return c.PublicName || c.Name;
  }

  wipeOutFloorPlanPricing() {
    const community = this.store.currentCommunity;
    for (const hood of community.Neighborhoods) {
      for (const fp of hood.FloorPlans) {
        fp.PriceRange = null;
        fp.Price = null;
      }
    }
  }
}

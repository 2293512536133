import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  private stateSubject: Subject<OverlayState> = new Subject();

  constructor() {}

  get state$(): Observable<OverlayState> {
    return this.stateSubject.asObservable();
  }

  setState(state: OverlayState) {
    this.stateSubject.next(state);
  }

  hide(targetedComponent: OverlayTypes) {
    this.setState({ isShowing: false, targetedComponent });
  }

  show(targetedComponent: OverlayTypes) {
    this.setState({ isShowing: true, targetedComponent });
  }
}

export class OverlayState {
  constructor(
    public isShowing: boolean = false,
    public targetedComponent: OverlayTypes,
    public stickRight?: boolean,
    public data?: any
  ) {}
}

export enum OverlayTypes {
  SalesPinPad,
  QuickNav,
  HomeBuyerRegistration,
  FullscreenGallery,
  PositionablePopup
}

<header>
  <icon-button *ngIf="!navigator.IsInStandaloneMode" aria-label="View {{themeService.fpLabel}}" class="view-floorplan-button"
               text="View {{themeService.fpLabel}}" [showArrow]="true" (click)="handleNavigateToFloorplan()"></icon-button>
  <h3>
    <icon [name]="effectiveFp.IsInventoryHome ? 'inventory_home' : 'floorplan'" [class.is-inventory-home]="effectiveFp.IsInventoryHome"></icon>
    {{modalTitle}}
  </h3>
  <icon-button aria-label="Close modal" class="close-modal-button" (click)="handleClose()" icon="x"></icon-button>
</header>
<div id="scroll-gallery-container">
  <div *ngFor="let image of images" class="scrolling-image-container">
    <img src="{{image.FullUrl}}" alt="{{image.Title}}" />
    <p>{{image.Title}}</p>
  </div>
</div>
import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DIRECTION_ALL, Manager, Pan, Pinch, Press, Tap } from 'hammerjs';

import { isInIframe } from '../utility/helpers/helpers';
import { Lookup } from '../utility/helpers/lookup';
import { GraphicsModel } from '../utility/models/graphics-model';

// Use this directive to handle pan and pinch interactions
// This ONLY registers Hammer events and emits data upward. Does NOT update any element styles. See transform-applier for that.
@Directive({
  selector: '[interactionEventsListener]'
})
export class InteractionEventsListenerDirective implements OnInit {
  @Output() interaction: EventEmitter<any> = new EventEmitter<GraphicsModel>();
  // This GM should never be modified directly by this directive.
  // Its only purpose is to sync starting event data when other page features will be modifying GM
  @Input('interactionEventsListener') readOnlyGm: GraphicsModel;
  @Input() enableDoubleTapScale = false;
  @Input() allowPanAtDefaultScale = false;

  private element: HTMLElement;
  private hammerManager: HammerManager;
  private isInIframe: boolean;

  constructor(private elementRef: ElementRef, private lookup: Lookup) {}

  ngOnInit() {
    this.isInIframe = isInIframe();
    this.element = this.elementRef.nativeElement;

    this.hammerManager = new Manager(this.element, {
      touchAction: this.isInIframe ? 'pan-y' : 'none'
    });

    this.createRecognizers(this.hammerManager);

    this.subscribeToEvents(this.hammerManager);
  }

  // creates pan and pinch recognizers with settings different than Hammer defaults
  createRecognizers(hm: HammerManager) {
    const pan = new Pan({ threshold: 0, pointers: 0, direction: DIRECTION_ALL });
    const pinch = new Pinch({ enable: true });
    const pressdown = new Press({ event: 'pressdown', time: 0 });

    // Important: allows gestures to fire simultaneously (only have to call recognizeWith() 1-way)
    pan.recognizeWith(pressdown);
    pinch.recognizeWith(pan);

    if (this.enableDoubleTapScale) {
      const doubleTap = new Tap({ event: 'doubletap', taps: 2 });
      hm.add(doubleTap);
    }

    hm.add([pressdown, pan, pinch]);
  }

  subscribeToEvents(hm: HammerManager) {
    let isReversed = false;
    let newX = 0;
    let newY = 0;
    let newScale = 1;

    let startX = newX;
    let startY = newY;
    let startScale = newScale;

    if (this.enableDoubleTapScale) {
      hm.on('doubletap', evt => {
        // the doubletap will toggle between zooming in and out
        startScale = this.readOnlyGm?.Scale ?? newScale;
        if (startScale === this.lookup.GraphicsMinScale) {
          newScale =
            this.lookup.GraphicsMinScale +
            (this.lookup.GraphicsMaxScale - this.lookup.GraphicsMinScale) / 2;
        } else {
          newScale = this.lookup.GraphicsMinScale;
        }
        this.interaction.emit(new GraphicsModel({ ...this.readOnlyGm, Scale: newScale }));
      });
    }

    // START
    // note that "panstart" does NOT fire on pressdown but on first move
    // We need to set the startScale on panstart and pinchstart otherwise when multiple events are firing, startScale doesn't set correctly.
    // Also, pressdown does not always fire if you immediately start panning or pinching.
    hm.on('pressdown panstart pinchstart', evt => {
      if (this.readOnlyGm) {
        isReversed = this.readOnlyGm.IsReversed;
        startX = this.readOnlyGm.X;
        startY = this.readOnlyGm.Y;
        startScale = this.readOnlyGm.Scale;

        // in case the end event fires without the move firing then new need to match the readOnlyGm values also
        newScale = startScale;
        newX = startX;
        newY = startY;
      } else {
        // if no readOnlyGm was passed then carry-over values from last event interactions as start values
        startX = newX;
        startY = newY;
        startScale = newScale;
      }
    });

    // MOVE
    hm.on('panmove pinchmove', evt => {
      // if in iframe then there must be 2 fingers to cancel page scroll and move content
      if (this.isInIframe && evt.pointers.length === 2) evt.preventDefault();

      // keep scale within bounds
      newScale = startScale * evt.scale;
      newScale = Math.max(
        this.lookup.GraphicsMinScale,
        Math.min(this.lookup.GraphicsMaxScale, newScale)
      );

      // only allow pan after scaling in
      if (newScale !== 1 || this.allowPanAtDefaultScale) {
        newX = startX + evt.deltaX;
        newY = startY + evt.deltaY;

        // bound x and y so that half of scaled content is always visible
        const maxNewScale = newScale !== 1 ? newScale - 1 : newScale;
        const maxX = Math.ceil((maxNewScale * this.element.clientWidth) / 2);
        const minX = -1 * maxX;
        const maxY = Math.ceil((maxNewScale * this.element.clientHeight) / 2);
        const minY = -1 * maxY;

        newX = Math.max(minX, Math.min(maxX, newX));
        newY = Math.max(minY, Math.min(maxY, newY));
      }

      this.interaction.emit(
        new GraphicsModel({
          ...this.readOnlyGm,
          X: newX,
          Y: newY,
          Scale: newScale,
          IsReversed: isReversed,
          ActiveInteraction: true
        })
      );
    });

    // END
    // this fires on pointerup but only if panning/pinching has occurred
    hm.on('panend pinchend', evt => {
      // We really only want to fire the end event if we're letting go of all inputs
      if (evt.isFinal) {
        // on end emit final GM with activeInteraction = false
        this.interaction.emit(
          new GraphicsModel({
            ...this.readOnlyGm,
            X: newX,
            Y: newY,
            Scale: newScale,
            IsReversed: isReversed,
            ActiveInteraction: false
          })
        );
      }
    });
  }
}

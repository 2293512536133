import MapImageVM from '../../site-map/models/map-image-vm';
import { sortByTitle } from '../helpers/sorters';
import { FloorplanVM } from './floorplan-vm';

import { IBuildingDTO_v2, ILot, ILotMapStatus, IMapImage, INeighborhood } from '@ml/common';

export class NeighborhoodVM implements INeighborhood {
  NeighborhoodId: number;
  Name: string;
  PublicName: string;
  Description: string;
  CommunityId: number;
  LotMapSvgFilename: string;
  LotMapBackgroundFilename: string;
  IsForOverallCommunity: boolean;
  IsActive: boolean;
  Lots: ILot[];
  FloorPlans: FloorplanVM[];
  LotMapStatuses: ILotMapStatus[];
  Latitude: number;
  Longitude: number;
  FocusLongitude: number;
  FocusLatitude: number;
  RotationDegreesFromNorth: number;
  LotMapPixelsPerFoot: number;
  MapImages: IMapImage[];
  MapImagesVM: MapImageVM;
  DisplayName: string;
  SvgModifiedOn: string;
  ClientUniqueId: string;
  VirtualDirectory: string;
  Buildings: IBuildingDTO_v2[];

  DirectoryName: string;
  BaseUrl: string;
  FullSvgUrl: string;
  FullBackgroundImageUrl: string;
  IconId: number;
  IconSvgContent: string;
  ExternalUrl: string;

  private communityBaseUrl: string;

  constructor(data?: INeighborhood, communityBaseUrl?: string, apiBaseUrl?: string) {
    Object.assign(this, data);
    this.communityBaseUrl = communityBaseUrl;
    if (data.FloorPlans) {
      this.FloorPlans = data.FloorPlans.map(
        x => new FloorplanVM(x, undefined, undefined, apiBaseUrl)
      ).sort(sortByTitle);
    }
    this.MapImagesVM = new MapImageVM(
      data.MapImages,
      communityBaseUrl + '/Amenities/' + data.NeighborhoodId
    );
    this.DisplayName = this.PublicName || this.Name;

    this.DirectoryName = `${this.Name}_${this.NeighborhoodId}`;
    this.BaseUrl = `${this.communityBaseUrl}/${this.DirectoryName}`;
    if (this.LotMapSvgFilename) {
      this.FullSvgUrl = `${this.BaseUrl}/${this.LotMapSvgFilename}`;
      // only use date query param if online, otherwise it could mess with SW cache
      if (this.SvgModifiedOn && navigator.onLine)
        this.FullSvgUrl += `?d=${new Date(this.SvgModifiedOn).getTime()}`;
    }
    if (this.LotMapBackgroundFilename)
      this.FullBackgroundImageUrl = `${this.BaseUrl}/${this.LotMapBackgroundFilename}`;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CommunityService } from './community.service';

import { ISalesRep } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class SalesRepresentativeService {
  private salesRepresentativeSubject = new BehaviorSubject<ISalesRep>(null);

  constructor(private communityService: CommunityService) {}

  getAll(): ISalesRep[] {
    return this.communityService.current.SalesRepresentatives;
  }

  get current$(): Observable<ISalesRep> {
    return this.salesRepresentativeSubject.asObservable();
  }

  get current(): ISalesRep {
    return this.salesRepresentativeSubject.value;
  }

  set current(rep: ISalesRep) {
    this.salesRepresentativeSubject.next(rep);
  }

  login(pin: string): ISalesRep {
    const all = this.getAll();
    const rep = all.find(x => x.PIN === pin);
    if (rep) this.current = rep;
    return rep;
  }

  logout() {
    this.current = null;
  }

  isAnyoneLoggedIn(): boolean {
    return !!this.salesRepresentativeSubject.value;
  }
}

<section class="text-content">
    <div *ngIf="customHelpMessage; else defaultMessages" class="message">
        <p [innerHtml]="customHelpMessage"></p>
    </div>

    <ng-template #defaultMessages [ngSwitch]="data.Type">
        <div class="message" *ngSwitchCase="messageType.NoFloorplan">
            <p>Welcome, you've selected {{theme.lotLabel}} <b>{{data.Lot.Title}}!</b></p>
            <p>To continue, click the <b>Select a {{theme.fpLabel}} button</b> to browse and select your {{theme.fpLabel}} for this {{theme.lotLabel}}
            </p>
            <p class="small"><b>Reminder:</b> Only specific {{theme.fpLabel}} are available per {{theme.lotLabel}} based on builder and community
                guidelines.</p>
        </div>

        <div class="message" *ngSwitchCase="messageType.NoLot">
            <p>Welcome, you've selected the {{data.Floorplan.Name}} {{theme.fpLabel.toLowerCase()}}!</p>
            <p>To get started, <b>click on any {{statusNames}} {{theme.lotLabel.toLowerCase()}}</b> that you are interested in.</p>
            <p class="small"><b>Reminder:</b> Only specific {{theme.fpLabel}} are available per {{theme.lotLabel}} based on builder and community
                guidelines.</p>
        </div>

        <div class="message" *ngSwitchCase="messageType.NoFloorplanAndLot">
            <p>Welcome, you have no {{theme.fpLabel}} or {{theme.lotLabel}} selected yet.</p>
            <p>To get started, <b>click on any {{statusNames}} {{theme.lotLabel.toLowerCase()}}</b> that you are interested in.</p>
            <p class="small"><b>Reminder:</b> Only specific {{theme.fpLabel}} are available per {{theme.lotLabel}} based on builder and community
                guidelines.</p>
        </div>

        <div class="message" *ngSwitchCase="messageType.LotJustSelectedButNotAddedToSession">
            <p>Excellent! Here you can make your selection by clicking the <b>Select {{theme.lotLabel}} button</b> and also change or browse
                {{theme.lotLabel.toLowerCase()}} {{theme.fpLabel.toLowerCase()}}s.</p>
            <p class="small"><b>Reminder:</b> Only specific {{theme.fpLabel}} are available per {{theme.lotLabel}} based on builder and community
                guidelines.</p>
        </div>

        <div class="message" *ngSwitchCase="messageType.LotJustAddedToSession">
            <p>Nice work! Now continue onto the <b>{{fpModuleName}} module</b> and browse or add custom structural options.</p>
            <p class="small"><b>Reminder:</b> You can always come back and select a different {{theme.lotLabel}} at any time.</p>
        </div>

        <div class="message" *ngSwitchCase="messageType.ExteriorWithOptionSelectionChanged">
            <p>The exterior you selected has also updated a previously selected structural option. You can review the structural option change by
                navigating back to the {{theme.fpLabel}}</p>
        </div>
    </ng-template>

</section>

<section class="actions">
    <button class="hc-btn success" (click)="close()">
        <icon name="check"></icon>
        Got It!
    </button>

    <a class="selectable" (click)="close(true)">Don't show this again.</a>
</section>
import { GalleryImageParentType, GalleryImageVM } from '../../shared/gallery/gallery-image-vm';
import { sortFloorsWithBasementLast } from '../helpers/sorters';
import { IFloorPlan } from '../interfaces/floorplan';
import { ElevationVM } from './elevation-vm';
import { FavoritesSession } from './favorites-session';
import { FloorVM } from './floor-vm';
import { MediaVM } from './media-vm';

import {
  IFloorPlanCustomData,
  IInventoryHome,
  ILot,
  ITag,
  MediaType,
  sortByName,
  sortByOrderNullable
} from '@ml/common';

export class FloorplanVM implements IFloorPlan {
  FloorplanId: number;
  ClientUniqueIdentifier: string;
  CommunityId: number;
  PublicName: string;
  Title: string;
  IsActive: boolean;
  Elevations: ElevationVM[] = [];
  NeighborhoodId: number;
  VirtualDirectory: string;
  SortOrder: number;
  BaseTotalSquareFeet: number;
  NumberOfBedrooms: number;
  NumberOfFullBaths: number;
  NumberOfHalfBaths: number;
  NumberOfGarages: number;
  GarageCarSize: number;
  OutdoorSquareFeet: number;
  NumberOfDens: number;
  Price: number;
  Floors: FloorVM[] = [];
  InventoryHomes: IInventoryHome[] = [];
  PixelsPerFoot = 7;
  Media: MediaVM[] = [];
  Description: string;
  Tags: ITag[];
  SvgRevisionDate: string;
  InteriorPanoId?: number;
  ExteriorPanoId?: number;
  InteriorLayeredImageSetId?: number;
  ExteriorLayeredImageSetId?: number;
  InteriorStaticImageSetId: string;
  ExteriorStaticImageSetId: string;
  LayerNameKey: string;
  CustomData: IFloorPlanCustomData;

  IsFavorited: boolean;
  IsSelected: boolean;
  IsReversedByDefault: boolean;
  AssociatedLots: ILot[] = [];
  baseUrl: string; // TODO - was private
  ElevationGalleryImages: GalleryImageVM[] = [];
  MediaGalleryImages: GalleryImageVM[] = [];
  FloorGalleryImages: GalleryImageVM[] = [];
  DefaultFloor: FloorVM;
  DefaultNumberOfFloors: number;
  CombinedBathrooms: number;
  NeighborhoodName: string;
  AllTagNames: string;

  FloorRange: string;
  FloorRangeMin: number;
  FloorRangeMax: number;

  NumberOfBedroomsRange: string;
  NumberOfBedroomsRangeMin: number;
  NumberOfBedroomsRangeMax: number;

  NumberOfFullBathsRange: string;
  NumberOfFullBathsRangeMin: number;
  NumberOfFullBathsRangeMax: number;

  GarageCarSizeRange: string;
  GarageCarSizeRangeMin: number;
  GarageCarSizeRangeMax: number;

  GarageNumberRange: string;
  GarageNumberMin: number;
  GarageNumberMax: number;

  DenRange: string;
  DenRangeMin: number;
  DenRangeMax: number;

  NumberOfHalfBathsRange: string;
  NumberOfHalfBathsMin: number;
  NumberOfHalfBathsMax: number;

  PriceRange: string;
  PriceRangeMin: number;
  PriceRangeMax: number;

  SquareFeetRange: string;
  SquareFeetMin: number;
  SquareFeetMax: number;

  // TODO - consider changing baseUrl to communityBaseUrl and use DirectoryName instead of
  // VirtualDirectory to build BaseUrl so this works like all the other constructors
  constructor(
    data?: IFloorPlan,
    favSession?: FavoritesSession,
    allLots?: ILot[],
    baseUrl?: string,
    selectedOptionIds: number[] = [],
    filteredElevationIds: number[] = []
  ) {
    Object.assign(this, data);

    this.DefaultNumberOfFloors = this.Floors.filter(x => !x.IsOption).length;

    [this.FloorRangeMin, this.FloorRangeMax] = this.setMinMax(this.FloorRange);
    [this.NumberOfBedroomsRangeMin, this.NumberOfBedroomsRangeMax] = this.setMinMax(
      this.NumberOfBedroomsRange
    );
    [this.NumberOfFullBathsRangeMin, this.NumberOfFullBathsRangeMax] = this.setMinMax(
      this.NumberOfFullBathsRange
    );
    [this.GarageCarSizeRangeMin, this.GarageCarSizeRangeMax] = this.setMinMax(
      this.GarageCarSizeRange
    );
    [this.GarageNumberMin, this.GarageNumberMax] = this.setMinMax(this.GarageNumberRange);
    [this.DenRangeMin, this.DenRangeMax] = this.setMinMax(this.DenRange);
    [this.NumberOfHalfBathsMin, this.NumberOfHalfBathsMax] = this.setMinMax(
      this.NumberOfHalfBathsRange
    );
    [this.PriceRangeMin, this.PriceRangeMax] = this.setMinMaxLarge(this.PriceRange);
    [this.SquareFeetMin, this.SquareFeetMax] = this.setMinMaxLarge(this.SquareFeetRange);

    this.IsFavorited = favSession?.containsFloorplan(this.FloorplanId);

    if (!this.CustomData) this.CustomData = {} as IFloorPlanCustomData;
    // wanted to phrase property as "show" but need it defaulted to true
    if (typeof this.CustomData.ShowBasePlanAlongWithInventoryHomes !== 'boolean') {
      this.CustomData.ShowBasePlanAlongWithInventoryHomes = true;
    }

    if (allLots) {
      this.AssociatedLots = allLots.filter(lot =>
        lot.MappedFloorPlanIds.map(i => i.Id).includes(this.FloorplanId)
      );
    }

    if (baseUrl) this.baseUrl = baseUrl;

    if (data?.Floors) {
      if (!selectedOptionIds.length && favSession)
        selectedOptionIds = favSession?.Floorplans.flatMap(x => x.SelectedActionSetIds);

      this.Floors = data.Floors.map(
        floor => new FloorVM(floor, this.BaseUrl, selectedOptionIds, this.SvgRevisionDate)
      );
    }

    if (data?.Elevations) {
      this.Elevations = data.Elevations.filter(
        x => !filteredElevationIds.length || filteredElevationIds.includes(x.ElevationId)
      ).map(x => new ElevationVM(x, favSession?.containsFloorplanElevation(x.ElevationId)));
    }

    if (data?.Media)
      this.Media = data.Media.map(
        x => new MediaVM(x, favSession?.containsFloorplanMedia(x.MediaId))
      );

    this.ElevationGalleryImages = this.Elevations.map(e =>
      new GalleryImageVM(this.BaseUrl).HydrateFromElevation(
        e,
        GalleryImageParentType.Floorplan,
        this.FloorplanId
      )
    ).sort(sortByOrderNullable);

    this.MediaGalleryImages = this.Media.filter(
      m => m.MediaType === MediaType.Unspecified || m.MediaType === MediaType.Static
    ).map(m =>
      new GalleryImageVM(this.BaseUrl).HydrateFromMedia(
        m,
        GalleryImageParentType.Floorplan,
        this.FloorplanId
      )
    );

    this.Floors = sortFloorsWithBasementLast<FloorVM>(this.Floors);
    const defaultFloor = this.Floors.find(floor => floor.IsDefaultFloor);
    this.DefaultFloor = defaultFloor || this.Floors[0];

    this.FloorGalleryImages = this.Floors.filter(x => !x.IsOption).map(x =>
      new GalleryImageVM(this.BaseUrl).HydrateFromFloor(x)
    );

    this.CombinedBathrooms = this.NumberOfFullBaths + this.NumberOfHalfBaths / 2;
    this.AllTagNames = this.Tags?.sort(sortByName)
      .map(t => t.Name)
      .join(', ');
  }

  get Name(): string {
    return (
      this.PublicName ||
      this.Title ||
      this.ClientUniqueIdentifier ||
      `Floorplan #${this.FloorplanId}`
    );
  }

  get DirectoryName(): string {
    return `${this.Title}_${this.FloorplanId}`;
  }

  get BaseUrl(): string {
    if (!this.baseUrl) return '';
    // Remove any // between directories that is not http://
    return `${this.baseUrl}/${this.VirtualDirectory}`.replace(/(\w)(\/\/)(\w)/g, '$1/$3');
  }

  get FavoritedElevationCount(): number {
    return this.Elevations.filter(e => e.IsFavorited).length;
  }

  setMinMax(range: string): Array<number> {
    const nullResult = [null, null];
    if (!range) {
      return nullResult;
    }

    const regex = /\d+\.?\d*/g;
    const array = [...range.matchAll(regex)];

    if (!array || array.length < 2) {
      return nullResult;
    }
    return [parseFloat(array[0][0]), parseFloat(array[array.length - 1][0])];
  }
  //  Handles large range numbers that have commas and whitespace
  setMinMaxLarge(range: string): Array<number> {
    const nullResult = [null, null];
    if (!range) {
      return nullResult;
    }
    const rangeArray = range.split('-');
    if (!rangeArray || rangeArray.length !== 2) {
      return nullResult;
    }
    const numberRangeArray = rangeArray.map(x => +x.replace(/,/g, '').trim());

    return numberRangeArray;
  }
}

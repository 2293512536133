<div class="backdrop" (click)="closeMenu()"></div>
<section class="menu">
    <header id="fsm-header">
        <ng-content select="[header]"></ng-content>
        <button (click)="closeMenu()">
            <icon name="x"></icon>
        </button>
    </header>
    <div class="body" elementScroll scrollClass="scrolled-shadow" applyToElementSelector="#fsm-header">
        <ng-content select="[body]"></ng-content>
    </div>
</section>
import {
  HcMortgageCalcLoanLengthTerms,
  HcMortgageCalcTerms
} from '../../hc-session/hc-session-models';

export enum MortgageCalcFormProperties {
  TotalPrice = 'TotalPrice',
  Years = 'Years',
  DownPayPercent = 'DownPayPercent',
  DownPayPrice = 'DownPayPrice',
  InterestRate = 'InterestRate',
  MortgageAmount = 'MortgageAmount'
}

export class MortgageCalcFormValues {
  TotalPrice: number;
  Years: HcMortgageCalcLoanLengthTerms;
  DownPayPercent: number;
  DownPayPrice: number;
  InterestRate: number;
  MortgageAmount: number;

  constructor(terms: HcMortgageCalcTerms) {
    Object.assign(this, terms);
    this.setDownPaymentPrice();
    this.setMortgageAmount();
  }

  setDownPaymentPrice() {
    const percentToDec = this.DownPayPercent * 0.01;
    this.DownPayPrice = this.TotalPrice * percentToDec;
  }

  setMortgageAmount() {
    this.MortgageAmount = this.TotalPrice - this.DownPayPrice;
  }
}

export class ModuleSummaryItem {
  Name: string;
  Subcontent?: string;
  Value: string;

  constructor(data?: ModuleSummaryItem) {
    Object.assign(this, data);
  }
}

export class ModuleSummarySection {
  ColumnNames: string[] = [];
  Items: ModuleSummaryItem[] = [];
}

export class ModuleSummary {
  EntityLabel: string;
  Sections: ModuleSummarySection[] = [];
  HasSelections = false;
  Action: () => void;
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  private windowWidthSubject = new BehaviorSubject<number>(window.innerWidth);
  windowInnerWidth: number;
  breakpoints: { Tablet: number; Desktop: number };

  constructor() {
    const parseNumber = (str: string) => +str.replace('px', '');
    this.breakpoints = {
      Tablet: parseNumber(getComputedStyle(document.body).getPropertyValue('--tabletMinSize')),
      Desktop: parseNumber(getComputedStyle(document.body).getPropertyValue('--desktopMinSize'))
    };

    this.windowInnerWidth = window.innerWidth;
    this.windowWidthSubject.next(this.windowInnerWidth);
    window.addEventListener(
      'resize',
      () => {
        this.windowInnerWidth = window.innerWidth;
        this.windowWidthSubject.next(this.windowInnerWidth);
      },
      false
    );
  }

  get isDesktopSized$(): Observable<boolean> {
    return this.windowWidthSubject.pipe(
      map(width => width >= this.breakpoints.Desktop),
      distinctUntilChanged()
    );
  }

  get isTabletSized$(): Observable<boolean> {
    return this.windowWidthSubject.pipe(
      map(width => width >= this.breakpoints.Tablet && width < this.breakpoints.Desktop),
      distinctUntilChanged()
    );
  }

  get isPhoneSized$(): Observable<boolean> {
    return this.windowWidthSubject.pipe(
      map(width => width < this.breakpoints.Tablet),
      distinctUntilChanged()
    );
  }

  /**Compact is anything less than Desktop */
  isCompact(): boolean {
    return this.windowInnerWidth < this.breakpoints.Desktop;
  }

  isCompatible(): boolean {
    let result = true;

    if (this.oldEdgeVersion() || this.isIE()) {
      result = false;
    }

    return result;
  }

  isIE(): boolean {
    return navigator.userAgent.indexOf('.NET') > -1;
  }

  oldEdgeVersion(): string {
    let result = '';

    // Old Edge - Edge/####
    // New Edge with Chromium - Edg/####

    const userAgent = navigator.userAgent;
    const match = 'Edge/';

    let index = userAgent.indexOf(match);
    if (index > -1) {
      result = userAgent.substring(index + match.length);
      index = result.indexOf(' ');
      if (index > -1) {
        result = result.substring(0, index);
      }
    }

    return result;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LotVM } from '../../site-map/models/lot-vm';
import { InternalStatusType } from '../../site-map/models/status-vm';
import { convertUnderscoresToBoldHtml } from '../../utility/helpers/helpers';
import { FloorplanVM } from '../../utility/models/floorplan-vm';
import { PageTitle } from '../../utility/services/navigation.service';
import { NeighborhoodService } from '../../utility/services/neighborhood.service';
import { SettingsService } from '../../utility/services/settings.service';
import { ThemeService } from '../../utility/services/theme.service';
import { HcModuleService } from '../hc-module-nav/hc-module.service';

@Component({
  selector: 'hc-help-message-dialog',
  templateUrl: './hc-help-message-dialog.component.html',
  styleUrls: ['./hc-help-message-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HcHelpMessageDialogComponent implements OnInit {
  @HostBinding('class') class = 'hc-styles';
  messageType = HcHelpMessagesType;
  statusNames: string;
  fpModuleName: string;

  customHelpMessage: string;

  constructor(
    public dialogRef: MatDialogRef<HcHelpMessageDialogComponent, boolean>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: HcHelpMessageDialogModel,
    public theme: ThemeService,
    private neighborhoodService: NeighborhoodService,
    private hcModuleService: HcModuleService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.fpModuleName = this.hcModuleService.getModuleTitle(PageTitle.FloorPlans);

    this.customHelpMessage = convertUnderscoresToBoldHtml(
      this.settingsService.get(this.getSettingNameForType(this.data.Type))
    );

    if (this.data.Floorplan) {
      this.statusNames = this.neighborhoodService
        .getStatuses(this.data.Floorplan.NeighborhoodId)
        .filter(x => x.InternalStatusType === InternalStatusType.Available)
        .reduce((prev, status, i, arr) => {
          if (i === 0) return status.Name;
          if (i === arr.length - 1) return prev + ' or ' + status.Name;
          return prev + ', ' + status.Name;
        }, '');
    }
  }

  close(doNotShowAgain = false) {
    this.dialogRef.close(doNotShowAgain);
  }

  private getSettingNameForType(type: HcHelpMessagesType) {
    switch (type) {
      case HcHelpMessagesType.NoFloorplan:
        return 'ModalHelpMessageNoFloorplan_HC';
      case HcHelpMessagesType.NoLot:
        return 'ModalHelpMessageNoLot_HC';
      case HcHelpMessagesType.NoFloorplanAndLot:
        return 'ModalHelpMessageNoFloorplanAndLot_HC';
      case HcHelpMessagesType.LotJustAddedToSession:
        return 'ModalHelpMessageLotAddedToSession_HC';
      case HcHelpMessagesType.LotJustSelectedButNotAddedToSession:
        return 'ModalHelpMessageLotSelectedNoSession_HC';
      case HcHelpMessagesType.ExteriorWithOptionSelectionChanged:
        return 'ModalHelpMessageExteriorWithOption_HC';
    }
  }
}

export class HcHelpMessageDialogModel {
  Type: HcHelpMessagesType;
  Lot?: LotVM;
  Floorplan?: FloorplanVM;
}

export enum HcHelpMessagesType {
  NoFloorplan,
  NoLot,
  NoFloorplanAndLot,
  LotJustSelectedButNotAddedToSession,
  LotJustAddedToSession,
  ExteriorWithOptionSelectionChanged
}

<div class="interactions-container" [interactionEventsListener]="frameGM" (interaction)="handleFrameInteraction($event)">
    <div class="transform-receptor" [transformApplier]="frameGM">
        <div class="frame" *ngFor="let frame of frames; index as index" [class.show]="index === currentFrameIndex">
            <img [src]="frame.ImageUrl" />
            <div *ngIf="frame.SvgOverlayUrl" class="svg-container" [svgInjector]="frame.SvgOverlayUrl" (svgLoad)="onSvgLoad($event)"
                 (loadFail)="handleSvgLoadFailure($event)"></div>
        </div>
    </div>
</div>
<div id="btn-container">
    <div>
        <button class="btn" [class.disabled]="currentFrameIndex === svgFrameIndex && frameGM.Scale === lookup.GraphicsMinScale"
                (click)="handleHomeSiteViewSelect()">Homesite View</button>
    </div>
    <scale-control [scale]="frameGM.Scale" (step)="handleScaleStep($event)"></scale-control>
</div>

<!-- <icon-button *ngIf="frameGM.Scale > lookup.GraphicsMinScale" icon="reset_arrow" text="Reset zoom" (click)="resetScale()"></icon-button>
<icon-button *ngIf="currentFrameIndex !== startingFrameIndex && frameGM.Scale === lookup.GraphicsMinScale" icon="reset_arrow"
             text="Return to default view" (click)="handleFrameSelect()">
</icon-button> -->
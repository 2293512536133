import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EffectiveFloorplan } from '../../utility/models/effective-floorplan';
import { NavigationService, PageTitle } from '../../utility/services/navigation.service';
import { ThemeService } from '../../utility/services/theme.service';
import { GalleryImageVM } from '../gallery/gallery-image-vm';

@Component({
  selector: 'scrolling-gallery',
  templateUrl: './scrolling-gallery.component.html',
  styleUrls: ['./scrolling-gallery.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollingGalleryComponent implements OnInit {
  @Input() images: GalleryImageVM[];
  @Input() effectiveFp: EffectiveFloorplan;
  modalTitle: string;
  floorplanUrl: string;

  constructor(
    public themeService: ThemeService,
    public navigator: NavigationService,
    @Optional() public dialogRef: MatDialogRef<ScrollingGalleryComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      images: GalleryImageVM[];
      effectiveFp: EffectiveFloorplan;
    }
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.images = this.data.images;
      this.effectiveFp = this.data.effectiveFp;
    }

    if (this.effectiveFp) {
      this.modalTitle = this.effectiveFp.PublicName || this.effectiveFp.Name;
    }
  }

  handleClose() {
    this.dialogRef.close();
  }

  handleNavigateToFloorplan() {
    this.navigator.go({
      PageTitle: PageTitle.FloorPlans,
      RouteParams: { FloorplanId: this.effectiveFp.FloorplanId },
      QueryParams: { inventoryHomeId: this.effectiveFp?.InventoryHomeId }
    });
    this.dialogRef.close();
  }
}

<accordion [openByDefault]="true" (isOpenChanged)="toggleOpen($event)" [ariaLabel]="theme.fpLabel + ' Filters'">
    <div header>
        <toggle [on]="isEnabled" (toggle)="handleFiltersEnabledToggle($event)"
                [ariaLabel]="isEnabled ? 'Disable ' + theme.fpLabel + ' Filters': 'Enable ' + theme.fpLabel + ' Filters'"></toggle>
        <h2>{{theme.fpLabel}} Filters</h2>
        <div class="counter" [class.active]="displayCounter !== 'All'">{{displayCounter}}</div>
    </div>
    <div class="badges" @enterLeaveFadeAnimation>
        <badge icon="floorplan" [value]="selectedFloorplan?.Name" [class.disabled]="!filters.IsFloorplanFilterSet" @enterLeaveFadeAnimation>
        </badge>
        <badge icon="price" [value]="filters.FormattedPriceRange" [class.disabled]="!filters.IsPriceFilterSet" @enterLeaveFadeAnimation>
        </badge>
        <badge icon="floor" [value]="filters.MinFloors" [class.disabled]="!filters.MinFloors" @enterLeaveFadeAnimation>
        </badge>
        <badge icon="bed" [value]="filters.MinBedrooms" [class.disabled]="!filters.MinBedrooms" @enterLeaveFadeAnimation>
        </badge>
        <badge icon="full_bath" [value]="filters.MinBathrooms" [class.disabled]="!filters.MinBathrooms" @enterLeaveFadeAnimation>
        </badge>
        <badge icon="garage" [value]="filters.MinGarageSize" [class.disabled]="!filters.MinGarageSize" @enterLeaveFadeAnimation></badge>
        <badge icon="sq_ft" [value]="filters.FormattedSqFootageRange" [class.disabled]="!filters.IsSqFootFilterSet" @enterLeaveFadeAnimation></badge>
    </div>
    <div body>
        <div class="header">
            <div class="filter-header">Filter by:</div>
            <icon-button icon="reset_arrow" text="Reset All" ariaLabel="Reset All" (click)="handleReset()"></icon-button>
        </div>
        <div class="filters">
            <div class="filter full-width">
                <div class="filter-header">
                    <icon name="floorplan" [ngClass]="filters.IsFloorplanFilterSet ? 'active' : 'inactive'">
                    </icon>{{theme.fpLabel}}
                </div>
                <mat-select id="floorplanSelect" [(value)]="filters.SelectedFloorplanId" (selectionChange)="handleFilterChange()"
                            aria-label="Select Floorplan Filter">
                    <mat-option [value]="filters.filterUnsetValue">View All</mat-option>
                    <mat-option *ngFor="let floorplan of floorplans; trackBy: trackByFloorPlanId" [value]="floorplan.FloorplanId">{{floorplan.Name}}
                    </mat-option>
                </mat-select>
            </div>
            <div *ngIf="showInventoryHomes" class="filter full-width">
                <div class="filter-header">
                    <icon name="inventory_home" [ngClass]="filters.IsInventoryHomeFilterSet ? 'active' : 'inactive'"></icon>{{
                    theme.inventoryHomeLabel }}
                </div>
                <mat-select id="inventoryHomeSelect" [(value)]="filters.InventoryHomeFilterStatus" (selectionChange)="handleFilterByInventoryHome()"
                            aria-label="Select Inventory Home Filter">
                    <mat-option *ngFor="let filter of inventoryHomeFilterValues | keyvalue" [value]="filter.key">{{filter.value}}</mat-option>
                </mat-select>
            </div>
            <div class="filter" [class.disabled]="!filters.priceOptions.length">
                <div class="filter-header">
                    <icon name="price" [ngClass]="filters.FormattedPriceRange ? 'active' : 'inactive'"></icon>Price
                </div><label>From</label>
                <mat-select [disabled]="!filters.priceOptions.length" name="minPrice" [(value)]="filters.MinPrice"
                            (selectionChange)="handleFilterChange()" aria-label="Select Minimum Price Filter">
                    <mat-option [value]="filters.filterUnsetValue">--</mat-option>
                    <mat-option *ngFor="let price of filters.priceOptions" [value]="price">
                        ${{price | number}}</mat-option>
                </mat-select>
            </div>
            <div class="filter" [class.disabled]="!filters.priceOptions.length">
                <div class="filter-header">
                </div><label>To</label>
                <mat-select [disabled]="!filters.priceOptions.length" name="maxPrice" [(value)]="filters.MaxPrice"
                            (selectionChange)="handleFilterChange()" aria-label="Select Maximum Price Filter">
                    <mat-option [value]="filters.filterUnsetValue">--</mat-option>
                    <mat-option *ngFor="let price of filters.priceOptions" [value]="price">
                        ${{price | number}}</mat-option>
                </mat-select>
            </div>
            <div class="filter" [class.disabled]="filters.MaxFloors <= 0">
                <div class="filter-header">
                    <icon name="floor" [ngClass]="filters.MinFloors ? 'active' : 'inactive'"></icon>Floors
                </div>
                <numeric-input [(value)]="filters.MinFloors" [maxValue]="filters.MaxFloors" (valueChange)="handleFilterChange()" ariaLabel="Floors">
                </numeric-input>
            </div>
            <div class="filter" [class.disabled]="filters.MaxBedrooms <= 0">
                <div class="filter-header">
                    <icon name="bed" [ngClass]="filters.MinBedrooms ? 'active' : 'inactive'"></icon>Bedrooms
                </div>
                <numeric-input [(value)]="filters.MinBedrooms" [maxValue]="filters.MaxBedrooms" (valueChange)="handleFilterChange()"
                               ariaLabel="Bedrooms">
                </numeric-input>
            </div>
            <div class="filter" [class.disabled]="filters.MaxBathrooms <= 0">
                <div class="filter-header">
                    <icon name="full_bath" [ngClass]="filters.MinBathrooms ? 'active' : 'inactive'"></icon>Bathrooms
                </div>
                <numeric-input [(value)]="filters.MinBathrooms" [maxValue]="filters.MaxBathrooms" (valueChange)="handleFilterChange()" [step]="0.5"
                               ariaLabel="Bathrooms">
                </numeric-input>
            </div>
            <div class="filter" [class.disabled]="filters.MaxGarageSize <= 0">
                <div class="filter-header">
                    <icon name="garage" [ngClass]="filters.MinGarageSize ? 'active' : 'inactive'"></icon>Garage Size
                </div>
                <numeric-input [(value)]="filters.MinGarageSize" [maxValue]="filters.MaxGarageSize" (valueChange)="handleFilterChange()" [step]="0.5"
                               ariaLabel="Garage Size">
                </numeric-input>
            </div>
            <div class="filter" [class.disabled]="!filters.sqFootOptions.length">
                <div class="filter-header">
                    <icon name="sq_ft" [ngClass]="filters.FormattedSqFootageRange ? 'active' : 'inactive'"></icon>Sq Footage
                </div><label>From</label>
                <mat-select [disabled]="!filters.sqFootOptions.length" name="minSqFootage" [(value)]="filters.MinSqFootage"
                            (selectionChange)="handleFilterChange()" aria-label="Select Minimum Square Foot Filter">
                    <mat-option [value]="filters.filterUnsetValue">--</mat-option>
                    <mat-option *ngFor="let sqFootage of filters.sqFootOptions" [value]="sqFootage">
                        {{sqFootage | number}} sq. ft.</mat-option>
                </mat-select>
            </div>
            <div class="filter" [class.disabled]="!filters.sqFootOptions.length">
                <div class="filter-header">
                </div><Label>To</Label>
                <mat-select [disabled]="!filters.sqFootOptions.length" name="maxSqFootage" [(value)]="filters.MaxSqFootage"
                            (selectionChange)="handleFilterChange()" aria-label="Select Maximum Square Foot Filter">
                    <mat-option [value]="filters.filterUnsetValue">--</mat-option>
                    <mat-option *ngFor="let sqFootage of filters.sqFootOptions" [value]="sqFootage">
                        {{sqFootage | number}} sq. ft.</mat-option>
                </mat-select>
            </div>
        </div>
    </div>
</accordion>
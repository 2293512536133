<div *ngIf="isShowing" class="toast-container" [class.blocking-overlay-backdrop]="options.infinite" @enterLeaveFadeAnimation>
    <div *ngIf="options.onlyLoadingIndicator" id="loading-indicator" class="toast-modal">
        <div class="spin-stretch"></div>
    </div>
    <div *ngIf="!options.onlyLoadingIndicator" class="toast-modal"
         [ngClass]="{success: options.useSuccessStyle, error: options.useErrorStyle, 'align-low': options.useLowAlignStyle}" cdkTrapFocus
         [cdkTrapFocusAutoCapture]="true">
        <icon *ngIf="options.showCloseButton" name="x" class="close-icon" (click)="close()" tabindex="0" (keyup.enter)="close()" aria-label="Close">
        </icon>
        <div *ngIf="options.iconName" class="icon">
            <icon [name]="options.iconName"></icon>
        </div>
        <div class="content">
            <div *ngIf="options.title" class="title">{{options.title}}</div>
            <div *ngIf="options.message" class="message" [innerHtml]="options.message"></div>
            <div *ngIf="options.isConfirmation" class="confirm-buttons">
                <icon-button [text]="options.confirmNegativeButtonText" icon="arrow_right" class="priority" (click)="confirm(false)" cdkFocusInitial>
                </icon-button>
                <icon-button [text]="options.confirmPositiveButtonText" icon="success" class="priority" (click)="confirm(true)">
                </icon-button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="useAltOpenButton" class="alt-side-menu-buttons" [class.open]="isOpen" (click)="toggleMenu($event)">
    <icon-button icon="carrot_arrow" class="carrot-arrow"></icon-button>
    <icon-button icon="filters"></icon-button>
</div>
<div class="menu" [class.open]="isOpen" [class.left-origin]="hasLeftOrigin" (click)="maybeBlockEventPropagation($event)">
    <header *ngIf="heading">
        <div class="heading">
            <icon *ngIf="showHeaderIcon" [name]="iconName" [svg]="iconSvg" [class.icon-background]="showIconBackground" class="heading-icon">
            </icon>
            <span *ngIf="heading" class="title" [innerHtml]="heading"></span>
            <favorite-btn *ngIf="hasFavoriteButton" class="medium" [isActive]="isFavorited" (toggle)="handleFavoriteToggle()"></favorite-btn>
        </div>
        <ng-content select="[header]"></ng-content>
    </header>
    <div class="body">
        <ng-content select="[body-fixed]"></ng-content>
        <ng-content select="[body]"></ng-content>
    </div>
    <div *ngIf="!useAltOpenButton" class="pull-tab-and-hint-container" [class.bottom]="isPullTabAtBottom" (click)="toggleMenu($event)">
        <div class="hint-container" *ngIf="showTouchHint && !isOpen">
            <icon name="touch"></icon>
            <span>Touch or click anywhere on the screen to begin.</span>
        </div>
        <button [matTooltip]="isOpen ? 'Close Side Menu': 'Open Side Menu'" matTooltipShowDelay="200" matTooltipPosition="left" class="pull-tab"
                [attr.aria-label]="isOpen ? 'Close Side Menu': 'Open Side Menu'">
            <icon name="menu_tab"></icon>
        </button>
    </div>
</div>
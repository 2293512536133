<h2>WAIT!</h2>

<ng-container [ngSwitch]="data.Type">
    <p *ngSwitchCase="changeType.IncomingFloorplanConflict">
        The {{theme.fpLabel}} you are trying to select is in a <b>different {{theme.neighborhoodLabel.toLowerCase()}}</b> than your current
        {{theme.lotLabel}}. How would you like to continue?
    </p>

    <p *ngSwitchCase="changeType.IncomingLotConflict">
        The {{theme.lotLabel}} you are trying to select is in a <b>different {{theme.neighborhoodLabel.toLowerCase()}}</b> than your current
        {{theme.fpLabel}}. How would you like to continue?
    </p>
    <P *ngSwitchCase="changeType.ClearSessionData">
        You are about to remove all of your selections and this action cannot be undone. You will be redirected back to the start.
        Are you sure you want to remove all of your selections?
    </P>
</ng-container>

<div class="flex row align-center justify-center allow-wrap">
    <ng-container [ngSwitch]="data.Type">
        <button *ngSwitchCase="changeType.IncomingFloorplanConflict" class="hc-btn secondary" (click)="handleProceed()">Unselect {{theme.lotLabel}}
            &amp;
            select {{theme.fpLabel}}</button>

        <button *ngSwitchCase="changeType.IncomingLotConflict" class="hc-btn secondary" (click)="handleProceed()">Unselect {{theme.fpLabel}} &amp;
            select
            {{theme.lotLabel}}</button>
        <button *ngSwitchCase="changeType.ClearSessionData" class="hc-btn secondary" (click)="handleProceed()">
            Remove All Selections
        </button>
    </ng-container>

    <button *ngIf="!changeType.ClearSessionData" class="hc-btn negative" (click)="handleCancel()">Cancel selection</button>
    <button *ngIf="changeType.ClearSessionData" class="hc-btn negative" (click)="handleCancel()">Cancel</button>
</div>
import { AreaMapPoiVM, IAreaMapPoi } from '../../myscp/area-map/models/poi-category';
import { IFloorplanFiltersState } from '../../shared/floorplan-filters/floorplan-filters';
import { FavoriteType } from '../helpers/favorite-types.enum';
import { PageTitle } from '../services/navigation.service';
import { Selection, VisualizerSelection } from './visualizer-selection';

import { IFavoriteFloorplan, IFavoriteLot, IFavoritesSession, IHomeBuyer } from '@ml/common';

export class FavoriteFloorplan implements IFavoriteFloorplan {
  FloorplanId: number;
  InventoryHomeId: number;
  FavoriteElevationIds: number[] = [];
  FavoriteInteriorIds: number[] = [];
  SelectedActionSetIds: number[] = [];

  TotalElevationsCount = 0;
  TotalMediaCount = 0;
  TotalFavoritesCount = 0;

  IsInventoryHome = false;

  constructor(data?: IFavoriteFloorplan) {
    if (data) Object.assign(this, data);

    this.TotalElevationsCount = this.FavoriteElevationIds.length;
    this.TotalMediaCount = this.FavoriteInteriorIds.length;
    this.TotalFavoritesCount = this.TotalElevationsCount + this.TotalMediaCount;

    this.IsInventoryHome = !!this.InventoryHomeId;
  }

  // On DTO but not used in this app yet
  FloorPlanName: string;
  FloorPlanClientUniqueId: string;
  ElevationNames: string[];
  InteriorNames: string[];
  OptionNames: string[];
}

export class FavoriteLot implements IFavoriteLot {
  LotId: number;
  MediaIds: number[] = [];

  // On DTO but not used in this app yet
  LotNumber: string;
  LotClientUniqueId: string;
  Address: string;
}

export interface IFavoritesNonStandardData {
  FurnitureLayouts: string; // eventually make interface for this
  GooglePlaces: IAreaMapPoi[];
  PageContentSectionIds: string[];
  VisualizerSelections: VisualizerSelection[];
  AmenityMediaIds: number[];
  FloorPlanReverseStates: { [key: string]: boolean };
  FloorplanFilterState: IFloorplanFiltersState;
  CustomSubPageIds: number[];
}

export class FavoritesSession implements IFavoritesSession {
  FavoritesSessionId: number;
  HomeBuyerId: number;
  CommunityId: number;
  FavoriteFloorplans: FavoriteFloorplan[] = [];

  /**Previously an actual DTO property, now dynamic based on FavoriteLots property */
  LotIds: number[] = [];
  FavoriteLots: FavoriteLot[] = [];

  PageIds: number[] = []; // TODO: remove SQL table and then this property
  GeneralJSON: string;
  HomeBuyer: IHomeBuyer;
  CreatedOn: string;
  ModifiedOn: string;
  GoogleMapsMarkerIds: number[];

  GooglePlaces: IAreaMapPoi[] = [];
  /** This propery holds guids for both sections ids and item ids. Should be renamed to reflect this. */
  PageContentSectionIds: string[] = [];
  VisualizerSelections: VisualizerSelection[] = [];
  AmenityMediaIds: number[] = [];
  FloorPlanReverseStates = new Map<number, boolean>();
  FloorplanFiltersState: IFloorplanFiltersState = null;

  TotalFloorplansCount = 0;
  TotalElevationsCount = 0;
  TotalInteriorsCount = 0;
  TotalLotsCount = 0;
  TotalLotMediaCount = 0;
  TotalPointsOfInterestCount = 0;
  TotalPageContentSectionCount = 0;
  TotalVisualizerSelectionsCount = 0;
  TotalAmenityMediaCount = 0;
  TotalFavoritesCount = 0;
  TotalCustomSubPagesCount = 0;

  // helpers since FavoriteFloorplans includes both
  Floorplans: FavoriteFloorplan[] = [];
  InventoryHomes: FavoriteFloorplan[] = [];
  CustomSubPageIds: number[] = [];

  constructor(data?: IFavoritesSession) {
    if (data) {
      Object.assign(this, data);

      // HACK -- test if is Map or plain object
      if (!this.FloorPlanReverseStates.get)
        this.FloorPlanReverseStates = new Map<number, boolean>();

      if (data.FavoriteFloorplans)
        this.FavoriteFloorplans = data.FavoriteFloorplans.map(x => new FavoriteFloorplan(x));

      if (data.FavoriteLots) this.LotIds = data.FavoriteLots.map(x => x.LotId);

      if (data.GeneralJSON) {
        const parsed = JSON.parse(data.GeneralJSON) as IFavoritesNonStandardData;
        if (parsed?.GooglePlaces?.length)
          this.GooglePlaces = parsed.GooglePlaces.map(x => new AreaMapPoiVM(x));
        if (parsed?.PageContentSectionIds?.length)
          this.PageContentSectionIds = parsed.PageContentSectionIds;
        if (parsed?.VisualizerSelections?.length)
          this.VisualizerSelections = parsed.VisualizerSelections as VisualizerSelection[];
        if (parsed?.AmenityMediaIds?.length) this.AmenityMediaIds = parsed.AmenityMediaIds;
        if (parsed?.FloorPlanReverseStates)
          this.FloorPlanReverseStates = new Map<number, boolean>(
            Object.entries(parsed.FloorPlanReverseStates).map(e => [+e[0], e[1]])
          );
        if (parsed?.FloorplanFilterState) this.FloorplanFiltersState = parsed.FloorplanFilterState;
        if (parsed?.CustomSubPageIds) this.CustomSubPageIds = parsed.CustomSubPageIds;

        this.GeneralJSON = null;
      }
    }

    this.Floorplans = this.FavoriteFloorplans.filter(fp => !fp.InventoryHomeId);
    this.InventoryHomes = this.FavoriteFloorplans.filter(fp => !!fp.InventoryHomeId);

    this.TotalFloorplansCount = this.FavoriteFloorplans.length;
    this.TotalElevationsCount = this.FavoriteFloorplans.reduce(
      (sum, floorplan) => sum + floorplan.TotalElevationsCount,
      0
    );
    this.TotalInteriorsCount = this.FavoriteFloorplans.reduce(
      (sum, floorplan) => sum + floorplan.TotalMediaCount,
      0
    );
    this.TotalLotsCount = this.FavoriteLots.length;
    this.TotalLotMediaCount = this.FavoriteLots.flatMap(x => x.MediaIds).length;
    this.TotalPointsOfInterestCount = this.GooglePlaces.length;
    this.TotalPageContentSectionCount = this.PageContentSectionIds.length;
    this.TotalVisualizerSelectionsCount = this.VisualizerSelections.length;
    this.TotalAmenityMediaCount = this.AmenityMediaIds.length;
    this.TotalCustomSubPagesCount = this.CustomSubPageIds.length;
    this.TotalFavoritesCount = this.calculateTotalFavorites();
  }

  stringifyNonStandardData() {
    return JSON.stringify({
      FurnitureLayouts: '',
      GooglePlaces: this.GooglePlaces,
      PageContentSectionIds: this.PageContentSectionIds,
      VisualizerSelections: this.VisualizerSelections,
      AmenityMediaIds: this.AmenityMediaIds,
      FloorPlanReverseStates: Object.fromEntries(this.FloorPlanReverseStates),
      FloorplanFilterState: this.FloorplanFiltersState
    } as IFavoritesNonStandardData);
  }

  clone(): FavoritesSession {
    return new FavoritesSession(this);
  }

  containsFloorplan(id: number): boolean {
    return this.Floorplans.some(x => x.FloorplanId === id);
  }

  containsInventoryHome(id: number): boolean {
    return this.InventoryHomes.some(x => x.InventoryHomeId === id);
  }

  containsFloorplanElevation(id: number): boolean {
    return this.FavoriteFloorplans.some(
      x => !x.IsInventoryHome && x.FavoriteElevationIds.includes(id)
    );
  }

  containsInventoryHomeElevation(elevationId: number, inventoryHomeId: number): boolean {
    return this.FavoriteFloorplans.some(
      x => x.InventoryHomeId === inventoryHomeId && x.FavoriteElevationIds.includes(elevationId)
    );
  }

  containsFloorplanMedia(id: number): boolean {
    return this.Floorplans.some(x => x.FavoriteInteriorIds.includes(id));
  }

  containsInventoryHomeMedia(id: number): boolean {
    return this.InventoryHomes.some(x => x.FavoriteInteriorIds.includes(id));
  }

  containsFloorplanOption(id: number): boolean {
    return this.Floorplans.some(x => x.SelectedActionSetIds.includes(id));
  }

  containsInventoryHomeOption(id: number): boolean {
    return this.InventoryHomes.some(x => x.SelectedActionSetIds.includes(id));
  }

  containsLot(id: number): boolean {
    return this.FavoriteLots.some(x => x.LotId === id);
  }

  containsLotMedia(id: number): boolean {
    return this.FavoriteLots.flatMap(x => x.MediaIds).includes(id);
  }

  containsPOI(id: string): boolean {
    return this.GooglePlaces.some(x => x.Id === id);
  }

  containsPageContentSection(id: string): boolean {
    return this.PageContentSectionIds.some(x => x === id);
  }

  containsVisualizerSelection(id: string): boolean {
    return this.VisualizerSelections.some(x => x.Id === id);
  }

  findVisualizerSelectionBySelections(selections: Array<Selection>): VisualizerSelection {
    let found = true;

    for (const visualizerSelection of this.VisualizerSelections) {
      found = true;

      for (let j = 0; j < visualizerSelection.Selections.length; ++j) {
        if (
          selections[j].Type !== visualizerSelection.Selections[j].Type ||
          selections[j].Value !== visualizerSelection.Selections[j].Value
        ) {
          found = false;
          break;
        }
      }

      if (found) {
        return visualizerSelection;
      }
    }

    return null;
  }

  containsAmenityMedia(id: number): boolean {
    return this.AmenityMediaIds.some(x => x === id);
  }

  containsCustomSubPage(id: number): boolean {
    return this.CustomSubPageIds.some(x => x === id);
  }

  getFloorplan(id: number): FavoriteFloorplan {
    return this.Floorplans.find(x => x.FloorplanId === id);
  }

  getFloorplanByElevation(id: number): FavoriteFloorplan {
    return this.Floorplans.find(x => x.FavoriteElevationIds.some(y => y === id));
  }

  getFloorplanByMedia(id: number): FavoriteFloorplan {
    return this.Floorplans.find(x => x.FavoriteInteriorIds.some(y => y === id));
  }

  getFloorplanByOption(id: number): FavoriteFloorplan {
    return this.Floorplans.find(x => x.SelectedActionSetIds.some(y => y === id));
  }

  getInventoryHome(id: number): FavoriteFloorplan {
    return this.InventoryHomes.find(x => x.InventoryHomeId === id);
  }

  getLotByMedia(id: number): FavoriteLot {
    return this.FavoriteLots.find(x => x.MediaIds.some(y => y === id));
  }

  getReverseState(floorplanId: number) {
    return this.FloorPlanReverseStates?.get ? this.FloorPlanReverseStates.get(floorplanId) : false;
  }

  hasBeenSaved(): boolean {
    return !!this.FavoritesSessionId;
  }

  private calculateTotalFavorites(): number {
    const favoritesPerFloorplan: number = this.FavoriteFloorplans.reduce(
      (sum, floorplan) => sum + floorplan.TotalFavoritesCount,
      0
    );
    return (
      this.TotalFloorplansCount +
      favoritesPerFloorplan +
      this.TotalLotsCount +
      this.TotalLotMediaCount +
      this.TotalPointsOfInterestCount +
      this.TotalPageContentSectionCount +
      this.TotalVisualizerSelectionsCount +
      this.TotalAmenityMediaCount +
      this.TotalCustomSubPagesCount
    );
  }

  getFavoritesCountByPage(page: PageTitle): number | null {
    switch (page) {
      case PageTitle.AreaMap:
        return this.TotalPointsOfInterestCount;
      case PageTitle.CommunityMap:
        return this.TotalLotsCount + this.TotalAmenityMediaCount + this.TotalLotMediaCount;
      case PageTitle.FloorPlans:
        return this.TotalFloorplansCount;
      default:
        return null;
    }
  }

  getAllIdsByType(type: FavoriteType) {
    switch (type) {
      case FavoriteType.Floorplan:
        return this.Floorplans.map(x => x.FloorplanId);
      case FavoriteType.InventoryHome:
        return this.InventoryHomes.map(x => x.InventoryHomeId);
      case FavoriteType.Elevation:
        return this.FavoriteFloorplans.flatMap(x => x.FavoriteElevationIds);
      case FavoriteType.Media:
        return this.FavoriteFloorplans.flatMap(x => x.FavoriteInteriorIds);
      case FavoriteType.Lot:
        return this.FavoriteLots.map(x => x.LotId);
      case FavoriteType.LotMedia:
        return this.FavoriteLots.flatMap(x => x.MediaIds);
      case FavoriteType.PointOfInterest:
        return this.GooglePlaces.map(x => x.Id);
      case FavoriteType.PageContentSection:
        return this.PageContentSectionIds.slice();
      case FavoriteType.VisualizerSelection:
        return this.VisualizerSelections.map(x => x.Id);
      case FavoriteType.AmenityMedia:
        return this.AmenityMediaIds.slice();
      case FavoriteType.CustomSubPage:
        return this.CustomSubPageIds.slice();
      default:
        return [];
    }
  }
}

export class GraphicsModel {
  X: number;
  Y: number;
  /** Any scale beyond default. Usually from user input -- pinch zoom or buttons */
  Scale: number;
  IsReversed: boolean;
  ActiveInteraction: boolean;
  /** The scale needed just to fit to container */
  DefaultScale: number;
  ItemWidth: number;
  ItemHeight: number;
  ContainerWidth: number;
  ContainerHeight: number;
  /** Offset to account for anything like printbox to root SVG distance */
  OffsetX?: number;
  /** Offset to account for anything like printbox to root SVG distance */
  OffsetY?: number;

  constructor(
    gm: GraphicsModel = {
      X: 0,
      Y: 0,
      Scale: 1,
      IsReversed: false,
      ActiveInteraction: false,
      DefaultScale: 1,
      ItemWidth: 0,
      ItemHeight: 0,
      ContainerWidth: 0,
      ContainerHeight: 0,
      OffsetX: 0,
      OffsetY: 0
    }
  ) {
    this.X = gm.X;
    this.Y = gm.Y;
    this.Scale = gm.Scale;
    this.IsReversed = gm.IsReversed;
    this.ActiveInteraction = gm.ActiveInteraction;
    this.DefaultScale = gm.DefaultScale;
    this.ItemWidth = gm.ItemWidth;
    this.ItemHeight = gm.ItemHeight;
    this.ContainerWidth = gm.ContainerWidth;
    this.ContainerHeight = gm.ContainerHeight;
    this.OffsetX = gm.OffsetX;
    this.OffsetY = gm.OffsetY;
  }

  static CreateFromBounds(
    item: SimpleBounds,
    container: SimpleBounds,
    coverContainer: boolean = false
  ): GraphicsModel {
    const gm = new GraphicsModel();
    gm.ItemWidth = item.width;
    gm.ItemHeight = item.height;
    gm.ContainerWidth = container.width;
    gm.ContainerHeight = container.height;

    const scaleX = gm.ContainerWidth / gm.ItemWidth;
    const scaleY = gm.ContainerHeight / gm.ItemHeight;
    gm.DefaultScale = coverContainer ? Math.max(scaleX, scaleY) : Math.min(scaleX, scaleY);

    return gm;
  }

  /** This will check for a printbox layer and zoom to fit to it */
  static CreateFromSvg(svg: SVGSVGElement, container: HTMLElement) {
    let containerBounds = {
      width: container.clientWidth,
      height: container.clientHeight
    };
    let svgBounds: SimpleBounds;
    const printbox = svg.querySelector('#printbox') as SVGGElement;
    if (printbox) {
      const bbox = printbox.getBBox();
      containerBounds = container.getBoundingClientRect();
      svgBounds = { width: bbox.width, height: bbox.height };
      const gm = this.CreateFromBounds(svgBounds, containerBounds);
      gm.OffsetX = bbox.x;
      gm.OffsetY = bbox.y;
      return gm;
    } else {
      svgBounds = { width: svg.width.baseVal.value, height: svg.height.baseVal.value };
      return this.CreateFromBounds(svgBounds, containerBounds);
    }
  }
}

export class SimpleBounds {
  width: number;
  height: number;
}

import { Directive, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[domChange]'
})
export class DomChangeDirective implements OnDestroy {
  private changes: MutationObserver;

  @Output() public domChange = new EventEmitter();

  constructor(private el: ElementRef) {
    const element = this.el.nativeElement;

    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => this.domChange.emit(mutation));
    });

    this.changes.observe(element, { childList: true });
  }

  ngOnDestroy() {
    this.changes.disconnect();
  }
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Lookup } from '../helpers/lookup';
import { ApiService } from './api.service';
import { CommunityService } from './community.service';
import { InventoryHomeService } from './inventory-home.service';
import { NeighborhoodService } from './neighborhood.service';

import { ILot, ILotMapStatus, ILotWatcher } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class LotService {
  private lastUpdated: Date;
  private lots = new Array<ILot>();

  constructor(
    private neighborhoodService: NeighborhoodService,
    private inventoryHomeService: InventoryHomeService,
    private api: ApiService,
    private communityService: CommunityService,
    private lookup: Lookup
  ) {
    this.communityService.current$.subscribe(() => {
      this.lots = [];
    });
  }

  getAll(): Array<ILot> {
    if (this.lots.length > 0) return this.lots;

    this.lots = this.neighborhoodService.getAll().flatMap(neighborhood =>
      neighborhood.Lots.map(l => {
        if (this.hasInventoryHome(l.LotId)) {
          const ih = this.inventoryHomeService.getByLotId(l.LotId);
          if (!!ih.Address) l.Address = ih.Address;
          if (!!ih.Swing) l.Swing = ih.Swing;
          if (!!ih.Price) l.Cost = ih.Price;
        }
        return l;
      })
    );
    return this.lots;
  }

  get(id: number): ILot {
    return this.getAll().find(x => x.LotId === id);
  }

  getByNeighborhoodId(neighborhoodId: number): Observable<Array<ILot>> {
    const hasBeenAtLeast30Seconds =
      (!this.lastUpdated || new Date().getTime() - this.lastUpdated.getTime() > 30 * 1000) &&
      (!this.communityService.getLastUpdate() ||
        new Date().getTime() - this.communityService.getLastUpdate().getTime() > 30 * 1000);

    if (hasBeenAtLeast30Seconds) {
      this.lastUpdated = new Date();

      const neighborhood = this.neighborhoodService
        .getAll()
        .find(n => n.NeighborhoodId === neighborhoodId);

      return this.api.getByUrl<ILot[]>(`lots/neighborhood/${neighborhoodId}`).pipe(
        switchMap(lots => {
          neighborhood.Lots = lots;
          return of(lots);
        })
      );
    } else {
      return of(this.getAll().filter(l => l.NeighborhoodId === neighborhoodId));
    }
  }

  getFromApi(id: number) {
    return this.api.getById<ILot>(id, 'lot');
  }

  async saveToApi(lot: ILot): Promise<ILot> {
    const updatedLot = await this.api.update<ILot>(lot, 'lot', `/${lot.LotId}`);
    this.neighborhoodService.updateLotInPlace(updatedLot);
    return updatedLot;
  }

  getMappedStatus(lot: ILot): ILotMapStatus {
    return this.neighborhoodService
      .get(lot.NeighborhoodId)
      .LotMapStatuses.find(x => x.LotMapStatusId === lot.StatusId);
  }

  getMediaBaseUrl(lot: ILot): string {
    return `${this.neighborhoodService.getBaseUrl(lot.NeighborhoodId)}/_LotMedia/${lot.LotId}`;
  }

  hasInventoryHome(lotId: number): boolean {
    return !!this.inventoryHomeService.getByLotId(lotId);
  }

  getPricesForCommunity(): number[] {
    return this.getAll().map(lot => lot.Cost);
  }

  addLotWatcher(lotWatcher: ILotWatcher): Promise<ILotWatcher> {
    return this.api.create<ILotWatcher>(lotWatcher, 'LotWatcher').toPromise();
  }

  removeLotWatcher(lotWatcher: ILotWatcher): Promise<boolean> {
    return this.api
      .deleteById<ILotWatcher>(lotWatcher.LotWatcherId, 'LotWatcher')
      .pipe(map(() => true))
      .toPromise();
  }

  isModelHome(lot: ILot) {
    return lot.CustomData?.IsModelHome === 'true';
  }

  getModelHomeIconUrl() {
    switch (this.communityService.current.ClientId) {
      case this.lookup.ClientIds.StanleyMartin:
        return '/assets/icons/SMH_MapSymbol.png';
      default:
        return '';
    }
  }
}

import { SettingFieldType } from '@ml/common';

export enum Layout {
  NavigationalSections,
  GallerySlider,
  ScrollingGrid,
  AccordionSections,
  EnergyEfficiency,
  IframeContent
}

// for backwards compatibility, must add new types to end of enum
export enum ContentItemType {
  Image,
  Video,
  Title,
  SubTitle,
  /** Formerly named Paragraph */
  FormattedText,
  /** DEPRECATED */
  BulletList,
  PDF,
  ContentGroup,
  IFrame
}

export enum ContentItemGroupType {
  Preview,
  PDF
}

export enum ContentSectionType {
  Primary,
  Sidebar,
  Header,
  Footer
}

export enum ContentSettingName {
  UseAutoplay = 'UseAutoplay',
  AutoplayDurationInSeconds = 'AutoplayDurationInSeconds',
  UseFullscreen = 'UseFullscreen',
  /**Now used to flip starting position in either vertical or horizontal, so "right" is not technically accurate */
  StartImageOnRight = 'StartImageOnRight',
  ScrollingGridHorizontalScroll = 'ScrollingGridHorizontalScroll',
  PaginationControlsLocation = 'PaginationControlsLocation',
  ControlsBackgroundOpacity = 'ControlsBackgroundOpacity',
  ContentBackgroundColor = 'ContentBackgroundColor',
  FavoritesLabel = 'FavoritesLabel',
  MediaPerSectionInsteadOfGlobal = 'MediaPerSectionInsteadOfGlobal',
  EnhancedNavigation = 'EnhancedNavigation',
  ShowGroupsAsAccordions = 'ShowGroupsAsAccordions',
  AllowPrintAndFavoriting = 'AllowPrintAndFavoriting'
}

export enum ContentItemRequiredName {
  MediaTitle = 'Media Title',
  LeftSectionTitle = 'Left Section Title',
  LeftSectionContent = 'Left Section Content',
  RightSectionTitle = 'Right Section Title',
  RightSectionSubTitle = 'Right Section Sub Title',
  NavigationButtonTitle = 'Navigation Button Title'
}

export interface ContentItem {
  Id: string;
  Name: string;
  Type: ContentItemType;
  Value: string;
  ExternalUrl: string;
  PreviewFilename: string;
  SortOrder: number;
  FileToUpload?: File;
  IconId: number;
  IconSvgContent: string;
  Title: string;
  IsFavoritable: boolean;
  IsMultiImage: boolean;
  IsContentGroup: boolean;
  MultiImages: ContentTypeMultiImage[];
  ContentGroup: ContentItemGroup;
}

export interface ContentSection {
  Id: string;
  Name: string;
  Type: ContentSectionType;
  IsFavoritable: boolean;
  SortOrder: number;
  ContentItems: ContentItem[];
  SubSections: ContentSection[];
}

export interface ContentSetting {
  Id: string;
  Name: string;
  DisplayName: string;
  Value: any;
  Type: SettingFieldType;
  Options: string[];
}

export interface Content {
  ContentItems: ContentItem[];
  Sections: ContentSection[];
  Settings: ContentSetting[];
}

export class ContentTypeMultiImage {
  Name: string;
  Type: ContentItemType;
  HasRequiredName = false;
  FileToUpload?: File;
  ExternalUrl: string;
  Value = '';
  PreviewFilename: string;

  constructor(name?: string) {
    this.Type = null;
    if (name) {
      this.Name = name;
    }
  }
}

export class ContentItemGroup {
  Id: string;
  Type: ContentItemGroupType;
  Name: string;
  Items: ContentItem[];
}

import { Directive, ElementRef, OnInit } from '@angular/core';

import { SettingsService } from '../utility/services/settings.service';

@Directive({
  selector: '[border-optional]'
})
export class BorderOptionalDirective implements OnInit {
  constructor(
    private settingsService: SettingsService,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnInit(): void {
    const headerBorder = this.settingsService.get('HeaderBorder');
    if (headerBorder)
      this.elementRef.nativeElement.classList.add(`border-${headerBorder.toLowerCase()}`);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LotVM } from '../../site-map/models/lot-vm';
import { AutoUnsubscriber } from '../../utility/helpers/mixins';
import { EffectiveFloorplan } from '../../utility/models/effective-floorplan';
import { FloorplanVM } from '../../utility/models/floorplan-vm';
import { InventoryHomeVM } from '../../utility/models/inventory-home-vm';
import { NeighborhoodVM } from '../../utility/models/neighborhood-vm';
import { BrowserService } from '../../utility/services/browser.service';
import { CommunityService } from '../../utility/services/community.service';
import { FloorplanService } from '../../utility/services/floorplan.service';
import { NavigationService, PageTitle } from '../../utility/services/navigation.service';
import { NeighborhoodService } from '../../utility/services/neighborhood.service';
import { ThemeService } from '../../utility/services/theme.service';
import { ViewModelFactoryService } from '../../utility/services/vm-factory.service';
import { HcModuleService } from '../hc-module-nav/hc-module.service';
import { HcSessionBuild } from '../hc-session/hc-session-models';
import { HcSessionService } from '../hc-session/hc-session.service';

import { sortByName, sortBySortOrder } from '@ml/common';

@Component({
  selector: 'hc-fp-list',
  templateUrl: './hc-fp-list.component.html',
  styleUrls: ['./hc-fp-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HcFpListComponent extends AutoUnsubscriber implements OnInit {
  @Input() availableFloorplanIds = [];

  allNeighborhoodsWithLots: NeighborhoodVM[];
  allFloorplansAndIH: EffectiveFloorplan[] = [];
  filteredFloorplansAndIH: EffectiveFloorplan[] = [];
  isWithinModal = true;
  communityName: string;
  currentBuild: HcSessionBuild;
  dropdownSelectedLot: LotVM;
  showLotFilter = true;

  constructor(
    private floorplanService: FloorplanService,
    private neighborhoodService: NeighborhoodService,
    public theme: ThemeService,
    private communityService: CommunityService,
    private hcSessionService: HcSessionService,
    private vmFactory: ViewModelFactoryService,
    private navigator: NavigationService,
    private cdr: ChangeDetectorRef,
    @Optional() public dialogRef: MatDialogRef<HcFpListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IHcFpListOptions,
    private hcModuleService: HcModuleService,
    private browser: BrowserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.communityName = this.communityService.getDisplayName();
    this.isWithinModal = !!this.dialogRef;

    this.allNeighborhoodsWithLots = this.neighborhoodService
      .getAll()
      .filter(x => !!x.Lots.length)
      .map(x => {
        const n = new NeighborhoodVM(x);
        n.Lots = n.Lots.map(l => new LotVM(l));
        return n;
      })
      .sort(sortByName);

    this.setupAllFPsAndIH();

    if (this.data?.CurrentFloorplan && this.data?.LimitToCurrent) {
      const current = this.data.CurrentFloorplan;
      const effFP = current.IsInventoryHome
        ? this.allFloorplansAndIH.find(x => x.InventoryHomeId === current.InventoryHomeId)
        : this.allFloorplansAndIH.find(
            x => x.FloorplanId === current.FloorplanId && !x.IsInventoryHome
          );
      this.filteredFloorplansAndIH = [effFP];
      this.showLotFilter = false;
    } else if (this.data?.CurrentLot || this.hcSessionService.currentBuild.Lot) {
      const lotId =
        this.data?.CurrentLot?.LotId ?? +this.hcSessionService.currentBuild.Lot?.EntityId;
      if (lotId) {
        this.dropdownSelectedLot = this.allNeighborhoodsWithLots
          .flatMap(x => x.Lots)
          .find(x => x.LotId === lotId) as LotVM;
        this.handleLotSelectChange(this.dropdownSelectedLot);
      }
    }

    this.addSub(this.hcSessionService.currentBuild$.subscribe(b => (this.currentBuild = b)));
  }

  private setupAllFPsAndIH() {
    let floorplans = this.floorplanService
      .getAll()
      .map(floorplan =>
        this.vmFactory.getEffectiveFloorplan(floorplan.FloorplanId, null, { useStore: true })
      )
      .sort(sortBySortOrder);

    if (this.availableFloorplanIds.length) {
      floorplans = floorplans.filter(fp => this.availableFloorplanIds.includes(fp.FloorplanId));
    }

    const inventoryHomes = floorplans
      .flatMap(floorplan =>
        floorplan.InventoryHomes.map(ih =>
          this.vmFactory.getEffectiveFloorplan(ih.FloorPlanId, ih.InventoryHomeId, {
            useStore: true
          })
        )
      )
      .sort(sortByName);

    this.allFloorplansAndIH = [...inventoryHomes, ...floorplans];
    this.filteredFloorplansAndIH = this.allFloorplansAndIH;
  }

  async handleFloorplanSelect(effectiveFP: EffectiveFloorplan) {
    if (await this.hcSessionService.setFloorplan(effectiveFP)) {
      this.navigator.go({
        PageTitle: PageTitle.FloorPlans,
        RouteParams: { FloorplanId: effectiveFP.FloorplanId },
        QueryParams: { inventoryHomeId: effectiveFP.InventoryHomeId }
      });
      this.dialogRef?.close();

      if (this.browser.isCompact()) this.hcModuleService.setModuleNavState(false);
    }
  }

  handleFloorplanChange() {
    this.showLotFilter = true;
    this.setupAllFPsAndIH();
    this.cdr.markForCheck();
  }

  handleLotSelectChange(lot: LotVM) {
    if (lot) {
      this.filteredFloorplansAndIH = this.allFloorplansAndIH.filter(fp => {
        if (lot.InventoryHomeIds.length)
          return fp.IsInventoryHome && lot.InventoryHomeIds.includes(fp.InventoryHomeId);
        else
          return (
            !fp.IsInventoryHome &&
            fp.NeighborhoodId === lot.NeighborhoodId &&
            (!lot.MappedFloorPlanIds.length ||
              lot.MappedFloorPlanIds.some(y => y.Id === fp.FloorplanId))
          );
      });
    } else {
      this.filteredFloorplansAndIH = this.allFloorplansAndIH;
    }
  }

  trackByFloorPlanId(index: number, floorplan: FloorplanVM) {
    return floorplan.FloorplanId;
  }

  trackByInventoryHomeId(index: number, inventoryHome: InventoryHomeVM) {
    return inventoryHome.InventoryHomeId;
  }
}

export interface IHcFpListOptions {
  CurrentFloorplan: EffectiveFloorplan;
  LimitToCurrent: boolean;
  CurrentLot: LotVM;
}

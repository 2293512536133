import { Injectable } from '@angular/core';

import { HomeBuyerVM } from '../../models/home-buyer';
import { IHomeBuyerIntegrationService } from './home-buyer-integration-factory.service';

@Injectable()
export class NoOpHomeBuyerIntegrationService implements IHomeBuyerIntegrationService {
  constructor() {}

  async register(homeBuyer: HomeBuyerVM): Promise<HomeBuyerVM> {
    return homeBuyer;
  }

  updateClientApi(): void {
    return;
  }
}

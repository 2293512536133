import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { LassoFavObj, LassoQuestionModel, LassoRegistrationModel } from '../../interfaces/lasso';
import { FavoritesSession } from '../../models/favorites-session';
import { HomeBuyerVM } from '../../models/home-buyer';
import { ApiService } from '../api.service';
import { CommunityService } from '../community.service';
import { FloorplanService } from '../floorplan.service';
import { LotService } from '../lot.service';
import {
  IHomeBuyerIntegrationService,
  VENDOR_API_KEY
} from './home-buyer-integration-factory.service';

@Injectable()
export class LassoHomeBuyerIntegrationService implements IHomeBuyerIntegrationService {
  constructor(
    private apiService: ApiService,
    private communityService: CommunityService,
    private floorplanService: FloorplanService,
    private lotService: LotService,
    @Inject(VENDOR_API_KEY) private crmApiKey: string
  ) {}

  async register(
    homeBuyer: HomeBuyerVM,
    favoritesSession: FavoritesSession,
    clientSpecificQuestions: { [key: string]: any }
  ): Promise<HomeBuyerVM> {
    // get favorites
    const favoriteFloorPlans = favoritesSession.Floorplans.map(
      x => this.floorplanService.get(x.FloorplanId).PublicName
    );
    const favoriteLots = favoritesSession.LotIds.map(x => this.lotService.get(x).LotNumber);

    const registrant = new LassoRegistrationModel({
      first: homeBuyer.FirstName,
      last: homeBuyer.LastName,
      email: homeBuyer.Email,
      phone: homeBuyer.PhoneNumber,
      community: this.communityService.current.Name,
      floorplan: favoriteFloorPlans.join(';'),
      lot: favoriteLots.join(';'),
      communityId: this.communityService.current.CommunityId,
      projectId: this.communityService.current.ProjectId,
      notes: '',
      apikey: this.crmApiKey,
      registrantId: null
    });

    const response = await this.apiService
      .postObj(registrant, 'utility/lasso/registrant')
      .toPromise();

    homeBuyer.ExternalCrmId = (response as any).registrantId;

    return homeBuyer;
  }

  updateClientApi(homeBuyer: HomeBuyerVM, favoritesSession: FavoritesSession): void {
    if (!homeBuyer.ExternalCrmId) return;

    const favsObj: LassoFavObj = {};
    favsObj.floorplans = favoritesSession.Floorplans.map(
      x => this.floorplanService.get(x.FloorplanId).PublicName
    ).join(';');
    favsObj.lots = favoritesSession.LotIds.map(x => this.lotService.get(x).LotNumber).join(';');
    favsObj.places = favoritesSession.GooglePlaces.map(x => x.Name).join(';');
    const questionModel = new LassoQuestionModel({
      apikey: this.crmApiKey,
      answer: JSON.stringify(favsObj)
    });

    // Century's favorites Lasso question ID. Will need to update to a setting for future integrations.
    const lassoFavoritesQuestionId = 114309;

    this.apiService
      .putObj(
        questionModel,
        'utility/lasso/registrant/' +
          homeBuyer.ExternalCrmId +
          '/questions/' +
          lassoFavoritesQuestionId
      )
      .toPromise()
      .then(res => {
        console.log('Lasso favorites saved');
      })
      .catch((err: HttpErrorResponse) =>
        console.error(`Error saving favorites to Lasso: ${err.message}`)
      );
  }
}

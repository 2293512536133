import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges
} from '@angular/core';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[shadowScroll]'
})
export class ShadowScrollDirective implements AfterViewInit, OnChanges {
  @Input() enabled = true;
  private lastCheck: number;

  constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.updateShadow(this.el.nativeElement);
    const scrollEvent = fromEvent(this.el.nativeElement, 'scroll');
    scrollEvent.subscribe((args: any) => this.updateShadow(args.target));

    const observer = new MutationObserver(mutations => {
      if (!this.lastCheck || this.lastCheck + 100 < Date.now()) {
        this.lastCheck = Date.now();
        this.updateShadow(this.el.nativeElement);
      }
    });

    observer.observe(this.el.nativeElement, {
      attributes: true,
      childList: true,
      characterData: true
    });
  }

  ngOnChanges() {
    this.updateShadow(this.el.nativeElement);
  }

  updateShadow(target: HTMLElement) {
    this.el.nativeElement.classList.remove('leftRightShadow', 'leftShadow', 'rightShadow');

    if (target.clientWidth !== target.scrollWidth && this.enabled) {
      let shadow = '';
      if (target.scrollLeft > 0) {
        shadow = 'leftRightShadow';
      }

      if (target.scrollLeft + target.clientWidth >= target.scrollWidth - 10) {
        shadow = 'leftShadow';
      }

      if (target.scrollLeft < 10 && target.scrollWidth > 0) {
        shadow = 'rightShadow';
      }

      this.el.nativeElement.classList.add(shadow);
    }

    this.cdr.markForCheck();
  }
}

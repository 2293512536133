<div *ngIf="isLoading && images.length && !currentImage?.IsVideo" class="loader" @enterLeaveFadeAnimation>
    <div class="spin-stretch"></div>
</div>
<header [ngClass]="{'has-title': showTitle && currentImage?.Title, 'has-inventory-home-label': isInventoryHomeGallery}"
        [class.top-header-controls]="topControls">
    <div *ngIf="showTitle && currentImage?.Title" class="title">{{currentImage.Title}}</div>
    <div *ngIf="isInventoryHomeGallery" class="inventory-home-label">{{ theme.inventoryHomeLabel }}</div>
</header>

<div class="swipe-container">
    <div class="swipe-wrapper" gallerySwiper [index]="selectedIndex" [slideCount]="images.length" (indexChange)="handleSwipeIndexChange($event)">
        <div class="image-slide" *ngFor="let item of images; trackBy: trackById; index as i">
            <ng-container *ngIf="selectedIndex - 1 <= i && i <= selectedIndex + 1">
                <img *ngIf="!item.IsVideo && !item.IsIframe && !item.InnerHtml" [src]="getImageSrc(item)" (load)="onResourceLoadOrError()"
                     [alt]="item.Title || altText + (i + 1)" (error)="onResourceLoadOrError()"
                     [class.fit-contain]="item.Type === types.Floor || useLetterbox" @enterLeaveFadeAnimation>

                <div *ngIf="item.InnerHtml" class="html-container" [innerHTML]="item.InnerHtml | safeHtml"></div>

                <iframe *ngIf="item?.IsIframe" [src]="item.FullUrl | safeUrl" (load)="onResourceLoadOrError()"></iframe>

                <video-with-preview *ngIf="item?.IsVideo" [src]="item.FullUrl" [previewUrl]="item.ThumbnailUrl" @enterLeaveFadeAnimation>
                </video-with-preview>
            </ng-container>
        </div>
    </div>
</div>
<div *ngIf="images.length > 1 && showControls || topControls" class="gallery-controls" [class.top-controls]="topControls">
    <icon-button *ngIf="topControls && images.length > 1 || !topControls" [class.btn-background]="buttonBkgEnabled" icon="carrot_arrow"
                 class="left-arrow" (click)="goToImage(selectedIndex - 1, true)" ariaLabel="Previous">
    </icon-button>
    <div *ngIf="topControls && images.length > 1 || !topControls" class="pager">
        <p class="page-number">
            {{selectedIndex + 1}} / {{images.length}}
        </p>
    </div>
    <icon-button *ngIf="topControls && images.length > 1 || !topControls" [class.btn-background]="buttonBkgEnabled" icon="carrot_arrow"
                 class="right-arrow" [class.top-screen]="topControls" (click)="goToImage(selectedIndex + 1, true)" ariaLabel="Next">
    </icon-button>
    <icon-button *ngIf="topControls && currentImage" class="fullscreen-btn" [class.btn-background]="buttonBkgEnabled" icon="fullscreen_enter"
                 (click)="goToFullscreen()" ariaLabel="Full Screen">
    </icon-button>
</div>

<favorite-btn *ngIf="!disableFavoriting && currentImage?.IsFavoritable" [isActive]="currentImage?.IsFavorited" (toggle)="handleFavoriteToggle($event)"
              [ngClass]="favButtonPlacement === 'Right' ? 'button-right' : 'button-left'">
</favorite-btn>

<icon-button class="fullscreen-btn" [class.btn-background]="buttonBkgEnabled" *ngIf="currentImage && !topControls" icon="fullscreen_enter"
             [ngClass]="favButtonPlacement === 'Right' ? 'button-left' : 'button-right'" (click)="goToFullscreen()" ariaLabel="Full Screen">
</icon-button>
import { sortBySortOrder } from '../helpers/sorters';
import { FloorArtService } from '../services/floor-art.service';
import { ActionRequest, ActionSet, LayerAction, Logic } from './action-set';
import { FloorVM } from './floor-vm';
import { FloorplanVM } from './floorplan-vm';

import { LogicState } from '@ml/common';

/* eslint-disable no-underscore-dangle */
export class FloorPlanRules {
  FloorplanId: number;
  Floors = new Array<FloorVM>();

  constructor(private floorArtService: FloorArtService, floorplan: FloorplanVM) {
    for (const floor of floorplan.Floors) {
      this.Floors.push(new FloorVM(floor));
    }

    this.FloorplanId = floorplan.FloorplanId;
    this.setDefaultActionSets();
  }

  setDefaultActionSets() {
    for (const floor of this.Floors) {
      for (const actionSet of floor.ActionSets) {
        this.setActionSet(actionSet);
      }
    }
  }

  // Only allow the user to pass in a number so that the states cannot be modified outside of this class
  toggleActionSet(actionSetId: number, state?: boolean): Array<Logic> {
    const allActionSets = this._getAllActionSets();
    const initialStates = allActionSets.map(aset => Object.assign(new ActionSet(), aset));

    const actionSet = allActionSets.find(o => o.ActionSetId === actionSetId);
    if (!actionSet) {
      console.log(
        'ActionSet (' + actionSetId + ') does not exist on floorplan(' + this.FloorplanId + ')'
      );
      return;
    }

    if (state !== undefined) {
      actionSet.State = state ? LogicState.ON : LogicState.OFF;
    } else {
      actionSet.State = actionSet.State === LogicState.ON ? LogicState.OFF : LogicState.ON;
    }

    this.setActionSet(actionSet);
    const endStates = allActionSets.map(aset => Object.assign(new ActionSet(), aset));
    return this.compareStates(initialStates, endStates);
  }

  private setActionSet(actionSet: ActionSet) {
    for (const request of actionSet.ActionRequest) {
      if (this.requestPasses(request, actionSet.State)) {
        this.setArt(request.LayerAction);
        for (const action of request.ActionSetAction) {
          const otherActionSet = this._getActionSetById(action.TargetActionSetId);
          if (
            otherActionSet &&
            this.doesStateNeedChanged(otherActionSet.State, action.DesiredState)
          ) {
            otherActionSet.State = action.DesiredState;
            this.setActionSet(otherActionSet);
          }
        }
      }
    }
  }

  private doesStateNeedChanged(currentState: LogicState, desiredState: LogicState) {
    if (currentState === LogicState.ON && desiredState === LogicState.ENABLED) return false;
    else return currentState !== desiredState;
  }

  requestPasses(request: ActionRequest, state: LogicState): boolean {
    if (typeof state !== 'number') state = LogicState.OFF;

    return (
      this.areStatesEquivalent(state, request.ActionSetState) &&
      this.logicCheckPasses(request.Logic)
    );
  }

  private areStatesEquivalent(state1: LogicState, state2: LogicState) {
    // For logic check purposes, disabled_off and enabled are equivalent to off
    const flattenState = s =>
      s === LogicState.DISABLED_OFF || s === LogicState.ENABLED ? LogicState.OFF : s;
    return flattenState(state1) === flattenState(state2);
  }

  /** The allLogic is an array of arrays. Each child array should be treated as "or" logic statements with respect to each other.
   * Then the inner logic of those are "and" type statements. Thats why first it uses some() and then every()
   */
  private logicCheckPasses(allLogicRuleSets: Array<Array<Logic>>): boolean {
    const ruleIsTrue = (logic: Logic) => {
      const aset = this._getActionSetById(logic.Id);
      return !aset || this.areStatesEquivalent(aset.State, logic.State);
    };

    if (!allLogicRuleSets.length) return true;
    // the "and" in logicAndSet is meant to define its logic operator type not literal "logic and set"
    return allLogicRuleSets.some(logicAndSet => logicAndSet.every(ruleIsTrue));
  }

  compareStates(intialSets: Array<ActionSet>, endSets: Array<ActionSet>): Array<Logic> {
    return endSets
      .filter(endSet => {
        const initSet = intialSets.find(i => i.ActionSetId === endSet.ActionSetId);
        return endSet.State !== initSet.State;
      })
      .map(set => new Logic(set.ActionSetId, set.State));
  }

  private setArt(layerActions: Array<LayerAction>) {
    for (const layerAction of layerActions) {
      const passedLogic = this.logicCheckPasses(layerAction.Logic);

      for (const layertoLayerAction of layerAction.LayerToLayerAction) {
        this.floorArtService.toggleArtOnFloorPlan(
          this.FloorplanId,
          layertoLayerAction.Layer.InstanceId,
          passedLogic ? layertoLayerAction.State : this.getOppositeState(layertoLayerAction.State)
        );
      }
    }
  }

  private getOppositeState(state: LogicState): LogicState {
    switch (state) {
      case LogicState.ON:
      case LogicState.DISABLED_ON:
        return LogicState.OFF;
      case LogicState.OFF:
      case LogicState.ENABLED:
      case LogicState.DISABLED_OFF:
        return LogicState.ON;
      default:
        return LogicState.ERROR;
    }
  }

  isFloorDisabled(floorId: number): boolean {
    // find ActionSets targeting this floor
    const relatedAsets = this._getAllActionSets().filter(aset => aset.AffectedFloorId === floorId);
    // split into groups of options that enable the floor or disable the floor
    const enablers = relatedAsets.filter(x => x.StateAffectingFloor !== LogicState.DISABLED_OFF);
    const disablers = relatedAsets.filter(x => x.StateAffectingFloor === LogicState.DISABLED_OFF);

    return (
      !!relatedAsets.length &&
      (enablers.every(x => x.State !== LogicState.ON) ||
        disablers.some(x => x.State === LogicState.ON))
    );
  }

  getActionSetById(id: number): ActionSet {
    return Object.assign(new ActionSet(), this._getActionSetById(id));
  }

  private _getActionSetById(id: number): ActionSet {
    const actionSets = this._getAllActionSets();
    return actionSets.find(set => set.ActionSetId === id);
  }

  getAllActionSets(): Array<ActionSet> {
    const actionSets = this._getAllActionSets();
    return actionSets.map(actionSet => new ActionSet(actionSet, actionSet.State));
  }

  private _getAllActionSets(): Array<ActionSet> {
    return this.Floors.flatMap(floor => floor.ActionSets).sort(sortBySortOrder);
  }

  getInMenuActionSets(): Array<ActionSet> {
    const actionSets = this.getAllActionSets();
    return actionSets.filter(o => o.IsInMenu && !o.IsGroup);
  }

  getOnInMenuActionSets(): Array<ActionSet> {
    const actionSets = this.getInMenuActionSets();
    return actionSets.filter(o => o.State === LogicState.ON);
  }

  getOnActionSets(): Array<ActionSet> {
    const actionSets = this.getAllActionSets();
    return actionSets.filter(o => o.State === LogicState.ON);
  }
}

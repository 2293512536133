import { GalleryImageVM } from '../../shared/gallery/gallery-image-vm';

import { IMapImage } from '@ml/common';

export default class MapImageVM {
  MapImages: IMapImage[];
  AllGalleryImages: GalleryImageVM[] = [];

  constructor(data: IMapImage[], baseUrl: string) {
    if (data.length) this.MapImages = data;

    data.forEach(x => {
      this.AllGalleryImages.push(
        new GalleryImageVM(baseUrl, x.ImageFilename).HydrateFromMapImage(x)
      );
    });
  }
}

<section class="accordion-container" [class.open]="isOpen" [class.no-collapse]="!allowCollapse">
  <header *ngIf="isHeaderVisible" class="accordion-header" [ngClass]="{'left-expander': leftSideExpander}" (click)="toggleOpen()">
    <ng-content select="[header]"></ng-content>

    <button *ngIf="allowCollapse" class="toggler" [attr.aria-label]="finalAriaLabel">
      <icon *ngIf="!isOpen" name="accordion_to_open"></icon>
      <icon *ngIf="isOpen" name="accordion_to_close"></icon>
    </button>
  </header>
  <ng-content></ng-content>
  <div class="accordion-body" [@heightExpandCollapse]="animationState" (@heightExpandCollapse.done)="animationStartStop($event)"
       (@heightExpandCollapse.start)="animationStartStop($event)" [class.is-hidden]="isHidden && !ignoreAccessibiliy">
    <div class="body-content">
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
</section>
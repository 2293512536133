import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { getDefaultIconNameForPage } from '../../utility/helpers/helpers';
import { AutoUnsubscriber } from '../../utility/helpers/mixins';
import { PageTitle } from '../../utility/services/navigation.service';
import { PageService } from '../../utility/services/page.service';
import { SettingsService } from '../../utility/services/settings.service';
import { ThemeService } from '../../utility/services/theme.service';

@Component({
  selector: 'floating-page-title',
  templateUrl: './floating-page-title.component.html',
  styleUrls: ['./floating-page-title.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloatingPageTitleComponent
  extends AutoUnsubscriber
  implements OnInit, AfterViewChecked
{
  @Input() pageTitle: PageTitle;
  @Input() text: string;
  @Input() iconName: string;
  iconSvg: string;
  showIconBackground = true;

  constructor(
    private pageService: PageService,
    private settingsService: SettingsService,
    private theme: ThemeService,
    private elementRef: ElementRef
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.pageTitle) {
      const page = this.pageService.get(this.pageTitle);
      if (page) {
        if (!this.text) this.text = page.NavigationTitle || page.PageTitle || this.pageTitle;

        if (!this.iconName) {
          this.iconName = getDefaultIconNameForPage(this.pageTitle);
          this.iconSvg = page.IconSvgContent;
        }
      }
    }

    this.showIconBackground = this.settingsService.get('ShowHeaderIconBkgrnd');

    this.addSub(
      fromEvent(window, 'resize')
        .pipe(debounceTime(300))
        .subscribe(() => this.updateCssVar())
    );
  }

  ngAfterViewChecked() {
    this.updateCssVar();
  }

  private updateCssVar() {
    const height = this.elementRef.nativeElement.clientHeight;
    this.theme.updateCssVariable('current-floating-page-title-height', `${height}px`);
  }
}

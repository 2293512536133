import { Injectable } from '@angular/core';

import { ICommunity, IHomeBuyer } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  private community: ICommunity;
  private homeBuyers = new Array<IHomeBuyer>();

  constructor() {}

  set currentCommunity(c: ICommunity) {
    this.community = c;
  }

  get currentCommunity() {
    if (!this.community) throw Error('No community in data store');
    return this.community;
  }

  getHomeBuyerByClientIdAndEmail(clientId: number, email: string) {
    return this.homeBuyers[this.getHomeBuyerIndexByClientIdAndEmail(clientId, email)];
  }

  private getHomeBuyerIndexByClientIdAndEmail(clientId: number, email: string) {
    return this.homeBuyers.findIndex(
      hb => hb.ClientId === clientId && hb.Email.toLowerCase() === email.toLowerCase()
    );
  }

  addOrUpdateHomeBuyer(homeBuyer: IHomeBuyer) {
    const index = this.getHomeBuyerIndexByClientIdAndEmail(homeBuyer.ClientId, homeBuyer.Email);
    if (index > -1) {
      this.homeBuyers[index] = homeBuyer;
    } else {
      this.homeBuyers.push(homeBuyer);
    }
  }
}

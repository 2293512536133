import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BadgeComponent {
  @Input() icon: string;
  @Input() value: string | number;
}

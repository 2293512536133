<accordion *ngFor="let accor of sections; first as isFirstOuter" [openByDefault]="isFirstOuter">
    <div header>
        <icon *ngIf="accor.IconSvgContent" [svg]="accor.IconSvgContent"></icon>
        <h2 [innerHTML]="accor.Title"></h2>
        <favorite-btn *ngIf="accor.IsFavoritable" (toggle)="handleFavoriteToggle($event, accor.Id)" [isActive]="accor.IsFavorited"></favorite-btn>
    </div>
    <div body>
        <ng-container *ngFor="let item of accor.TextItems">
            <p *ngIf="item.Paragraph" [innerHTML]="item.Paragraph"></p>
            <ul class="with-bullets" *ngIf="item.BulletListItems.length">
                <li *ngFor="let blItem of item.BulletListItems" [innerHTML]="blItem"></li>
            </ul>
        </ng-container>
        <accordion *ngFor="let subAccor of accor.SubAccordions; first as isFirstInner" [openByDefault]="isFirstInner">
            <div header>
                <h2 [innerHTML]="subAccor.Title"></h2>
            </div>
            <div body>
                <ng-container *ngFor="let subItem of subAccor.TextItems">
                    <p *ngIf="subItem.Paragraph" [innerHTML]="subItem.Paragraph"></p>
                    <ul class="with-bullets" *ngIf="subItem.BulletListItems.length">
                        <li *ngFor="let blItem of subItem.BulletListItems" [innerHTML]="blItem"></li>
                    </ul>
                </ng-container>
            </div>
        </accordion>
    </div>
</accordion>
<ng-container *ngFor="let item of pdfs">
  <div class="row-item-container">
    <div class="row-content-container">
      <icon name="pdf" svg="{{item.IconSvgContent}}"></icon>
      <h3>{{ item.Title }}</h3>
    </div>
    <div class="row-content-container">
      <icon-button text="View" icon="arrow_right" (click)="handleSelectPdf(item)"></icon-button>
      <favorite-btn *ngIf="item.IsFavoritable && !inBrochure" [isActive]="item.IsFavorited" class="fav-btn" (toggle)="handleFavoriteToggle(item)">
      </favorite-btn>
      <icon-button class="fav-btn" *ngIf="inBrochure" icon="trash_can" (click)="removeFavoriteItem(item)"></icon-button>
    </div>
  </div>
</ng-container>

<ng-container *ngFor="let image of images">
  <div class="row-item-container">
    <div class="row-content-container">
      <icon name="image"></icon>
      <h3>{{ image.Title }}</h3>
    </div>
    <div class="row-content-container">
      <icon-button text="View" icon="arrow_right" (click)="goToFullScreen(image)"></icon-button>
    </div>
  </div>
</ng-container>

<div *ngIf="showModal" class="pdf-viewer">
  <div class="modal-backdrop blocking-overlay-backdrop" @enterLeaveFadeAnimation>
    <div class="modal">
      <div class="modal-header">
        <div class="title-container">
          <icon name="pdf" svg="{{ selectedPdf.IconSvgContent }}"></icon>
          <h3>{{ selectedPdf.Title }}</h3>
        </div>
        <div class="btn-container">
          <favorite-btn *ngIf="selectedPdf.IsFavoritable && !inBrochure" class="fav-btn" [isActive]="selectedPdf.IsFavorited"
                        (toggle)="handleFavoriteToggle(selectedPdf)"></favorite-btn>
          <icon-button icon="x" class="close-icon fav-btn" (click)="closeModal()"></icon-button>
        </div>
      </div>
      <div class="modal-content">
        <div *ngIf="selectedPdf.ExternalLink" class="pdf-container">
          <iframe [src]="selectedPdf.ExternalLink | safeUrl" (load)="handleIframeLoad()"></iframe>
        </div>
        <div *ngIf="isLoading" class="loader" @enterLeaveFadeAnimation>
          <div class="spin-stretch"></div>
        </div>
      </div>
    </div>
  </div>
</div>
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { MattamyHomeBuyer } from '../../interfaces/client-integration';
import { FavoritesSession } from '../../models/favorites-session';
import { HomeBuyerVM } from '../../models/home-buyer';
import { FloorplanService } from '../floorplan.service';
import { InventoryHomeService } from '../inventory-home.service';
import { LotService } from '../lot.service';
import { SalesRepresentativeService } from '../sales-representative.service';
import {
  IHomeBuyerIntegrationService,
  VENDOR_API_KEY
} from './home-buyer-integration-factory.service';

@Injectable()
export class MattamyHomebuyerIntegrationService implements IHomeBuyerIntegrationService {
  // Same credentials used for Mattamy and Mattamy-Canada
  apiClientId = '24987f9e2a804ef6832d8b1827dcfa47';
  apiClientSecret = '1e60c499e5f04314AC6aB71Af43952d0';
  apiUrl: string;

  private simpleHttp: HttpClient;

  constructor(
    private floorplanService: FloorplanService,
    private lotService: LotService,
    private inventoryHomeService: InventoryHomeService,
    handler: HttpBackend,
    @Inject(VENDOR_API_KEY) private crmApiKey: string,
    private salesRepService: SalesRepresentativeService
  ) {
    if (environment.production) {
      this.apiUrl = 'https://mattamy-my360-opp-eapi-prd.ca-c1.cloudhub.io/v1/opportunity';
    } else {
      this.apiUrl = 'https://mattamy-my360-opp-eapi-nonprd.ca-c1.cloudhub.io/v1/opportunity';
    }

    this.simpleHttp = new HttpClient(handler);
  }

  async register(
    homeBuyer: HomeBuyerVM,
    favoritesSession: FavoritesSession,
    clientSpecificQuestions: { [key: string]: any }
  ): Promise<HomeBuyerVM> {
    homeBuyer.CustomData = { ...homeBuyer.CustomData, ...clientSpecificQuestions };

    const mattamyHomeBuyer = new MattamyHomeBuyer(
      homeBuyer,
      this.crmApiKey,
      true,
      this.salesRepService.current
    );

    await this.postToClientApi(mattamyHomeBuyer);

    return homeBuyer;
  }

  updateClientApi(
    homeBuyer: HomeBuyerVM,
    favoritesSession: FavoritesSession,
    isLoggingOut?: boolean
  ): void {
    // Current requirements are only to update on logout
    if (!isLoggingOut) return;

    const favPlanNames = favoritesSession.FavoriteFloorplans.map(fp =>
      fp.IsInventoryHome
        ? this.inventoryHomeService.get(fp.InventoryHomeId).Name
        : this.floorplanService.get(fp.FloorplanId).PublicName
    ).join(',');
    const favLots = favoritesSession.LotIds.map(
      l => `Homesite ${this.lotService.get(l).ClientUniqueIdentifier}`
    ).join(',');

    const mattamyData = new MattamyHomeBuyer(
      homeBuyer,
      this.crmApiKey,
      false,
      this.salesRepService.current
    );

    if (favPlanNames.length) mattamyData.FavFloorplans = favPlanNames;
    if (favLots.length) mattamyData.FavHomesites = favLots;

    this.postToClientApi(mattamyData);
  }

  private async postToClientApi(mattamyData: MattamyHomeBuyer) {
    const options = {
      headers: new HttpHeaders({
        ['client_id']: this.apiClientId,
        ['client_secret']: this.apiClientSecret,
        ['Access-Control-Allow-Origin']: '*',
        ['Content-Type']: 'application/json'
      })
    };

    // the standard http client uses an interceptor and would not allow additional headers.
    // simpHttp accepts custom options for the headers to add the proper credentials

    try {
      await this.simpleHttp.put(this.apiUrl, mattamyData, options).toPromise();
      console.log('Successfully registered Mattamy Homebuyer');
    } catch (err) {
      console.log(`Falied to register Mattamy homebuyer ${err}`);
      console.log({ err });
    }
  }
}

import { sortBySortOrder } from '../helpers/sorters';
import { ActionSet } from './action-set';

import { IFloor, IHotspot, LogicState } from '@ml/common';

export class FloorVM implements IFloor {
  ActionSets: ActionSet[] = [];
  FloorId: number;
  FloorplanId: number;
  PreviewFilename: string;
  SvgFilename: string;
  Title: string;
  Hotspots: IHotspot[] = [];
  IsOption: boolean;
  IsBasement: boolean;
  IsDefaultFloor: boolean;
  FloorNumber: number;
  AffectedActionSetId: number;
  FullSvgAsString: string;

  BaseUrl: string;
  SelectedOptions = '';
  UnSelectedOptions = '';
  FullSvgUrl = '';
  FullPreviewUrl = '';
  IsStaticArt = false;

  constructor(
    data?: IFloor,
    baseUrl?: string,
    selectedOptionIds?: number[],
    svgRevisionDate?: string
  ) {
    if (data) Object.assign(this, data);

    this.BaseUrl = baseUrl;
    if (this.SvgFilename) {
      this.FullSvgUrl = `${this.BaseUrl}/${this.SvgFilename}`;
      // only use date query param if online, otherwise it could mess with SW cache
      if (svgRevisionDate && navigator.onLine)
        this.FullSvgUrl += `?d=${new Date(svgRevisionDate).getTime()}`;
    }
    if (this.PreviewFilename) this.FullPreviewUrl = `${this.BaseUrl}/${this.PreviewFilename}`;
    this.IsStaticArt = !this.FullSvgUrl;

    this.ActionSets = this.ActionSets.map(
      a => new ActionSet(a, selectedOptionIds?.includes(a.ActionSetId) ? LogicState.ON : a.State)
    ).sort(sortBySortOrder);
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { GallerySwiperDirective } from '../../shared/gallery-swipe.directive';
import {
  GalleryImageParentType,
  GalleryImageType,
  GalleryImageVM
} from '../../shared/gallery/gallery-image-vm';
import { FavoriteType } from '../../utility/helpers/favorite-types.enum';
import { FavoritesSession } from '../../utility/models/favorites-session';
import { FavoritesService } from '../../utility/services/favorites.service';
import { OverlayService, OverlayTypes } from '../../utility/services/overlay.service';
import { SettingsService } from '../../utility/services/settings.service';
import { enterLeaveFadeAnimation } from '../animations';
import { FullscreenGalleryVM } from './fullscreen-gallery-vm';

@Component({
  selector: 'fullscreen-gallery',
  templateUrl: './fullscreen-gallery.component.html',
  styleUrls: ['./fullscreen-gallery.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [enterLeaveFadeAnimation()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullscreenGalleryComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(GallerySwiperDirective) gallerySwiper: GallerySwiperDirective;
  isShowing = false;
  images: GalleryImageVM[] = [];
  selectedIndex = 0;
  isLoading = true;
  fullscreen = false;
  hideGalleryControls = false;
  types = GalleryImageType;
  buttonBkgEnabled = true;

  private favoritesSub: Subscription;
  private unsubscribe$ = new Subject<void>();

  get currentImage(): GalleryImageVM {
    return this.images[this.selectedIndex];
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private overlay: OverlayService,
    private settingsService: SettingsService,
    private favorites: FavoritesService
  ) {}

  ngOnInit() {
    this.overlay.state$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(state => state.targetedComponent === OverlayTypes.FullscreenGallery)
      )
      .subscribe(state => {
        this.isShowing = state.isShowing;
        this.isLoading = state.isShowing;

        if (state.data) {
          const data = state.data as FullscreenGalleryVM;
          this.images = data.Images;
          this.selectedIndex = data.SelectedIndex;
        }

        if (this.isShowing) {
          if (this.images.every(x => x.Type === GalleryImageType.Floor)) this.isLoading = false;

          this.favoritesSub = this.favorites.current$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(this.handleFavoritesChange);
        } else if (this.favoritesSub) this.favoritesSub.unsubscribe();

        if (this.currentImage?.ParentType === GalleryImageParentType.Floorplan) {
          const fullScreenSetting = this.settingsService.get('Display Elevations');
          if (fullScreenSetting === 'Full Screen') this.fullscreen = true;
        }

        if (this.currentImage?.ParentType === GalleryImageParentType.CommunityMap) {
          const fullScreenSetting = this.settingsService.get('CommunityMapHotspotMediaDisplay');
          if (fullScreenSetting === 'Full Screen') this.fullscreen = true;

          this.hideGalleryControls = this.settingsService.get(
            'CommunityMapHotspotMediaHideCarouselUI'
          );
        }

        this.cdr.detectChanges();
      });

    this.buttonBkgEnabled = this.settingsService.get('GalleryBtnBkgEnabled');
  }

  ngAfterViewInit(): void {
    document.addEventListener('keyup', (evt: KeyboardEvent) => {
      if (evt.code === 'Escape' && this.isShowing) this.closeFullscreen();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onImgLoad = () => {
    this.isLoading = false;
    this.cdr.detectChanges();
  };

  goToImage(index: number) {
    index = this.validateIndexBounds(index);
    this.selectedIndex = index;

    if (this.images[index].IsIframe) this.gallerySwiper.enableTouchAction();
    else this.gallerySwiper.disableTouchAction();

    this.cdr.detectChanges();
  }

  onIndexChange(index: number) {
    this.goToImage(index);
  }

  private validateIndexBounds(index: number) {
    if (index > this.images.length - 1) index = 0;
    if (index < 0) index = this.images.length - 1;
    return index;
  }

  closeFullscreen() {
    this.overlay.setState({
      isShowing: false,
      targetedComponent: OverlayTypes.FullscreenGallery,
      data: new FullscreenGalleryVM(this.images, this.selectedIndex) // send current index back down
    });
  }

  handleFavoriteToggle(state: boolean) {
    if (this.currentImage.Type === GalleryImageType.Elevation) {
      this.handleElevationFavorite(state);
    } else if (this.currentImage.Type === GalleryImageType.Media) {
      this.handleMediaFavorite(state);
    } else if (this.currentImage.Type === GalleryImageType.Amenity) {
      this.handleAmenityMediaFavorite(state);
    }
  }

  private handleAmenityMediaFavorite(isFavorited: boolean) {
    if (isFavorited) {
      this.favorites.add({ favoriteType: FavoriteType.AmenityMedia, id: this.currentImage.Id });
    } else {
      this.favorites.remove(FavoriteType.AmenityMedia, this.currentImage.Id);
    }
  }

  private handleMediaFavorite(isFavorited: boolean) {
    if (isFavorited) {
      if (this.currentImage.ParentType === GalleryImageParentType.InventoryHome) {
        this.favorites.addInventoryHomeMedia(this.currentImage.Id, this.currentImage.ParentId);
      } else if (this.currentImage.ParentType === GalleryImageParentType.Lot) {
        this.favorites.addLotMedia(this.currentImage.Id, this.currentImage.ParentId);
      } else {
        this.favorites.addFloorplanMedia(this.currentImage.Id, this.currentImage.ParentId);
      }
    } else if (this.currentImage.ParentType === GalleryImageParentType.InventoryHome) {
      this.favorites.removeInventoryHomeMedia(this.currentImage.Id, this.currentImage.ParentId);
    } else if (this.currentImage.ParentType === GalleryImageParentType.Lot) {
      this.favorites.removeLotMedia(this.currentImage.Id, this.currentImage.ParentId);
    } else {
      this.favorites.removeFloorplanMedia(this.currentImage.Id);
    }
  }

  private handleElevationFavorite(isFavorited: boolean) {
    if (isFavorited) {
      if (this.currentImage.ParentType === GalleryImageParentType.InventoryHome) {
        this.favorites.addInventoryHomeElevation(this.currentImage.Id, this.currentImage.ParentId);
      } else {
        this.favorites.addFloorplanElevation(this.currentImage.Id, this.currentImage.ParentId);
      }
    } else {
      if (this.currentImage.ParentType === GalleryImageParentType.InventoryHome) {
        this.favorites.removeInventoryHomeElevation(
          this.currentImage.Id,
          this.currentImage.ParentId
        );
      } else {
        this.favorites.removeFloorplanElevation(this.currentImage.Id);
      }
    }
  }

  private handleFavoritesChange = (favSession: FavoritesSession) => {
    this.images = this.images.map(x => {
      let isFav;
      if (x.ParentType === GalleryImageParentType.Floorplan) {
        if (x.Type === GalleryImageType.Elevation)
          isFav = favSession.containsFloorplanElevation(x.Id);
        else isFav = favSession.containsFloorplanMedia(x.Id);
      } else if (x.ParentType === GalleryImageParentType.InventoryHome) {
        if (x.Type === GalleryImageType.Elevation)
          isFav = favSession.containsInventoryHomeElevation(x.Id, x.ParentId);
        else isFav = favSession.containsInventoryHomeMedia(x.Id);
      } else if (x.Type === GalleryImageType.Amenity) {
        isFav = favSession.containsAmenityMedia(x.Id);
      } else if (x.Type === GalleryImageType.Media) {
        isFav = favSession.containsLotMedia(x.Id);
      }
      return { ...x, IsFavorited: isFav } as GalleryImageVM;
    });
  };

  handleSwipeIndexChange(newIndex: number) {
    this.goToImage(newIndex);
  }

  trackById(index: number, item: GalleryImageVM) {
    return item.Id;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import { EffectiveFloorplan } from '../../utility/models/effective-floorplan';
import { SettingsService } from '../../utility/services/settings.service';
import { ViewModelFactoryService } from '../../utility/services/vm-factory.service';

@Component({
  selector: 'floorplan-specs',
  templateUrl: './floorplan-specs.component.html',
  styleUrls: ['./floorplan-specs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloorplanSpecsComponent implements OnInit, OnChanges {
  @Input() fp: EffectiveFloorplan;
  @Input() forceNonCollapsible = false;
  @Input() showPrice = true;
  isOpen = false;
  isCollapsible = false;

  constructor(
    private settingsService: SettingsService,
    private vmFactory: ViewModelFactoryService
  ) {}

  ngOnInit(): void {
    this.isCollapsible = this.settingsService.get('DetailsCollapsed');
    if (!this.isCollapsible) this.isOpen = true;
    if (this.forceNonCollapsible) {
      this.isCollapsible = false;
      this.isOpen = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fp && !changes.fp.isFirstChange && this.fp) {
      this.vmFactory.setupSpecCounts(this.fp);
    }
  }

  toggleState() {
    this.isOpen = !this.isOpen;
  }

  handleBlur() {
    if (this.isCollapsible) this.isOpen = false;
  }
}

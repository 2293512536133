import { FavoritesSession } from './favorites-session';
import { FloorplanVM } from './floorplan-vm';

import {
  IActionSet,
  IElevation,
  IInventoryHome,
  ILot,
  InventoryHomeCustomData,
  ITag,
  sortByName
} from '@ml/common';
import { MediaVM } from './media-vm';

export class InventoryHomeVM implements IInventoryHome {
  InventoryHomeId: number;
  Name: string;
  NumberOfBedrooms: number;
  TotalSquareFeet: number;
  NumberOfFullBaths: number;
  NumberOfHalfBaths: number;
  NumberOfGarages: number;
  GarageCarSize: number;
  Swing: string;
  IsReversed: boolean;
  CustomElevationName: string;
  CustomElevationFilename: string;
  Price: number;
  Address: string;
  FloorPlanId: number;
  ElevationId: number;
  Elevation: IElevation;
  CreatedOn: string;
  CreatedBy: string;
  ModifiedOn: string;
  ModifiedBy: string;
  Lots: ILot[];
  LotIds: number[];
  ActionSets: IActionSet[];
  ActionSetIds: number[];
  Description: string;
  ClientUniqueIdentifier: string;
  MoveInDate?: string;
  CustomData: InventoryHomeCustomData;
  Tags: ITag[];
  Media: MediaVM[];

  IsFavorited: boolean;
  NeighborhoodId: number;
  AssociatedLots: ILot[] = [];
  FloorPlan: FloorplanVM;
  CombinedBathrooms: number;
  AllTagNames: string;
  BaseUrl = '';

  constructor(
    data?: IInventoryHome,
    session?: FavoritesSession,
    communityBaseUrl?: string,
    floorplan?: FloorplanVM,
    associatedLots?: ILot[]
  ) {
    Object.assign(this, data);

    this.IsFavorited = session?.containsInventoryHome(this.InventoryHomeId);

    if (communityBaseUrl)
      this.BaseUrl = `${communityBaseUrl}/InventoryHomes/${this.InventoryHomeId}`;

    if (floorplan) this.FloorPlan = floorplan;
    this.NeighborhoodId = floorplan ? floorplan.NeighborhoodId : 0;
    this.Name = floorplan ? floorplan.PublicName || floorplan.Title : '';
    if (associatedLots) this.AssociatedLots = associatedLots;

    if (
      (this.NumberOfFullBaths !== null && this.NumberOfFullBaths !== undefined) ||
      (this.NumberOfHalfBaths !== null && this.NumberOfHalfBaths !== undefined)
    )
      this.CombinedBathrooms = this.NumberOfFullBaths + this.NumberOfHalfBaths / 2;

    this.AllTagNames = this.Tags?.sort(sortByName)
      .map(t => t.Name)
      .join(', ');
  }
}

<div class="card-header">
    <div *ngIf="isSelected" class="bubble">
        <icon name="check"></icon>
    </div>
    <div class="flex column">
        <h4>{{effectiveFP.Name}}</h4>
        <p>{{effectiveFP.NeighborhoodName}}</p>
    </div>

    <div class="flex row align-center push-right">
        <hc-ih-badge *ngIf="effectiveFP.IsInventoryHome"></hc-ih-badge>

        <div class="index" *ngIf="indexInList && listTotal > 1">
            <b>{{indexInList}} </b>/ {{listTotal}}
        </div>
    </div>
</div>
<div class="card-body">
    <gallery [images]="galleryImages" [disableFavoriting]="true">
    </gallery>

    <div class="container">
        <div class="flex row align-center justify-between">
            <p *ngIf="effectiveFP.Price || effectiveFP.PriceRange">Base Price <b>{{effectiveFP.PriceDisplay}}</b></p>
            <p *ngIf="effectiveFP.AssociatedLots.length">{{lotLabelPlural}} Available: <b>{{effectiveFP.AssociatedLots.length}}</b></p>
        </div>

        <button *ngIf="!isSelected" class="hc-btn primary fp-select-btn" (click)="floorplanSelect.emit()" aria-label="Select Floor Plan">Select
            {{theme.fpLabel}}</button>
        <button *ngIf="listTotal > 1 && isSelected" class="hc-btn primary fp-select-btn success">
            <icon name="check"></icon>
            Selected
        </button>
        <button *ngIf="listTotal === 1 && isSelected" class="hc-btn negative fp-select-btn" (click)="floorplanChange.emit()"
                aria-label="Change Floor Plan">
            <icon name="arrows_switch"></icon>
            Change {{theme.fpLabel}}
        </button>

        <floorplan-specs [fp]="effectiveFP" [forceNonCollapsible]="true" [showPrice]="false"></floorplan-specs>
    </div>
</div>
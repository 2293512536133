/* eslint-disable @typescript-eslint/naming-convention */
export function roundToPlace(num, place = 0) {
  const mult = Math.pow(10, place);
  return Math.round(mult * num) / mult;
}
export function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}
export function getDistanceBetweenLatLng(lat1, lng1, lat2, lng2) {
  const EARTH_RADIUS = 3963.1676;
  const dLat = degreesToRadians(lat2 - lat1);
  const dLng = degreesToRadians(lng2 - lng1);
  // Magic
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = EARTH_RADIUS * c; // Distance in miles
  return d;
}
export function getDistanceBetweenLatLngRounded(lat1, lng1, lat2, lng2, place = 0) {
  return roundToPlace(getDistanceBetweenLatLng(lat1, lng1, lat2, lng2), place);
}
export function filterByUniqueItems(item, index, array) {
  return array.indexOf(item) === index;
}
export function removeFromArray(item, array) {
  const copy = array.slice();
  const i = copy.findIndex(x => x === item);
  if (i > -1) copy.splice(i, 1);
  return copy;
}
// Very basic pluralization helper. We should definitely update this for more usecases
// Use with caution
const plural = {
  '(quiz)$': '$1zes',
  '^(ox)$': '$1en',
  '([m|l])ouse$': '$1ice',
  '(matr|vert|ind)ix|ex$': '$1ices',
  '(x|ch|ss|sh)$': '$1es',
  '([^aeiouy]|qu)y$': '$1ies',
  '(hive)$': '$1s',
  '(?:([^f])fe|([lr])f)$': '$1$2ves',
  '(shea|lea|loa|thie)f$': '$1ves',
  sis$: 'ses',
  '([ti])um$': '$1a',
  '(tomat|potat|ech|her|vet)o$': '$1oes',
  '(bu)s$': '$1ses',
  '(alias)$': '$1es',
  '(octop)us$': '$1i',
  '(ax|test)is$': '$1es',
  '(us)$': '$1es',
  '([^s]+)$': '$1s'
};
const irregular = {
  move: 'moves',
  foot: 'feet',
  goose: 'geese',
  sex: 'sexes',
  child: 'children',
  man: 'men',
  tooth: 'teeth',
  person: 'people'
};
const uncountable = ['sheep', 'fish', 'deer', 'moose', 'series', 'species', 'money', 'rice', 'information', 'equipment'];
export function pluralizeString(strToPluralize) {
  const arr = plural;
  // save some time in the case that singular and plural are the same
  uncountable.forEach(x => {
    if (strToPluralize.endsWith(x)) return strToPluralize;
  });
  // check for irregular forms
  for (const word in irregular) {
    if (irregular.hasOwnProperty(word)) {
      let reGex;
      let replace;
      if (strToPluralize) {
        reGex = new RegExp(`${irregular[word]}$`, 'i');
        replace = word;
      } else {
        reGex = new RegExp(`${word}$`, 'i');
        replace = irregular[word];
      }
      if (reGex.test(strToPluralize)) return strToPluralize.replace(reGex, replace);
    }
  }
  // check for matches using regular expressions
  for (const reg in arr) {
    if (arr.hasOwnProperty(reg)) {
      const reGex = new RegExp(reg, 'i');
      if (reGex.test(strToPluralize)) return strToPluralize.replace(reGex, arr[reg]);
    }
  }
  return strToPluralize;
}
export function getPercentage(part, total) {
  return Math.trunc(part / total * 100);
}
export function convertMilesToMeters(miles) {
  return roundToPlace(miles * 5280 / 3, 0);
}
export function hasImageFileExtension(filename) {
  return /\.jpg|png|gif|tiff|tif|btf|tf8|bigtiff|jpeg|kro$/gi.test(filename);
}
export function hasVideoFileExtension(filename) {
  return /\.(mp4|mov|wmv|avi|mkv)$/gi.test(filename);
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleComponent implements OnInit {
  @Input() on: boolean;
  @Input() customFill: string;
  @Input() preventSelfSwitchOff = false;
  @Input() ariaLabel = '';
  @Output() toggle = new EventEmitter<boolean>();
  @ViewChild('toggle', { static: true }) toggleElement: ElementRef;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.customFill) {
      this.toggleElement.nativeElement.style.setProperty('--customToggleOnFill', this.customFill);
    }
  }

  onClick(evt: Event) {
    evt.stopPropagation();
    if (this.on && this.preventSelfSwitchOff) return;

    this.on = !this.on;
    this.cdr.detectChanges();
    this.toggle.emit(this.on);
  }
}

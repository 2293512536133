import { sortBySortOrder } from '../helpers/sorters';
import { ActionSet } from './action-set';
import { FloorVM } from './floor-vm';
import { OptionVM } from './option-vm';

export class FloorWithOptionsVM {
  FloorId: number;
  FloorplanId: number;
  Title: string;
  Options: OptionVM[];
  IsDisabled: boolean;
  IsOptional: boolean;
  IsStaticArt: boolean;
  AffectedActionSetId: number;
  SelectedOptionCount: number; // currently only set within hc-options-menu-desktop -- TODO make central if needed

  constructor(floorVM: FloorVM, allActionSets: ActionSet[], isDisabled: boolean = false) {
    if (floorVM) {
      this.FloorId = floorVM.FloorId;
      this.FloorplanId = floorVM.FloorplanId;
      this.Title = floorVM.Title;
      this.IsDisabled = isDisabled;
      this.IsOptional = floorVM.IsOption;
      this.IsStaticArt = !floorVM.FullSvgUrl;
      this.AffectedActionSetId = floorVM.AffectedActionSetId;

      this.Options = allActionSets
        .map(actionSet => {
          if (actionSet.FloorId === this.FloorId) {
            return new OptionVM(actionSet, floorVM.BaseUrl);
          } else if (actionSet.AdditionalFloors) {
            const adtnFloorIdAndSortOrder = actionSet.AdditionalFloors.split(',')
              .map(x => x.split(':'))
              .find(pair => +pair[0] === this.FloorId);

            if (adtnFloorIdAndSortOrder)
              return new OptionVM(
                {
                  ...actionSet,
                  FloorId: +adtnFloorIdAndSortOrder[0],
                  SortOrder: +adtnFloorIdAndSortOrder[1]
                },
                floorVM.BaseUrl
              );
          }

          return null;
        })
        .filter(Boolean)
        .sort(sortBySortOrder);

      this.SelectedOptionCount = this.Options.filter(x => x.IsOn).length;
    }
  }
}

import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';

import { AutofocusDirective } from '../global/autofocus.directive';
import { FullscreenGalleryComponent } from '../global/fullscreen-gallery/fullscreen-gallery.component';
import { PositionablePopupComponent } from '../global/positionable-popup/positionable-popup.component';
import { ToastComponent } from '../global/toast/toast.component';
import { CustomTooltipDefaults } from '../utility/helpers/configs';
import { AccordionSectionsContentComponent } from './accordion-sections-content/accordion-sections-content.component';
import { AccordionComponent } from './accordion/accordion.component';
import { BadgeComponent } from './badge/badge.component';
import { BorderOptionalDirective } from './border-optional.directive';
import { BrowserCompatibilityComponent } from './browser-compatibility/browser-compatibility.component';
import { DomChangeDirective } from './dom-change.directive';
import { ElementScrollDirective } from './element-scroll.directive';
import { ExpandingQuickMenuComponent } from './expanding-quick-menu/expanding-quick-menu.component';
import { FavoriteBtnComponent } from './favorite-btn/favorite-btn.component';
import { FavoritesCounterComponent } from './favorites-counter/favorites-counter.component';
import { FloatingPageTitleComponent } from './floating-page-title/floating-page-title.component';
import { FloatingSideMenuComponent } from './floating-side-menu/floating-side-menu.component';
import { FloorplanCardComponent } from './floorplan-card/floorplan-card.component';
import { FloorplanFeaturesComponent } from './floorplan-features/floorplan-features.component';
import { FloorplanFiltersComponent } from './floorplan-filters/floorplan-filters.component';
import { FloorplanSpecsComponent } from './floorplan-specs/floorplan-specs.component';
import { GalleryAccordionComponent } from './gallery-accordion/gallery-accordion.component';
import { GallerySwiperDirective } from './gallery-swipe.directive';
import { GalleryComponent } from './gallery/gallery.component';
import { HotspotComponent } from './hotspot/hotspot.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { IconComponent } from './icon/icon.component';
import { ImageWithTitleComponent } from './image-with-title/image-with-title.component';
import { InteractionEventsListenerDirective } from './interaction-events-listener.directive';
import { LotSelectorComponent } from './lot-selector/lot-selector.component';
import { LotZoomInComponent } from './lot-zoom-in/lot-zoom-in.component';
import { MasonryLayoutComponent } from './masonry-layout/masonry-layout.component';
import { ModalComponent } from './modal/modal.component';
import { NavigationalSectionsContentComponent } from './navigational-sections-content/navigational-sections-content.component';
import { NumericInputComponent } from './numeric-input/numeric-input.component';
import { OfflineHelpComponent } from './offline-help/offline-help.component';
import {
  CustomDataMapperPipe,
  SafeBackgroundImageUrlPipe,
  SafeHtmlPipe,
  SafeStylePipe,
  SafeUrlPipe
} from './sanitizers.pipe';
import { ScaleControlComponent } from './scale-control/scale-control.component';
import { ScrollIntoViewDirective } from './scroll-into-view.directive';
import { ShadowScrollDirective } from './scroll-shadow.directive';
import { ShareDialogComponent } from './share-dialog/share-dialog.component';
import { SitemapViewerComponent } from './sitemap-viewer/sitemap-viewer.component';
import { SlideOutMenuComponent } from './slide-out-menu/slide-out-menu.component';
import { SlideshowComponent } from './slideshow/slideshow.component';
import { SpecsPluralPipe } from './specsplural.pipe';
import { SpinnerComponent } from './spinner/spinner.component';
import { SvgInjectorDirective } from './svg-injector.directive';
import { TagFilterComponent } from './tag-filter/tag-filter.component';
import { ToggleWithLabelComponent } from './toggle-with-label/toggle-with-label.component';
import { ToggleComponent } from './toggle/toggle.component';
import { TransformApplierDirective } from './transform-applier.directive';
import { VideoWithPreviewComponent } from './video-with-preview/video-with-preview.component';
import { WatermarkBannerComponent } from './watermark-banner/watermark-banner.component';

import '@ml/web-components';
import { PanControlComponent } from './pan-control/pan-control.component';
import { ScrollingGalleryComponent } from './scrolling-gallery/scrolling-gallery.component';

@NgModule({
  declarations: [
    SlideOutMenuComponent,
    IconComponent,
    FavoriteBtnComponent,
    ToggleComponent,
    SvgInjectorDirective,
    SafeBackgroundImageUrlPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    SafeUrlPipe,
    CustomDataMapperPipe,
    InteractionEventsListenerDirective,
    TransformApplierDirective,
    ScaleControlComponent,
    BadgeComponent,
    NumericInputComponent,
    AccordionComponent,
    GalleryComponent,
    IconButtonComponent,
    SlideshowComponent,
    FloorplanFiltersComponent,
    FloorplanCardComponent,
    LotSelectorComponent,
    FloorplanFeaturesComponent,
    GalleryAccordionComponent,
    FavoritesCounterComponent,
    MasonryLayoutComponent,
    ToggleWithLabelComponent,
    DomChangeDirective,
    AutofocusDirective,
    GallerySwiperDirective,
    BorderOptionalDirective,
    BrowserCompatibilityComponent,
    VideoWithPreviewComponent,
    AccordionSectionsContentComponent,
    OfflineHelpComponent,
    ShareDialogComponent,
    WatermarkBannerComponent,
    FloorplanSpecsComponent,
    SitemapViewerComponent,
    ImageWithTitleComponent,
    ModalComponent,
    HotspotComponent,
    ElementScrollDirective,
    FloatingPageTitleComponent,
    FloatingSideMenuComponent,
    ExpandingQuickMenuComponent,
    SpinnerComponent,
    NavigationalSectionsContentComponent,
    ScrollIntoViewDirective,
    ShadowScrollDirective,
    SpecsPluralPipe,
    TagFilterComponent,
    ToastComponent,
    FullscreenGalleryComponent,
    PositionablePopupComponent,
    LotZoomInComponent,
    ScrollingGalleryComponent,
    PanControlComponent
  ],
  exports: [
    SlideOutMenuComponent,
    IconComponent,
    FavoriteBtnComponent,
    ToggleComponent,
    SvgInjectorDirective,
    SafeBackgroundImageUrlPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    SafeUrlPipe,
    CustomDataMapperPipe,
    InteractionEventsListenerDirective,
    TransformApplierDirective,
    ScaleControlComponent,
    BadgeComponent,
    NumericInputComponent,
    AccordionComponent,
    GalleryComponent,
    IconButtonComponent,
    SlideshowComponent,
    FloorplanFiltersComponent,
    FloorplanCardComponent,
    LotSelectorComponent,
    GalleryAccordionComponent,
    FavoritesCounterComponent,
    MasonryLayoutComponent,
    ToggleWithLabelComponent,
    DomChangeDirective,
    AutofocusDirective,
    GallerySwiperDirective,
    BorderOptionalDirective,
    BrowserCompatibilityComponent,
    VideoWithPreviewComponent,
    AccordionSectionsContentComponent,
    OfflineHelpComponent,
    MatSelectModule,
    MatTooltipModule,
    ShareDialogComponent,
    WatermarkBannerComponent,
    FloorplanSpecsComponent,
    SitemapViewerComponent,
    ImageWithTitleComponent,
    ModalComponent,
    HotspotComponent,
    ElementScrollDirective,
    FloatingPageTitleComponent,
    FloatingSideMenuComponent,
    ExpandingQuickMenuComponent,
    SpinnerComponent,
    NavigationalSectionsContentComponent,
    ScrollIntoViewDirective,
    ShadowScrollDirective,
    SpecsPluralPipe,
    TagFilterComponent,
    ToastComponent,
    FullscreenGalleryComponent,
    PositionablePopupComponent,
    LotZoomInComponent,
    PanControlComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, FormsModule, MatSelectModule, MatTooltipModule, A11yModule],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltipDefaults },
    SpecsPluralPipe
  ]
})
export class SharedAppModule {}

<header>
    <img [src]="brandImageUrl" />

    <span>Your&nbsp;<b>Saved</b>&nbsp;Builds</span>

    <button class="close-btn" (click)="dialogRef.close()">
        <icon name="x"></icon>
    </button>
</header>

<section class="message-section">
    Your session has been saved to this device. If you would like to share it with yourself or others, <a (click)="openShareDialog()">click here.</a>
</section>

<section class="cards-section">
    <div class="cards-header">
        <h1>Your Home Configurations</h1>
        <p class="results-count">{{builds.length}} Result{{builds.length > 1 ? 's': ''}}</p>

        <button class="hc-btn primary shadow new-build-btn" (click)="startNewBuild()">
            <icon name="plus"></icon>
            Start New Build
        </button>
    </div>

    <div class="cards-container">
        <div class="col" *ngFor="let build of builds; index as index">
            <hc-build-card [build]="build" [index]="index" [deleteDisabled]="builds.length === 1" (buildSelect)="handleBuildSelect(build)"
                           (delete)="handleBuildDelete(build)" (share)="handleShare(build)" [showScheduleAppt]="showScheduleAppt"
                           (scheduleAppt)="handleScheduleAppt(build)">
            </hc-build-card>
        </div>
    </div>
</section>
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'watermark-banner',
  templateUrl: './watermark-banner.component.html',
  styleUrls: ['./watermark-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WatermarkBannerComponent implements OnInit {
  @Input() message: string;
  @Input() styleOptions: { [key: string]: string | undefined | null };
  @Input() useBlinkAnimation = false;

  constructor() {}

  ngOnInit(): void {}
}

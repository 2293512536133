import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';

import { AppModeTypes } from '../../utility/helpers/app-mode.enum';
import { BrowserService } from '../../utility/services/browser.service';
import { NavigationService } from '../../utility/services/navigation.service';

@Component({
  selector: 'browser-compatibility',
  templateUrl: './browser-compatibility.component.html',
  styleUrls: ['./browser-compatibility.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrowserCompatibilityComponent implements OnInit {
  browserCompatible = true;
  message = '';

  constructor(private browserService: BrowserService, private navigator: NavigationService) {}

  ngOnInit(): void {
    const isInStandaloneMode = this.navigator.AppMode === AppModeTypes.StandaloneISM;

    if (isInStandaloneMode) {
      this.browserCompatible = this.browserService.isCompatible();

      if (!this.browserCompatible) {
        const isIE = this.browserService.isIE();
        const oldEdgeVersion = this.browserService.oldEdgeVersion();

        if (isIE) {
          this.message =
            "You're using a web browser we don't support. Try a modern browser solution to have a better experience.";
        } else if (oldEdgeVersion) {
          this.message = `We see you are using Edge v${oldEdgeVersion}. This browser is unsupported by this product.
                        Please install the newest version from this link: https://www.microsoft.com/en-us/edge/`;
        }
      }
    }
  }
}

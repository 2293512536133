import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { MapSvgService } from '../../site-map/map-svg.service';
import { LotVM } from '../../site-map/models/lot-vm';
import { NeighborhoodVM } from '../../utility/models/neighborhood-vm';
import { ISvgInjectorLoaded } from '../svg-injector.directive';

@Component({
  selector: 'lot-zoom-in',
  templateUrl: './lot-zoom-in.component.html',
  styleUrls: ['./lot-zoom-in.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MapSvgService]
})
export class LotZoomInComponent implements OnChanges {
  @ViewChild('svgContainer', { static: true }) svgContainer: ElementRef<HTMLDivElement>;
  @Input() lot: LotVM;
  @Input() neighborhood: NeighborhoodVM;
  @Input() injectCircle = true;
  unalteredSvg: SVGElement;

  constructor(private mapSvgService: MapSvgService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.mapSvgService.cleanSlate();

    // the change was to lot in the same neighborhood then there will be no svg load event to trigger preview setup
    if (changes.lot.currentValue?.NeighborhoodId === changes.lot.previousValue?.NeighborhoodId)
      this.setupLotPreview();
  }

  onSvgLoad(data: ISvgInjectorLoaded) {
    this.unalteredSvg = data.Element as SVGElement;
    this.setupLotPreview();
  }

  setupLotPreview() {
    if (!this.unalteredSvg) return;
    const mapSvg = this.unalteredSvg.cloneNode(true) as SVGElement;
    this.mapSvgService.addSvgAndUpdateViewBoxToZoomInOnLot(
      this.lot,
      mapSvg,
      this.svgContainer.nativeElement
    );
    this.mapSvgService.colorLotsWithStatuses([this.lot], this.neighborhood.LotMapStatuses, mapSvg, [
      this.lot.LotId
    ]);

    if (this.injectCircle)
      this.mapSvgService.injectCircleIntoLot(this.lot, mapSvg, 'GeneralAccentColor1_HC'); // TODO: this is the HC specific setting
  }
}

import { formatNumber } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[mlNumberToCommaString]'
})
export class MlNumberToCommaStringDirective implements OnInit, OnChanges {
  @Input() mlNumberToCommaString: number;
  @Output() mlNumberToCommaStringChange = new EventEmitter<number>();

  constructor(private elemRef: ElementRef) {}

  ngOnInit(): void {
    this.elemRef.nativeElement.value = this.convertNumberToEnUsFormat(this.mlNumberToCommaString);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.elemRef.nativeElement.value = this.convertNumberToEnUsFormat(
      changes.mlNumberToCommaString.currentValue
    );
  }
  @HostListener('input') onInput() {
    const numVal = +this.elemRef.nativeElement.value.replace(/\D+/g, '');
    this.mlNumberToCommaStringChange.emit(numVal);
  }

  convertNumberToEnUsFormat(value: number): string {
    return formatNumber(value, 'en-US');
  }
}

<div aria-live="polite" aria-label="position controls" id="position-control">
  <button aria-label="move map up" id="move-up" (click)="handleMove(moveTypes.Up)">
    <div class="arrow"></div>
  </button>
  <div class="position-control-middle">
    <button aria-label="move map left" id="move-left" (click)="handleMove(moveTypes.Left)">
      <div class="arrow"></div>
    </button>
    <div class="position-control-spacer"></div>
    <button aria-label="move map right" id="move-right" (click)="handleMove(moveTypes.Right)">
      <div class="arrow"></div>
    </button>
  </div>
  <button aria-label="move map down" id="move-down" (click)="handleMove(moveTypes.Down)">
    <div class="arrow"></div>
  </button>
  <div *ngIf="!!ariaLiveText" aria-live="polite" aria-atomic="true" id="map-moved-message">
    {{ariaLiveText}}
  </div>
</div>
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewEncapsulation
} from '@angular/core';

import { getLibraryIcon } from '@ml/web-components';

@Component({
  selector: 'icon',
  template: '',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnChanges {
  @Input() name: string;
  @Input() svg = '';
  svgToInject = '';

  constructor(private elementRef: ElementRef) {}

  ngOnChanges() {
    if (this.svg) this.svgToInject = this.svg;
    else this.svgToInject = getLibraryIcon(this.name);

    if (this.svgToInject) {
      this.elementRef.nativeElement.innerHTML = this.svgToInject;
    } else {
      console.error(`Error: Unable to find an icon named "${this.name}"`);
    }
  }
}

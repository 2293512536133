import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

import { LotVM } from '../../site-map/models/lot-vm';
import { EffectiveFloorplan } from '../../utility/models/effective-floorplan';
import { FloorplanVM } from '../../utility/models/floorplan-vm';
import { OptionVM } from '../../utility/models/option-vm';
import { LocalStorageService } from '../../utility/services/local-storage.service';
import { SettingsService } from '../../utility/services/settings.service';
import {
  HcShareDialogComponent,
  HcShareType,
  IHcShareData
} from '../hc-final-summary/hc-share-dialog/hc-share-dialog.component';
import { HcFpListComponent, IHcFpListOptions } from '../hc-fp-list/hc-fp-list.component';
import {
  HcHelpMessageDialogComponent,
  HcHelpMessageDialogModel,
  HcHelpMessagesType
} from '../hc-help-message-dialog/hc-help-message-dialog.component';
import { HcMortgageCalculatorComponent } from '../hc-pricing-outline/hc-mortgage-calculator/hc-mortgage-calculator.component';
import { HcSavedBuildsComponent } from './hc-saved-builds/hc-saved-builds.component';
import {
  HcSessionChangeDialogComponent,
  SessionChangeDialogModel,
  SessionChangeDialogModelResponse,
  SessionChangeType
} from './hc-session-change-dialog/hc-session-change-dialog.component';
import {
  HcMortgageCalcLoanLengthTerms,
  HcMortgageCalcTerms,
  HcSession,
  HcSessionBuild,
  HcSessionItem,
  HcSessionItemType
} from './hc-session-models';

import { IActionSet, IElevation, IHomeConfiguratorSession, LogicState } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class HcSessionService {
  private _currentSession: HcSession;
  private _currentBuildSubject = new BehaviorSubject<HcSessionBuild>(null);
  private _loanTermSubject = new BehaviorSubject<HcMortgageCalcTerms>(null);

  private hcSessionLocalStorageKey = 'hc-session-';

  private hcHelpMessagesNoShowKey = 'hc-help-message-no-show';
  private hcHelpMessagesToNotShow: HcHelpMessagesType[] = [];
  private pendingSave: number;

  constructor(
    private dialog: MatDialog,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private settingsService: SettingsService
  ) {}

  async initialize(communityId: number, sessionId?: number, buildId?: string) {
    this.hcSessionLocalStorageKey += communityId;

    let json = this.localStorage.getItem(this.hcSessionLocalStorageKey);
    if (json) this._currentSession = new HcSession(JSON.parse(json));
    else this._currentSession = new HcSession();

    if (sessionId || this._currentSession.HomeConfiguratorSessionId) {
      const fresh = await this.getFromApi(
        sessionId ?? this._currentSession.HomeConfiguratorSessionId
      );
      if (fresh) {
        this._currentSession = fresh;
        this.saveToLocalStorage();
      }
    }

    if (buildId && this._currentSession.Builds.some(x => x.Id === buildId))
      this._currentSession.SelectedBuildId = buildId;

    this._currentSession.CommunityId = communityId;
    this._currentBuildSubject.next(this._currentSession.getSelectedBuild());

    // if no Total Price saved then we can assume new session and apply default values
    if (!this._currentSession.LoanTerms.TotalPrice) {
      this._currentSession.LoanTerms.DownPayPercent = +this.settingsService.get(
        'MortgageDefaultDownPayPercent_HC'
      );
      this._currentSession.LoanTerms.InterestRate = +this.settingsService.get(
        'MortgageDefaultInterestRate_HC'
      );
      this._currentSession.LoanTerms.Years = this.settingsService
        .get('MortgageDefaultLoanType_HC')
        .startsWith('15')
        ? HcMortgageCalcLoanLengthTerms.Fifteen
        : HcMortgageCalcLoanLengthTerms.Thirty;
    }
    this._loanTermSubject.next(this._currentSession.LoanTerms);

    json = this.localStorage.getItem(this.hcHelpMessagesNoShowKey);
    if (json) this.hcHelpMessagesToNotShow = JSON.parse(json);
  }

  get currentSession() {
    return this._currentSession;
  }

  get currentBuild$(): Observable<HcSessionBuild> {
    return this._currentBuildSubject.asObservable();
  }

  get currentBuild() {
    return this._currentBuildSubject.value;
  }

  get loanTerms$(): Observable<HcMortgageCalcTerms> {
    return this._loanTermSubject.asObservable();
  }

  get loanTerms() {
    return this._loanTermSubject.value;
  }

  getAllBuilds() {
    return this._currentSession.Builds;
  }

  clearEntireSession() {
    this.hcHelpMessagesToNotShow = [];
    this.localStorage.setItem(
      this.hcHelpMessagesNoShowKey,
      JSON.stringify(this.hcHelpMessagesToNotShow)
    );

    this.localStorage.removeItem(this.hcSessionLocalStorageKey);
    const commId = this._currentSession.CommunityId;
    this._currentSession = new HcSession();
    this._currentSession.CommunityId = commId;
    this.saveAndEmitChanges(this._currentSession.getSelectedBuild());
  }

  private saveAndEmitChanges(build?: HcSessionBuild) {
    build = build ?? this.currentBuild;
    this._currentBuildSubject.next(build);
    this._currentSession.SelectedBuildId = this.currentBuild.Id;
    this._currentSession.setBuild(this.currentBuild);

    this.saveToLocalStorage();
    this.trySaveToApi();
  }

  saveToLocalStorage() {
    this.localStorage.setItem(this.hcSessionLocalStorageKey, JSON.stringify(this._currentSession));
  }

  private trySaveToApi() {
    // for now only for updating existing saved session -- initial save to API only occurs when needed for share
    if (!this._currentSession.HomeConfiguratorSessionId) return;

    if (this.pendingSave) window.clearTimeout(this.pendingSave);

    // debounce saves by 3 seconds
    this.pendingSave = window.setTimeout(() => {
      this.pendingSave = null;
      this.saveToApi();
    }, 3000);
  }

  async saveToApi() {
    const promise = this._currentSession.HomeConfiguratorSessionId
      ? this.http
          .put<IHomeConfiguratorSession>(
            `/api/homeconfiguratorsessions/${this._currentSession.HomeConfiguratorSessionId}`,
            this._currentSession.exportForApi()
          )
          .toPromise()
      : this.http
          .post<IHomeConfiguratorSession>(
            `/api/homeconfiguratorsessions`,
            this._currentSession.exportForApi()
          )
          .toPromise();

    const session = await promise;

    this._currentSession.HomeConfiguratorSessionId = session.HomeConfiguratorSessionId;
    this.saveToLocalStorage();
    return session;
  }

  async getFromApi(id: number): Promise<HcSession> {
    try {
      const hcs = await this.http
        .get<IHomeConfiguratorSession>(`/api/homeconfiguratorsessions/${id}`)
        .toPromise();

      if (hcs) return HcSession.CreateFromInterface(hcs);
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  /** If the EffectiveFloorplan is an InventoryHome then this will also set Lot, Exterior, and FP Options */
  async setFloorplan(fp: EffectiveFloorplan) {
    let canProceed = true;

    if (this.currentBuild.Lot && this.currentBuild.Lot.ParentEntityId !== fp.NeighborhoodId) {
      canProceed = await this.confirmChangeWithUser(SessionChangeType.IncomingFloorplanConflict);
    }

    if (canProceed) {
      if (fp.IsInventoryHome) {
        this.setInventoryHomeAndRelatedItems(fp);
      } else {
        const fpItem = HcSessionItem.CreateFromFloorPlan(fp);
        fpItem.Options = fp.Floors.flatMap(x => x.ActionSets)
          .filter(x => x.State === LogicState.ON && x.IsInMenu)
          .map(x => HcSessionItem.CreateFromFpOption(x));

        this.currentBuild.updateItem(fpItem, HcSessionItemType.FloorPlan);
        this.currentBuild.updateItem(null, HcSessionItemType.Exterior);
        this.currentBuild.updateItem(null, HcSessionItemType.Interior);

        // if IH was previously selected then clear out lot
        if (!!this.currentBuild.InventoryHomeId) {
          this.currentBuild.InventoryHomeId = null;
          this.currentBuild.updateItem(null, HcSessionItemType.Lot);
        }
      }

      this.saveAndEmitChanges();
    }

    return canProceed;
  }

  private setInventoryHomeAndRelatedItems(fp: EffectiveFloorplan) {
    this.currentBuild.InventoryHomeId = fp.InventoryHomeId;

    const fpItem = HcSessionItem.CreateFromFloorPlan(fp);
    fpItem.Options = fp.Floors.flatMap(x => x.ActionSets)
      .filter(x => x.State === LogicState.ON && x.IsInMenu)
      .map(x => HcSessionItem.CreateFromFpOption(x));
    this.currentBuild.updateItem(fpItem, HcSessionItemType.FloorPlan);

    if (fp.AssociatedLots.length === 1) {
      this.currentBuild.updateItem(
        HcSessionItem.CreateFromLot(new LotVM(fp.AssociatedLots[0])),
        HcSessionItemType.Lot
      );
    } else {
      this.currentBuild.updateItem(null, HcSessionItemType.Lot);
    }

    if (fp.Elevations.length === 1) {
      this.currentBuild.updateItem(
        HcSessionItem.CreateFromElevation(fp.Elevations[0]),
        HcSessionItemType.Exterior
      );
    } else {
      this.currentBuild.updateItem(null, HcSessionItemType.Exterior);
    }
  }

  setFpOptions(options: OptionVM[] | IActionSet[], doEmit = true) {
    if (!this.currentBuild.FloorPlan) {
      console.warn('Attempted to set FP Options to build before FP was set.');
      return;
    }

    this.currentBuild.FloorPlan.Options = options.map(x => HcSessionItem.CreateFromFpOption(x));
    this.currentBuild.updateItem(this.currentBuild.FloorPlan, HcSessionItemType.FloorPlan);
    if (doEmit) this.saveAndEmitChanges();
  }

  addFpOption(option: IActionSet, doEmit = true) {
    this.currentBuild.FloorPlan.Options.push(HcSessionItem.CreateFromFpOption(option));
    this.currentBuild.updateItem(this.currentBuild.FloorPlan, HcSessionItemType.FloorPlan);

    if (doEmit) this.saveAndEmitChanges();
  }

  removeFpOption(id: number, doEmit = true) {
    this.currentBuild.FloorPlan.Options = this.currentBuild.FloorPlan.Options.filter(
      x => x.EntityId !== id
    );
    if (doEmit) this.saveAndEmitChanges();
  }

  setExterior(elevation: IElevation, floorplanId: number, doEmit = true) {
    // TODO: show conflict confirmation and ability to autoswitch FPs here??
    if (this.currentBuild.FloorPlan?.EntityId === floorplanId) {
      const item = elevation ? HcSessionItem.CreateFromElevation(elevation) : null;
      this.currentBuild.updateItem(item, HcSessionItemType.Exterior);

      if (doEmit) this.saveAndEmitChanges();
    }
  }

  async setLot(lot: LotVM, fp?: EffectiveFloorplan) {
    let canProceed = true;

    if (
      this.currentBuild.FloorPlan &&
      this.currentBuild.FloorPlan.ParentEntityId !== lot.NeighborhoodId
    ) {
      canProceed = await this.confirmChangeWithUser(SessionChangeType.IncomingLotConflict);
    }

    if (canProceed) {
      if (fp) {
        this.setInventoryHomeAndRelatedItems(fp);
      } else {
        this.currentBuild.updateItem(HcSessionItem.CreateFromLot(lot), HcSessionItemType.Lot);

        // if IH was previously selected then clear out FP
        if (!!this.currentBuild.InventoryHomeId) {
          this.currentBuild.InventoryHomeId = null;
          this.currentBuild.updateItem(null, HcSessionItemType.FloorPlan);
          this.currentBuild.updateItem(null, HcSessionItemType.Exterior);
          this.currentBuild.updateItem(null, HcSessionItemType.Interior);
        }
      }

      this.saveAndEmitChanges();
    }

    return canProceed;
  }

  removeLotFromCurrentBuild() {
    this.currentBuild.updateItem(null, HcSessionItemType.Lot);

    if (this.currentBuild.InventoryHomeId) {
      this.currentBuild.InventoryHomeId = null;
      this.currentBuild.updateItem(null, HcSessionItemType.FloorPlan);
      this.currentBuild.updateItem(null, HcSessionItemType.Exterior);
      this.currentBuild.updateItem(null, HcSessionItemType.Interior);
    }

    this.saveAndEmitChanges();
  }

  setSelectedBuild(build: HcSessionBuild) {
    this._currentSession.setBuild(build);
    this.saveAndEmitChanges(build);
  }

  deleteBuild(build: HcSessionBuild) {
    const selectNew = build.Id === this._currentSession.SelectedBuildId;
    this._currentSession.discardBuild(build.Id);

    if (selectNew) this.saveAndEmitChanges(this._currentSession.getSelectedBuild());
  }

  startNewBuild(discardCurrent = false, newFP?: EffectiveFloorplan) {
    if (discardCurrent) this._currentSession.discardBuild(this._currentSession.SelectedBuildId);

    const build = new HcSessionBuild();
    if (newFP) build.FloorPlan = HcSessionItem.CreateFromFloorPlan(newFP);

    this._currentSession.setBuild(build, true);
    this.saveAndEmitChanges(build);
  }

  saveBuildNickname(buildId: string, name: string) {
    const b = this._currentSession.Builds.find(x => x.Id === buildId);
    b.Nickname = name;

    if (b.Id === this.currentBuild.Id) {
      this.saveAndEmitChanges(b);
    } else {
      this.saveToLocalStorage();
      this.trySaveToApi();
    }
  }

  private async confirmChangeWithUser(changeType: SessionChangeType) {
    const response = await firstValueFrom<SessionChangeDialogModelResponse>(
      this.dialog
        .open(HcSessionChangeDialogComponent, {
          data: {
            Type: changeType
          } as SessionChangeDialogModel,
          panelClass: 'session-change-dialog-panel'
        })
        .afterClosed()
    );

    if (response?.ClearOutExisting) {
      switch (changeType) {
        case SessionChangeType.IncomingFloorplanConflict:
          this.currentBuild.Lot = null;
          break;
        case SessionChangeType.IncomingLotConflict:
          this.currentBuild.FloorPlan = null;
          break;
      }
    }

    return response?.Proceed ?? false;
  }

  showFloorplanListOverlay({
    fpToLimitTo,
    lotToLimitTo
  }: { fpToLimitTo?: EffectiveFloorplan; lotToLimitTo?: LotVM } = {}) {
    return this.dialog.open(HcFpListComponent, {
      panelClass: ['fullscreen-overlay', 'hc-styles'],
      data: {
        CurrentFloorplan: fpToLimitTo,
        LimitToCurrent: !!fpToLimitTo || !!lotToLimitTo,
        CurrentLot: lotToLimitTo
      } as IHcFpListOptions
    });
  }

  showSavedBuildsOverlay() {
    this.dialog.open(HcSavedBuildsComponent, {
      panelClass: ['fullscreen-overlay', 'hc-styles']
    });
  }

  async showMortgageCalculatorOverlay(): Promise<HcMortgageCalcTerms> {
    const terms = await this.dialog
      .open<HcMortgageCalculatorComponent, HcMortgageCalcTerms, HcMortgageCalcTerms>(
        HcMortgageCalculatorComponent,
        {
          panelClass: 'mortgage-calculator-modal',
          backdropClass: 'mortgage-calculator-modal-backdrop',
          data: new HcMortgageCalcTerms({
            ...this.loanTerms,
            TotalPrice: this.currentBuild.getTotalPrice()
          })
        }
      )
      .afterClosed()
      .toPromise();

    // will be undefined if user clicks blank space or uses ESC
    if (!terms) return;

    this._currentSession.LoanTerms = terms;
    this.saveToLocalStorage();
    this.trySaveToApi();
    this._loanTermSubject.next(terms);
    return terms;
  }

  determineIsmHelpMessage(lot: LotVM, fp: FloorplanVM) {
    if (!lot && !fp) {
      this.attemptToOpenHelpDialog(HcHelpMessagesType.NoFloorplanAndLot, lot, fp);
    } else if (!lot) {
      if (!this.hcHelpMessagesToNotShow.includes(HcHelpMessagesType.NoLot))
        this.attemptToOpenHelpDialog(HcHelpMessagesType.NoLot, lot, fp);
    } else if (!fp) {
      if (!this.hcHelpMessagesToNotShow.includes(HcHelpMessagesType.NoFloorplan))
        this.attemptToOpenHelpDialog(HcHelpMessagesType.NoFloorplan, lot, fp);
    }
  }

  attemptToOpenHelpDialog(type: HcHelpMessagesType, lot?: LotVM, fp?: FloorplanVM) {
    if (this.hcHelpMessagesToNotShow.includes(type) || this.dialog.openDialogs.length) return;

    this.dialog
      .open(HcHelpMessageDialogComponent, {
        panelClass: 'hc-help-dialog',
        data: {
          Type: type,
          Lot: lot,
          Floorplan: fp
        } as HcHelpMessageDialogModel
      })
      .afterClosed()
      .subscribe((doNotShowAgain: boolean) => {
        if (doNotShowAgain) {
          this.hcHelpMessagesToNotShow.push(type);
          this.localStorage.setItem(
            this.hcHelpMessagesNoShowKey,
            JSON.stringify(this.hcHelpMessagesToNotShow)
          );
        }
      });
  }

  openShareDialog(type: HcShareType, data: Partial<IHcShareData> = {}) {
    return this.dialog.open(HcShareDialogComponent, {
      data: { Type: type, ...data },
      panelClass: 'hc-share-dialog-panel'
    });
  }

  async confirmClearSessionData() {
    const confirmClear = await this.confirmChangeWithUser(SessionChangeType.ClearSessionData);

    if (confirmClear) {
      this.clearEntireSession();
    }

    return confirmClear ?? false;
  }
}

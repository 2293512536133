import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { GalleryImageVM } from '../../../shared/gallery/gallery-image-vm';
import { EffectiveFloorplan } from '../../../utility/models/effective-floorplan';
import { ThemeService } from '../../../utility/services/theme.service';
import { HcSessionBuild } from '../../hc-session/hc-session-models';

@Component({
  selector: 'hc-fp-card',
  templateUrl: './hc-fp-card.component.html',
  styleUrls: ['./hc-fp-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HcFpCardComponent implements OnInit, OnChanges {
  @Input() effectiveFP: EffectiveFloorplan;
  @Input() build: HcSessionBuild;
  @Input() indexInList: number;
  @Input() listTotal: number;
  @Output() floorplanSelect = new EventEmitter();
  @Output() floorplanChange = new EventEmitter();
  galleryImages: GalleryImageVM[];
  isSelected = false;

  lotLabelPlural: string;

  constructor(public theme: ThemeService) {}

  ngOnInit(): void {
    this.lotLabelPlural = this.theme.getLotLabel(true);

    this.galleryImages = [
      ...this.effectiveFP.ElevationGalleryImages,
      ...this.effectiveFP.MediaGalleryImages,
      ...(this.effectiveFP.IsInventoryHome ? [] : this.effectiveFP.FloorGalleryImages) // hide for IH because of option art
    ];
  }

  ngOnChanges(): void {
    if (this.build?.FloorPlan) {
      if (this.build.InventoryHomeId)
        this.isSelected = this.effectiveFP.InventoryHomeId === this.build.InventoryHomeId;
      else
        this.isSelected =
          !this.effectiveFP.IsInventoryHome &&
          this.effectiveFP.FloorplanId === this.build.FloorPlan.EntityId;
    }
  }
}

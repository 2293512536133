import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { DIRECTION_ALL, Manager, Pan, Press } from 'hammerjs';

import { roundToPlace } from '../utility/helpers/helpers';

@Directive({
  selector: '[gallerySwiper]'
})
export class GallerySwiperDirective implements OnInit, OnChanges {
  @Input() slideCount = 0;
  @Input() index = 0;
  @Output() indexChange = new EventEmitter<number>();

  private hammerManager: HammerManager;
  private element: HTMLElement;
  private timeout: number;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.element = this.elementRef.nativeElement;

    if (this.slideCount > 1) {
      this.hammerManager = new Manager(this.element);
      this.createRecognizers(this.hammerManager);
      this.subscribeToEvents(this.hammerManager);
      this.goToSlide(this.index);
    }
  }

  ngOnChanges() {
    if (this.element) this.goToSlide(this.index);
  }

  createRecognizers(hm: HammerManager) {
    const pan = new Pan({
      threshold: 0,
      pointers: 0,
      direction: DIRECTION_ALL
    });
    const pressdown = new Press({ event: 'pressdown', time: 0 });

    // Important: allows gestures to fire simultaneously (only have to call recognizeWith() 1-way)
    pan.recognizeWith(pressdown);

    hm.add(pan);
  }

  subscribeToEvents(hm: HammerManager) {
    let startTransX = 0;
    let viewportWidth = 1;

    hm.on('pressdown panstart', evt => {
      const computed = getComputedStyle(this.element);
      const matrix = new WebKitCSSMatrix(computed.transform);

      startTransX = matrix.e;
      viewportWidth = this.element.clientWidth;
    });

    hm.on('panmove', evt => {
      const newX = startTransX + evt.deltaX;
      const transform = `translateX(${newX}px)`;
      this.setTransformStyle(this.element, transform);
    });

    hm.on('panend', evt => {
      const currentTransX = startTransX + evt.deltaX;

      let newIndex = roundToPlace(currentTransX / viewportWidth, 0);

      if (Math.abs(evt.velocityX) > 0.25) {
        // fast swipe -- just use direction and closest index
        if (evt.velocityX > 0) newIndex = Math.ceil(currentTransX / viewportWidth);
        else newIndex = Math.floor(currentTransX / viewportWidth);
      }

      // correcting screen direction for index direction -- dragging left is negative X but positive index change
      newIndex *= -1;

      // making swipe only move one image at a time
      if (newIndex > this.index) newIndex = this.index + 1;
      if (newIndex < this.index) newIndex = this.index - 1;

      if (newIndex < 0) newIndex = this.slideCount + newIndex;

      if (newIndex === this.index) {
        this.goToSlide(this.index);
      } else {
        this.indexChange.emit(newIndex);
      }
    });
  }

  enableTouchAction() {
    this.element.style.touchAction = 'auto';
  }

  disableTouchAction() {
    this.element.style.touchAction = 'none';
  }

  private setTransformStyle(el: HTMLElement, transformString: string, doAnimate: boolean = false) {
    if (doAnimate) {
      clearTimeout(this.timeout);
      el.classList.add('is-animating');

      this.timeout = window.setTimeout(() => {
        el.classList.remove('is-animating');
      }, 400);
    }

    el.style.transform = transformString;
  }

  private goToSlide(index: number) {
    const percentage = -100 * index;
    const transform = `translateX(${percentage}%)`;

    this.setTransformStyle(this.element, transform, true);
  }
}

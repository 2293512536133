import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { NavigationService, PageTitle } from '../../../utility/services/navigation.service';
import { SettingsService } from '../../../utility/services/settings.service';
import { HcShareType } from '../../hc-final-summary/hc-share-dialog/hc-share-dialog.component';
import { HcModuleService } from '../../hc-module-nav/hc-module.service';
import { HcSessionBuild } from '../hc-session-models';
import { HcSessionService } from '../hc-session.service';

@Component({
  selector: 'hc-saved-builds',
  templateUrl: './hc-saved-builds.component.html',
  styleUrls: ['./hc-saved-builds.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HcSavedBuildsComponent implements OnInit {
  brandImageUrl: string;
  builds: HcSessionBuild[] = [];
  showScheduleAppt: boolean;

  constructor(
    private settingsService: SettingsService,
    public dialogRef: MatDialogRef<HcSavedBuildsComponent>,
    private hcSessionService: HcSessionService,
    private hcModuleService: HcModuleService,
    private navigator: NavigationService
  ) {}

  ngOnInit(): void {
    this.brandImageUrl = this.settingsService.get('HeaderBrandingImage');
    this.showScheduleAppt = !!this.settingsService.get('SalesLeadsEmailAddresses');
    this.builds = this.hcSessionService.getAllBuilds();
  }

  openShareDialog() {
    this.hcSessionService.openShareDialog(HcShareType.Email);
  }

  startNewBuild() {
    this.hcSessionService.startNewBuild(false);

    if (this.hcModuleService.isModuleEnabled(PageTitle.CommunityMap))
      this.navigator.go({ PageTitle: PageTitle.CommunityMap });
    else this.hcSessionService.showFloorplanListOverlay();

    this.dialogRef.close();
  }

  handleBuildSelect(build: HcSessionBuild) {
    this.hcSessionService.setSelectedBuild(build);
    this.navigator.go({ PageTitle: PageTitle.Summary });
    this.dialogRef.close();
  }

  handleBuildDelete(build: HcSessionBuild) {
    this.builds = this.builds.filter(x => x.Id !== build.Id);
    this.hcSessionService.deleteBuild(build);
  }

  handleShare(build: HcSessionBuild) {
    this.hcSessionService
      .openShareDialog(HcShareType.Email, { BuildId: build.Id })
      .afterClosed()
      .subscribe(success => {
        if (typeof success === 'boolean') this.dialogRef.close();
      });
  }

  handleScheduleAppt(build: HcSessionBuild) {
    this.hcSessionService
      .openShareDialog(HcShareType.ScheduleAppointment, { BuildId: build.Id })
      .afterClosed()
      .subscribe(success => {
        if (typeof success === 'boolean') this.dialogRef.close();
      });
  }
}

import { IAreaMapPoi } from '../../myscp/area-map/models/poi-category';

export enum PageVersion {
  MyScp = 3,
  HomeConfigurator = 4
}

// 1 is legacy and not currently used by MyScp
export enum PageType {
  FastPrintContent = 1,
  IframeContent = 2,
  EnergyEfficiency = 3,
  Visualizer = 4
}

export interface IScpContentImage {
  Filename: string;
  SortOrder: number;
}

export interface IHomepageContent extends IBaseContentJson {
  HeroImages: IScpContentImage[];
}

export interface IAreaMapPoiCategory {
  Id: string;
  Name: string;
  Keyword: string;
  ExcludedMarkers: IAreaMapPoi[];
  IncludedSavedResults: IAreaMapPoi[];
  IconSvgContent: string;
  IconId: number;
  MarkerColor: string;
  SearchRadius: number;
}

export interface IAreaMapContent extends IBaseContentJson {
  Categories: IAreaMapPoiCategory[];
}

export interface IBaseContentJson {
  IsInPrimaryQuickNav: boolean;
  IsInSecondaryQuickNav: boolean;
  IsHiddenFromNav: boolean;
  Description: string;
  IsUsingSavedResults: boolean;
}

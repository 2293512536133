import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ThemeService } from '../../../utility/services/theme.service';

@Component({
  selector: 'hc-session-change-dialog',
  templateUrl: './hc-session-change-dialog.component.html',
  styleUrls: ['./hc-session-change-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HcSessionChangeDialogComponent implements OnInit {
  @HostBinding('class') class = 'hc-styles';
  changeType = SessionChangeType;

  constructor(
    public dialogRef: MatDialogRef<
      HcSessionChangeDialogComponent,
      SessionChangeDialogModelResponse
    >,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: SessionChangeDialogModel,
    public theme: ThemeService
  ) {}

  ngOnInit(): void {}

  handleCancel() {
    if (this.dialogRef) this.dialogRef.close({ Proceed: false });
  }

  handleProceed() {
    if (this.dialogRef) this.dialogRef.close({ Proceed: true, ClearOutExisting: true });
  }
}

export enum SessionChangeType {
  IncomingFloorplanConflict,
  IncomingLotConflict,
  ClearSessionData
}

export class SessionChangeDialogModel {
  Type: SessionChangeType;
  // Floorplan: FloorplanVM;
  // Lot: LotVM;
}

export class SessionChangeDialogModelResponse {
  Proceed: boolean;
  ClearOutExisting?: boolean;
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

import { SettingsService } from '../../utility/services/settings.service';

import { ITag, sortByName } from '@ml/common';

@Component({
  selector: 'tag-filter',
  templateUrl: './tag-filter.component.html',
  styleUrls: ['./tag-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagFilterComponent implements OnInit, OnChanges {
  @Input() tags: ITag[] = [];
  @Input() initialSelectedTags: ITag[] = [];
  @Input() titleSettingName: string;
  @Input() placeholderSettingName: string;
  @Output() selectedTagsChange = new EventEmitter<ITag[]>();
  selectedTags: ITag[] = [];
  availableTags: ITag[] = [];
  isEnabled = false;
  titleText = 'Tag Filter';
  placeholderText = 'Select a tag';
  selectedTag: ITag;

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    this.selectedTags = this.initialSelectedTags;
    this.isEnabled = !!this.selectedTags.length;
    this.availableTags = this.availableTags.filter(x => !this.selectedTags.includes(x));
    if (this.titleSettingName)
      this.titleText = this.settingsService.getByUniqueName(this.titleSettingName);
    if (this.placeholderSettingName)
      this.placeholderText = this.settingsService.getByUniqueName(this.placeholderSettingName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tags?.currentValue) {
      this.availableTags = [...this.tags].sort(sortByName);
      this.selectedTags = [];
      this.isEnabled = false;
    }
  }

  handleEnabledToggle(isEnabled: boolean) {
    this.isEnabled = isEnabled;
    if (isEnabled) this.selectedTagsChange.emit(this.selectedTags);
    else this.selectedTagsChange.emit([]);
  }

  handleTagSelect(change: MatSelectChange) {
    const tag = change.value as ITag;
    this.selectedTags.push(tag);
    this.selectedTags.sort(sortByName);
    this.availableTags = this.availableTags.filter(x => x.TagId !== tag.TagId);

    this.isEnabled = true;
    this.selectedTagsChange.emit(this.selectedTags);
  }

  handleTagRemove(tag: ITag) {
    this.selectedTags = this.selectedTags.filter(x => x.TagId !== tag.TagId);
    this.availableTags.push(tag);
    this.availableTags.sort(sortByName);

    if (!this.selectedTags.length) this.isEnabled = false;
    this.selectedTagsChange.emit(this.selectedTags);
    // mainly necessary so that you are able to reselect that same tag
    this.selectedTag = null;
  }

  trackByTagId(_, tag: ITag) {
    return tag.TagId;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import { HotspotVM } from '../../site-map/models/hotspot-vm';

@Component({
  selector: 'hotspot',
  templateUrl: './hotspot.component.html',
  styleUrls: ['./hotspot.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotspotComponent implements OnInit, OnChanges {
  @Input() hotspot: HotspotVM;
  @Input() scale = 1;
  @Input() pulse = false;
  transform = '';
  rotate = '';

  constructor() {}

  ngOnInit(): void {
    this.transform = this.getTransform();
    this.rotate = `rotate(${this.hotspot.InitialPovDegrees}deg)`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scale) this.transform = this.getTransform();
  }

  getTransform() {
    const scale = 1 / this.scale;
    // using translate(-50%, -50%) so we can translate from the center point
    return `translate(-50%, -50%) translate(${this.hotspot.X}px, ${this.hotspot.Y}px) scale(${scale})`;
  }
}

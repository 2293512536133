import { IElevation } from '@ml/common';

export class ElevationVM implements IElevation {
  ElevationId: number;
  FloorplanId: number;
  ClientUniqueIdentifier: string;
  Title: string;
  ShortTitle: string;
  IsActive: boolean;
  ImageFilename: string;
  Order?: number;
  Cost: number;

  IsFavorited = false;

  constructor(data?: IElevation, isFavorited: boolean = false) {
    Object.assign(this, data);
    if (typeof isFavorited === 'boolean') this.IsFavorited = isFavorited;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ToastService } from '../../global/toast/toast.service';
import { IAreaMapPoi } from '../../myscp/area-map/models/poi-category';
import { IFloorplanFiltersState } from '../../shared/floorplan-filters/floorplan-filters';
import { FavoriteType } from '../helpers/favorite-types.enum';
import { FavoriteFloorplan, FavoriteLot, FavoritesSession } from '../models/favorites-session';
import { VisualizerSelection } from '../models/visualizer-selection';
import { ApiService } from './api.service';
import { CommunityService } from './community.service';
import { HomeBuyerService } from './home-buyer/home-buyer.service';
import { SessionStorageService } from './session-storage.service';
import { SettingsService } from './settings.service';

import { IFavoritesSession } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
  private sessionSubject = new BehaviorSubject<FavoritesSession>(new FavoritesSession());
  readonly favoritesSessionStorageKey = 'favoritesSession';
  private isEnabled = true;
  private pendingSave: number;
  private showVisualizerDeleteFavorites = true;
  private viewedFloorplansIds: number[] = [];

  constructor(
    private api: ApiService,
    private homeBuyerService: HomeBuyerService,
    private communityService: CommunityService,
    private toaster: ToastService,
    private settingsService: SettingsService,
    private sessionStorageService: SessionStorageService
  ) {}

  initializeFromSessionStorage() {
    try {
      const previousFavoritesSession = this.getFromSessionStorage();
      if (previousFavoritesSession) this.current = previousFavoritesSession;
    } catch {
      console.error('Favorites found in sessionStorage but error while parsing');
    }
  }

  get current$() {
    return this.sessionSubject.asObservable();
  }

  get current() {
    return this.sessionSubject.value;
  }

  set current(session: FavoritesSession) {
    this.sessionSubject.next(session);
    this.saveToSessionStorage(session);
  }

  hasSessionBeenSaved(): boolean {
    return this.current.hasBeenSaved();
  }

  /**
   * Saves a favorite session to the database and session storage and emits the change
   *
   * @param session A favorite session to save
   * @param wait Whether to wait for the API save to finish before emitting the change.
   * Only necessary on an initial saves if you need to wait for the new session id
   */
  async saveAndEmitChange(session: FavoritesSession, wait: boolean = false) {
    if (wait) {
      await this.save(session);
    } else {
      this.save(session);
    }
    this.current = session;

    if (this.current.FavoritesSessionId) this.homeBuyerService.updateFavorites(this.current);
  }

  private getFromSessionStorage(): FavoritesSession {
    const savedFavorites = this.sessionStorageService.getItem(this.favoritesSessionStorageKey);
    if (savedFavorites) {
      const parsed = JSON.parse(savedFavorites) as FavoritesSession;
      return new FavoritesSession(parsed);
    }
  }

  private saveToSessionStorage(session: FavoritesSession) {
    const serialized = JSON.stringify(session);
    this.sessionStorageService.setItem(this.favoritesSessionStorageKey, serialized);
  }

  resetFavoritesAndSave() {
    const emptySession = new FavoritesSession();
    emptySession.FavoritesSessionId = this.current.FavoritesSessionId;
    this.saveAndEmitChange(emptySession);
  }

  logoutAndClearSession() {
    this.homeBuyerService.updateFavorites(this.current, true);
    this.homeBuyerService.logout();
    this.current = new FavoritesSession();
    this.viewedFloorplansIds = [];
  }

  addFloorlan(floorplanId: number, optionIds?: number[], reverseState?: boolean): boolean {
    if (this.current.containsFloorplan(floorplanId)) return true;
    if (this.hasReachedMaxFloorPlanLimit()) return false;

    const favFp = { ...new FavoriteFloorplan(), FloorplanId: floorplanId };
    this.current.FavoriteFloorplans.push(favFp);
    if (optionIds) favFp.SelectedActionSetIds.push(...optionIds);
    const newSession = this.current.clone();

    if (typeof reverseState === 'boolean')
      newSession.FloorPlanReverseStates.set(floorplanId, reverseState);

    this.saveAndEmitChange(newSession);
    return true;
  }

  removeFloorplan(floorplanId: number) {
    if (!this.current.containsFloorplan(floorplanId)) return;

    const i = this.current.FavoriteFloorplans.findIndex(x => x.FloorplanId === floorplanId);
    this.current.FavoriteFloorplans.splice(i, 1);
    const newSession = this.current.clone();
    newSession.FloorPlanReverseStates.delete(floorplanId);

    this.saveAndEmitChange(newSession);
  }

  addInventoryHome(inventoryHomeId: number): boolean {
    if (this.current.containsInventoryHome(inventoryHomeId)) return true;
    if (this.hasReachedMaxFloorPlanLimit()) return false;

    const favFp = { ...new FavoriteFloorplan(), InventoryHomeId: inventoryHomeId };
    this.current.FavoriteFloorplans.push(favFp);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
    return true;
  }

  removeInventoryHome(inventoryHomeId: number) {
    if (!this.current.containsInventoryHome(inventoryHomeId)) return;

    const i = this.current.FavoriteFloorplans.findIndex(x => x.InventoryHomeId === inventoryHomeId);
    this.current.FavoriteFloorplans.splice(i, 1);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  addFloorplanElevation(elevationId: number, floorplanId: number) {
    if (this.current.containsFloorplanElevation(elevationId)) return;

    // favoriting an elevation forces FP to also be favorite
    const success = this.addFloorlan(floorplanId);
    if (!success) return;

    const favFp = this.current.getFloorplan(floorplanId);
    favFp.FavoriteElevationIds = [...favFp.FavoriteElevationIds, elevationId];
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  removeFloorplanElevation(elevationId: number) {
    if (!this.current.containsFloorplanElevation(elevationId)) return;

    const favFp = this.current.getFloorplanByElevation(elevationId);
    const i = favFp.FavoriteElevationIds.findIndex(id => id === elevationId);
    favFp.FavoriteElevationIds.splice(i, 1);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  addInventoryHomeElevation(elevationId: number, inventoryHomeId: number) {
    if (this.current.containsInventoryHomeElevation(elevationId, inventoryHomeId)) return;

    // favoriting an elevation forces IH to also be favorite
    const success = this.addInventoryHome(inventoryHomeId);
    if (!success) return;

    const favFp = this.current.getInventoryHome(inventoryHomeId);
    favFp.FavoriteElevationIds = [...favFp.FavoriteElevationIds, elevationId];
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  removeInventoryHomeElevation(elevationId: number, inventoryHomeId: number) {
    if (!this.current.containsInventoryHomeElevation(elevationId, inventoryHomeId)) return;

    const favFp = this.current.getInventoryHome(inventoryHomeId);
    const i = favFp.FavoriteElevationIds.findIndex(id => id === elevationId);
    favFp.FavoriteElevationIds.splice(i, 1);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  addFloorplanMedia(mediaId: number, floorplanId: number) {
    if (this.current.containsFloorplanMedia(mediaId)) return;

    // favoriting media forces FP to also be favorite
    const success = this.addFloorlan(floorplanId);
    if (!success) return;

    const favFp = this.current.getFloorplan(floorplanId);
    favFp.FavoriteInteriorIds = [...favFp.FavoriteInteriorIds, mediaId];
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  removeFloorplanMedia(mediaId: number) {
    if (!this.current.containsFloorplanMedia(mediaId)) return;

    const favFp = this.current.getFloorplanByMedia(mediaId);
    const i = favFp.FavoriteInteriorIds.findIndex(id => id === mediaId);
    favFp.FavoriteInteriorIds.splice(i, 1);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  addInventoryHomeMedia(mediaId: number, inventoryHomeId: number) {
    if (this.current.containsInventoryHomeMedia(mediaId)) return;

    // favoriting media forces IH to also be favorite
    const success = this.addInventoryHome(inventoryHomeId);
    if (!success) return;

    const favFp = this.current.getInventoryHome(inventoryHomeId);
    favFp.FavoriteInteriorIds = [...favFp.FavoriteInteriorIds, mediaId];
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  removeInventoryHomeMedia(mediaId: number, inventoryHomeId: number) {
    if (!this.current.containsInventoryHomeMedia(mediaId)) return;

    const favFp = this.current.getInventoryHome(inventoryHomeId);
    const i = favFp.FavoriteInteriorIds.findIndex(id => id === mediaId);
    favFp.FavoriteInteriorIds.splice(i, 1);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  addLot(id: number) {
    if (this.current.containsLot(id)) return;

    this.current.FavoriteLots.push({ ...new FavoriteLot(), LotId: id });
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  removeLot(id: number) {
    if (!this.current.containsLot(id)) return;

    const i = this.current.FavoriteLots.findIndex(x => x.LotId === id);
    this.current.FavoriteLots.splice(i, 1);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  addLotMedia(id: number, lotId: number) {
    if (this.current.containsLotMedia(id)) return;

    // favoriting media forces Lot to also be favorite
    this.addLot(lotId);

    const favLot = this.current.FavoriteLots.find(x => x.LotId === lotId);
    favLot.MediaIds.push(id);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  removeLotMedia(id: number, lotId?: number) {
    if (!this.current.containsLotMedia(id)) return;

    let favLot = this.current.FavoriteLots.find(x => x.LotId === lotId);
    if (!favLot) favLot = this.current.getLotByMedia(id);

    favLot.MediaIds = favLot.MediaIds.filter(mediaId => mediaId !== id);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  addPOI(id: string, poi?: IAreaMapPoi) {
    if (this.current.containsPOI(id)) return;

    this.current.GooglePlaces.push(poi);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  removePOI(id: string) {
    if (!this.current.containsPOI(id)) return;

    const i = this.current.GooglePlaces.findIndex(x => x.Id === id);
    this.current.GooglePlaces.splice(i, 1);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  addPageContentSection(id: string) {
    if (this.current.containsPageContentSection(id)) return;

    this.current.PageContentSectionIds.push(id);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  removePageContentSection(id: string) {
    if (!this.current.containsPageContentSection(id)) return;

    const i = this.current.PageContentSectionIds.findIndex(x => x === id);
    this.current.PageContentSectionIds.splice(i, 1);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  addCustomSubPage(id: number) {
    if (this.current.containsCustomSubPage(id)) return;

    this.current.CustomSubPageIds.push(id);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  removeCustomSubPage(id: number) {
    if (!this.current.containsCustomSubPage(id)) return;
    const i = this.current.CustomSubPageIds.findIndex(x => x === id);
    this.current.CustomSubPageIds.splice(i, 1);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  setAllVisualizerSelections(selections: VisualizerSelection[]) {
    this.current.VisualizerSelections = selections;
    const newSession = this.current.clone();
    this.saveAndEmitChange(newSession);
  }

  addVisualizerSelection(visualizerSelection: VisualizerSelection) {
    if (this.current.containsVisualizerSelection(visualizerSelection.Id)) return;

    this.current.VisualizerSelections.push(visualizerSelection);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  removeVisualizerSelection(id: string) {
    if (!this.current.containsVisualizerSelection(id)) return;

    const i = this.current.VisualizerSelections.findIndex(x => x.Id === id);
    this.current.VisualizerSelections.splice(i, 1);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  addAmenityMedia(id: number) {
    if (this.current.containsAmenityMedia(id)) return;

    this.current.AmenityMediaIds.push(id);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  removeAmenityMedia(id: number) {
    if (!this.current.containsAmenityMedia(id)) return;

    const i = this.current.AmenityMediaIds.findIndex(x => x === id);
    this.current.AmenityMediaIds.splice(i, 1);
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  updateFloorplanReverseState(floorplanId: number, state: boolean) {
    if (!this.current.containsFloorplan(floorplanId)) return;

    const newSession = this.current.clone();
    newSession.FloorPlanReverseStates.set(floorplanId, state);
    this.saveAndEmitChange(newSession);
  }

  updateFloorplanFilterState(state: IFloorplanFiltersState) {
    const newSession = this.current.clone();
    newSession.FloorplanFiltersState = state;
    this.saveAndEmitChange(newSession);
  }

  addOptions(optionIds: number[], floorplanId: number) {
    if (!optionIds?.length) return;

    const favFp = this.current.getFloorplan(floorplanId);
    if (!favFp) return;

    optionIds.forEach(optionId => {
      if (!this.current.containsFloorplanOption(optionId))
        favFp.SelectedActionSetIds = [...favFp.SelectedActionSetIds, optionId];
    });
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  removeOptions(optionIds: number[], floorplanId: number) {
    if (!optionIds?.length) return;

    const favFp = this.current.getFloorplan(floorplanId);
    if (!favFp) return;

    optionIds.forEach(optionId => {
      if (this.current.containsFloorplanOption(optionId)) {
        const i = favFp.SelectedActionSetIds.findIndex(id => id === optionId);
        favFp.SelectedActionSetIds.splice(i, 1);
      }
    });
    const newSession = this.current.clone();

    this.saveAndEmitChange(newSession);
  }

  removeAllOptions(floorplanId: number) {
    const favFp = this.current.getFloorplan(floorplanId);
    if (!favFp) return;
    favFp.SelectedActionSetIds = [];
    const newSession = this.current.clone();
    this.saveAndEmitChange(newSession);
  }

  async save(session: FavoritesSession) {
    if (this.homeBuyerService.isAnyoneLoggedIn()) {
      session.HomeBuyerId = this.homeBuyerService.current.HomeBuyerId;
      session.CommunityId = this.communityService.current.CommunityId;

      try {
        this.trySaveToApi(session);
      } catch {
        console.error('Unable to save favorites to API');
      }
    }
  }

  trySaveToApi(session: FavoritesSession) {
    if (this.pendingSave) window.clearTimeout(this.pendingSave);

    // debounce saves by 3 seconds
    this.pendingSave = window.setTimeout(() => {
      this.pendingSave = null;
      this.saveToApi(session);
    }, 3000);
  }

  async saveToApi(session: FavoritesSession): Promise<IFavoritesSession> {
    const dto = { ...session, GeneralJSON: session.stringifyNonStandardData() };
    dto.GooglePlaces = null;
    dto.PageContentSectionIds = null;
    dto.VisualizerSelections = null;
    dto.AmenityMediaIds = null;
    dto.FloorPlanReverseStates = null;
    dto.FloorplanFiltersState = null;

    let promise: Promise<IFavoritesSession>;
    if (session.FavoritesSessionId)
      promise = this.api.update<IFavoritesSession>(
        dto,
        'FavoritesSession',
        `/${session.FavoritesSessionId}`
      );
    else promise = this.api.create<IFavoritesSession>(dto, 'FavoritesSession').toPromise();

    const sess = await promise;
    session.FavoritesSessionId = sess.FavoritesSessionId;
    return sess;
  }

  add({
    favoriteType,
    id,
    inventoryHomeId,
    optionIds,
    floorplanId,
    reverseState
  }: {
    favoriteType: FavoriteType;
    id: number | string;
    inventoryHomeId?: number;
    optionIds?: number[];
    floorplanId?: number;
    reverseState?: boolean;
  }) {
    const isInventoryHome = !!inventoryHomeId;
    switch (favoriteType) {
      case FavoriteType.Floorplan:
      case FavoriteType.InventoryHome:
        return isInventoryHome
          ? this.addInventoryHome(inventoryHomeId)
          : this.addFloorlan(id as number, optionIds, reverseState);
      case FavoriteType.Elevation:
        return isInventoryHome
          ? this.addInventoryHomeElevation(id as number, inventoryHomeId)
          : this.addFloorplanElevation(id as number, floorplanId);
      case FavoriteType.Media:
        return isInventoryHome
          ? this.addInventoryHomeMedia(id as number, inventoryHomeId)
          : this.addFloorplanMedia(id as number, floorplanId);
      case FavoriteType.Lot:
        return this.addLot(id as number);
      case FavoriteType.PointOfInterest:
        return this.addPOI(id as string);
      case FavoriteType.PageContentSection:
        return this.addPageContentSection(id as string);
      case FavoriteType.VisualizerSelection:
        throw new Error('Unable to add Visualizer Selection by ID');
      case FavoriteType.AmenityMedia:
        return this.addAmenityMedia(id as number);
      case FavoriteType.CustomSubPage:
        return this.addCustomSubPage(id as number);
    }
  }

  remove(favoriteType: FavoriteType, id: number | string, inventoryHomeId?: number) {
    const isInventoryHome = !!inventoryHomeId;
    switch (favoriteType) {
      case FavoriteType.Floorplan:
      case FavoriteType.InventoryHome:
        return isInventoryHome
          ? this.removeInventoryHome(inventoryHomeId)
          : this.removeFloorplan(id as number);
      case FavoriteType.Elevation:
        return isInventoryHome
          ? this.removeInventoryHomeElevation(id as number, inventoryHomeId)
          : this.removeFloorplanElevation(id as number);
      case FavoriteType.Media:
        return isInventoryHome
          ? this.removeInventoryHomeMedia(id as number, inventoryHomeId)
          : this.removeFloorplanMedia(id as number);
      case FavoriteType.Lot:
        return this.removeLot(id as number);
      case FavoriteType.LotMedia:
        return this.removeLotMedia(id as number);
      case FavoriteType.PointOfInterest:
        return this.removePOI(id as string);
      case FavoriteType.PageContentSection:
        return this.removePageContentSection(id as string);
      case FavoriteType.VisualizerSelection:
        return this.removeVisualizerSelection(id as string);
      case FavoriteType.AmenityMedia:
        return this.removeAmenityMedia(id as number);
      case FavoriteType.CustomSubPage:
        return this.removeCustomSubPage(id as number);
    }
  }

  async get(favoritesSessionId: number): Promise<IFavoritesSession> {
    return await this.api.getById<IFavoritesSession>(favoritesSessionId, 'FavoritesSession');
  }

  turnOffFavoriting() {
    this.isEnabled = false;
  }

  isFavoritingEnabled(): boolean {
    return this.isEnabled;
  }

  private hasReachedMaxFloorPlanLimit() {
    const maxLimit = this.settingsService.get('MaxFavoritesLimitFloorPlansAndInventoryHomes');
    if (maxLimit && this.current.TotalFloorplansCount >= maxLimit) {
      this.toaster.showInfo(
        'Wait!',
        // eslint-disable-next-line max-len
        `The maximum number of floor plan favorites is ${maxLimit}. Please remove an existing one if you would like to make this a favorite.`
      );
      return true;
    } else return false;
  }

  isAllowedVisualizerDeleteFavoritesModalToShow() {
    return this.showVisualizerDeleteFavorites;
  }

  setAllowedVisualizerDeleteFavoritesModalToShow(val) {
    this.showVisualizerDeleteFavorites = val;
  }

  mergeFavoritesWithIncomingHomeBuyerFavorites(existingHbFavSession: IFavoritesSession) {
    // Create a new FavoriteSession so that the GenrealJson is parsed to compare
    const mappedIncomingFavSession = new FavoritesSession(existingHbFavSession);
    // If current favorites exist check the current selections against the existing homebuyer selections
    if (this.current.TotalFavoritesCount) {
      if (this.current.FavoriteFloorplans.length) {
        this.current.FavoriteFloorplans.forEach(fp => {
          if (
            !mappedIncomingFavSession.FavoriteFloorplans.find(x => {
              if (fp.IsInventoryHome) {
                return fp.InventoryHomeId === x.InventoryHomeId;
              } else {
                return x.FloorplanId && x.FloorplanId === fp.FloorplanId;
              }
            })
          ) {
            mappedIncomingFavSession.FavoriteFloorplans.push(fp);
          }
        });
      }
      if (this.current.FavoriteLots.length) {
        this.current.FavoriteLots.forEach(l => {
          if (!mappedIncomingFavSession.FavoriteLots.find(exlot => exlot.LotId === l.LotId)) {
            mappedIncomingFavSession.FavoriteLots.push(l);
          }
        });
      }
      if (this.current.PageIds.length) {
        const merged = new Set([...mappedIncomingFavSession.PageIds, ...this.current.PageIds]);
        mappedIncomingFavSession.PageIds = [...merged];
      }
      if (this.current.GoogleMapsMarkerIds?.length) {
        const merged = new Set([
          ...mappedIncomingFavSession.GoogleMapsMarkerIds,
          ...this.current.GoogleMapsMarkerIds
        ]);
        mappedIncomingFavSession.GoogleMapsMarkerIds = [...merged];
      }
      if (this.current.GooglePlaces.length) {
        this.current.GooglePlaces.forEach(gp => {
          if (!mappedIncomingFavSession.GooglePlaces.find(exgp => exgp.Id === gp.Id)) {
            mappedIncomingFavSession.GooglePlaces.push(gp);
          }
        });
      }
      if (this.current.PageContentSectionIds.length) {
        const merged = new Set([
          ...mappedIncomingFavSession.PageContentSectionIds,
          ...this.current.PageContentSectionIds
        ]);
        mappedIncomingFavSession.PageContentSectionIds = [...merged];
      }
      if (this.current.VisualizerSelections.length) {
        this.current.VisualizerSelections.forEach(vs => {
          if (!mappedIncomingFavSession.VisualizerSelections.find(exvs => exvs.Id === vs.Id)) {
            mappedIncomingFavSession.VisualizerSelections.push(vs);
          }
        });
      }
      if (this.current.AmenityMediaIds.length) {
        const merged = new Set([
          ...mappedIncomingFavSession.AmenityMediaIds,
          ...this.current.AmenityMediaIds
        ]);
        mappedIncomingFavSession.AmenityMediaIds = [...merged];
      }
      if (this.current.FloorPlanReverseStates.size) {
        for (const [key, value] of this.current.FloorPlanReverseStates) {
          if (!mappedIncomingFavSession.FloorPlanReverseStates.get(key)) {
            mappedIncomingFavSession.FloorPlanReverseStates.set(key, value);
          }
        }
      }
      if (this.current.FloorplanFiltersState) {
        mappedIncomingFavSession.FloorplanFiltersState = this.current.FloorplanFiltersState;
      }
      this.saveAndEmitChange(mappedIncomingFavSession);
    }
    this.current = new FavoritesSession(mappedIncomingFavSession);
  }

  hasFloorplanBeenViewed(id: number) {
    if (this.viewedFloorplansIds.includes(+id)) {
      return true;
    } else {
      this.viewedFloorplansIds.push(+id);
      return false;
    }
  }
}

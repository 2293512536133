import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'floating-side-menu',
  templateUrl: './floating-side-menu.component.html',
  styleUrls: ['./floating-side-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloatingSideMenuComponent implements OnInit, OnChanges {
  @HostBinding('class.open') showMenuOpen = false;
  @Input() open = false;
  @Output() openChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.showMenuOpen = this.open;
  }

  closeMenu() {
    this.openChange.emit(false);
  }
}

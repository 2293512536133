import { getDefaultIconNameForPage } from '../helpers/helpers';
import { IBaseContentJson, PageType } from '../interfaces/page';
import { PageVM } from './page-vm';

/**For use with MyScp's home page nav and quick nav */
export class ModuleGridItemVM {
  static SalesPinUniqueName = 'PinLogin';
  static HomeBuyerRegisterUniqueName = 'HomeBuyerRegister';

  Id: number;
  UniqueName: string;
  Title: string;
  IconName: string;
  IconSvg: string;
  Description: string;
  HasRelatedFavorites = false;
  RelatedFavoritesNumber = 0;
  IsInPrimaryQuickNav = false;
  IsInSecondaryQuickNav = false;
  IsHiddenInNav = false;
  IsCustomPage = false;
  Subtitle: string;
  IsSelected = false;
  ShowResetButton = false;
  ResetButtonTooltip: string;
  Tooltip: string;
  ShortName = '';

  constructor(data: Partial<ModuleGridItemVM>);

  constructor(page: PageVM<IBaseContentJson>, favoritesCount?: number);

  constructor(
    moduleOrPage: Partial<ModuleGridItemVM> | PageVM<IBaseContentJson>,
    favoritesCount?: number
  ) {
    if (moduleOrPage instanceof PageVM) {
      const page = moduleOrPage as PageVM<IBaseContentJson>;
      this.Id = page.PageId;
      this.UniqueName = page.PageTitle;
      this.Title = page.NavigationTitle || page.PageTitle;
      this.IconName = getDefaultIconNameForPage(page.PageTitle);
      this.IconSvg = page.IconSvgContent;
      this.Description = page.Content.Description;
      this.IsInPrimaryQuickNav = page.Content.IsInPrimaryQuickNav;
      this.IsInSecondaryQuickNav = page.Content.IsInSecondaryQuickNav;
      this.IsHiddenInNav = page.Content.IsHiddenFromNav;
      this.IsCustomPage =
        !page.IsBuiltIn && (!page.PageType || page.PageType === PageType.IframeContent);
      this.ShortName = page.PageHeading || this.Title?.replace('\r\n', ' ');
    } else if (moduleOrPage) {
      Object.assign(this, moduleOrPage);
    }

    if (typeof favoritesCount === 'number') {
      this.HasRelatedFavorites = true;
      this.RelatedFavoritesNumber = favoritesCount;
    }

    this.Tooltip = this.Title?.replace('\r\n', ' ');
  }
}

import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[elementScroll]'
})
export class ElementScrollDirective implements OnInit, OnDestroy {
  @Input() scrollClass: string;
  @Input() applyToElementSelector: string;

  private elementToApplyClass: any;
  private intersectionObserver: IntersectionObserver;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.elementToApplyClass = this.renderer.selectRootElement(this.applyToElementSelector, true);
    this.setObserver();
  }

  @HostListener('scroll', [])
  onScroll() {
    this.checkScrollClass(this.elementToApplyClass);
  }

  ngOnDestroy() {
    this.intersectionObserver.disconnect();
  }

  checkScrollClass(elem: any) {
    if (this.shouldAddClass()) this.addClass(elem);
    else this.removeClass(elem);
  }

  private setObserver() {
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(x => {
        if (x.isIntersecting) this.checkScrollClass(this.elementToApplyClass);
      });
    });
    this.intersectionObserver.observe(this.el.nativeElement);
  }

  private shouldAddClass() {
    return this.el.nativeElement.scrollTop >= 20;
  }

  private addClass(elem: any) {
    if (elem) this.renderer.addClass(elem, this.scrollClass);
  }

  private removeClass(elem: any) {
    if (elem) this.renderer.removeClass(elem, this.scrollClass);
  }
}

import { IFavoritesSession, IHomeBuyer } from '@ml/common';

export class HomeBuyerVM implements IHomeBuyer {
  HomeBuyerId: number;
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  ClientId: number;
  SalesRepresentativeId: string;
  CreatedOn: string;
  FavoritesSessions: IFavoritesSession[] = [];
  ExternalCrmId: string;
  CustomData: { [index: string]: any };
  FullName: string;

  constructor(homeBuyer?: IHomeBuyer) {
    if (homeBuyer) Object.assign(this, homeBuyer);

    this.FullName = `${this.FirstName} ${this.LastName}`;
  }

  mapToEntity(): IHomeBuyer {
    const hb: IHomeBuyer = Object.assign({}, this);
    return hb;
  }
}

<form class="share-form hc-styles" #shareForm="ngForm" (ngSubmit)="handleShare(shareForm)" [ngSwitch]="shareType">

    <ng-container *ngSwitchCase="shareTypes.Email">
        <div class="row">
            <h3>Share this with someone</h3>
            <i>Separate multiple email addresses with a comma.</i>
        </div>

        <div class="row">
            <label for="Emails">Send To</label>
            <input type="email" multiple required [mlAutofocus]="true" id="Emails" name="Emails" [(ngModel)]="emailAddresses"
                   placeholder="Enter email addresses" [pattern]="regexPatterns.CommaSeparatedEmailAddresses" />
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="shareTypes.ScheduleAppointment">
        <div class="row">
            <h3>Schedule Appointment</h3>
            <i>Provide details for an associate to contact you and discuss your configured build further.</i>
        </div>

        <div class="row">
            <label for="userFullName">Your Name</label>
            <input type="text" required [mlAutofocus]="true" id="userFullName" name="Full Name" [(ngModel)]="userFullName" />
        </div>
        <div class="row">
            <label for="userEmail">Your Email</label>
            <input type="email" required id="userEmail" name="Email" [(ngModel)]="userEmail" />
        </div>
        <div class="row">
            <label for="userPreferredDate">Preferred Date & Time (Optional)</label>
            <input type="datetime-local" id="userPreferredDate" name="Preferred Date" [(ngModel)]="userPreferredDate" />
        </div>
    </ng-container>

    <div class="row">
        <label for="message">Your Message (Optional)</label>
        <textarea rows="4" id="message" name="message" [(ngModel)]="customMessage"></textarea>
    </div>

    <button class="hc-btn primary" [class.disabled]="shareForm.pristine || shareForm.invalid">Send</button>
</form>
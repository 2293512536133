import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { filter } from 'rxjs/operators';

import { ToastService } from '../../global/toast/toast.service';
import { RegexPatterns } from '../../utility/helpers/regex';
import { EmailRequest } from '../../utility/interfaces/email-request';
import { CommunityService } from '../../utility/services/community.service';
import { EmailService } from '../../utility/services/email.service';
import { FavoritesService } from '../../utility/services/favorites.service';
import { HomeBuyerService } from '../../utility/services/home-buyer/home-buyer.service';
import { NavigationService } from '../../utility/services/navigation.service';
import { OverlayService, OverlayState, OverlayTypes } from '../../utility/services/overlay.service';
import { SalesRepresentativeService } from '../../utility/services/sales-representative.service';
import { SettingsService } from '../../utility/services/settings.service';

@Component({
  selector: 'share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareDialogComponent implements OnChanges {
  @Input()
  get isOpen() {
    return this.isOpenValue;
  }
  set isOpen(val: boolean) {
    this.isOpenValue = val;
    this.isOpenChange.emit(val);
  }
  @Input() isSharedFromVisualizer = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  isOpenValue = false;
  emailAddresses: string;
  customMessage: string;
  regexPatterns = RegexPatterns;

  constructor(
    private emailService: EmailService,
    private communityService: CommunityService,
    private cdr: ChangeDetectorRef,
    private settingsService: SettingsService,
    private salesRepService: SalesRepresentativeService,
    private navigator: NavigationService,
    private favorites: FavoritesService,
    private homeBuyerService: HomeBuyerService,
    private overlay: OverlayService,
    private toaster: ToastService
  ) {}

  ngOnChanges() {
    if (this.isOpen) {
      this.emailAddresses = '';
      this.customMessage = '';

      this.ensureHomeBuyerIsRegistered();
    }
  }

  private ensureHomeBuyerIsRegistered() {
    if (this.homeBuyerService.isAnyoneLoggedIn()) {
      this.emailAddresses = this.homeBuyerService.current.Email;
    } else {
      this.isOpen = false;
      this.overlay.show(OverlayTypes.HomeBuyerRegistration);
      const overlaySub = this.overlay.state$
        .pipe(
          filter(
            (state: OverlayState) => state.targetedComponent === OverlayTypes.HomeBuyerRegistration
          )
        )
        .subscribe(async (state: OverlayState) => {
          if (!state.isShowing) {
            if (this.homeBuyerService.isAnyoneLoggedIn()) {
              if (!this.favorites.hasSessionBeenSaved())
                await this.favorites.saveAndEmitChange(this.favorites.current, true);

              this.emailAddresses = this.homeBuyerService.current.Email;
              this.isOpen = true;
            }
            overlaySub.unsubscribe();
          }
        });
    }
  }

  handleClose() {
    this.isOpen = false;
  }

  handleShare(form: NgForm) {
    if (form.invalid) return;

    this.isOpen = false;
    this.cdr.detectChanges();
    this.toaster.showLoading();

    const emailRequest = this.buildEmail();
    this.emailService.sendEmail(emailRequest).subscribe(
      () => {
        this.toaster.showSuccess('Success!', 'Your favorites brochure has been shared!');
      },
      (error: HttpErrorResponse) => {
        console.error(`Error sending email: ${error.message}`);
        this.toaster.showStickyError(
          'Oh no...',
          'Something went wrong.\nPlease close this window and try again.'
        );
      }
    );
  }

  private buildEmail() {
    const title = this.settingsService
      .get('ShareEmailSubjectLineText')
      .replace(/{{communityName}}/g, this.communityService.getDisplayName());
    const message = this.buildEmailBody();
    const emailRequest = new EmailRequest(this.emailAddresses, title, message);
    emailRequest.isHtml = true;
    emailRequest.communityIdForSettings = this.communityService.current.CommunityId;

    const cc = this.settingsService.get('ShareEmailCC');

    const salesRep = this.salesRepService.current;
    if (salesRep) {
      cc.push(salesRep.Email);
    }

    if (cc.length) {
      const ccAddresses = cc.join(',');
      emailRequest.cc = ccAddresses;
    }
    return emailRequest;
  }

  private buildEmailBody() {
    const content: string[] = [];

    let shareEmailText: string = this.settingsService.get('ShareEmailText');
    let salesRepInfo: string;
    const salesRep = this.salesRepService.current;
    if (!shareEmailText) {
      // eslint-disable-next-line max-len
      shareEmailText = `Thank you for choosing ${this.communityService.current.ClientName}. Here is a personalized brochure from your visit today.`;
      if (salesRep)
        // eslint-disable-next-line max-len
        shareEmailText += ` If you have any questions, please reach out to ${salesRep.FirstName} ${salesRep.LastName} at ${salesRep.Email}.`;
    } else {
      if (salesRep)
        salesRepInfo = `${salesRep.FirstName} ${salesRep.LastName}\r\n${salesRep.Email}`;
    }

    // Converge setting HelpText specifies users may use {{communityName}} to insert proper name
    shareEmailText = shareEmailText.replace(
      /{{communityName}}/gi,
      this.communityService.getDisplayName()
    );

    content.push(this.customMessage);

    const shareEmailLogo = this.settingsService.get('ShareEmailLogo');
    if (shareEmailLogo) {
      content.push(
        `<img src=${shareEmailLogo}  style="width: 200px; height: 125px; margin: 0 auto; display: block; object-fit: contain;" />`
      );
    }
    content.push(shareEmailText);

    if (!this.favorites.current?.FavoritesSessionId) {
      throw new Error('No favorites session id to generate the shared brochure link');
    }

    if (this.favorites.current?.VisualizerSelections.length && this.isSharedFromVisualizer) {
      const schemeDetailsArray: { schemeTitle: string; schemeDetails: string }[] = [];
      this.favorites.current?.VisualizerSelections.forEach(vs => {
        const title = vs.Selections.find(s => s.Type === 'Scheme');
        if (vs.Description && title) {
          const formattedTitle = title.Value;
          schemeDetailsArray.push({
            schemeTitle: formattedTitle.replace(/_/g, ' '),
            schemeDetails: vs.Description
          });
        }
      });
      // Add Scheme details to email body if they are available
      if (schemeDetailsArray.length) {
        schemeDetailsArray.forEach(sd => {
          // email does not recognize just the \n for a new line, replace all with \r\n to move the test to a new line.
          // Added white space to create an indentation effect for the title and details
          content.push(
            `Scheme Title:\r\n &nbsp;${
              sd.schemeTitle
            }\r\n\r\nScheme Details:\r\n &nbsp;${sd.schemeDetails.replace(/\n/g, '\r\n&nbsp;')}`
          );
        });
      }
    }

    const shareEmailLink = this.navigator.getSharedBrochureLink(
      this.favorites.current.FavoritesSessionId
    );
    const shareEmailLinkText = this.settingsService.get('ShareEmailLinkText');

    content.push(`&lt;a href=&quot;${shareEmailLink}&quot;&gt;${shareEmailLinkText}&lt;/a&gt;`);
    content.push(salesRepInfo);
    return content.filter(item => !!item).join('\r\n\r\n');
  }
}

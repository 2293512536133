import {
  IActionRequest,
  IActionSet,
  IActionSetAction,
  ILayer,
  ILayerAction,
  ILayerToLayerAction,
  ILogic,
  LogicState
} from '@ml/common';

// export enum LogicState {
//   ERROR,
//   ON,
//   OFF,
//   DISABLED_ON,
//   DISABLED_OFF,
//   /** Technically not needed and should be treated the same as OFF -- however data will contain states with this 5 enum value */
//   ENABLED
// }

export class ActionSet implements IActionSet {
  ActionSetId: number;
  AdditionalFloors: string;
  AffectedFloorId?: number;
  AffectedFloorState?: number;
  AlwaysListening: boolean;
  BathAdj?: number;
  BedroomAdj?: number;
  ClientUniqueIdentifier: string;
  Description: string;
  DenAdj?: number;
  FloorId: number;
  GarageAdj?: number;
  GarageCarSizeAdj?: number;
  HalfBathAdj?: number;
  InitialState?: number;
  InternalNotes: string;
  IsFromAdditionalFloors: boolean;
  IsGroup: boolean;
  IsInMenu: boolean;
  MediaId?: number;
  Name: string;
  SortOrder: number;
  SquareFeetAdj?: number;
  StateAffectingFloor: number;
  Price?: number;
  ActionRequest = new Array<ActionRequest>();
  ImageFilename: string;
  ElevationId?: number;
  IsStructural: boolean;
  ShowOptionHighlight: boolean;

  State: LogicState;

  constructor(data?: IActionSet, state?: LogicState) {
    if (data) {
      Object.assign(this, data);
      if (typeof this.InitialState === 'number') this.State = this.InitialState;
      else this.State = LogicState.OFF;
      this.ActionRequest = data.ActionRequest.map(ar => new ActionRequest(ar));
    }

    if (typeof state === 'number') this.State = state;
  }
}

export class ActionRequest implements IActionRequest {
  ActionRequestId: number;
  ActionSetId: number;
  ActionSetState: number;
  LogicCheck: string;
  Logic: Array<Array<Logic>>;
  ActionSetAction: ActionSetAction[];
  LayerAction: LayerAction[];

  constructor(data?: IActionRequest) {
    if (data) {
      Object.assign(this, data);
      this.LayerAction = data.LayerAction.map(la => new LayerAction(la));
    }
  }
}

export class Logic implements ILogic {
  Id: number;
  State: LogicState;

  constructor(id: number, state: LogicState) {
    this.Id = id;
    this.State = state;
  }
}

export class ActionSetAction implements IActionSetAction {
  ActionSetActionId: number;
  SourceActionRequestId: number;
  TargetActionSetId: number;
  DesiredState: number;
  CommandOrder: number;
  FailureAction: number;
}

export class LayerAction implements ILayerAction {
  LayerActionId: number;
  ActionRequestId: number;
  LogicCheck: string;
  Logic: Array<Array<Logic>>;
  LayerToLayerAction = new Array<LayerToLayerAction>();

  constructor(data?: ILayerAction) {
    if (data) {
      Object.assign(this, data);
      this.LayerToLayerAction = data.LayerToLayerAction.map(lal => new LayerToLayerAction(lal));
    }
  }
}

export class LayerToLayerAction implements ILayerToLayerAction {
  LayerToLayerActionId: number;
  LayerId: number;
  LayerActionId: number;
  State = LogicState.OFF;
  Layer: Layer;

  constructor(data?: ILayerToLayerAction) {
    if (data) {
      Object.assign(this, data);
      this.Layer = new Layer(data.Layer);
    }
  }
}

export class Layer implements ILayer {
  LayerId: number;
  FloorId: number;
  Title: string;
  SortOrder: number;
  InitialState: number;
  AllowUserToggle: boolean;
  EnabledFloorId: number;
  PreConditionMetState: number;
  InstanceId: string;
  IsOption: boolean;

  constructor(data?: ILayer) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'numeric-input',
  templateUrl: './numeric-input.component.html',
  styleUrls: ['./numeric-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NumericInputComponent {
  @Input() value = 0;
  @Input() maxValue = 0;
  @Input() step = 1;
  @Input() ariaLabel: string;
  @Output() valueChange = new EventEmitter<number>();
  minValue: number;

  constructor() {
    this.maxValue = this.maxValue === 0 ? 99 : this.maxValue;
    this.minValue = 0;
  }

  handleStep(direction: number) {
    const newValue = this.value + direction * this.step;
    if (newValue > this.maxValue || newValue < this.minValue) return;
    this.value = newValue;
    this.valueChange.emit(this.value);
  }
}

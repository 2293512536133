import { MatTooltipDefaultOptions } from '@angular/material/tooltip';

export const CustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1500,
  hideDelay: 0,
  touchendHideDelay: 1500
};

export interface IColorTransparencyModifier {
  Suffix: string;
  Opacity: number;
}

export const ColorTransparencyModifiers: IColorTransparencyModifier[] = [
  {
    Suffix: '-alpha-low',
    Opacity: 0.2
  },
  {
    Suffix: '-alpha-medium',
    Opacity: 0.5
  },
  {
    Suffix: '-alpha-high',
    Opacity: 0.9
  }
];

export interface IColorLuminanceModifier {
  Suffix: string;
  PercentLighter: number;
}

export const ColorLuminanceModifiers: IColorLuminanceModifier[] = [
  // Lighter not needed at this time
  // {
  //   Suffix: '-lightest',
  //   PercentLighter: 25
  // },
  // {
  //   Suffix: '-lighter',
  //   PercentLighter: 10
  // },
  {
    Suffix: '-darker',
    PercentLighter: -10
  }
];

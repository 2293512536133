import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeBackgroundImageUrl'
})
export class SafeBackgroundImageUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string) {
    if (!url) return '';
    return this.sanitizer.bypassSecurityTrustStyle(`url('${url}')`);
  }
}

@Pipe({
  name: 'safeStyle'
})
export class SafeStylePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(style: string) {
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}

@Pipe({
  name: 'customDataMapper'
})
export class CustomDataMapperPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    // regex changed from "/((?<!^)(?<!\s)[A-Z](?![A-Z]))/g" since Safari does not support lookbehind regex statements
    // matches capital letters and inserts a space before each following a trim
    // the next step in the regex is to ensure any multiple space occurrences are modified to a single space
    // test cases:
    // "CustomDataProperties" -> "Custom Data Properties"
    // "Custom DataProperties" -> "Custom Data Properties"
    value = value.replace(/([A-Z])/g, ' $1').trim();
    return value.replace(/(\s+)/g, ' ');
  }
}

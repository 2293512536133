import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ActionSet, Logic } from '../models/action-set';
import { FloorPlanRules } from '../models/floorplan-rules';
import { FloorplanVM } from '../models/floorplan-vm';
import { FloorArtService } from './floor-art.service';

import { LogicState } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class FloorplanRulesService {
  private store = new Array<FloorPlanRules>();
  private changeSubject = new Subject<Logic[]>();

  constructor(private floorArtService: FloorArtService) {}

  get changes$() {
    return this.changeSubject.asObservable();
  }

  initialize(floorplan: FloorplanVM) {
    this.store = this.store.filter(rules => rules.FloorplanId !== floorplan.FloorplanId);
    const fpRules = new FloorPlanRules(this.floorArtService, floorplan);
    this.store.push(fpRules);
  }

  getByFloorPlanId(id: number): FloorPlanRules {
    return this.store.find(rules => rules.FloorplanId === id);
  }

  toggleActionSet(floorplanId: number, actionSetId: number, state?: boolean): Array<Logic> {
    const floorplanRules = this.getRules(floorplanId);

    const changedStates = floorplanRules.toggleActionSet(actionSetId, state);
    this.changeSubject.next(changedStates);
    return changedStates;
  }

  isActionSetDisabledOff(floorplanId: number, actionSetId: number) {
    const aset = this.getAllActionSets(floorplanId)?.find(x => x.ActionSetId === actionSetId);
    return aset?.State === LogicState.DISABLED_OFF;
  }

  private getRules(floorplanId: number) {
    const floorplanRules = this.store.find(fpr => fpr.FloorplanId === floorplanId);
    if (!floorplanRules)
      throw new Error('FloorplanRules not initialized for floorplan id: ' + floorplanId);
    return floorplanRules;
  }

  isFloorDisabled(floorId: number, floorplanId: number) {
    try {
      return this.getRules(floorplanId).isFloorDisabled(floorId);
    } catch {
      console.warn(
        // eslint-disable-next-line max-len
        `Cannot accurately determine if floor ${floorId} is disabled for floorplan ${floorplanId} since Rules have not yet been initialized for it`
      );
      return false;
    }
  }

  getAllActionSets(floorplanId: number): ActionSet[] {
    const actionSets = this.getByFloorPlanId(floorplanId);
    return actionSets?.getAllActionSets() || [];
  }

  getInMenuActionSets(floorplanId: number): ActionSet[] {
    const actionSets = this.getByFloorPlanId(floorplanId);
    return actionSets?.getInMenuActionSets() || [];
  }

  getOnInMenuActionSets(floorplanId: number): ActionSet[] {
    const actionSets = this.getByFloorPlanId(floorplanId);
    return actionSets?.getOnInMenuActionSets() || [];
  }

  getOnActionSetIds(floorplanId: number): number[] {
    const actionSets = this.getByFloorPlanId(floorplanId).getOnInMenuActionSets();
    return actionSets?.map(o => o.ActionSetId) || [];
  }
}

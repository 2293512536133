export interface IHubSpotRegistration {
  ClientId: number;
  CommunityId: number;
  CustomProps: object;
  SalesRepEmail: string;
}

export class HubSpotRegistrationModel implements IHubSpotRegistration {
  ClientId: number;
  CommunityId: number;
  CustomProps: object;
  SalesRepEmail: string;

  constructor(data?: IHubSpotRegistration) {
    if (data) Object.assign(this, data);
  }
}

import { Injectable } from '@angular/core';

import { CommunityService } from './community.service';

import { ILot, INeighborhood } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class NeighborhoodService {
  constructor(private communityService: CommunityService) {}

  getAll(): INeighborhood[] {
    if (!this.communityService.current.Neighborhoods) return [];
    return this.communityService.current.Neighborhoods;
  }

  get(neighborhoodId: number): INeighborhood {
    const hood = this.getAll().find(x => x.NeighborhoodId === neighborhoodId);
    if (hood) return hood;
    else throw Error(`No neighborhood found with id ${neighborhoodId}`);
  }

  getBaseUrl(neighborhoodId: number): string {
    const neighborhood = this.get(neighborhoodId);
    return `${this.communityService.getBaseApiUrl()}/${neighborhood.Name}_${neighborhoodId}`;
  }

  getStatuses(neighborhoodId: number) {
    const hood = this.get(neighborhoodId);
    return hood.LotMapStatuses;
  }

  updateLotInPlace(lot: ILot) {
    const hood = this.get(lot.NeighborhoodId);
    const i = hood.Lots.findIndex(x => x.LotId === lot.LotId);
    hood.Lots[i] = lot;
  }
}

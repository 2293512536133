import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

import { roundToPlace } from '../utility/helpers/helpers';
import { GraphicsModel } from '../utility/models/graphics-model';

// Use this directive to apply CSS Transforms to an element based on the GraphicsModel obj input param
// it will convert a GraphicsModel obj into a CSS Transform string
// and then apply it to the elements style (using appropriate browser property)
@Directive({
  selector: '[transformApplier]'
})
export class TransformApplierDirective implements OnChanges {
  @Input('transformApplier') gm: GraphicsModel;
  @Input() ignoreOffsets = false;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges() {
    if (this.gm) this.applyTransform();
  }

  private applyTransform() {
    const {
      X,
      Y,
      Scale,
      DefaultScale,
      ItemWidth,
      ItemHeight,
      ContainerWidth,
      ContainerHeight,
      IsReversed,
      OffsetX,
      OffsetY
    }: GraphicsModel = this.gm;
    const fullScale = Scale * DefaultScale;
    let containerOffSetX = (ContainerWidth - ItemWidth * fullScale) / 2;
    const containerOffSetY = (ContainerHeight - ItemHeight * fullScale) / 2;
    if (IsReversed) containerOffSetX += ItemWidth * fullScale;

    let x = X - OffsetX * fullScale;
    let y = Y - OffsetY * fullScale;
    if (!this.ignoreOffsets) {
      x += containerOffSetX;
      y += containerOffSetY;
    }

    let translate = `translate(${x}px, ${y}px)`;
    // only apply 3D translate if interaction is active
    if (this.gm.ActiveInteraction) translate = `translate3d(${x}px, ${y}px, 0)`;

    const transformString = `${translate} ${this.getScaleTransform(fullScale, this.gm.IsReversed)}`;
    this.setTransformStyle(this.elementRef.nativeElement, transformString);

    this.applyTextTransform();
  }

  private setTransformStyle(element: HTMLElement, transform: string) {
    requestAnimationFrame(() => {
      // this should cover IE, Chrome, Firefox, and Safari
      element.style.transform = transform;
      // eslint-disable-next-line import/no-deprecated
      element.style.webkitTransform = transform;
    });
  }

  private applyTextTransform() {
    const textElements = this.elementRef.nativeElement.querySelectorAll(
      'use[id^=te],use[id^=LevelText],g[id^=te],g[id^=tr],g[id^=tc],text,[id^=tl],[id^=tdm],[id^=tm],[id^=tf],g[id^=tback]'
    );

    const svg = this.elementRef.nativeElement.querySelector('svg');
    if (!svg) return;
    const svgWidth = svg.width.baseVal.value;

    textElements.forEach(textElement => {
      let transformString = '';

      if (textElement.hasAttribute('transform')) {
        transformString = textElement.getAttribute('transform');
      }

      // check if transform string is using matrix format
      if (transformString.indexOf('matrix') > -1) {
        const useCommas = transformString.indexOf(',') > -1;
        transformString = `matrix(${this.updateMatrixTransform(transformString, textElement)})`;
        if (!useCommas) transformString = transformString.replace(/,/gi, '');
        textElement.setAttribute('transform', transformString);
      } else {
        let elementBoundingBox;
        let centerPointX;
        try {
          elementBoundingBox = textElement.getBBox();
        } catch (e) {
          centerPointX = 0;
        }

        if (elementBoundingBox !== undefined)
          centerPointX = elementBoundingBox.x + elementBoundingBox.width / 2;

        const translateXAmount = this.gm.IsReversed
          ? roundToPlace((centerPointX - svgWidth / 2) * 2 + svgWidth, 2)
          : 0;
        const translateTransform = `translateX(${translateXAmount}px)`;
        let scale = 'scale(1, 1)';
        if (this.gm.IsReversed) scale = 'scale(-1, 1)';
        transformString = `${translateTransform} ${scale}`;
        this.setTransformStyle(textElement, transformString);
      }
    });
  }

  private updateMatrixTransform(transformString: string, textElement: any) {
    transformString = transformString
      .replace('matrix( ', 'matrix(')
      .replace('matrix(', '')
      .replace(')', '')
      .replace(/, /gi, ',') // this seems insane but necessary to normalize inconsistent comma/space like: 1, 0, 0, 1, 69.65,64.7
      .replace(/,/gi, ' ');

    const [a, b, c, d, e, f]: number[] = transformString
      .split(' ')
      .map(part => roundToPlace(+part, 2));
    if (this.gm.IsReversed) {
      if (textElement.id.indexOf('tr') >= 0) {
        transformString = '0, -1, -1, 0, ' + e + ', ' + f;
      } else if (textElement.id.indexOf('tc') >= 0) {
        transformString = '0, 1, 1, 0, ' + e + ', ' + f;
      } else if (a === 0 && b === 1 && c === -1) {
        transformString = '0, 1, 1, ' + d + ', ' + e + ', ' + f;
      } else if (textElement.id.indexOf('tback') > -1) {
        // do nothing
      } else if (a > 0) {
        transformString = -1 * a + ', ' + b + ', ' + c + ', ' + d + ', ' + e + ', ' + f;
      }
    } else {
      if (textElement.id.indexOf('tr') >= 0) {
        transformString = '1, 0, 0, 1, ' + e + ', ' + f;
      } else if (textElement.id.indexOf('tc') >= 0) {
        transformString = '1, 0, 0, 1, ' + e + ', ' + f;
      } else if (a === 0 && b === 1 && c === -1) {
        transformString = '0, 1, -1, ' + d + ', ' + e + ', ' + f;
      } else {
        transformString = Math.abs(a) + ', ' + b + ', ' + c + ', ' + d + ', ' + e + ', ' + f;
      }
    }
    return transformString;
  }

  getScaleTransform(scale: number, isReversed: boolean) {
    const flipHorizontal = isReversed ? -1 : 1;
    return `scale(${scale * flipHorizontal}, ${scale})`;
  }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';

import { LotVM } from '../../site-map/models/lot-vm';
import { formatUsaCurrency } from '../../utility/helpers/helpers';
import { PageVersion } from '../../utility/interfaces/page';
import { EffectiveFloorplan } from '../../utility/models/effective-floorplan';
import { NavigationService, PageTitle } from '../../utility/services/navigation.service';
import { PageService } from '../../utility/services/page.service';
import { ThemeService } from '../../utility/services/theme.service';
import { HcSessionItem } from '../hc-session/hc-session-models';
import { HcSessionService } from '../hc-session/hc-session.service';
import {
  ModuleSummary,
  ModuleSummaryItem,
  ModuleSummarySection
} from './hc-module-summary/hc-module-summary-vm';

@Injectable({
  providedIn: 'root'
})
export class HcModuleService {
  private moduleNavStateSubject = new BehaviorSubject<boolean>(true);
  private closeAllSubject = new Subject<boolean>();

  public startingModuleUniqueName: PageTitle;

  constructor(
    private theme: ThemeService,
    private navigator: NavigationService,
    private hcSessionService: HcSessionService,
    private dialog: MatDialog,
    private pageService: PageService
  ) {}

  get moduleNavState$() {
    return this.moduleNavStateSubject.asObservable();
  }

  get closeAllSummaries$() {
    return this.closeAllSubject.asObservable();
  }

  setModuleNavState(isShowing: boolean) {
    this.moduleNavStateSubject.next(isShowing);
  }

  closeAllSummaries() {
    this.closeAllSubject.next(true);
  }

  getModuleTitle(title: PageTitle) {
    return this.pageService.getAll(PageVersion.HomeConfigurator).find(x => x.PageTitle === title)
      ?.NavigationTitle;
  }

  isModuleEnabled(title: PageTitle) {
    return this.pageService.getAll(PageVersion.HomeConfigurator).find(x => x.PageTitle === title)
      ?.IsActive;
  }

  getFloorPlanSummary(fp: EffectiveFloorplan, sessionItem: HcSessionItem): ModuleSummary {
    const summary = new ModuleSummary();
    summary.EntityLabel = this.theme.getFloorPlanLabel();
    summary.Action = () => {
      this.dialog.closeAll();
      this.closeAllSummaries();

      if (fp)
        this.navigator.go({
          PageTitle: PageTitle.FloorPlans,
          RouteParams: { FloorplanId: fp.FloorplanId },
          QueryParams: { inventoryHomeId: fp.InventoryHomeId }
        });
      else this.hcSessionService.showFloorplanListOverlay();
    };

    let ss = new ModuleSummarySection();
    ss.ColumnNames = [summary.EntityLabel, 'Base Price'];
    summary.Sections.push(ss);

    if (!fp) return summary;

    ss.Items = [new ModuleSummaryItem({ Name: fp.Name, Value: fp.PriceDisplay ?? '--' })];

    ss = new ModuleSummarySection();
    ss.ColumnNames = ['Your Options', 'Price'];
    ss.Items = sessionItem.Options.map(
      x =>
        new ModuleSummaryItem({
          Name: x.Name,
          Value: x.Price ? formatUsaCurrency(x.Price) : '--'
        })
    );

    summary.Sections.push(ss);
    summary.HasSelections = true;
    return summary;
  }

  getLotSummary(lot: LotVM, sessionItem: HcSessionItem): ModuleSummary {
    const summary = new ModuleSummary();
    summary.EntityLabel = this.theme.getLotLabel();
    summary.Action = () => {
      this.navigator.go({
        PageTitle: PageTitle.CommunityMap,
        RouteParams: { NeighborhoodId: lot?.NeighborhoodId }
      });
      this.dialog.closeAll();
      this.closeAllSummaries();
    };

    let ss = new ModuleSummarySection();
    ss.ColumnNames = [summary.EntityLabel, 'Price'];
    summary.Sections.push(ss);

    if (!lot) return summary;

    ss.Items = [
      new ModuleSummaryItem({
        Name: sessionItem.Name,
        Subcontent: lot.Address,
        Value: sessionItem.Price ? formatUsaCurrency(sessionItem.Price) : '--'
      })
    ];

    if (sessionItem.Options.length) {
      ss = new ModuleSummarySection();
      ss.ColumnNames = ['Extras', 'Price'];
      ss.Items = sessionItem.Options.map(
        x =>
          new ModuleSummaryItem({
            Name: x.Name,
            Value: x.Price ? formatUsaCurrency(x.Price) : '--'
          })
      );

      summary.Sections.push(ss);
    }

    summary.HasSelections = true;
    return summary;
  }

  getExteriorSummary(sessionItem: HcSessionItem): ModuleSummary {
    const summary = new ModuleSummary();
    summary.EntityLabel = 'Exterior';
    summary.Action = () => {
      this.navigator.go({
        PageTitle: PageTitle.Exterior
      });
      this.dialog.closeAll();
      this.closeAllSummaries();
    };

    const ss = new ModuleSummarySection();
    ss.ColumnNames = ['Exterior', 'Price'];
    summary.Sections.push(ss);

    if (!sessionItem) return summary;

    ss.Items = [
      new ModuleSummaryItem({
        Name: sessionItem.Name,
        Value: sessionItem.Price ? formatUsaCurrency(sessionItem.Price) : '--'
      })
    ];

    summary.HasSelections = true;
    return summary;
  }

  getInteriorSummary(sessionItem: HcSessionItem): ModuleSummary {
    const summary = new ModuleSummary();
    summary.EntityLabel = 'Interior';
    summary.Action = () => {
      this.navigator.go({
        PageTitle: PageTitle.Interior
      });
      this.dialog.closeAll();
      this.closeAllSummaries();
    };

    const ss = new ModuleSummarySection();
    ss.ColumnNames = ['Interior', 'Price'];
    summary.Sections.push(ss);

    if (!sessionItem) return summary;

    ss.Items = [
      new ModuleSummaryItem({
        Name: sessionItem.Name,
        Value: sessionItem.Price ? formatUsaCurrency(sessionItem.Price) : '--'
      })
    ];

    summary.HasSelections = true;
    return summary;
  }
}

import { IMedia, MediaType } from '@ml/common';

export class MediaVM implements IMedia {
  AmenityMapId?: number;
  CommunityId?: number;
  DefaultResourceFilename: string;
  ExternalUrl: string;
  FloorId?: number;
  FloorPlanId?: number;
  ImageFilename: string;
  ImageModifiedOn: string;
  ImageUrl: string;
  MediaId: number;
  MediaType: MediaType;
  ModifiedOn: string;
  Order?: number;
  ResourceFoldername: string;
  Title: string;
  TriggerButtonId: string;
  LotId: number;
  OpenExternalUrlInPopup: boolean;
  OpenExternalUrlInPopupNoChrome: boolean;
  LockAgainstApi: boolean;
  InternalImageUrl: string;
  HotspotIds: number[];
  FloorPlateId?: number;
  IsPrimary: boolean;

  IsFavorited: boolean;

  constructor(data?: IMedia, isFavorited: boolean = false) {
    Object.assign(this, data);
    if (typeof isFavorited === 'boolean') this.IsFavorited = isFavorited;
  }
}

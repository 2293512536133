import { HomeBuyerVM } from '../models/home-buyer';

import { ISalesRep } from '@ml/common';

export class MattamyHomeBuyer {
  FirstName: string;
  LastName: string;
  Email: string;
  Phone?: string;
  PhoneCountryCode?: string;
  PhoneAreaCode?: string;
  Type: string;
  EmailConsent: string;
  RegistrationDate: string;
  RegistrationType: string;
  MPC: string;
  Source?: string;
  WorkingWithRealtor: string;
  FavFloorplans?: string;
  FavHomesites?: string;
  CreationMethod: string;
  RealtorName?: string;
  SEmail?: string;
  Zip?: string;
  State?: string;
  Country?: string;

  constructor(
    homeBuyer: HomeBuyerVM,
    mpc: string,
    initialRegistration: boolean,
    salesRep?: ISalesRep
  ) {
    this.FirstName = homeBuyer.FirstName;
    this.LastName = homeBuyer.LastName;
    this.Email = homeBuyer.Email;
    this.Type = homeBuyer.CustomData.Type === 'No' ? 'Buyer' : 'Real Estate Agent';
    this.WorkingWithRealtor = homeBuyer.CustomData.WorkingWithRealtor === 'No' ? 'N' : 'Y';
    this.RegistrationDate = new Date(homeBuyer.CreatedOn).toISOString().split('T')[0];
    this.CreationMethod = 'Walk in-mySCP';
    this.MPC = mpc;

    this.RegistrationType = initialRegistration ? 'Initial' : 'Secondary';

    if (salesRep) this.SEmail = salesRep.Email;

    if (homeBuyer.CustomData.DisclaimerConsent) this.EmailConsent = 'Expressed';
    else this.EmailConsent = 'Implied';

    if (homeBuyer.CustomData.Zip) this.Zip = homeBuyer.CustomData.Zip;
    if (homeBuyer.CustomData.State) this.State = homeBuyer.CustomData.State;
    if (homeBuyer.CustomData.Country) this.Country = homeBuyer.CustomData.Country;
    if (homeBuyer.CustomData.Source) this.Source = homeBuyer.CustomData.Source;
    if (homeBuyer.CustomData.RealtorName) this.RealtorName = homeBuyer.CustomData.RealtorName;

    if (homeBuyer.PhoneNumber) {
      this.getOptionalPhoneInformation(homeBuyer.PhoneNumber);
    }
  }

  // Split phone number into area code and country code
  private getOptionalPhoneInformation(phoneNumber: string) {
    const phoneLength = phoneNumber.length;
    if (phoneLength === 7) {
      this.Phone = phoneNumber;
    } else if (phoneLength > 7 && phoneLength <= 10) {
      this.Phone = phoneNumber.slice(phoneLength - 7, phoneLength);
      this.PhoneAreaCode = phoneNumber.slice(0, phoneLength - 7);
    } else if (phoneLength > 10) {
      this.Phone = phoneNumber.slice(phoneLength - 7, phoneLength);
      this.PhoneAreaCode = phoneNumber.slice(phoneLength - 10, phoneLength - 7);
      const unformattedCountryCode = +phoneNumber.slice(0, phoneLength - 10);
      if (unformattedCountryCode < 10) {
        this.PhoneCountryCode = `00${unformattedCountryCode}`;
      } else if (unformattedCountryCode < 100) {
        this.PhoneCountryCode = `0${unformattedCountryCode}`;
      } else {
        this.PhoneCountryCode = `${unformattedCountryCode}`;
      }
    }
  }
}

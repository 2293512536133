import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { filter } from 'rxjs/operators';

import { enterLeaveFadeAnimation } from '../../global/animations';
import { FullscreenGalleryVM } from '../../global/fullscreen-gallery/fullscreen-gallery-vm';
import { PushToGoogleTagManager } from '../../utility/helpers/google-tag-manager';
import { OverlayService, OverlayTypes } from '../../utility/services/overlay.service';
import { SettingsService } from '../../utility/services/settings.service';
import { ThemeService } from '../../utility/services/theme.service';
import { VideoWithPreviewComponent } from '../video-with-preview/video-with-preview.component';
import { GalleryImageType, GalleryImageVM } from './gallery-image-vm';

@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [enterLeaveFadeAnimation()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent implements OnInit, OnChanges {
  @Input() images: Array<GalleryImageVM> = [];
  @Input() showTitle = false;
  @Input() isInventoryHomeGallery = false;
  @Input() useThumbnail = true;
  @Input() showControls = true;
  @Input() topControls = false;
  @Input() useLetterbox = false;
  @Input() disableFavoriting = false;
  @Input() customAltText: string;
  @Input() displayedImageOverrideId: { elevationId: number };
  @Output() favoriteToggle = new EventEmitter<GalleryImageVM>();
  @Output() imageChange = new EventEmitter<GalleryImageVM>();
  @ViewChild(VideoWithPreviewComponent) video: VideoWithPreviewComponent;
  selectedIndex = 0;
  isLoading = true;
  currentImage: GalleryImageVM;
  types = GalleryImageType;
  buttonBkgEnabled = true;
  favButtonPlacement = 'Right';
  altText = 'Image ';

  getImageSrc(image: GalleryImageVM): string {
    if (!image) return '';
    return this.useThumbnail ? image.ThumbnailUrl : image.FullUrl;
  }

  constructor(
    private overlay: OverlayService,
    private cdr: ChangeDetectorRef,
    public theme: ThemeService,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.buttonBkgEnabled = this.settingsService.get('GalleryBtnBkgEnabled');
    this.favButtonPlacement = this.settingsService.get('GalleryFavButtonPlacement');
    if (this.topControls) this.favButtonPlacement = 'Left';
    if (this.customAltText) this.altText = this.customAltText;
  }

  ngOnChanges(changes: SimpleChanges) {
    // can happen on lot details fp card, when switching between lots with the same floorplan but different elevations mapped
    if (
      changes.images?.previousValue &&
      changes.images.previousValue.length !== changes.images.currentValue.length
    )
      this.selectedIndex = 0;

    if (changes.displayedImageOverrideId?.currentValue?.elevationId) {
      const overrideImageIdx = this.images.findIndex(
        im => im.Id === changes.displayedImageOverrideId.currentValue.elevationId
      );

      if (overrideImageIdx > -1) {
        this.selectedIndex = overrideImageIdx;
      }
    }

    this.goToImage(this.selectedIndex);
  }

  onResourceLoadOrError = () => {
    this.isLoading = false;
  };

  goToImage(index: number, fromClick: boolean = false) {
    index = this.validateIndexBounds(index);
    this.selectedIndex = index;
    this.currentImage = this.images[this.selectedIndex];
    this.imageChange.emit(this.currentImage);

    this.cdr.markForCheck();

    if (fromClick)
      PushToGoogleTagManager({
        eventName: 'ClickGallery',
        eventType: 'App Click Interaction',
        eventValue: GalleryImageType[this.currentImage.Type]
      });
  }

  private validateIndexBounds(index: number) {
    if (index > this.images.length - 1) index = 0;
    if (index < 0) index = this.images.length - 1;
    return index;
  }

  goToFullscreen() {
    if (this.currentImage.IsVideo && this.video.isPlaying)
      this.currentImage.VideoTime = this.video.getCurrentTime();

    this.overlay.setState({
      isShowing: true,
      targetedComponent: OverlayTypes.FullscreenGallery,
      data: new FullscreenGalleryVM(this.images, this.selectedIndex)
    });

    // listen for fullscreen closing
    const sub = this.overlay.state$
      .pipe(filter(state => state.targetedComponent === OverlayTypes.FullscreenGallery))
      .subscribe(state => {
        if (!state.isShowing && state.data) {
          const data = state.data as FullscreenGalleryVM;
          this.goToImage(data.SelectedIndex);
          this.cdr.markForCheck();
        }

        // unsubscribe since this was one-time for fullscreen close
        sub.unsubscribe();
      });

    PushToGoogleTagManager({
      eventName: 'ClickFullscreenGallery',
      eventType: 'App Click Interaction',
      eventValue: GalleryImageType[this.currentImage.Type]
    });
  }

  onIndexChange(index: number) {
    this.selectedIndex = index;
  }

  handleFavoriteToggle(state: boolean) {
    this.favoriteToggle.emit({ ...this.currentImage, IsFavorited: state } as GalleryImageVM);
  }

  handleSwipeIndexChange(newIndex: number) {
    this.goToImage(newIndex);
  }

  trackById(index: number, item: GalleryImageVM) {
    return item.Id;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { DIRECTION_ALL, Manager, Pan, Press } from 'hammerjs';

import { Lookup } from '../../utility/helpers/lookup';
import { Spinner } from '../../utility/interfaces/spinner';
import { GraphicsModel } from '../../utility/models/graphics-model';
import { ISvgInjectorLoaded } from '../svg-injector.directive';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent implements OnInit {
  @Input() spinner: Spinner;
  @Output() svgsLoad = new EventEmitter<SVGElement[]>();
  frames: SpinnerFrameVM[] = [];
  startingFrameIndex = 1;
  currentFrameIndex = this.startingFrameIndex;
  svgFrameIndex = 0;
  allSvgs: SVGSVGElement[] = [];
  totalSvgCount = 0;
  frameGM = new GraphicsModel();

  readonly MaxScale = 2.5;

  constructor(
    public lookup: Lookup,
    private elementRef: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const baseUrl = `${this.lookup.ApiProductDataEndpoint}/Test/site-map-spinner`;

    this.frames = this.spinner.Frames.map(f => ({
        ImageUrl: `${baseUrl}/${f.ImageFilename}`,
        SvgOverlayUrl: f.SvgOverlayFileName ? `${baseUrl}/${f.SvgOverlayFileName}` : ''
      }));
    this.totalSvgCount = this.frames.filter(x => !!x.SvgOverlayUrl).length;

    this.startingFrameIndex = this.frames.length > 0 ? 1 : 0;
    // hack for demo which just happens to only have one frame with an svg overlay
    // can't be sure this will always work
    this.svgFrameIndex = this.frames.findIndex(x => !!x.SvgOverlayUrl);
    this.setupEvents(this.elementRef.nativeElement, this.frames.length);
  }

  setupEvents(element: HTMLElement, totalFrames: number) {
    const hammerManager = new Manager(element);
    const pan = new Pan({ threshold: 0, pointers: 0, direction: DIRECTION_ALL });
    const pressdown = new Press({ event: 'pressdown', time: 0 });
    pan.recognizeWith(pressdown);
    hammerManager.add([pressdown, pan]);

    const spinSpeedConstant = 1.5;
    const halfWidth = element.clientWidth / 2;
    // this is how many pixels the cursor needs to move to trigger a frame
    const pixelThreshhold = halfWidth / totalFrames / spinSpeedConstant;

    let framesMovedSoFar = 0;
    let lastFramesMoved = 0;
    let startingFrame = this.startingFrameIndex;

    hammerManager.on('pressdown panstart', () => {
      if (this.frameGM.Scale > 1) return;

      lastFramesMoved = 0;
      framesMovedSoFar = 0;
      startingFrame = this.currentFrameIndex;
    });

    hammerManager.on('panmove', evt => {
      if (this.frameGM.Scale > 1) return;

      const deltaX = evt.deltaX;

      framesMovedSoFar = Math.round(deltaX / pixelThreshhold);

      if (lastFramesMoved !== framesMovedSoFar) {
        lastFramesMoved = framesMovedSoFar;
        this.currentFrameIndex = this.calculateNewFrameIndex(
          startingFrame - framesMovedSoFar,
          totalFrames
        );
        this.cdr.markForCheck();
      }
    });
  }

  // logic to keep the index within correct frame min/max bounds
  private calculateNewFrameIndex(potentialIndex: number, maxFrame: number) {
    let naturalIndex = 0;
    let multiplier = 0;
    let newIndex = 0;

    naturalIndex = Math.abs(potentialIndex);
    multiplier = Math.floor(naturalIndex / maxFrame);
    newIndex = naturalIndex - maxFrame * multiplier;

    if (potentialIndex < 0 && newIndex !== 0) {
      newIndex = maxFrame - newIndex;
    }

    return newIndex;
  }

  handleFrameSelect() {
    this.animateBackToZeroIndex();
  }

  private animateBackToZeroIndex() {
    const halfIndex = this.frames.length / 2;
    const targetIndex = this.currentFrameIndex > halfIndex ? this.frames.length - 1 : 0;
    const direction = this.currentFrameIndex < targetIndex ? 1 : -1;

    const updateFrameIndex = () => {
      if (this.currentFrameIndex === targetIndex) {
        this.currentFrameIndex = this.startingFrameIndex;
        this.cdr.markForCheck();
      } else {
        this.currentFrameIndex += direction * 1;
        this.cdr.markForCheck();
        requestAnimationFrame(updateFrameIndex);
      }
    };
    requestAnimationFrame(updateFrameIndex);
  }

  handleHomeSiteViewSelect() {
    this.animateToHomeSiteIndex();
  }

  private animateToHomeSiteIndex() {
    const halfIndex = this.frames.length / 2;
    const targetIndex =
      this.currentFrameIndex > halfIndex ? this.frames.length - 1 : this.svgFrameIndex;
    const direction = this.currentFrameIndex < targetIndex ? 1 : -1;

    const updateFrameIndex = () => {
      if (this.currentFrameIndex === targetIndex) {
        this.currentFrameIndex = this.svgFrameIndex;
        this.resetScale();
        this.cdr.markForCheck();
      } else {
        this.currentFrameIndex += direction * 1;
        this.cdr.markForCheck();
        requestAnimationFrame(updateFrameIndex);
      }
    };
    requestAnimationFrame(updateFrameIndex);
  }

  onSvgLoad(data: ISvgInjectorLoaded) {
    this.allSvgs.push(data.Element as SVGSVGElement);
    if (this.allSvgs.length === this.totalSvgCount) this.svgsLoad.emit(this.allSvgs);
  }

  handleSvgLoadFailure(error: Error) {
    console.error(error);
  }

  handleFrameInteraction(gm: GraphicsModel) {
    this.frameGM = gm;
  }

  handleScaleStep(step: number) {
    let newScale = this.frameGM.Scale + step;
    newScale = Math.max(newScale, this.lookup.GraphicsMinScale);
    newScale = Math.min(newScale, this.MaxScale);
    this.frameGM = { ...this.frameGM, Scale: newScale };

    // ensure map is centered when at min scale
    if (newScale === this.lookup.GraphicsMinScale) {
      this.frameGM.X = 0;
      this.frameGM.Y = 0;
    }
  }

  resetScale() {
    this.frameGM = { ...this.frameGM, Scale: this.lookup.GraphicsMinScale, X: 0, Y: 0 };
  }
}

class SpinnerFrameVM {
  ImageUrl: string;
  SvgOverlayUrl: string;
}

<section class="specs-container" [class.open-enabled]="isCollapsible" [class.open]="isOpen" tabindex="0" (blur)="handleBlur()">
    <div *ngIf="isCollapsible" class="specs-header" (click)="toggleState()">
        <h2>Details</h2>
        <button class="arrow">
            <icon name="carrot_arrow"></icon>
        </button>
    </div>
    <div class="specs-body" [class.expand]="isCollapsible">
        <div class="price" *ngIf="showPrice && (fp.Price || fp.PriceRange)">
            <icon name="price"></icon>From&nbsp;<span class="value-display">{{fp.PriceDisplay}}</span>
        </div>
        <div class="floorplan-details" [ngClass]="fp.Price ? 'details-with-price' : 'details-without-price'">
            <div class="detail">
                <icon name="floor"></icon>
                <span [innerHtml]="fp.FloorCount"></span>
            </div>
            <div class="detail" *ngIf="fp.NumberOfBedrooms || fp.NumberOfBedroomsRange">
                <icon name="bed"></icon>
                <span [innerHtml]="fp.BedCount"></span>
            </div>
            <div class="detail" *ngIf="fp.NumberOfFullBaths || fp.NumberOfFullBathsRange">
                <icon name="full_bath"></icon>
                <span [innerHtml]="fp.BathCount"></span>
            </div>
            <div class="detail" *ngIf="fp.NumberOfHalfBaths || fp.NumberOfHalfBathsRange">
                <icon name="half_bath"></icon>
                <span [innerHtml]="fp.HalfBathCount"></span>
            </div>
            <div class="detail" *ngIf="fp.NumberOfDens || fp.DenRange">
                <icon name="den"></icon>
                <span [innerHtml]="fp.DenCount"></span>
            </div>
            <div class="detail" *ngIf="fp.NumberOfGarages || fp.GarageNumberRange">
                <icon name="garage"></icon>
                <span [innerHtml]="fp.GarageCount"></span>
            </div>
            <div class="detail" *ngIf="fp.GarageCarSize || fp.GarageCarSizeRange">
                <icon name="garage"></icon>
                <span [innerHtml]="fp.GarageSizeCount"></span>
            </div>
            <div class="detail" *ngIf="fp.BaseTotalSquareFeet || fp.SquareFeetRange">
                <icon name="sq_ft"></icon>
                <span [innerHtml]="fp.SqFtCount"></span>
            </div>
            <div class="detail" *ngIf="fp.OutdoorSquareFeet">
                <icon name="sq_ft"></icon>{{fp.OutdoorSquareFeet | specsplural:'SpecOutdoorSqFtLabel'}}
            </div>
        </div>
        <div *ngIf="fp.AllTagNames" class="tags">
            <icon name="tag"></icon>{{fp.AllTagNames}}
        </div>
        <div *ngIf="fp.IsInventoryHome && fp.MoveInDate" class="detail">
            <span>Move In Date: </span>{{fp.MoveInDate | date}}
        </div>
    </div>
</section>
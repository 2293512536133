import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { FavoritesSession } from '../../models/favorites-session';
import { HomeBuyerVM } from '../../models/home-buyer';
import { ApiService } from '../api.service';
import { DataStoreService } from '../data-store.service';
import { LocalStorageService } from '../local-storage.service';
import { OverlayService, OverlayTypes } from '../overlay.service';
import { SessionStorageService } from '../session-storage.service';
import { HomeBuyerIntegrationService } from './home-buyer-integration-factory.service';

import { ICommunity, IHomeBuyer } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class HomeBuyerService {
  private homeBuyerSubject = new BehaviorSubject<IHomeBuyer>(null);
  readonly homeBuyerLocalStorageKey = 'homeBuyerEmail';
  readonly homeBuyerCrmKey = 'homeBuyerExternalCrmId';

  readonly homeBuyerRegistrationHasAutoPoppedKey = 'homeBuyerRegistrationHasAutoPopped';
  private _hasRegistrationAutoPopped: boolean;

  constructor(
    private api: ApiService,
    private store: DataStoreService,
    private localStorageService: LocalStorageService,
    private homeBuyerIntegrationService: HomeBuyerIntegrationService,
    private overlay: OverlayService,
    private sessionStorage: SessionStorageService
  ) {}

  async initializeFromLocalStorage(community: ICommunity) {
    const homeBuyerEmail = this.localStorageService.getItem(this.homeBuyerLocalStorageKey);
    if (!homeBuyerEmail) return;

    try {
      const homeBuyer = await this.getByEmail(homeBuyerEmail, community.ClientId).toPromise();
      if (!homeBuyer) return;
      homeBuyer.ExternalCrmId = this.localStorageService.getItem(this.homeBuyerCrmKey);
      this.current = homeBuyer;
    } catch {
      console.warn(
        `HomeBuyer email ${homeBuyerEmail} was found in localStorage but no record found within client ${community.ClientId}`
      );
    }
  }

  get current$(): Observable<IHomeBuyer> {
    return this.homeBuyerSubject.asObservable();
  }

  get current(): IHomeBuyer {
    return this.homeBuyerSubject.value;
  }

  set current(hb: IHomeBuyer) {
    this.homeBuyerSubject.next(hb);
    this.updateLocalStorage(hb);
  }

  get hasRegistrationAutoPopped(): boolean {
    if (typeof this._hasRegistrationAutoPopped !== 'boolean') {
      this._hasRegistrationAutoPopped =
        this.sessionStorage.getItem(this.homeBuyerRegistrationHasAutoPoppedKey) === 'true';
    }

    return this._hasRegistrationAutoPopped;
  }

  set hasRegistrationAutoPopped(value: boolean) {
    this._hasRegistrationAutoPopped = value;
    this.sessionStorage.setItem(this.homeBuyerRegistrationHasAutoPoppedKey, value.toString());
  }

  checkForAutoPop(stickRight: boolean) {
    if (!this.hasRegistrationAutoPopped && !this.current) {
      this.hasRegistrationAutoPopped = true;

      this.overlay.setState({
        isShowing: true,
        targetedComponent: OverlayTypes.HomeBuyerRegistration,
        stickRight: stickRight
      });

      return firstValueFrom(
        this.overlay.state$.pipe(
          filter(x => x.targetedComponent === OverlayTypes.HomeBuyerRegistration && !x.isShowing)
        )
      );
    }
  }

  updateLocalStorage(hb: IHomeBuyer) {
    if (hb?.Email) {
      this.localStorageService.setItem(this.homeBuyerLocalStorageKey, hb.Email);
    } else {
      this.localStorageService.removeItem(this.homeBuyerLocalStorageKey);
    }

    if (hb?.ExternalCrmId) {
      this.localStorageService.setItem(this.homeBuyerCrmKey, hb.ExternalCrmId);
    } else {
      this.localStorageService.removeItem(this.homeBuyerCrmKey);
    }
  }

  get(homeBuyer: IHomeBuyer): Observable<IHomeBuyer> {
    const homeBuyerFromStore = this.store.getHomeBuyerByClientIdAndEmail(
      homeBuyer.ClientId,
      homeBuyer.Email
    );
    if (homeBuyerFromStore) return of(homeBuyerFromStore);

    return this.getByEmail(homeBuyer.Email, homeBuyer.ClientId);
  }

  getByEmail(email: string, clientId: number): Observable<IHomeBuyer> {
    const url = `homebuyers/client/${clientId}/email/${email}?includeFavoritesSessions=true`;
    return this.api.getByUrl<IHomeBuyer>(url).pipe(
      map((hb: IHomeBuyer) => {
        this.store.addOrUpdateHomeBuyer(hb);
        return hb;
      })
    );
  }

  async create(
    newHomeBuyer: IHomeBuyer,
    favoritesSession: FavoritesSession,
    clientSpecificQuestions: { [key: string]: any }
  ): Promise<HomeBuyerVM> {
    const params = '?sendEmailToSalesRep=true';
    newHomeBuyer.CustomData = { ...newHomeBuyer.CustomData, ...clientSpecificQuestions };
    const hb = await this.api.create<IHomeBuyer>(newHomeBuyer, 'HomeBuyer', params).toPromise();
    this.store.addOrUpdateHomeBuyer(hb);

    let hbVM = new HomeBuyerVM(hb);
    hbVM = await this.homeBuyerIntegrationService.register(
      hbVM,
      favoritesSession,
      clientSpecificQuestions
    );

    if (hbVM) this.updateLocalStorage(hbVM);

    return hbVM;
  }

  isAnyoneLoggedIn(): boolean {
    return !!this.homeBuyerSubject.value;
  }

  updateFavorites(favesSession: FavoritesSession, isLoggingOut?: boolean) {
    if (!this.current) return;

    const index = this.current.FavoritesSessions.findIndex(
      s => s.FavoritesSessionId === favesSession.FavoritesSessionId
    );
    if (index > -1) {
      this.current.FavoritesSessions[index] = favesSession;
    } else {
      this.current.FavoritesSessions.push(favesSession);
    }

    this.homeBuyerIntegrationService.updateClientApi(
      new HomeBuyerVM(this.current),
      favesSession,
      isLoggingOut
    );

    this.store.addOrUpdateHomeBuyer(this.current);
  }

  logout() {
    this.current = null;
    this.hasRegistrationAutoPopped = false;
  }
}

import { HttpBackend } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

import { Lookup } from '../../helpers/lookup';
import { FavoritesSession } from '../../models/favorites-session';
import { HomeBuyerVM } from '../../models/home-buyer';
import { ApiService } from '../api.service';
import { CommunityService } from '../community.service';
import { FloorplanService } from '../floorplan.service';
import { InventoryHomeService } from '../inventory-home.service';
import { LotService } from '../lot.service';
import { SalesRepresentativeService } from '../sales-representative.service';
import { SettingsService } from '../settings.service';
import { HubspotHomeBuyerIntegrationService } from './hubspot-home-buyer-integration.service';
import { LassoHomeBuyerIntegrationService } from './lasso-home-buyer-integration.service';
import { MattamyHomebuyerIntegrationService } from './mattamy-home-buyer-integration.service';
import { NoOpHomeBuyerIntegrationService } from './noop-home-buyer-integration.service';

export interface IHomeBuyerIntegrationService {
  register(
    homeBuyer: HomeBuyerVM,
    favoritesSession: FavoritesSession,
    clientSpecificQuestions: { [key: string]: any }
  ): Promise<HomeBuyerVM>;

  updateClientApi(homeBuyer: HomeBuyerVM, favoritesSession: FavoritesSession): void;
}

export abstract class HomeBuyerIntegrationService implements IHomeBuyerIntegrationService {
  abstract register(
    homeBuyer: HomeBuyerVM,
    favoritesSession: FavoritesSession,
    clientSpecificQuestions: { [key: string]: any },
    salesRepId?: string
  ): Promise<HomeBuyerVM>;

  abstract updateClientApi(
    homeBuyer: HomeBuyerVM,
    favoritesSession: FavoritesSession,
    isLoggingOut?: boolean
  ): void;
}

export const VENDOR_API_KEY = new InjectionToken<string>('VendorApiKey');

const HomeBuyerIntegrationFactory = (
  communityService: CommunityService,
  settingsService: SettingsService,
  apiService: ApiService,
  handler: HttpBackend,
  floorplanService: FloorplanService,
  lotService: LotService,
  salesRepService: SalesRepresentativeService,
  inventoryHomeService: InventoryHomeService,
  lookup: Lookup
): IHomeBuyerIntegrationService => {
  const crmApiKey = settingsService.get('CrmApiKey');

  switch (communityService.current.ClientId) {
    case lookup.ClientIds.Mattamy:
    case lookup.ClientIds.MattamyCanada:
      if (!crmApiKey) return new NoOpHomeBuyerIntegrationService();

      return new MattamyHomebuyerIntegrationService(
        floorplanService,
        lotService,
        inventoryHomeService,
        handler,
        crmApiKey,
        salesRepService
      );

    default:
      const hubspotKey = settingsService.get('HubSpotApiKey');
      if (hubspotKey) {
        return new HubspotHomeBuyerIntegrationService(
          apiService,
          communityService,
          floorplanService,
          lotService,
          salesRepService
        );
      }

      if (crmApiKey) {
        return new LassoHomeBuyerIntegrationService(
          apiService,
          communityService,
          floorplanService,
          lotService,
          crmApiKey
        );
      }

      return new NoOpHomeBuyerIntegrationService();
  }
};

export const HomeBuyerIntegrationServiceProvider = {
  provide: HomeBuyerIntegrationService,
  useFactory: HomeBuyerIntegrationFactory,
  deps: [
    CommunityService,
    SettingsService,
    ApiService,
    HttpBackend,
    FloorplanService,
    LotService,
    SalesRepresentativeService,
    InventoryHomeService,
    Lookup
  ]
};

<section id="fullscreen-gallery" *ngIf="isShowing" @enterLeaveFadeAnimation cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <header>
        <favorite-btn *ngIf="currentImage?.IsFavoritable" [isActive]="currentImage?.IsFavorited" (toggle)="handleFavoriteToggle($event)">
        </favorite-btn>
        <h3 *ngIf="currentImage?.Title" [matTooltip]="currentImage?.Title">{{currentImage?.Title}}</h3>
        <div *ngIf="images.length > 1 && !hideGalleryControls" class="pager">
            <icon-button ariaLabel="Previous" [class.btn-background]="buttonBkgEnabled" icon="carrot_arrow" class="left-arrow"
                         *ngIf="images.length > 1" (click)="goToImage(selectedIndex - 1)"></icon-button>
            <p>
                <b>{{selectedIndex + 1}}</b> of {{images.length}}
            </p>
            <icon-button ariaLabel="Next" [class.btn-background]="buttonBkgEnabled" icon="carrot_arrow" class="right-arrow" *ngIf="images.length > 1"
                         (click)="goToImage(selectedIndex + 1)"></icon-button>
        </div>
        <button aria-label="Close Fullscreen" class="close-btn" (click)="closeFullscreen()">
            <icon name="x"></icon>
        </button>
    </header>
    <div *ngIf="isLoading && images.length && !currentImage?.IsVideo" class="loader" @enterLeaveFadeAnimation>
        <div class="spin-stretch"></div>
    </div>
    <div class="swipe-container">
        <div class="swipe-wrapper" gallerySwiper [index]="selectedIndex" [slideCount]="images.length" (indexChange)="handleSwipeIndexChange($event)">
            <div class="image-slide" *ngFor="let item of images; trackBy: trackById; index as i">
                <ng-container *ngIf="selectedIndex - 1 <= i && i <= selectedIndex + 1">
                    <img *ngIf="!item.IsVideo && !item.IsIframe && !item.InnerHtml" [class.fullscreen]="fullscreen && item.Type !== types.Floor"
                         [src]="item.FullUrl" (load)="onImgLoad()" @enterLeaveFadeAnimation>

                    <div *ngIf="item.InnerHtml" class="svg-container" [innerHTML]="item.InnerHtml | safeHtml"></div>

                    <iframe *ngIf="item?.IsIframe" [src]="item.FullUrl | safeUrl" (load)="onImgLoad()"></iframe>

                    <video-with-preview *ngIf="item?.IsVideo" [src]="item.FullUrl" [previewUrl]="item.ThumbnailUrl" [showControls]="true"
                                        [startTime]="item.VideoTime" @enterLeaveFadeAnimation></video-with-preview>
                </ng-container>
            </div>
        </div>
    </div>
</section>
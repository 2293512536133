import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HubSpotRegistrationModel } from '../../interfaces/hubspot';
import { HubSpotFavorites } from '../../interfaces/hubspot-favorites';
import { FavoritesSession } from '../../models/favorites-session';
import { HomeBuyerVM } from '../../models/home-buyer';
import { ApiService } from '../api.service';
import { CommunityService } from '../community.service';
import { FloorplanService } from '../floorplan.service';
import { LotService } from '../lot.service';
import { SalesRepresentativeService } from '../sales-representative.service';
import { IHomeBuyerIntegrationService } from './home-buyer-integration-factory.service';

@Injectable()
export class HubspotHomeBuyerIntegrationService implements IHomeBuyerIntegrationService {
  constructor(
    private apiService: ApiService,
    private communityService: CommunityService,
    private floorplanService: FloorplanService,
    private lotService: LotService,
    private salesRepService: SalesRepresentativeService
  ) {}

  async register(
    homeBuyer: HomeBuyerVM,
    favoritesSession: FavoritesSession,
    clientSpecificQuestions: { [key: string]: any }
  ): Promise<HomeBuyerVM> {
    // get favorites
    const favoriteFloorPlans = favoritesSession.Floorplans.map(
      x => this.floorplanService.get(x.FloorplanId).PublicName
    );
    const favoriteLots = favoritesSession.LotIds.map(x => this.lotService.get(x).LotNumber);
    const favoritePlaces = favoritesSession.GooglePlaces.map(x => x.Name);

    const customMap = new Map<string, string>();
    customMap.set('CommunityName', this.communityService.current.Name);
    customMap.set('FirstName', homeBuyer.FirstName);
    customMap.set('LastName', homeBuyer.LastName);
    customMap.set('Email', homeBuyer.Email);
    customMap.set('Phone', homeBuyer.PhoneNumber);
    customMap.set('FavoritePlans', favoriteFloorPlans.join(';'));
    customMap.set('FavoriteLots', favoriteLots.join(';'));
    customMap.set('FavoritePlaces', favoritePlaces.join(','));
    customMap.set('Source', 'MySCP');
    Object.keys(clientSpecificQuestions).forEach(key =>
      customMap.set(key, clientSpecificQuestions[key])
    );

    try {
      await this.registerHubSpot(
        new HubSpotRegistrationModel({
          ClientId: homeBuyer.ClientId,
          CommunityId: this.communityService.current.CommunityId,
          CustomProps: Object.fromEntries(customMap),
          SalesRepEmail: this.salesRepService.current?.Email
        })
      );
      console.log('HubSpot contact created');
    } catch (error) {
      console.error(`Error creating HubSpot contact: ${error.message}`);
    }

    return homeBuyer;
  }

  private registerHubSpot(newRegistration: HubSpotRegistrationModel): Promise<string> {
    return this.apiService.postObj(newRegistration, 'hubspot/contact').toPromise();
  }

  updateClientApi(homeBuyer: HomeBuyerVM, favoritesSession: FavoritesSession): void {
    const favorites = new HubSpotFavorites({
      CommunityId: this.communityService.current.CommunityId,
      FavoriteFloorPlanNames: favoritesSession.Floorplans.map(
        x => this.floorplanService.get(x.FloorplanId).PublicName
      ).join(';'),
      FavoriteLotNames: favoritesSession.LotIds.map(x => this.lotService.get(x).LotNumber).join(
        ';'
      ),
      FavoritePlaceNames: favoritesSession.GooglePlaces.map(x => x.Name).join(','),
      Email: homeBuyer.Email
    });

    this.updateHubSpotFavorites(favorites)
      .then(res => {
        console.log('HubSpot favorites updated');
      })
      .catch((err: HttpErrorResponse) =>
        console.error(`Error updating HubSpot favorites: ${err.message}`)
      );
  }

  updateHubSpotFavorites(favorites: HubSpotFavorites): Promise<string> {
    return this.apiService.postObj(favorites, 'hubspot/contact/favorites').toPromise();
  }
}

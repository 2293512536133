import { Injectable } from '@angular/core';

import { IBaseContentJson, PageVersion } from '../interfaces/page';
import { PageVM } from '../models/page-vm';
import { CommunityService } from './community.service';
import { PageTitle } from './navigation.service';

import { IPage } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  constructor(private communityService: CommunityService) {}

  getById<T extends IBaseContentJson>(id: number): PageVM<T> {
    if (!this.communityService.current.Pages) return null;

    const page = this.communityService.current.Pages.find(x => x.PageId === id);
    if (!page) return null;

    return new PageVM<T>(page);
  }

  getAll<T extends IBaseContentJson>(version?: PageVersion): PageVM<T>[] {
    if (!this.communityService.current.Pages) return null;

    let pages = this.communityService.current.Pages;
    if (version) pages = pages.filter(x => x.Version === version);

    return pages.map(p => new PageVM<T>(p));
  }

  get<T extends IBaseContentJson>(pageTitle: PageTitle): PageVM<T> {
    if (!this.communityService.current.Pages) return null;

    return new PageVM<T>(
      this.communityService.current.Pages.find(
        x => x.PageTitle.toLowerCase() === pageTitle.toLowerCase()
      )
    );
  }

  getBaseFileUrl(page: IPage): string {
    const communityBaseUrl = this.communityService.getBaseApiUrl();
    const pagePath = `Pages/${page.PageTitle}_${page.PageId}`;
    return `${communityBaseUrl}/${pagePath}`;
  }
}

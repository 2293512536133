import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { GalleryImageVM } from '../gallery/gallery-image-vm';

@Component({
  selector: 'gallery-accordion',
  templateUrl: './gallery-accordion.component.html',
  styleUrls: ['./gallery-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryAccordionComponent implements OnInit {
  @Input() title: string;
  @Input() images: GalleryImageVM[];
  @Input() displayedImageOverrideId: { elevationId: number };
  @Input() openByDefault = false;
  @Input() useThumbnail = true;
  @Input() customAltText = '';
  @Output() favoriteToggle = new EventEmitter<GalleryImageVM>();

  constructor() {}

  ngOnInit() {}
}

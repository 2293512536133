import { Directive, Input, Output, ElementRef, EventEmitter } from '@angular/core';

import { ApiService } from '../utility/services/api.service';

@Directive({
  selector: '[svgInjector]'
})
export class SvgInjectorDirective {
  @Input() loadParam?: any;
  @Output() svgLoad = new EventEmitter<ISvgInjectorLoaded>();
  @Output() loadFail = new EventEmitter<Error>();

  constructor(private elementRef: ElementRef, private api: ApiService) {}

  @Input() set svgInjector(src: string) {
    let toInject = '';

    // TODO: cache the svg string response to avoid multiple http calls when navigating away and back to page

    if (src) {
      // our Argo /fs/pd endpoint freaks out when any headers are present so must tell to skip
      this.api.getWithNoAuth(src).then(
        content => {
          toInject = content;

          if (toInject.indexOf('<script') === -1) {
            this.elementRef.nativeElement.innerHTML = toInject;
            this.checkForLoadComplete();
          }
        },
        error => {
          this.loadFail.emit(error);
        }
      );
    }
  }

  checkForLoadComplete = () => {
    window.setTimeout(() => {
      if (this.elementRef.nativeElement.innerHTML) {
        this.svgLoad.emit({
          Param: this.loadParam,
          Element: this.elementRef.nativeElement.firstChild,
          Parent: this.elementRef.nativeElement
        });
      } else {
        this.checkForLoadComplete();
      }
    }, 100);
  };
}

export interface ISvgInjectorLoaded {
  Param: any;
  Element: Element;
  Parent: Element;
}

import { IAreaMapPoiCategory, IScpContentImage } from '../../../utility/interfaces/page';
import { PlacesSearchResult } from '../../../utility/services/google-map.service';

export interface IAreaMapPoi {
  Id: string;
  Name: string;
  Address: string;
  Latitude: number;
  Longitude: number;
  DistanceFromCommunity: number;
  CommunityName: string;
  IsSelected: boolean;
  IsFavorited: boolean;
  IsUserSearchResult: boolean;
  IconSvgContent: string;
  Image?: IScpContentImage;
}

export class AreaMapPoiVM implements IAreaMapPoi {
  Id: string;
  Name: string;
  Address: string;
  Latitude: number;
  Longitude: number;
  DistanceFromCommunity: number;
  CommunityName: string;
  IsSelected = false;
  IsFavorited = false;
  IsUserSearchResult = false;
  IconSvgContent = '';

  ImageUrl?: string;
  MarkerColor?: string;

  constructor(
    data?: AreaMapPoiVM | PlacesSearchResult,
    isUserSearchResult: boolean = false,
    distanceFromCommunity?: number,
    communityName?: string
  ) {
    if (data) {
      this.Id = data.Id;
      this.Name = data.Name;
      this.Address = data.Address;
      this.Latitude = data.Latitude;
      this.Longitude = data.Longitude;
      this.DistanceFromCommunity = distanceFromCommunity || data.DistanceFromCommunity;
      this.CommunityName = communityName || data.CommunityName;
      this.ImageUrl = data.ImageUrl;
    }
    this.IsUserSearchResult = isUserSearchResult;
  }
}

export class AreaMapPoiCategoryVM implements IAreaMapPoiCategory {
  Id: string;
  Name: string;
  Keyword: string;
  ExcludedMarkers: IAreaMapPoi[];
  IncludedSavedResults: IAreaMapPoi[];
  IconSvgContent: string;
  IconId: number;
  MarkerColor: string;
  SearchRadius: number;

  IsShownOnMap = true;
  POIs: AreaMapPoiVM[];
}

import { Injectable } from '@angular/core';

import { Lookup } from '../helpers/lookup';
import { CommunityService } from './community.service';

import { ISetting, ISettingMedia, SettingFieldType, SettingsCategoryTitle } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private _allSettings: ISetting[] = [];

  get allSettings(): ISetting[] {
    if (!this._allSettings.length)
      this._allSettings = this.communityService.current.SettingsCategories.flatMap(x => x.Settings);

    return this._allSettings;
  }

  constructor(private lookup: Lookup, private communityService: CommunityService) {}

  get(settingName: string): any {
    const setting = this.getSetting(settingName);
    // if (!setting) console.warn(`No setting found with name ${settingName}`);
    return this.getValue(setting);
  }

  getByUniqueName(settingName: string): any {
    const setting = this.allSettings.find(s => s.Name === settingName);
    return this.getValue(setting);
  }

  /**Returns settings as dictionary object.
   * SettingName is the key and will return the value for the setting
   */
  getMultiple(...settingNames: string[]): Map<string, any> {
    const settings = new Map<string, any>();
    settingNames.forEach(name => {
      const setting = this.getSetting(name);
      const val = this.getValue(setting);
      if (val) settings.set(name, val);
    });

    return settings;
  }

  private getSetting(settingName: string): ISetting {
    const setting = this.allSettings.find(s => s.Name.toLowerCase() === settingName.toLowerCase());
    return setting;
  }

  getValue(setting: ISetting): any {
    if (!setting || !setting.Value) return null;

    switch (setting.FieldType) {
      case SettingFieldType.FileUpload:
        const relativePath = JSON.parse(setting.Value)[0];
        if (!relativePath) return null;
        return `${this.lookup.ApiProductDataEndpoint}${relativePath}`;
      case SettingFieldType.CheckBox:
        return setting.Value.toLowerCase() === 'true';
      case SettingFieldType.DropDown:
        return setting.Value.toLowerCase() === 'none' ? null : setting.Value;
      case SettingFieldType.NumberBox:
        return Number(setting.Value);
      case SettingFieldType.Icon:
        return setting.UIValue;
      case SettingFieldType.ExpandingSet:
        return JSON.parse(setting.Value) || [];
      case SettingFieldType.AdvancedMedia:
        const media: ISettingMedia[] = JSON.parse(setting.Value);
        return media.map(m => ({
          ...m,
          Filename: `${this.lookup.ApiProductDataEndpoint}${m.Filename}`
        }));
      default:
        return setting.Value;
    }
  }

  getAllForCategory(category: SettingsCategoryTitle): ISetting[] {
    return (
      this.communityService.current.SettingsCategories.find(s => s.Title === category)?.Settings ||
      []
    );
  }

  getAllValuesForCategory(category: SettingsCategoryTitle): Map<string, any> {
    const settings = new Map<string, any>();
    this.getAllForCategory(category).forEach(s => {
      settings.set(s.Name, this.getValue(s));
    });
    return settings;
  }

  getAllForIcons(): ISetting[] {
    return this.allSettings.filter(x => x.UIName && x.FieldType === SettingFieldType.Icon);
  }

  getAllForFonts(): ISetting[] {
    return this.allSettings.filter(x => x.UIName && x.FieldType === SettingFieldType.Font);
  }

  getAllForCssVariables(): ISetting[] {
    return this.allSettings.filter(
      x =>
        x.UIName && x.FieldType !== SettingFieldType.Icon && x.FieldType !== SettingFieldType.Font
    );
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { enterLeaveFadeAnimation } from '../../global/animations';
import { FullscreenGalleryVM } from '../../global/fullscreen-gallery/fullscreen-gallery-vm';
import { ToastService } from '../../global/toast/toast.service';
import { PdfItem } from '../../myscp/custom-page/layouts/helpers';
import { FavoriteType } from '../../utility/helpers/favorite-types.enum';
import { AutoUnsubscriber } from '../../utility/helpers/mixins';
import { FavoritesService } from '../../utility/services/favorites.service';
import { OverlayService, OverlayTypes } from '../../utility/services/overlay.service';
import { GalleryImageVM } from '../gallery/gallery-image-vm';

@Component({
  selector: 'navigational-sections-content',
  templateUrl: './navigational-sections-content.component.html',
  styleUrls: ['./navigational-sections-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterLeaveFadeAnimation()]
})
export class NavigationalSectionsContentComponent extends AutoUnsubscriber implements OnDestroy {
  @Input() pdfs: PdfItem[] = [];
  @Input() images: GalleryImageVM[] = [];
  @Input() inBrochure = false;
  @Output() pdfImagesLoaded = new EventEmitter<void>();
  showModal = false;
  selectedPdf: PdfItem;
  isLoading = false;

  constructor(
    private favorites: FavoritesService,
    private cdr: ChangeDetectorRef,
    private toaster: ToastService,
    private overlay: OverlayService
  ) {
    super();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  handleFavoriteToggle(item: PdfItem) {
    item.IsFavorited = !item.IsFavorited;
    if (item.IsFavorited) {
      this.favorites.add({ favoriteType: FavoriteType.PageContentSection, id: item.Id });
    } else {
      this.favorites.remove(FavoriteType.PageContentSection, item.Id);
    }
  }

  removeFavoriteItem(item: PdfItem) {
    this.favorites.remove(FavoriteType.PageContentSection, item.Id);
  }

  handleSelectPdf(pdf: PdfItem) {
    if (!navigator.onLine) {
      this.toaster.showInfo('Offline', 'Sorry, you must be online in order to view this content');
      return;
    }

    this.isLoading = true;
    this.showModal = true;
    this.selectedPdf = pdf;
  }

  handleIframeLoad() {
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  handleImageLoad() {
    URL.revokeObjectURL(this.selectedPdf.PdfDataUrl);
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  closeModal() {
    super.ngOnDestroy();
    this.selectedPdf = null;
    this.showModal = false;
    this.cdr.detectChanges();
  }

  goToFullScreen(image: GalleryImageVM) {
    this.overlay.setState({
      isShowing: true,
      targetedComponent: OverlayTypes.FullscreenGallery,
      data: new FullscreenGalleryVM([image], 0)
    });
  }
}

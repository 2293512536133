import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Renderer2,
  Input,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewChecked,
  AfterViewInit
} from '@angular/core';

@Component({
  selector: 'masonry-layout',
  templateUrl: './masonry-layout.component.html',
  styleUrls: ['./masonry-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasonryLayoutComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @Input() gridChildString: string;
  @Input() canRefresh = false;
  @Output() canRefreshChange = new EventEmitter<boolean>();

  constructor(private ref: Renderer2, private el: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.refreshLayout();
  }

  ngAfterViewChecked() {
    if (this.canRefresh) this.refreshLayout();
  }

  refreshLayout() {
    const gridContainer: HTMLElement = this.el.nativeElement;
    let gridChild = gridContainer.getElementsByClassName(this.gridChildString);
    if (!gridChild.length) {
      gridChild = gridContainer.getElementsByTagName(this.gridChildString);
    }
    const rowHeight = parseInt(
      window.getComputedStyle(gridContainer).getPropertyValue('grid-auto-rows'),
      10
    );
    const rowGap = parseInt(
      window.getComputedStyle(gridContainer).getPropertyValue('grid-row-gap'),
      10
    );

    const columnArray = window
      .getComputedStyle(gridContainer)
      .getPropertyValue('grid-template-columns')
      .split(' ');

    let numberOfColumns = columnArray.length;

    // get last column value and then make sure its at leas the minimum
    // view can be checked/calculated before its updated in dom causing inconsistincies
    const lastValue = Number(columnArray[columnArray.length - 1].replace('px', ''));

    if (lastValue < 320) numberOfColumns = columnArray.length - 1;

    // Cannot do for-of, htmlcollectionof does not contain iterator
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let x = 0; x < gridChild.length; x++) {
      const gc = gridChild[x];
      const gcHeightObj = gc.children[0];
      const itemHeight = this.getBoundingHeight(gcHeightObj);
      const rowsize = Math.ceil((itemHeight + rowGap) / (rowHeight + rowGap));

      switch (true) {
        case x % numberOfColumns === 0:
          this.ref.setStyle(gc, 'gridColumnStart', 1);
          break;
        case x % numberOfColumns === 1:
          this.ref.setStyle(gc, 'gridColumnStart', 2);
          break;
        default:
          this.ref.setStyle(gc, 'gridColumnStart', 3);
          break;
      }

      if (x >= numberOfColumns) {
        const itemAbove = gridChild[x - numberOfColumns];
        const rowEnd = parseInt(
          window.getComputedStyle(itemAbove).getPropertyValue('grid-row-end'),
          10
        );
        this.ref.setStyle(gc, 'gridRowStart', rowEnd);
        this.ref.setStyle(gc, 'gridRowEnd', rowEnd + rowsize);
      } else {
        this.ref.setStyle(gc, 'gridRowStart', 1);
        this.ref.setStyle(gc, 'gridRowEnd', 1 + rowsize);
      }
    }
    this.canRefreshChange.emit(false);
  }

  // GIFLENS-https://media1.giphy.com/media/3ov9jQX2Ow4bM5xxuM/200.gif
  private getBoundingHeight(el: any): number {
    const bh = el.getBoundingClientRect().height;
    if (!bh) {
      if (el.firstChild) return this.getBoundingHeight(el.firstChild);
    }
    return bh;
  }
}

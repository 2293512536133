import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ToastService } from '../../../global/toast/toast.service';
import { RegexPatterns } from '../../../utility/helpers/regex';
import { EmailRequest } from '../../../utility/interfaces/email-request';
import { CommunityService } from '../../../utility/services/community.service';
import { EmailService } from '../../../utility/services/email.service';
import { NavigationService } from '../../../utility/services/navigation.service';
import { SettingsService } from '../../../utility/services/settings.service';
import { HcSessionService } from '../../hc-session/hc-session.service';

export interface IHcShareData {
  Type?: HcShareType;
  BuildId?: string;
}

@Component({
  selector: 'hc-share-dialog',
  templateUrl: './hc-share-dialog.component.html',
  styleUrls: ['./hc-share-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HcShareDialogComponent implements OnInit {
  emailAddresses: string;
  customMessage: string;
  regexPatterns = RegexPatterns;

  userFullName: string;
  userEmail: string;
  userPreferredDate: string;

  shareType: HcShareType;
  shareTypes = HcShareType;
  buildIdToShare: string;

  constructor(
    private toaster: ToastService,
    private emailService: EmailService,
    private communityService: CommunityService,
    private navigator: NavigationService,
    private hcSessionService: HcSessionService,
    public dialogRef: MatDialogRef<HcShareDialogComponent, boolean>,
    private setttingsService: SettingsService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: IHcShareData
  ) {}

  ngOnInit(): void {
    this.shareType = this.data?.Type ?? HcShareType.Email;
    this.buildIdToShare = this.data?.BuildId;
  }

  async handleShare(form: NgForm) {
    if (form.invalid) return;

    this.toaster.showLoading();

    try {
      if (!this.hcSessionService.currentSession.HomeConfiguratorSessionId)
        await this.hcSessionService.saveToApi();
    } catch (error) {
      console.error(error);
      this.dialogRef.close(false);
      this.toaster.showError('Error', 'Sorry something went wrong trying to save this build');
      return;
    }

    const emailRequest = this.buildEmail();
    this.dialogRef.close(true);
    this.emailService.sendEmail(emailRequest).subscribe(
      () => {
        this.toaster.showSuccess('Success!', 'Your configured build has been shared!');
      },
      (error: HttpErrorResponse) => {
        console.error(`Error sending email: ${error.message}`);
        this.toaster.showStickyError(
          'Oh no...',
          'Something went wrong.\nPlease try again and if the issue persists contact support.'
        );
      }
    );
  }

  private buildEmail() {
    const communityName = this.communityService.getDisplayName();
    let title = 'Your configured build within ' + communityName;
    const message = this.buildEmailBody();

    if (this.shareType === HcShareType.ScheduleAppointment) {
      title = `Request to schedule an appointent in ${communityName}`;
      this.emailAddresses = this.setttingsService.get('SalesLeadsEmailAddresses');
    }

    const emailRequest = new EmailRequest(this.emailAddresses, title, message);
    emailRequest.isHtml = true;
    emailRequest.communityIdForSettings = this.communityService.current.CommunityId;

    return emailRequest;
  }

  private buildEmailBody() {
    const content: string[] = [];

    let emailLinkText = 'View Your Home Configuration';

    if (this.shareType === HcShareType.ScheduleAppointment) {
      content.push('Request to schedule an appointent. Please see details below');
      content.push(`Customer Name: ${this.userFullName}`);
      content.push(`Custom Email: ${this.userEmail}`);
      if (this.userPreferredDate)
        content.push(
          `Preferred Date & Time: ${formatDate(this.userPreferredDate, 'M/dd/yy, h:mm a', 'en-US')}`
        );
      emailLinkText = `Customer's configured build`;
    } else {
      content.push(
        `Thank you for choosing ${this.communityService.current.ClientName}. Here is a link to your configured build.`
      );
      content.push(this.customMessage);
    }

    const shareEmailLink = this.navigator.getSharedHomeConfigurationLink(
      this.hcSessionService.currentSession.HomeConfiguratorSessionId,
      this.buildIdToShare ?? this.hcSessionService.currentBuild.Id
    );

    content.push(`&lt;a href=&quot;${shareEmailLink}&quot;&gt;${emailLinkText}&lt;/a&gt;`);

    return content.filter(item => !!item).join('\r\n\r\n');
  }
}

export enum HcShareType {
  Email,
  ScheduleAppointment
}

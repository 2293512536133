import { FloorVM } from '../../utility/models/floor-vm';
import { FloorWithOptionsVM } from '../../utility/models/floor-with-options-vm';
import { FloorplanVM } from '../../utility/models/floorplan-vm';
import { GraphicsModel } from '../../utility/models/graphics-model';

export class ComparePlanVM {
  FloorPlanId: number;
  GraphicsModel = new GraphicsModel();
  FullSvgUrl: string;
  DefaultFloor: FloorVM;
  CurrentFloorId: number;
  FloorsWithOptions: FloorWithOptionsVM[] = [];
  HasOptions = false;

  constructor(fp: FloorplanVM, floorsWithOptions?: FloorWithOptionsVM[]) {
    this.FloorPlanId = fp.FloorplanId;
    this.DefaultFloor = fp.DefaultFloor;
    this.CurrentFloorId = fp.DefaultFloor.FloorId;
    if (floorsWithOptions) this.FloorsWithOptions = floorsWithOptions;

    this.HasOptions = this.FloorsWithOptions.some(x => x.Options.length);
  }
}

<accordion [openByDefault]="true" [ariaLabel]="titleText">
  <div header>
    <toggle [on]="isEnabled" (toggle)="handleEnabledToggle($event)"></toggle>
    <h2>{{titleText}}</h2>
  </div>
  <div *ngIf="selectedTags.length" class="badges" [class.disabled]="!isEnabled">
    <button *ngFor="let tag of selectedTags; trackBy: trackByTagId" aria-label="Remove Tag Filter" (click)="handleTagRemove(tag)">
      <badge icon="x" [value]="tag.Name"></badge>
    </button>
  </div>
  <div body>
    <mat-select (selectionChange)="handleTagSelect($event)" [(value)]="selectedTag" [placeholder]="placeholderText" [aria-label]="placeholderText">
      <mat-option *ngFor="let tag of availableTags; trackBy: trackByTagId" [value]="tag">
        {{tag.Name}}
      </mat-option>
    </mat-select>
  </div>
</accordion>
import { ActionSet } from './action-set';

import { LogicState } from '@ml/common';

export class OptionVM {
  ActionSetId: number;
  FloorId: number;
  Name: string;
  SortOrder: number;
  IsInMenu: boolean;
  State: LogicState;
  Price: number;
  ClientUniqueIdentifier: string;
  Description: string;
  ImageFilename: string;
  FullImageUrl: string;
  ElevationId: number;
  SquareFeetAdj?: number;

  readonly IsOn: boolean;
  readonly IsDisabledOff: boolean;

  constructor(data?: ActionSet | OptionVM, baseUrl?: string) {
    if (data) {
      this.ActionSetId = data.ActionSetId;
      this.FloorId = data.FloorId;
      this.Name = data.Name;
      this.SortOrder = data.SortOrder;
      this.IsInMenu = data.IsInMenu;
      this.State = data.State || LogicState.OFF;
      this.IsDisabledOff = this.State === LogicState.DISABLED_OFF;
      this.IsOn = this.State === LogicState.ON;
      this.Price = data.Price;
      this.ClientUniqueIdentifier = data.ClientUniqueIdentifier;
      this.Description = data.Description;
      this.ImageFilename = data.ImageFilename;
      this.ElevationId = data.ElevationId;
      this.SquareFeetAdj = data.SquareFeetAdj;

      if (data instanceof OptionVM && data.FullImageUrl) this.FullImageUrl = data.FullImageUrl;
    }

    if (baseUrl && this.ImageFilename)
      this.FullImageUrl = `${baseUrl}/thumbs/lg/${this.ImageFilename}`;
  }
}

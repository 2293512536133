export interface ILassoRegistration {
  first: string;
  last: string;
  email: string;
  phone: string;
  community: string;
  floorplan: string;
  lot: string;
  communityId: number;
  projectId: number;
  notes: string;
  apikey: string;
  registrantId: string;
}

export class LassoRegistrationModel implements ILassoRegistration {
  first: string;
  last: string;
  email: string;
  phone: string;
  community: string;
  floorplan: string;
  lot: string;
  communityId: number;
  projectId: number;
  notes: string;
  apikey: string;
  registrantId: string;

  constructor(data?: ILassoRegistration) {
    if (data) Object.assign(this, data);
  }
}

export interface LassoFavObj {
  floorplans?: string;
  lots?: string;
  places?: string;
}
export class LassoQuestionModel {
  apikey: string;
  answer: string;

  constructor(data?: LassoQuestionModel) {
    if (data) Object.assign(this, data);
  }
}

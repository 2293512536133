import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { enterLeaveFadeAnimation } from '../../global/animations';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterLeaveFadeAnimation()]
})
export class ModalComponent implements OnInit {
  @HostListener('keydown', ['$event']) onKeydown(event) {
    if (event.key === 'Escape' || event.key === 'Enter') {
      event.preventDefault();
      this.handleModalClose();
    }
  }
  @Input() isShowing = true;
  @Output() modalClose = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  handleModalClose() {
    this.isShowing = false;
    this.modalClose.emit();
  }
}

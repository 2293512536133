import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Lookup {
  readonly ApiBaseUrl = window.location.protocol + '//' + environment.apiDomain;
  readonly ApiProductDataEndpoint = environment.productDataPath;

  readonly SkipAuthHeader = 'x-ml-skipauth';

  readonly GraphicsMinScale = 1;
  readonly GraphicsMaxScale = 4;
  readonly GraphicsScaleStep = 0.5;

  readonly CurrentCommunityIdSessionKey = 'currentCommunityId';

  readonly ClientIds = {
    Mattamy: 26,
    MattamyCanada: 124,
    StanleyMartin: 108
  };
}

import { IFloor } from '@ml/common';

export function sortByName(
  a: { Name: string; PublicName?: string },
  b: { Name: string; PublicName?: string }
) {
  return (a.PublicName?.toLowerCase() || a.Name.toLowerCase()) <
    (b.PublicName?.toLowerCase() || b.Name.toLowerCase())
    ? -1
    : 1;
}

export function sortByTitle(a: { Title: string }, b: { Title: string }) {
  return a.Title.toLowerCase() < b.Title.toLowerCase() ? -1 : 1;
}

export function sortBySortOrder(a: { SortOrder?: number }, b: { SortOrder?: number }) {
  if (typeof a.SortOrder !== 'number' || typeof b.SortOrder !== 'number')
    console.warn('SortOrder property does not exist!');

  if (isNaN(a.SortOrder) && !isNaN(b.SortOrder)) return 1;
  if (isNaN(b.SortOrder) && !isNaN(a.SortOrder)) return -1;
  if (isNaN(a.SortOrder) && isNaN(b.SortOrder)) return 0;
  return a.SortOrder - b.SortOrder;
}

export function sortByOrder(a: { Order: number }, b: { Order: number }) {
  if (isNaN(a.Order) && !isNaN(b.Order)) return 1;
  if (isNaN(b.Order) && !isNaN(a.Order)) return -1;
  if (isNaN(a.Order) && isNaN(b.Order)) return 0;
  return a.Order - b.Order;
}

export function sortByListOrder(a: { ListOrder?: number }, b: { ListOrder?: number }) {
  if (isNaN(a.ListOrder) && !isNaN(b.ListOrder)) return 1;
  if (isNaN(b.ListOrder) && !isNaN(a.ListOrder)) return -1;
  if (isNaN(a.ListOrder) && isNaN(b.ListOrder)) return 0;
  return a.ListOrder - b.ListOrder;
}

export function sortByFloorNumber(a: { FloorNumber: number }, b: { FloorNumber: number }) {
  return a.FloorNumber - b.FloorNumber;
}

export function sortFloorsWithBasementLast<T extends IFloor>(floors: T[]): T[] {
  const basement = floors.find(x => x.IsBasement);
  const nonBasements = floors.filter(x => !x.IsBasement);
  const sorted = nonBasements.sort(sortByFloorNumber);

  if (basement) sorted.push(basement);

  return sorted;
}

/** TODO: account for a fallback to SortOrder property */
export function sortByIsFavorited(a: { IsFavorited: boolean }, b: { IsFavorited: boolean }) {
  return a.IsFavorited ? -1 : 1;
}

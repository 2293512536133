import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  OnInit
} from '@angular/core';
import { interval, Observable, BehaviorSubject, NEVER, Subscription } from 'rxjs';
import { switchMap, startWith } from 'rxjs/operators';

import { enterLeaveFadeAnimation } from '../../global/animations';

@Component({
  selector: 'slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterLeaveFadeAnimation(500)]
})
export class SlideshowComponent implements OnInit, OnChanges {
  @Input() images: Array<string>;
  @Input() isPaused: boolean;
  @Input() hideDotsOnPause = false;
  selectedIndex = 0;
  loopObservable: Observable<string | number>;
  slideshowLoopIntervalInMS = 10000;
  isPlayingSubject = new BehaviorSubject(true);
  loopSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.images.length < 2) return;
    this.loopObservable = this.isPlayingSubject.pipe(
      switchMap(e =>
        !!e ? interval(this.slideshowLoopIntervalInMS).pipe(startWith('start')) : NEVER
      )
    );
    this.loopSubscription = this.loopObservable.subscribe(e => {
      if (e === 'start') return;
      this.goToImage(this.selectedIndex + 1);
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isPaused) {
      this.togglePauseSlideshow(this.isPaused);
    }
  }

  ngDestory() {
    if (this.loopSubscription) this.loopSubscription.unsubscribe();
    if (this.isPlayingSubject) this.isPlayingSubject.unsubscribe();
  }

  goToImage(index: number) {
    if (index > this.images.length - 1) index = 0;
    if (index < 0) index = this.images.length - 1;
    this.selectedIndex = index;
  }

  handleImageDotClick(evt: Event, index: number) {
    evt.stopPropagation();
    this.goToImage(index);
    this.togglePauseSlideshow(this.isPaused);
  }

  private togglePauseSlideshow(pause: boolean) {
    this.isPlayingSubject.next(!pause);
  }
}

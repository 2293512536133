export function bound01(n, max) {
  if (isOnePointZero(n)) {
    n = '100%';
  }
  var processPercent = isPercentage(n);
  n = max === 360 ? n : Math.min(max, Math.max(0, parseFloat(n)));
  if (processPercent) {
    n = parseInt(String(n * max), 10) / 100;
  }
  if (Math.abs(n - max) < 0.000001) {
    return 1;
  }
  if (max === 360) {
    n = (n < 0 ? n % max + max : n % max) / parseFloat(String(max));
  } else {
    n = n % max / parseFloat(String(max));
  }
  return n;
}
export function clamp01(val) {
  return Math.min(1, Math.max(0, val));
}
export function isOnePointZero(n) {
  return typeof n === 'string' && n.includes('.') && parseFloat(n) === 1;
}
export function isPercentage(n) {
  return typeof n === 'string' && n.includes('%');
}
export function boundAlpha(a) {
  a = parseFloat(a);
  if (isNaN(a) || a < 0 || a > 1) {
    a = 1;
  }
  return a;
}
export function convertToPercentage(n) {
  if (n <= 1) {
    return Number(n) * 100 + "%";
  }
  return n;
}
export function pad2(c) {
  return c.length === 1 ? '0' + c : String(c);
}
import { GalleryImageVM } from '../../shared/gallery/gallery-image-vm';
import { FavoritesSession } from '../../utility/models/favorites-session';
import { MediaVM } from '../../utility/models/media-vm';

import { IAmenityMap, IHotspot } from '@ml/common';

export class AmenityMapVM implements IAmenityMap {
  AmenityMapId: number;
  CommunityId: number;
  SvgFilename: string;
  Name: string;
  Description: string;
  Hotspots: IHotspot[] = [];

  BaseUrl: string;
  FullSvgUrl: string;
  AllGalleryImages: GalleryImageVM[] = [];
  GalleryImages: GalleryImageVM[] = [];

  constructor(data?: IAmenityMap, baseUrl?: string, session?: FavoritesSession) {
    Object.assign(this, data);

    if (baseUrl) {
      this.BaseUrl = baseUrl;
      if (this.SvgFilename) {
        this.FullSvgUrl = `${baseUrl}/Amenities/${this.AmenityMapId}/${this.SvgFilename}`;

        const vm = new GalleryImageVM(
          `${baseUrl}/Amenities/${this.AmenityMapId}`,
          this.SvgFilename
        );
        vm.IsFavoritable = false;
        this.AllGalleryImages.push(vm);
      }

      const media = this.Hotspots.map(
        x => new MediaVM(x.Media, session?.containsAmenityMedia(x.MediaId))
      );

      this.GalleryImages = media.map(m =>
        new GalleryImageVM(baseUrl).HydrateFromAmenityMedia(m, this.AmenityMapId)
      );
      this.AllGalleryImages.push(...this.GalleryImages);
    }
  }
}

import { ElevationVM } from '../../utility/models/elevation-vm';
import { FavoritesSession } from '../../utility/models/favorites-session';
import { FloorVM } from '../../utility/models/floor-vm';
import { MediaVM } from '../../utility/models/media-vm';

import { IMapImage, IMedia, MediaType } from '@ml/common';

export class GalleryImageVM {
  ThumbnailUrl: string;
  FullUrl: string;
  InnerHtml?: string;
  Title: string;
  Id: number;
  IsFavorited = false;
  IsFavoritable = true;
  Type = GalleryImageType.Elevation;
  ParentType = GalleryImageParentType.None;
  ParentId: number;
  IsVideo = false;
  IsIframe = false;
  BaseUrl = '';
  VideoTime: number;
  Order?: number;
  Cost?: number;

  constructor(baseUrl: string, fileName?: string, title?: string) {
    this.BaseUrl = baseUrl;
    this.ThumbnailUrl = `${baseUrl}/thumbs/sm/${fileName}`;
    this.FullUrl = fileName ? `${baseUrl}/${fileName}` : baseUrl;
    this.Title = title;

    // only use date query param if online, otherwise it could mess with SW cache
    if (!navigator.onLine) {
      this.ThumbnailUrl = this.ThumbnailUrl.replace(/\?d=.*/, '');
      this.FullUrl = this.FullUrl.replace(/\?d=.*/, '');
    }
  }

  static Hydrate(
    item: ElevationVM | MediaVM,
    baseUrl: string,
    parentType: GalleryImageParentType,
    parentId: number
  ): GalleryImageVM {
    if (item instanceof MediaVM)
      return new GalleryImageVM(baseUrl).HydrateFromMedia(item, parentType, parentId);
    else if (item instanceof ElevationVM)
      return new GalleryImageVM(baseUrl).HydrateFromElevation(item, parentType, parentId);
  }

  HydrateFromElevation(
    elev: ElevationVM,
    parentType: GalleryImageParentType,
    parentId: number,
    isInventoryHomeCustomElevation: boolean = false
  ): GalleryImageVM {
    if (isInventoryHomeCustomElevation) {
      this.FullUrl = `${this.BaseUrl}/${elev.ImageFilename}`;
      this.ThumbnailUrl = this.FullUrl;
    } else {
      this.FullUrl = `${this.BaseUrl}/thumbs/lg/${elev.ImageFilename}`;
      this.ThumbnailUrl = `${this.BaseUrl}/thumbs/sm/${elev.ImageFilename}`;
    }

    this.Title = elev.Title;
    this.Id = elev.ElevationId;
    this.IsFavorited = elev.IsFavorited;
    this.IsFavoritable = !isInventoryHomeCustomElevation;
    this.Type = GalleryImageType.Elevation;
    this.ParentType = parentType;
    this.ParentId = parentId;
    this.Order = elev.Order;
    this.Cost = elev.Cost;

    return this;
  }

  HydrateFromMedia(
    media: MediaVM,
    parentType: GalleryImageParentType,
    parentId: number
  ): GalleryImageVM {
    this.ThumbnailUrl = `${this.BaseUrl}/thumbs/sm/${media.ImageFilename}`;
    this.FullUrl = `${this.BaseUrl}/thumbs/lg/${media.ImageFilename}`;
    this.Title = media.Title;
    this.Id = media.MediaId;
    this.IsFavorited = media.IsFavorited;
    this.Type = GalleryImageType.Media;
    this.ParentType = parentType;
    this.ParentId = parentId;
    this.Order = media.Order;

    // TODO - can this go in constructor and is it same for all hydrates?
    switch (media.MediaType) {
      case MediaType.Video:
        this.FullUrl = media.ExternalUrl;
        this.IsVideo = true;
        break;
      case MediaType.Matterport:
      case MediaType.VRTour:
      case MediaType.MLPanoramic:
      case MediaType.Pano:
        this.FullUrl = media.ExternalUrl;
        this.IsIframe = true;
        break;
      case MediaType.Static:
        if (media.ExternalUrl) this.FullUrl = media.ExternalUrl;
    }

    return this;
  }

  HydrateFromLotMedia(media: IMedia, favoritesSession: FavoritesSession) {
    this.Id = media.MediaId;
    this.ThumbnailUrl = this.FullUrl;
    this.Type = GalleryImageType.Media;
    this.ParentType = GalleryImageParentType.Lot;
    this.ParentId = media.LotId;
    this.IsFavorited = favoritesSession.containsLotMedia(this.Id);
    this.IsFavoritable = true;
    this.Order = media.Order;

    return this;
  }

  HydrateFromAmenityMedia(media: MediaVM, amenityMapId: number): GalleryImageVM {
    this.ThumbnailUrl = null;
    if (media.ExternalUrl) this.FullUrl = media.ExternalUrl;
    else this.FullUrl = `${this.BaseUrl}/Amenities/${amenityMapId}/${media.ImageFilename}`;

    this.Title = media.Title;
    this.Id = media.MediaId;
    this.IsFavorited = media.IsFavorited;
    this.Type = GalleryImageType.Amenity;

    switch (media.MediaType) {
      case MediaType.Video:
        this.IsVideo = true;
        break;
      case MediaType.Matterport:
      case MediaType.VRTour:
      case MediaType.MLPanoramic:
      case MediaType.Pano:
        this.IsIframe = true;
        break;
    }

    this.IsFavoritable = !this.IsVideo;

    return this;
  }

  HydrateFromFloor(floor: FloorVM, svg?: string) {
    if (svg) {
      this.InnerHtml = svg;
    } else {
      this.FullUrl = floor.FullSvgUrl || floor.FullPreviewUrl;
      this.ThumbnailUrl = this.FullUrl;
    }
    this.Title = floor.Title;
    this.IsFavoritable = false;
    this.Id = floor.FloorId;
    this.Type = GalleryImageType.Floor;
    return this;
  }

  HydrateFromMapImage(mapimage: IMapImage) {
    this.Id = mapimage.MapImageId;
    this.IsFavoritable = false;
    this.Title = mapimage.Name;
    // this.Type = GalleryImageType.; // not needed until favoritable
    this.ParentType = GalleryImageParentType.CommunityMap;
    return this;
  }
}

export enum GalleryImageType {
  Elevation,
  Media, // could be either FP/IH interior or lot media -- check ParentType
  Floor,
  Amenity
}

export enum GalleryImageParentType {
  None,
  Floorplan,
  InventoryHome,
  Lot,
  CommunityMap
}

import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { PushToGoogleTagManager } from '../../utility/helpers/google-tag-manager';
import { getDefaultIconNameForPage } from '../../utility/helpers/helpers';
import { CommunityService } from '../../utility/services/community.service';
import { NavigationService, PageTitle } from '../../utility/services/navigation.service';
import { PageService } from '../../utility/services/page.service';
import { SettingsService } from '../../utility/services/settings.service';
import { ThemeService } from '../../utility/services/theme.service';

@Component({
  selector: 'slide-out-menu',
  templateUrl: './slide-out-menu.component.html',
  styleUrls: ['./slide-out-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SlideOutMenuComponent implements OnInit {
  @Input() pageTitle: PageTitle;
  @Input() heading: string;
  @Input() iconName: string;
  @Input() isOpen = true;
  @Input() isPullTabAtBottom = false;
  @Input() useAltOpenButton = false; // need better name
  @Input() doStopClickPropagation = true;
  @Input() hasLeftOrigin = false;
  @Input() hasFavoriteButton = false;
  @Input() isFavorited = false;
  @Input() showTouchHint = false;
  @Output() toggle = new EventEmitter<boolean>();
  @Output() favorite = new EventEmitter<boolean>();
  iconSvg: string;
  showIconBackground = true;
  showHeaderIcon = true;

  constructor(
    private pageService: PageService,
    private settingsService: SettingsService,
    private theme: ThemeService,
    private navigator: NavigationService,
    private communityService: CommunityService
  ) {}

  ngOnInit() {
    if (this.pageTitle) {
      const page = this.pageService.get(this.pageTitle);

      if (!this.heading) {
        const pageTitle = page.NavigationTitle || page.PageTitle || this.pageTitle;
        if (this.navigator.IsInStandaloneMode)
          this.heading = `${this.communityService.getDisplayName()}<br/> <span>${pageTitle}</span>`;
        else this.heading = pageTitle;
      }
      if (!this.iconName) {
        this.iconName = getDefaultIconNameForPage(this.pageTitle);
        this.iconSvg = page.IconSvgContent;
      }
      this.showIconBackground = this.settingsService.get('ShowHeaderIconBkgrnd');
      this.showHeaderIcon =
        !!this.settingsService.get('HeaderIconShow') && (!!this.iconName || !!this.iconSvg);
    }
  }

  toggleMenu(evt: Event) {
    evt.stopPropagation();
    this.isOpen = !this.isOpen;
    this.theme.updateSlideOutMenuCurrentWidth(this.isOpen);
    this.toggle.emit(this.isOpen);

    PushToGoogleTagManager({
      eventName: 'ToggleSideMenuOpenClosed',
      eventType: 'App Click Interaction',
      eventValue: `${this.isOpen}`
    });
  }

  maybeBlockEventPropagation(evt: Event) {
    if (this.doStopClickPropagation) evt.stopPropagation();
  }

  handleFavoriteToggle() {
    this.isFavorited = !this.isFavorited;
    this.favorite.emit(this.isFavorited);
  }
}

export interface IHubSpotFavorites {
  FavoriteLotNames: string;
  FavoriteFloorPlanNames: string;
  FavoritePlaceNames: string;
  Email: string;
  CommunityId: number;
}

export class HubSpotFavorites implements IHubSpotFavorites {
  FavoriteLotNames: string;
  FavoriteFloorPlanNames: string;
  FavoritePlaceNames: string;
  Email: string;
  CommunityId: number;

  constructor(data?: IHubSpotFavorites) {
    if (data) Object.assign(this, data);
  }
}

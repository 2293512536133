import { IEmailRequest } from '@ml/common';

export class EmailRequest implements IEmailRequest {
  recipients: string;
  cc: string;
  replyto: string;
  subject: string;
  body: string;
  topcontent: string;
  bottomcontent: string;
  filecontent: string;
  filename: string;
  isHtml: boolean;
  communityIdForSettings: number;

  constructor(recipients: string, title: string, message: string) {
    this.recipients = recipients;
    this.subject = title;
    this.body = message;
  }

  mapToFormData(): FormData {
    const formData = new FormData();
    formData.set('mailDTO', JSON.stringify(this as IEmailRequest));
    return formData;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { MapSvgService } from '../../site-map/map-svg.service';
import { StatusVM } from '../../site-map/models/status-vm';
import { FavoriteType } from '../../utility/helpers/favorite-types.enum';
import { GraphicsModel } from '../../utility/models/graphics-model';
import { NeighborhoodVM } from '../../utility/models/neighborhood-vm';
import { CommunityService } from '../../utility/services/community.service';
import { FavoritesService } from '../../utility/services/favorites.service';
import { LotService } from '../../utility/services/lot.service';
import { SettingsService } from '../../utility/services/settings.service';
import { ViewModelFactoryService } from '../../utility/services/vm-factory.service';
import { ISvgInjectorLoaded } from '../svg-injector.directive';

@Component({
  selector: 'sitemap-viewer',
  templateUrl: './sitemap-viewer.component.html',
  styleUrls: ['./sitemap-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SitemapViewerComponent implements OnInit, OnDestroy {
  @ViewChild('mapViewport') mapViewport: ElementRef<HTMLElement>;
  @Input() neighborhood: NeighborhoodVM;
  @Input() presetGraphicsModel?: GraphicsModel;
  @Input() statuses?: StatusVM[] = [];
  @Input() lotIdsToColor?: number[] = [];
  @Output() sitemapLoad = new EventEmitter<void>();
  FavoriteType = FavoriteType;
  graphicsModel?: GraphicsModel;
  private svg: SVGSVGElement;
  hasPrintbox = false;

  //stanley martin model home status
  showModelHomeStatus = false;
  modelHomeImgUrl: string;

  constructor(
    private mapSvgService: MapSvgService,
    private favorites: FavoritesService,
    private communityService: CommunityService,
    private lotService: LotService,
    protected vmFactory: ViewModelFactoryService,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    if (this.statuses && this.settingsService.get('PrintSelectedStatusesOnly'))
      this.statuses = this.statuses.filter(x => x.IsSelected);
    else {
      this.statuses = this.vmFactory.getLotStatusesForNeighborhood(
        this.neighborhood.NeighborhoodId
      );
    }
  }

  ngOnDestroy() {
    this.mapSvgService.clearCache();
  }

  async onSvgLoad(data: ISvgInjectorLoaded) {
    this.mapSvgService.clearCache();
    this.svg = data.Element as SVGSVGElement;

    const container = this.mapViewport.nativeElement;
    this.graphicsModel = GraphicsModel.CreateFromSvg(this.svg, container);

    if (this.presetGraphicsModel?.Scale > 1) {
      // calculations here are an attempt to account for differences in container sizes
      this.graphicsModel.X =
        (this.presetGraphicsModel.X / this.presetGraphicsModel.ContainerWidth) *
        this.graphicsModel.ContainerWidth;
      this.graphicsModel.Y =
        (this.presetGraphicsModel.Y / this.presetGraphicsModel.ContainerHeight) *
        this.graphicsModel.ContainerHeight;
      this.graphicsModel.Scale = this.presetGraphicsModel.Scale;
    }
    if (this.lotIdsToColor?.length) {
      this.mapSvgService.colorLotsWithStatuses(
        this.neighborhood.Lots,
        this.statuses,
        this.svg,
        this.lotIdsToColor
      );
    } else {
      this.mapSvgService.colorLotsWithStatuses(
        this.neighborhood.Lots,
        this.statuses,
        this.svg,
        this.neighborhood.Lots.map(x => x.LotId)
      );
    }

    this.favorites.current.LotIds.forEach(id => {
      const lot = this.neighborhood.Lots.find(x => x.LotId === id);
      if (lot) this.mapSvgService.injectCircleIntoLot(lot, this.svg);
    });

    //Stanley Martin model home status
    if (this.communityService.current.ClientId === 108) {
      this.modelHomeImgUrl = '/assets/icons/SMH_MapSymbol.png';

      this.showModelHomeStatus = this.mapSvgService.checkAndSetSecondaryIndicator(
        this.neighborhood.Lots.filter(x => this.lotService.isModelHome(x)),
        this.svg
      );
    }

    //provide small delay to make sure processing is complete
    setTimeout(() => {
      this.sitemapLoad.emit();
    }, 200);
  }

  sizeSvgToPrintbox(svg: SVGSVGElement, printbox: SVGSVGElement) {
    const { x, y, width, height } = printbox.getBBox();
    svg.setAttribute('viewBox', `${x} ${y} ${width} ${height}`);
    return svg;
  }
}

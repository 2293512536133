<section class="features-container" [class.open]="isOpen" (blur)="isOpen = false">
    <div class="features-header" (click)="toggleState()">
        <h2>{{infoDescriptionTitle}}</h2>
        <button class="arrow" [attr.aria-label]="infoDescriptionTitle">
            <icon name="carrot_arrow"></icon>
        </button>
    </div>
    <div class="features-body">
        <div class="features-content">
            <p class="text" *ngIf="showDescription">{{ description }}</p>
            <ul class="features-list-item" *ngIf="showOptions">
                <li *ngFor="let floor of optionsByFloor" class="text" [attr.tabindex]="isOpen ? 0: -1">
                    {{floor.Title}}
                    <ul>
                        <ng-container *ngFor="let option of floor.ActionSets">
                            <li *ngIf="option.IsInMenu">
                                {{ option.Name }}
                            </li>
                        </ng-container>
                    </ul>
                </li>
                <li *ngIf="noOptionsMessage">{{noOptionsMessage}}</li>
            </ul>
        </div>
    </div>
</section>
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import {
  AccordionSectionVM
} from '../../myscp/custom-page/layouts/accordion-sections/accordion-section-vm';
import { ContentItem, ContentSection } from '../../utility/interfaces/custom-sub-page';
import { FavoritesService } from '../../utility/services/favorites.service';

@Component({
  selector: 'accordion-sections-content',
  templateUrl: './accordion-sections-content.component.html',
  styleUrls: ['./accordion-sections-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionSectionsContentComponent implements OnInit {
  @Input() sections: AccordionSectionVM[] = [];

  constructor(private favorites: FavoritesService) {}

  ngOnInit(): void {}

  handleFavoriteToggle(isFavorited: boolean, id: string) {
    if (isFavorited) this.favorites.addPageContentSection(id);
    else this.favorites.removePageContentSection(id);
  }

  trackById(index: number, item: ContentItem | ContentSection) {
    return item.Id;
  }
}

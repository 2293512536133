import { getActiveProductName } from './helpers';

export interface GTMEventData {
  eventName: string;
  eventType?: string;
  eventValue?: string;
  extraData?: Record<string, any>;
}

/** EventName will get prefixed with "myscp." */
export const PushToGoogleTagManager = ({
  eventName,
  eventType,
  eventValue,
  extraData
}: GTMEventData) => {
  if (!navigator.onLine) return;

  // "interaction" naming matches previous app nomenclature but maybe could be better ¯\_(ツ)_/¯
  const payload = {
    event: getActiveProductName() + '.' + eventName,
    InteractionType: eventType || eventName,
    InteractionValue: eventValue,
    CurrentLocation: window.location.href,
    ...extraData
  };

  // wait until first attempt at pushing an event to actually load GTM
  if (!(window as any).dataLayer) {
    (window as any).dataLayer = [];
    // first event should not have an event name... because reasons
    delete payload.event;

    // ensure our event is first in dataLayer by slightly delaying here
    window.setTimeout(loadGTM, 100);
  }

  (window as any).dataLayer.push(payload);
};

function loadGTM() {
  var gtmKey = window.location.pathname.includes('home-configurator')
    ? 'GTM-PSHJWHZ'
    : 'GTM-KG4Q8DK';
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s) as HTMLScriptElement,
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', gtmKey);
}

import { FloorplanVM } from '../../utility/models/floorplan-vm';
import { StatusVM } from './status-vm';

import { IIdInfo, ILot, ILotWatcher, IMedia, ITag, sortByName } from '@ml/common';

export class LotVM implements ILot {
  LotId: number;
  NeighborhoodId: number;
  ClientId: number;
  StatusId: number;
  StatusIds: string[];
  Statuses: { [key: string]: string };
  ShortDescription: string;
  Description: string;
  Name: string;
  LotNumber: string;
  LotNumberDisplay: string;
  Block: string;
  BlockDisplay: string;
  Phase: string;
  Address: string;
  UnitNumber: string;
  Cost: number;
  IsVisible: boolean;
  UIInstanceId: string;
  ClientIdIsJdeId: boolean;
  ClientUniqueIdentifier: string;
  Swing: string;
  Premium: number;
  OptionsPremium: number;
  MappedFloorPlanIds: IIdInfo[];
  MappedElevationIds: IIdInfo[];
  CustomData: { [index: string]: any };
  Latitude: number;
  Longitude: number;
  ModifiedOn: string;
  StartedOn: string;
  /** @deprecated No longer used */
  TakedownOn: string;
  /** @deprecated No longer used */
  PermitReceivedOn: string;
  /** @deprecated No longer used */
  PermitAppliedOn: string;
  /** @deprecated No longer used */
  SoldOn: string;
  /** @deprecated No longer used */
  ClosedOn: string;
  ApiLastModifiedOn: string;
  InventoryHomeIds: number[];
  Media: IMedia[] = [];
  NeighborhoodVirtualDirectory: string;
  Watchers: ILotWatcher[] = [];
  TotalCost: number;
  IsReverse: boolean;
  AllowEdit: boolean;
  AllowImageOverride: boolean;
  OverrideDisplayImageFilename: string;
  OverrideDisplayImageTitle: string;
  BuildStatusId?: number;
  LandStatusId?: number;
  SeriesStatusId?: number;
  ExtendedData: string;
  RotationDegreesFromNorth: number;
  MappedElevationsJSON: string;
  MappedFloorPlansJSON: string;
  Tags: ITag[];

  Title: string;
  IsFavorited: boolean;
  IsSelected: boolean;
  MappedStatus: StatusVM;
  Floorplans: FloorplanVM[] = [];
  HasSpecData: boolean;
  HtmlId: string;
  HasInventoryHome: boolean;
  AllTagNames: string;
  SelectedFromKeyboard = false;

  constructor(
    data?: ILot,
    statuses?: StatusVM[],
    isFavorited: boolean = false,
    isSelected: boolean = false
  ) {
    if (data) Object.assign(this, data);
    if (statuses) this.MappedStatus = statuses.find(x => x.LotMapStatusId === this.StatusId);
    this.IsFavorited = isFavorited;
    this.IsSelected = isSelected;
    this.AllTagNames = this.Tags?.sort(sortByName)
      .map(t => t.Name)
      .join(', ');

    this.HasSpecData =
      !!this.Address ||
      !!this.Phase ||
      !!this.Swing ||
      !!this.Block ||
      !!this.Premium ||
      !!this.Cost ||
      !!this.Description ||
      !!this.AllTagNames;

    this.HtmlId = 'lot-' + this.LotId;
    this.Title = this.getTitle();

    // filter out empty strings and 0 values
    for (const key in this.CustomData) {
      if (this.CustomData.hasOwnProperty(key)) {
        const isNumber = !isNaN(+this.CustomData[key]);
        if (!this.CustomData[key] || (isNumber && !+this.CustomData[key]))
          delete this.CustomData[key];
      }
    }
  }

  getTitle() {
    if (this.LotNumberDisplay) return this.LotNumberDisplay;
    if (this.LotNumber) return '#' + this.LotNumber;
    return this.Name || this.ClientUniqueIdentifier || 'Lot';
  }

  checkForSpecDetails(customDataFields?: string[], includePricing = true) {
    const detailSpecArray = [
      'Phase',
      'Swing',
      'BlockDisplay',
      'Description',
      'ShortDescription',
      'AllTagNames'
    ];
    for (const spec of detailSpecArray) {
      if (this[spec]) {
        return true;
      }
    }
    if (customDataFields) {
      for (const field of customDataFields) {
        if (field in this.CustomData) {
          return true;
        }
      }
    }

    if (includePricing) return this.checkForPricingData();

    return false;
  }

  checkForPricingData() {
    const specArray = ['Premium', 'OptionsPremium', 'Cost', 'TotalCost'];
    for (const spec of specArray) {
      if (this[spec]) {
        return true;
      }
    }

    return false;
  }
}

<div class="close" (click)="handleClose()">
  <icon name="x"></icon>
</div>
<div class="calculator-container">
  <header>
    <h2>Calculate Your Mortgage</h2>
    <p>Use this calculator to determine your estimated monthly payment.</p>
  </header>
  <div class="calc-form">
    <div class="row">
      <label>Loan Type:</label>
      <div class="loan-type-check-container">
        <div tabindex="0" class="loan-type-check" (click)="handleLoanLengthTerms(loanLengthTerms.Fifteen)"
             [class.enabled]="formValues.Years === loanLengthTerms.Fifteen">
          <label>15 YEAR</label>
          <div class="check">
            <icon name="check"></icon>
          </div>
        </div>
        <div tabindex="0" class="loan-type-check" (click)="handleLoanLengthTerms(loanLengthTerms.Thirty)"
             [class.enabled]="formValues.Years === loanLengthTerms.Thirty">
          <label>30 YEAR</label>
          <div class="check">
            <icon name="check"></icon>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <label>Home Price:</label>
      <div class="input-container">
        <span>$</span>
        <input type="text" inputmode="numeric" pattern="[0-9]*" [mlNumberToCommaString]="formValues.TotalPrice"
               (mlNumberToCommaStringChange)="handleTotalPriceChange($event)">
      </div>
    </div>
    <div class="row">
      <label>Down Payment:</label>
      <div class="input-container">
        <div class="small">
          <span>%</span>
          <input type="number" [ngModel]="formValues.DownPayPercent"
                 (ngModelChange)="handleDownPaymentChange($event, calcFormProperties.DownPayPercent)">
        </div>
        <div class="small">
          <span>$</span>
          <input type="text" inputmode="numeric" pattern="[0-9]*" [mlNumberToCommaString]="formValues.DownPayPrice"
                 (mlNumberToCommaStringChange)="handleDownPaymentChange($event, calcFormProperties.DownPayPrice)">
        </div>
      </div>
    </div>
    <div class="row">
      <label>Interest Rate:</label>
      <div class="input-container">
        <span>%</span>
        <input type="number" [ngModel]="formValues.InterestRate" (ngModelChange)="handleInterestRate($event)">
      </div>
    </div>
    <div class="row">
      <label>Mortgage Amount:</label>
      <div class="input-container">
        <span>$</span>
        <input disabled [ngModel]="formValues.MortgageAmount | number: '1.0-0':'en-US'">
      </div>
    </div>
  </div>
  <div class="result-container">
    <label>Estimated Payment:</label>
    <p>{{monthlyPayment}}/Month</p>
  </div>
  <div class="row center">
    <button class="hc-btn primary" (click)="handleSave()">
      <icon name="save"></icon>
      Save
    </button>
  </div>
</div>
import { Injectable, NgZone, Renderer2, RendererFactory2 } from '@angular/core';
import { TinyColor } from '@ctrl/tinycolor';

import { Lookup } from '../utility/helpers/lookup';
import { BBox } from '../utility/models/bbox';
import { SettingsService } from '../utility/services/settings.service';
import { InternalStatusType } from './models/status-vm';

import { ILot, ILotMapStatus } from '@ml/common';

@Injectable()
export class MapSvgService {
  private eventRemovalHandlers: (() => void)[] = [];
  private elementCache: { [key: string]: SVGElement } = {};
  private renderer: Renderer2;
  private colorSymbolInsteadOfBaseArt = false;

  private readonly injectedCircleClass = 'injected-circle';
  private readonly LotNoMatchFillColor: string = '#ffffff';

  constructor(
    private rendererFactory: RendererFactory2,
    private lookup: Lookup,
    private settingsService: SettingsService,
    private zone: NgZone
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.colorSymbolInsteadOfBaseArt = this.settingsService.get('SetStatusColorInSymbolOnly');

    if (this.colorSymbolInsteadOfBaseArt) this.LotNoMatchFillColor = 'transparent';
  }

  cleanSlate() {
    this.clearCache();
    this.removeEventListeners();
  }

  clearCache() {
    this.elementCache = {};
  }

  removeEventListeners() {
    this.eventRemovalHandlers.forEach(h => h());
  }

  attachClickEvent(svg: SVGElement, lots: ILot[], onClickHandler: (l: ILot) => void) {
    // ensure clean slate
    this.eventRemovalHandlers.forEach(h => h());
    this.eventRemovalHandlers = [];

    let mouseDownEvent: MouseEvent = null;
    const trackMouse = (evt: MouseEvent) => {
      mouseDownEvent = evt;
    };

    const handleLotSelect = (evt: MouseEvent, lot: ILot) => {
      // when the overall map is dragged around this click event can still be fired on the lot SVG element
      // so we must compare the click event position to the initial mousedown event position to make sure the user did not move
      if (
        !mouseDownEvent ||
        (Math.abs(mouseDownEvent.clientX - evt.clientX) < 20 &&
          Math.abs(mouseDownEvent.clientY - evt.clientY) < 20)
      )
        onClickHandler(lot);
    };

    lots.forEach(lot => {
      const lotElement = this.getLotElement(lot, svg);

      if (lotElement) {
        this.eventRemovalHandlers.push(
          this.renderer.listen(lotElement, 'mousedown', evt => {
            trackMouse(evt);
          })
        );
        this.eventRemovalHandlers.push(
          this.renderer.listen(lotElement, 'click', evt => {
            this.zone.run(() => handleLotSelect(evt, lot));
          })
        );
      }
    });
  }

  attachKeyDownEvent(
    svg: SVGElement,
    lots: ILot[],
    onClickHandler: (l: ILot, k: boolean) => void,
    lotLabel: string
  ) {
    lots.forEach(lot => {
      const lotElement = this.getLotElement(lot, svg);

      if (lotElement) {
        this.eventRemovalHandlers.push(
          this.renderer.listen(lotElement, 'keydown', evt => {
            if (evt.key === 'Enter') {
              evt.stopPropagation();
              this.zone.run(() => onClickHandler(lot, true));
            }
          })
        );
        this.renderer.setAttribute(lotElement, 'tabindex', '-1');
        this.renderer.setAttribute(
          lotElement,
          'aria-label',
          `${lotLabel}${lot.LotNumber ? ' ' + lot.LotNumber : ''}`
        );
      }
    });
  }

  colorLots(lots: ILot[], fill: string, svg: SVGElement) {
    lots.forEach(x => this.setLotFill(x, fill, svg));
  }

  colorLotsWithStatuses(
    lots: ILot[],
    statuses: ILotMapStatus[],
    svg: SVGElement,
    lotIdsToColor: Array<number>
  ): number {
    let numColoredLots = 0;
    const hideLotSetting = this.settingsService.get('HideLotsWithUnavailableInternalStatus');

    lots.forEach(lot => {
      let fill = this.LotNoMatchFillColor;
      let hideText = false;
      const mappedStatus = statuses.find(x => x.LotMapStatusId === lot.StatusId);
      if (lotIdsToColor.includes(lot.LotId)) {
        if (mappedStatus) {
          fill = `#${mappedStatus.ColorHexValue}`;
          numColoredLots++;
        }
      }
      if (mappedStatus?.InternalStatusType === InternalStatusType.Unavailable && hideLotSetting)
        hideText = true;

      this.setLotFill(lot, fill, svg, hideText);
    });
    return numColoredLots;
  }

  injectCircleIntoLot(
    lot: ILot,
    svg: SVGElement,
    fillSettingName: string = 'FavoritesActiveColor'
  ) {
    const lotElement = this.getLotElement(lot, svg);

    if (this.getLotInjectedCircle(lot, lotElement)) return;

    if (lotElement) {
      const text = this.getLotText(lot, lotElement);
      if (text) {
        const bounds = text.getBBox();
        // the plus 1 for slight padding around text
        const radius = Math.max(bounds.width, bounds.height) / 2 + 0.5;
        const circleDims = {
          x: bounds.x + bounds.width / 2,
          y: bounds.y + bounds.height / 2,
          rx: radius,
          ry: radius
        };

        const circle = document.createElementNS('http://www.w3.org/2000/svg', 'ellipse');
        circle.classList.add(this.injectedCircleClass);
        circle.setAttribute('cx', circleDims.x.toString());
        circle.setAttribute('cy', circleDims.y.toString());
        circle.setAttribute('rx', circleDims.rx.toString());
        circle.setAttribute('ry', circleDims.ry.toString());
        circle.style.fill = this.settingsService.get(fillSettingName) ?? '#15a55d';

        lotElement.insertBefore(circle, text);
        this.elementCache[lot.UIInstanceId] = lotElement;
        this.adjustBrightness(lot, lotElement);
      }
    }
  }

  removeInjectedCircleFromLot(lot: ILot, svg: SVGElement) {
    const lotElement = this.getLotElement(lot, svg);
    const circle = this.getLotInjectedCircle(lot, lotElement);
    if (circle) {
      circle.parentElement.removeChild(circle);
      const cacheName = `${lot.UIInstanceId}-${this.injectedCircleClass}`;
      delete this.elementCache[cacheName];
      this.adjustBrightness(lot, lotElement);
    }
  }

  makeClipPath(lot: ILot, svg: SVGElement) {
    const elem = this.getLotElement(lot, svg);
    if (elem) {
      const art = this.getLotArt(lot, elem);
      if (art) {
        const clipPath = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
        clipPath.id = 'myPath';
        // clipPath.appendChild(art.cloneNode(true));

        const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        rect.setAttributeNS(null, 'x', '0');
        rect.setAttributeNS(null, 'y', '0');
        rect.setAttributeNS(null, 'width', '20');
        rect.setAttributeNS(null, 'height', '10');
        clipPath.appendChild(rect);

        svg.appendChild(clipPath);
        // svg.style.clipPath = 'url(#myPath)';
        elem.setAttributeNS(null, 'clip-path', 'url(#myPath)');
      }
    }
  }

  addTabIndex(svg: SVGElement) {
    if (svg) {
      const lotsElements = svg.querySelectorAll('[tabindex]');
      lotsElements.forEach((lot: SVGElement) => {
        lot.setAttribute('tabindex', '0');
      });
    }
  }
  removeTabIndex(svg: SVGElement) {
    if (svg) {
      const lotsElements = svg.querySelectorAll('[tabindex]');
      lotsElements.forEach((lot: SVGElement) => {
        //lot.removeAttribute('tabindex');
        lot.setAttribute('tabindex', '-1');
      });
    }
  }

  getLotElement(lot: ILot, svg?: SVGElement, returnCopy: boolean = false) {
    let lotElement = this.elementCache[lot.UIInstanceId];
    if (!lotElement && !!svg) {
      lotElement = svg.querySelector('#' + lot.UIInstanceId);
      this.elementCache[lot.UIInstanceId] = lotElement;
    }

    let retval = lotElement;
    if (lotElement && returnCopy) {
      retval = lotElement.cloneNode(true) as SVGElement;
      retval.id = lotElement.id + '_copy';
    }

    return retval;
  }

  removeExcessNodes(cloneSvg: SVGElement, bBox: BBox) {
    const topLeftX = bBox.x;
    const topLeftY = bBox.y;
    const bottomRightX = bBox.x + bBox.width;
    const bottomRightY = bBox.y + bBox.height;

    let nodeList = Array.from(cloneSvg.childNodes);
    let childNodes = new Array<ChildNode>();

    for (let i = nodeList.length - 1; i >= 0; --i) {
      childNodes = [...childNodes, ...Array.from(nodeList[i].childNodes)];
    }

    nodeList = [...nodeList, ...childNodes];

    for (let i = nodeList.length - 1; i >= 0; --i) {
      const node = nodeList[i];
      if (node && (node as any).getBBox) {
        const box = (node as any)?.getBBox();

        if (box) {
          const topLeftXBox = box.x;
          const topLeftYBox = box.y;
          const bottomRightXBox = box.x + box.width;
          const bottomRightYBox = box.y + box.height;

          if (
            topLeftX > bottomRightXBox * 2 ||
            topLeftXBox > bottomRightX * 2 ||
            topLeftY > bottomRightYBox * 2 ||
            topLeftYBox > bottomRightY * 2
          ) {
            // element not within viewBox. Should be removed.
            nodeList[i].remove();
          }
        }
      }
    }
  }

  getLotZoomedInBBox(lot: ILot, svg: SVGElement, padding: number = 1): BBox {
    const lotRef: SVGGraphicsElement = this.getLotElement(lot, svg) as SVGGraphicsElement;
    const lotBBox = lotRef.getBBox();

    if (lotRef && lotBBox) {
      const widthPadding = Math.ceil((lotBBox.width * padding) / 2);
      const heightPadding = Math.ceil((lotBBox.height * padding) / 2);

      const bBox = new BBox();
      bBox.x = lotBBox.x - widthPadding;
      bBox.y = lotBBox.y - heightPadding;
      bBox.width = lotBBox.width + widthPadding;
      bBox.height = lotBBox.height + heightPadding;

      return bBox;
    }

    return new BBox();
  }

  addSvgAndUpdateViewBoxToZoomInOnLot(lot: ILot, svg: SVGElement, container: HTMLElement) {
    svg.style.position = 'relative';

    if (container.hasChildNodes()) container.removeChild(container.firstChild);
    container.appendChild(svg);
    svg.setAttribute('width', '100%');
    svg.setAttribute('height', '100%');

    const bBox = this.getLotZoomedInBBox(lot, svg, 0.25);
    svg.setAttribute('viewBox', `${bBox.x} ${bBox.y} ${bBox.width} ${bBox.height}`);

    // the below function is causing big performance delays in big SVGs. I honestly can't find any difference when removing.
    // -JE 6/3/2022
    // setTimeout(() => {
    //   this.removeExcessNodes(svg, bBox);
    // }, 100);
  }

  checkAndSetSecondaryIndicator(lots: ILot[], svg: SVGElement) {
    //this will depend on if at least one lot is checked to show and svg is present
    const secondaryElements = svg.querySelectorAll(`[id*='-secondarysymbol']`);

    if (secondaryElements.length) this.setSecondaryIndicator(lots, svg);

    return !!secondaryElements.length;
  }

  private setSecondaryIndicator(lots: ILot[], svg: SVGElement) {
    lots.forEach(lot => {
      const secondary: SVGGraphicsElement = svg.querySelector(
        `#${lot.UIInstanceId}-secondarysymbol`
      );
      if (secondary) {
        secondary.style.display = 'block';
      }
    });
  }

  private setLotFill(lot: ILot, fill: string, svg: SVGElement, hideText: boolean = false) {
    const elem = this.getLotElement(lot, svg);
    if (elem) {
      const art = this.getLotArt(lot, elem);
      if (art) {
        art.style.fill = fill;
        this.adjustBrightness(lot, elem);
      }
      const text = this.getLotText(lot, elem);
      if (text) {
        text.style.opacity = hideText ? '0' : '1';
      }
    }
  }

  private getLotArt(lot: ILot, lotElement: SVGElement): SVGElement {
    let lotArt: any;
    let cacheName: string;

    if (lot) {
      cacheName = lot.UIInstanceId + '-art';
      lotArt = this.elementCache[cacheName];
    }
    if (!lotArt) {
      const selector = this.colorSymbolInsteadOfBaseArt
        ? `g[id$='secondarysymbol'] circle, g[id$='secondarysymbol'] path`
        : 'path, polygon, rect, polyline';

      lotArt = lotElement.querySelector(selector);

      if (cacheName) this.elementCache[cacheName] = lotArt;
    }

    return lotArt;
  }

  private getLotText(lot: ILot, lotElement: SVGElement): SVGTextElement {
    let lotText: any;

    const cacheName = lot.UIInstanceId + '-text';
    lotText = this.elementCache[cacheName];
    if (!lotText) {
      lotText = lotElement.querySelector('[id^="TEXT"]');
      this.elementCache[cacheName] = lotText;
    }

    return lotText;
  }

  private getLotInjectedCircle(lot: ILot, lotElement: SVGElement): SVGElement {
    if (!lot) return;
    let lotInjectedCircle: any;

    const cacheName = `${lot.UIInstanceId}-${this.injectedCircleClass}`;
    lotInjectedCircle = this.elementCache[cacheName];
    if (!lotInjectedCircle) {
      lotInjectedCircle = lotElement.querySelector('.' + this.injectedCircleClass);
      this.elementCache[cacheName] = lotInjectedCircle;
    }

    return lotInjectedCircle;
  }

  private adjustBrightness(lot: ILot, lotElement: SVGElement) {
    if (this.colorSymbolInsteadOfBaseArt) return;

    let elementUnderLotText = this.getLotInjectedCircle(lot, lotElement);
    if (!elementUnderLotText) elementUnderLotText = this.getLotArt(lot, lotElement);

    const brightness = new TinyColor(elementUnderLotText.style.fill).getBrightness();
    // brightness > 1 because extremely dark colors (such as #000000 are done on purpose to mask lot data)
    if (brightness > 1 && brightness < 135) {
      lotElement.classList.add('bright');
    } else {
      // just in case a lot filter was made, we need to remove the bright text where not needed
      lotElement.classList.remove('bright');
    }
  }
}

export enum MapViewerType {
  Standard,
  GoogleOverlay,
  Spinner
}

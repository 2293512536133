import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'toggle-with-label',
  templateUrl: './toggle-with-label.component.html',
  styleUrls: ['./toggle-with-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleWithLabelComponent {
  @Input() on: boolean;
  @Input() label: string;
  @Input() icon: string;
  @Input() showCheckbox = false;
  @Output() toggle = new EventEmitter<boolean>();

  handleCheckboxToggle(evt: Event) {
    evt.stopPropagation();
    this.on = !this.on;
    this.toggle.emit(this.on);
  }

  handleLabelClick(evt: Event) {
    evt.stopPropagation();
  }
}

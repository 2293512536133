import { Injectable } from '@angular/core';
import { TinyColor } from '@ctrl/tinycolor';

import { ActionSet } from '../models/action-set';
import { FloorVM } from '../models/floor-vm';
import { FloorplanVM } from '../models/floorplan-vm';
import { SettingsService } from './settings.service';

import { LogicState } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class FloorArtService {
  private store = new Array<FloorArt>();

  constructor(private settingsService: SettingsService) {}

  addFloorSvg(floor: FloorVM, floorSvg: SVGElement) {
    this.store = this.store.filter(art => art.FloorId !== floor.FloorId);
    const floorArt = new FloorArt(floor.FloorId, floorSvg, floor.FloorplanId);
    this.store.push(floorArt);
  }

  getFloorSvg(floorId: number): SVGElement {
    const retVal = this.store.find(art => art.FloorId === floorId);
    return retVal ? retVal.FloorSvg : null;
  }

  initializeFloorPlanArt(floorplan: FloorplanVM) {
    this.updateAllOptionsHighlightColor(
      floorplan.FloorplanId,
      floorplan.Floors.flatMap(x => x.ActionSets)
    );

    this.hideAllFloorMaterialLayers(
      this.store.filter(x => x.FloorplanId === floorplan.FloorplanId)
    );
  }

  toggleArtOnFloorPlan(floorplanId: number, instanceId: string, state: LogicState) {
    const { artLayer } = this.findFloorAndGetOptionArt(floorplanId, instanceId);
    if (artLayer) {
      if (state === LogicState.ON) {
        artLayer.classList.remove('off');
        artLayer.classList.add('on');
      } else {
        artLayer.classList.remove('on');
        artLayer.classList.add('off');
      }
    } else console.warn(`Unable to find art instance ${instanceId} from floor plan ${floorplanId}`);
  }

  detectSuggestedFurniture(floorplanId: number): boolean {
    return this.store
      .filter(x => x.FloorplanId === floorplanId)
      .some(
        x =>
          x.FloorSvg.querySelectorAll('#_x31_st_Furn_-_furn,svg > g[id^=furn],svg > g[id$=furn]')
            .length
      );
  }

  toggleSuggestedFurniture(on: boolean, floorplanId: number) {
    this.store
      .filter(x => x.FloorplanId === floorplanId)
      .forEach(x => {
        const suggestedFurniture = x.FloorSvg.querySelectorAll(
          '#_x31_st_Furn_-_furn,svg > g[id^=furn],svg > g[id$=furn]'
        );
        suggestedFurniture.forEach(item => {
          let styleAttribute = 'display:none;';
          if (on) {
            styleAttribute = 'display:block;';
          }
          item.setAttribute('style', styleAttribute);
        });

        const noTxt = x.FloorSvg.querySelectorAll('svg > g[id^=T][id$=-NoTXT]');

        noTxt.forEach(item => {
          let styleAttribute = 'display:block;';
          if (on) {
            styleAttribute = 'display:none;';
          }
          item.setAttribute('style', styleAttribute);
        });
      });
  }

  hideAllFloorMaterialLayers(floors: FloorArt[]) {
    floors.forEach(f => {
      const materialLayers = f.FloorSvg.querySelectorAll(`[id^='fm-']`);
      materialLayers.forEach(layer => layer.setAttribute('style', 'display: none;'));
    });
  }

  private findFloorAndGetOptionArt(floorPlanId: number, instanceId: string) {
    const floors = this.store.filter(f => f.FloorplanId === floorPlanId);
    if (floors.length) {
      for (const floorArt of floors) {
        const artLayer = floorArt.getOptionArtElement(instanceId);
        if (artLayer) return { artLayer, floorArt };
      }
      return {};
    } else return {};
  }

  private updateAllOptionsHighlightColor(floorplanId: number, allActionSets: ActionSet[]) {
    const fillColor = this.settingsService.get('OptionHighlightColor');
    if (!fillColor) return;

    const hideHighlightOnStandardOptions = this.settingsService.get('HideStandardOptionsHighlight');
    const optionIsStandard = (aset: ActionSet) =>
      aset.InitialState === LogicState.ON && !aset.IsInMenu;

    // Options can set to hide highlight at the ActionSet level or if it's a standard option and setting is enabled
    const actionSetsToHighlight = allActionSets.filter(
      aset =>
        aset.ShowOptionHighlight && (!hideHighlightOnStandardOptions || !optionIsStandard(aset))
    );

    const completedArtInstanceIds: string[] = [];
    const detectOptionStandard = /^os/i;
    actionSetsToHighlight.forEach((actionSet: ActionSet) => {
      for (const request of actionSet.ActionRequest) {
        for (const layerAction of request.LayerAction) {
          for (const layertoLayerAction of layerAction.LayerToLayerAction) {
            const instanceId = layertoLayerAction.Layer.InstanceId;
            if (completedArtInstanceIds.includes(instanceId)) {
              // I don't understand why we need to drill down so far in an ActionSet to get the art's
              // identifier but once we do, it seems to be repeated so skipping dupes
              continue;
            }
            if (detectOptionStandard.test(instanceId)) {
              // Former options that now are standard to the plan should not be highlighted
              continue;
            }

            const { artLayer, floorArt } = this.findFloorAndGetOptionArt(floorplanId, instanceId);
            if (!artLayer) continue;

            this.colorHighlightLayers(floorArt, artLayer, fillColor);
            completedArtInstanceIds.push(instanceId);
          }
        }
      }
    });
  }

  private colorHighlightLayers(floorArt: FloorArt, layer: SVGElement, fillColor: string) {
    const selector = this.getHighlightPathSelector('h');
    const highlightLayers = [...layer.querySelectorAll(selector)];

    const useSources = floorArt.getUseSourceElements(layer);
    useSources?.forEach((useSource: SVGElement) => {
      const matches = useSource.querySelectorAll(selector);
      if (matches) highlightLayers.push(...matches);
    });

    highlightLayers.forEach((highlightLayer: SVGElement) => {
      this.setFillColor(highlightLayer, fillColor);
    });

    if (highlightLayers.length > 0) this.adjustTextColor(floorArt, layer, fillColor);
  }

  private adjustTextColor(floorArt: FloorArt, layer: SVGElement, fillColor: string) {
    // Check if color is bright enough, leave it; else we'll change it to white
    const brightness = new TinyColor(fillColor).getBrightness();
    if (brightness > 135) return;

    // Only turn text the alternate color IF it does not have the "-l" prefix following the "tl", "tm", "tf", "tdm" prefixes'
    // EXAMPLE: "tl-l", "tm-l", "tf-l" for locked text layers, "tl", "tm", "tf" for non-locked layers
    const containsLock = /^(tl|tm|tf|tdm|te|tr|tc)-(l-|lc-)/i; // 'l' or 'lc' should prevent the text from changing color
    const textSelector = `[id^='tl'],[id^='tm'],[id^='tf'],[id^='tdm'],[id^='te'],[id^='tr'],[id^='tc']`;
    const selector = this.getHighlightPathSelector('ht');
    const textLayers = layer.querySelectorAll(textSelector);
    const highlightTextLayers = [...textLayers];

    textLayers?.forEach((textLayer: SVGElement) => {
      const useSources = floorArt.getUseSourceElements(textLayer);
      useSources?.forEach((useSource: SVGElement) => {
        const matches = useSource.querySelectorAll(selector);
        if (matches) highlightTextLayers.push(...matches);
      });
    });

    highlightTextLayers?.forEach((highlightLayer: SVGElement) => {
      if (!containsLock.test(highlightLayer.id)) {
        this.setFillColor(highlightLayer, 'white');
      }
    });
  }

  private getHighlightPathSelector(idPrefix: string) {
    // Get anything that has a style setting, and is a child of an h-, or is an h- with a style setting, and
    // only if the style setting has a fill value, identifying it as an element that is participating in the
    // highlighting system
    return `[id^='${idPrefix}-'][style*=fill], [id^='${idPrefix}-'] *[style*=fill], [id^='${idPrefix}-'][fill]`;
  }

  private setFillColor(highlightLayer: SVGElement, fillColor: string) {
    // I don't see any examples that would require setting an inline style
    // and it only matches RGB instead of RGBA/hex/etc but copypasta'd from ICON...
    // GIFLENS-https://media0.giphy.com/media/JRhS6WoswF8FxE0g2R/200.gif
    const inlinecss = highlightLayer.style.cssText.replace(/fill:\s*rgb\(\d*,\s*\d*,\s*\d*\);/, '');
    if (inlinecss) {
      highlightLayer.setAttribute('style', inlinecss + 'fill: ' + fillColor + ';');
    } else {
      highlightLayer.style.fill = fillColor;
    }
  }
}

class FloorArt {
  FloorId: number;
  FloorSvg: SVGElement;
  FloorplanId: number;
  private elementCache: { [key: string]: SVGElement } = {};

  constructor(floorId: number, floorSvgElement: SVGElement, floorplanId: number) {
    this.FloorId = floorId;
    this.FloorSvg = floorSvgElement;
    this.FloorplanId = floorplanId;
  }

  getOptionArtElement(instanceId: string): SVGElement {
    let lotArt: any;
    const cacheName = instanceId + '-art';
    lotArt = this.elementCache[cacheName];
    if (!lotArt) {
      lotArt = this.FloorSvg.querySelector(`#${instanceId}`);
      this.elementCache[cacheName] = lotArt;
    }
    return lotArt;
  }

  getDefsElement(): SVGElement {
    let defs: any;
    const cacheName = 'defs';
    defs = this.elementCache[cacheName];
    if (!defs) {
      defs = this.FloorSvg.querySelector('defs');
      this.elementCache[cacheName] = defs;
    }
    return defs;
  }

  getUseSourceElements(layer: SVGElement): SVGElement[] {
    const useSources: SVGElement[] = [];
    const defs = this.getDefsElement();
    if (!defs) return;
    const useElems = layer.querySelectorAll('use');
    useElems?.forEach((elem: SVGUseElement) => {
      if (elem.href && elem.href.baseVal) {
        // lookup source USE element in the DEFS section of SVG
        let use = defs.querySelector(elem.href.baseVal) as SVGElement;
        // UGH -- HACK!... some ActionSets reference art on a different floor... true fix will require higher level refactor
        if (!use) use = document.querySelector(elem.href.baseVal);
        if (use) useSources.push(use);
      }
    });
    return useSources;
  }
}

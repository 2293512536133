import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import { enterLeaveFadeAnimation } from '../../global/animations';
import { ToastService } from '../../global/toast/toast.service';
import { sortByName } from '../../utility/helpers/sorters';
import { FloorplanVM } from '../../utility/models/floorplan-vm';
import { ThemeService } from '../../utility/services/theme.service';
import { FloorplanFilters, IHFilterType } from '../floorplan-filters/floorplan-filters';

@Component({
  selector: 'floorplan-filters',
  templateUrl: './floorplan-filters.component.html',
  styleUrls: ['./floorplan-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterLeaveFadeAnimation()]
})
export class FloorplanFiltersComponent implements OnChanges {
  @Input() floorplans: Array<FloorplanVM> = [];
  @Input() displayCounter: string;
  @Input() filters: FloorplanFilters;
  @Input() showInventoryHomes = false;
  @Input() isEnabled: boolean;
  @Output() isEnabledChange = new EventEmitter<boolean>();
  @Output() filtersChanged = new EventEmitter<FloorplanFilters>();
  isOpen = true;

  inventoryHomeFilterValues: Map<IHFilterType, string> = new Map<IHFilterType, string>([
    [IHFilterType.Show, 'Show'],
    [IHFilterType.ShowOnly, 'Show Only'],
    [IHFilterType.Hide, 'Hide']
  ]);

  get selectedFloorplan(): FloorplanVM {
    return this.floorplans.find(
      floorplan => floorplan.FloorplanId === this.filters.SelectedFloorplanId
    );
  }

  constructor(
    private cdr: ChangeDetectorRef,
    public theme: ThemeService,
    private toaster: ToastService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.floorplans) {
      this.floorplans = this.floorplans.sort(sortByName);
      // this seems to run more than I expected on the FP list page... needed for ISM when FP selected and switch hoods
      if (this.filters.IsFloorplanFilterSet && !this.selectedFloorplan) {
        this.filters.clearSelectedFloorplan();
        this.handleFilterChange();
      }
    }
  }

  toggleOpen(open: boolean) {
    this.isOpen = open;
    this.cdr.detectChanges();
  }

  handleFiltersEnabledToggle(isFiltersEnabled: boolean) {
    this.isEnabledChange.emit(isFiltersEnabled);
    this.filtersChanged.emit(this.filters);
    this.isEnabled = isFiltersEnabled;
  }

  handleFilterChange() {
    if (this.filters.IsAnyFilterSet) {
      this.isEnabledChange.emit(true);
    } else {
      this.isEnabledChange.emit(false);
    }
    this.filtersChanged.emit(this.filters);
  }

  handleFilterByInventoryHome() {
    if (this.filters.IsInventoryHomeFilterSet) {
      this.isEnabledChange.emit(true);
    }
    this.handleFilterChange();
  }

  handleReset() {
    this.toaster
      .showConfirm(
        'Are you sure?',
        'You are about to remove all filters. This action cannot be undone.'
      )
      .then((shouldContinue: boolean) => {
        if (!shouldContinue) return;
        this.filters.resetFilterState();
        this.handleFilterChange();
      });
  }

  trackByFloorPlanId(index: number, floorplan: FloorplanVM) {
    return floorplan.FloorplanId;
  }
}

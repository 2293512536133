import { animate, state, style, transition, trigger } from '@angular/animations';

export const enterLeaveFadeAnimation = (
  enterAnimationTimings: number | string = 250,
  leaveAnimationTimings: number | string = 250
) =>
  trigger('enterLeaveFadeAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate(enterAnimationTimings)]),
    transition(':leave', animate(leaveAnimationTimings, style({ opacity: 0 })))
  ]);

export const enterLeaveFadeAndSlideAnimation = (
  enterAnimationTimings: number | string = 250,
  leaveAnimationTimings: number | string = 250
) =>
  trigger('enterLeaveFadeAndSlideAnimation', [
    transition(
      ':enter',
      // animate(enterAnimationTimings, style({ opacity: 0, transform: 'translateY(-50%)' }))
      [style({ opacity: 0, transform: 'translateY(-50%)' }), animate(enterAnimationTimings)]
    ),
    transition(
      ':leave',
      animate(leaveAnimationTimings, style({ opacity: 0, transform: 'translateY(-50%)' }))
    )
  ]);

export const enterLeaveScaleAnimation = (animationSpeed: number = 300) =>
  trigger('enterLeaveScaleAnimation', [
    state('in', style({ transform: 'scale(1)' })),
    transition(':enter', [style({ transform: 'scale(0)' }), animate(animationSpeed)]),
    transition(':leave', [animate(animationSpeed, style({ transform: 'scale(0)' }))])
  ]);

export enum AnimationState {
  Expanded = 'expanded',
  Collapsed = 'collapsed',
  Visible = 'visible',
  Hidden = 'hidden'
}
export const heightExpandCollapseAnimation = (animationSpeed: number = 200) =>
  trigger('heightExpandCollapse', [
    state(
      AnimationState.Expanded,
      style({
        height: '*'
      })
    ),
    state(
      AnimationState.Collapsed,
      style({
        height: '0',
        overflow: 'hidden'
      })
    ),
    transition(
      `${AnimationState.Expanded} <=> ${AnimationState.Collapsed}`,
      animate(`${animationSpeed}ms ease-in-out`)
    )
  ]);

export const visibilityScaleAnimation = (animationSpeed: number = 300) =>
  trigger('visibilityScaleAnimation', [
    state(
      AnimationState.Visible,
      style({
        transform: 'scale(1)'
      })
    ),
    state(
      AnimationState.Hidden,
      style({
        transform: 'scale(0)'
      })
    ),
    transition(
      `${AnimationState.Visible} <=> ${AnimationState.Hidden}`,
      animate(`${animationSpeed}ms ease-in-out`)
    )
  ]);

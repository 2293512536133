import { LotVM } from '../../site-map/models/lot-vm';
import { Guid } from '../../utility/helpers/guid';
import { EffectiveFloorplan } from '../../utility/models/effective-floorplan';
import { OptionVM } from '../../utility/models/option-vm';
import { ISessionBuild, ISessionBuildItem } from './hc-session-interfaces';

import { IActionSet, IElevation, IHomeConfiguratorSession } from '@ml/common';

export enum HcSessionItemType {
  FloorPlan,
  FloorPlanOption,
  Exterior,
  Interior,
  Lot
}

export class HcSessionItem {
  Type: HcSessionItemType;
  EntityId: number | string;
  ClientUniqueId: string;
  ParentEntityId: number;
  Name: string;
  Price = 0;
  Options: HcSessionItem[] = [];

  constructor(data?: HcSessionItem) {
    if (data) {
      Object.assign(this, data);
      this.Options = data.Options.map(x => new HcSessionItem(x));
    }
  }

  getDTO(): ISessionBuildItem {
    return {
      Id: this.EntityId,
      ClientUniqueId: this.ClientUniqueId,
      Name: this.Name,
      Price: this.Price,
      Options: this.Options.map(x => x.getDTO())
    };
  }

  getTotalPrice() {
    return this.Price + this.Options.reduce((sum, opt) => sum + opt.Price, 0);
  }

  static CreateFromFloorPlan(fp: EffectiveFloorplan) {
    const item = new HcSessionItem();
    item.Type = HcSessionItemType.FloorPlan;
    item.EntityId = fp.FloorplanId;
    item.ClientUniqueId = fp.ClientUniqueIdentifier;
    item.ParentEntityId = fp.NeighborhoodId;
    item.Name = fp.Name;
    item.Price = fp.Price;
    return item;
  }

  static CreateFromFpOption(option: OptionVM | IActionSet) {
    const item = new HcSessionItem();
    item.Type = HcSessionItemType.FloorPlanOption;
    item.EntityId = option.ActionSetId;
    item.ClientUniqueId = option.ClientUniqueIdentifier;
    item.ParentEntityId = option.FloorId;
    item.Name = option.Name;
    item.Price = option.Price;
    return item;
  }

  static CreateFromElevation(elevation: IElevation) {
    const item = new HcSessionItem();
    item.Type = HcSessionItemType.Exterior;
    item.EntityId = elevation.ElevationId;
    item.ClientUniqueId = elevation.ClientUniqueIdentifier;
    item.ParentEntityId = elevation.FloorplanId;
    item.Name = elevation.Title;
    item.Price = elevation.Cost;
    return item;
  }

  static CreateFromLot(lot: LotVM) {
    const item = new HcSessionItem();
    item.Type = HcSessionItemType.Lot;
    item.EntityId = lot.LotId;
    item.ClientUniqueId = lot.ClientUniqueIdentifier;
    item.ParentEntityId = lot.NeighborhoodId;
    item.Name = lot.Title;
    item.Price = lot.TotalCost || lot.Cost || 0;

    if (lot.Premium) {
      const premium = new HcSessionItem();
      premium.Name = 'Premium';
      premium.Price = lot.Premium;
      item.Options.push(premium);
    }

    if (lot.OptionsPremium) {
      const optPremium = new HcSessionItem();
      optPremium.Name = 'Options Premium';
      optPremium.Price = lot.OptionsPremium;
      item.Options.push(optPremium);
    }

    return item;
  }
}

export class HcSessionBuild {
  Id: string = Guid.Generate();
  Nickname: string;
  LastModifiedOn: Date;
  FloorPlan: HcSessionItem;
  Exterior: HcSessionItem;
  Interior: HcSessionItem;
  Lot: HcSessionItem;
  InventoryHomeId: number;

  constructor(data?: HcSessionBuild) {
    if (data) {
      Object.assign(this, data);

      // ensure actual instances of HcSessionItem class
      if (this.FloorPlan) this.FloorPlan = new HcSessionItem(this.FloorPlan);
      if (this.Lot) this.Lot = new HcSessionItem(this.Lot);
      if (this.Exterior) this.Exterior = new HcSessionItem(this.Exterior);
      if (this.Interior) this.Interior = new HcSessionItem(this.Interior);
    }

    if (!this.LastModifiedOn) this.LastModifiedOn = new Date();
  }

  updateItem(item: HcSessionItem, type: HcSessionItemType) {
    this.LastModifiedOn = new Date();
    switch (type) {
      case HcSessionItemType.FloorPlan:
        this.FloorPlan = item;
        break;
      case HcSessionItemType.Lot:
        this.Lot = item;
        break;
      case HcSessionItemType.Exterior:
        this.Exterior = item;
        break;
      case HcSessionItemType.Interior:
        this.Interior = item;
        break;
    }
  }

  getDTO(): ISessionBuild {
    return {
      Id: this.Id,
      LastModifiedOn: this.LastModifiedOn,
      FloorPlan: this.FloorPlan?.getDTO(),
      Lot: this.Lot?.getDTO(),
      Interior: this.Interior?.getDTO(),
      Exterior: this.Exterior?.getDTO(),
      GrandTotal: this.getTotalPrice()
    };
  }

  getTotalPrice() {
    return (
      (this.FloorPlan?.getTotalPrice() ?? 0) +
      (this.Exterior?.getTotalPrice() ?? 0) +
      (this.Interior?.getTotalPrice() ?? 0) +
      (this.Lot?.getTotalPrice() ?? 0)
    );
  }
}

export class HcSession {
  HomeConfiguratorSessionId: number;
  CommunityId: number;
  Builds: HcSessionBuild[] = [];
  SelectedBuildId: string;
  LoanTerms = new HcMortgageCalcTerms();

  constructor(data?: HcSession) {
    if (data) {
      Object.assign(this, data);

      this.Builds = this.Builds.map(x => new HcSessionBuild(x));
    }
  }

  static CreateFromInterface(hcs: IHomeConfiguratorSession) {
    const session = new HcSession();
    session.HomeConfiguratorSessionId = hcs.HomeConfiguratorSessionId;
    session.CommunityId = hcs.CommunityId;
    const parsed = JSON.parse(hcs.ContentJSON) as IHcSesionContent;
    session.Builds = parsed.Builds.map(x => new HcSessionBuild(x));
    session.LoanTerms = new HcMortgageCalcTerms(parsed.LoanTerms);
    session.SelectedBuildId = parsed.SelectedBuildId;
    return session;
  }

  getSelectedBuild() {
    let build: HcSessionBuild;
    if (this.SelectedBuildId) build = this.Builds.find(x => x.Id === this.SelectedBuildId);
    if (build) return build;

    build = this.Builds.length ? this.Builds[0] : new HcSessionBuild();
    this.SelectedBuildId = build.Id;
    return build;
  }

  setBuild(build: HcSessionBuild, makeSelected = false) {
    const i = this.Builds.findIndex(x => x.Id === build.Id);
    if (i > -1) this.Builds[i] = build;
    else this.Builds.push(build);

    if (makeSelected) this.SelectedBuildId = build.Id;
  }

  discardBuild(id: string) {
    this.Builds = this.Builds.filter(x => x.Id !== id);
    if (this.SelectedBuildId === id) this.SelectedBuildId = null;
  }

  exportForApi(): IHomeConfiguratorSession {
    return {
      HomeConfiguratorSessionId: this.HomeConfiguratorSessionId,
      CommunityId: this.CommunityId,
      ContentJSON: JSON.stringify({
        Builds: this.Builds,
        LoanTerms: this.LoanTerms,
        SelectedBuildId: this.SelectedBuildId
      } as IHcSesionContent)
    };
  }
}

interface IHcSesionContent {
  Builds: HcSessionBuild[];
  LoanTerms: HcMortgageCalcTerms;
  SelectedBuildId: string;
}

export enum HcMortgageCalcLoanLengthTerms {
  Fifteen = 15,
  Thirty = 30
}
export class HcMortgageCalcTerms {
  TotalPrice?: number;
  DownPayPercent = 20;
  InterestRate = 3;
  Years = HcMortgageCalcLoanLengthTerms.Thirty;

  constructor(terms?: HcMortgageCalcTerms) {
    if (terms) {
      this.TotalPrice = terms.TotalPrice;
      this.DownPayPercent = terms.DownPayPercent;
      this.InterestRate = terms.InterestRate;
      this.Years = terms.Years;
    }
  }
}

import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { pluralizeString } from '../utility/helpers/helpers';
import { SettingsService } from '../utility/services/settings.service';

@Pipe({
  name: 'specsplural'
})
export class SpecsPluralPipe implements PipeTransform {
  nonPluralSettings = ['SpecGarageTypeLabel', 'SpecSqFtLabel', 'SpecOutdoorSqFtLabel'];
  constructor(private settingsService: SettingsService) {}

  transform(value: number, settingName: string, wrapValueInHtml?: boolean): string {
    const nameLabel: string = this.settingsService.get(settingName);
    value = value ?? 0;

    let formattedValue = formatNumber(value, 'en-US', '1.0-2');
    if (wrapValueInHtml) formattedValue = `<b>${formattedValue}</b>`;

    // excluding CarGarage and values with square feet
    if (this.nonPluralSettings.includes(settingName)) return `${formattedValue} ${nameLabel}`;

    if (value === 0 || value > 1) return `${formattedValue} ${pluralizeString(nameLabel)}`;
    else return `${formattedValue} ${nameLabel}`;
  }

  getPluralizedLabel(settingName: string) {
    const label: string = this.settingsService.get(settingName);
    if (this.nonPluralSettings.includes(settingName)) return label;
    else return pluralizeString(label);
  }
}

<accordion [openByDefault]="openByDefault" [allowCollapse]="allowCollapse" [ariaLabel]="floorPlanLabel + card.Name">
  <div header>
    <icon [name]="card.IsInventoryHome ? 'inventory_home' : 'floorplan'" [class.is-inventory-home]="card.IsInventoryHome"></icon>
    <h2>{{card.Name}}</h2>
    <favorite-btn [isActive]="card.IsFavorited" (toggle)="handleFavoriteToggle($event)"></favorite-btn>
  </div>
  <div body>
    <div *ngIf="showNeighborhoodName" class="neighborhood-label">
      <icon *ngIf="showNeighborhoodIcon" name="neighborhood"></icon>{{card.NeighborhoodName}}
    </div>
    <div class="card-content">
      <div class="navigation-buttons">
        <lot-selector *ngIf="card.AssociatedLots.length" [currentLot]="currentLot" [lots]="card.AssociatedLots"
                      [isInventoryHome]="card.IsInventoryHome" (selectLot)="selectLot.emit($event)" (selectAll)="selectAllLots.emit()"
                      [lotLabel]="lotLabel"></lot-selector>
        <icon-button *ngIf="!!staticMediaImages.length" class="view-static-gallery-button" icon="camera_frame"
                     (click)="handleGalleryImageModal()"></icon-button>
        <icon *ngIf="hasExternalMedia" name="walkthrough" class="external-media-icon"
              matTooltip="Select 'View Floorplan' to explore more media for this plan."></icon>
        <icon-button *ngIf="showIfpButton" class="view-floorplan-button" [ngClass]="{noMediaImages : !hasExternalMedia && !staticMediaImages.length }"
                     text="View {{floorPlanLabel}}" [showArrow]="true" (click)="handleNavigateToFloorplan()"></icon-button>
        <icon-button *ngIf="fullDetailsUrl" text="Full Details" (click)="handleClickFullDetails()"></icon-button>
      </div>

      <gallery (domChange)="handleDomChange()" [images]="galleryImages" [isInventoryHomeGallery]="card.IsInventoryHome" [useLetterbox]="useLetterbox"
               (favoriteToggle)="handleImageFavoriteToggle($event)">
      </gallery>

      <div *ngIf="card.IsInventoryHome && !doneWithHiddenSvgs" class="hidden-svg-loader">
        <div *ngFor="let floor of card.Floors" class="transform-receptor" [transformApplier]="graphicsModel">
          <div class="svg-container" [svgInjector]="floor.FullSvgUrl" (svgLoad)="onFloorLoad(floor, $event)">
          </div>
        </div>
      </div>

      <floorplan-features *ngIf="showFloorplanFeatures" [floors]="card.Floors" [featuresSettingValue]="featuresSettingValue"
                          [description]="card.Description" [infoDescriptionTitle]="infoDescriptionTitle"
                          [isInventoryHome]="card.IsInventoryHome"></floorplan-features>
      <floorplan-specs [fp]="card" [forceNonCollapsible]="forceNonCollapsibleSpecs"></floorplan-specs>
    </div>
  </div>
</accordion>
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[ScrollIntoView]'
})
export class ScrollIntoViewDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('click', ['$event.target'])
  scrollIntoView() {
    this.elementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
